/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  Link, useNavigate, useParams
} from 'react-router-dom';
import gsap from 'gsap';
import { AuthContext } from '../context/AuthContext';
import { arrayUnion, doc, getDoc, increment, onSnapshot, serverTimestamp, setDoc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';
import moment from 'moment';

const Chat = (props) => {
  const { currentUser } = useContext(AuthContext);
  const [bottomBarVisible, setBottomBarVisible] = useState(window.screen.width);

  useEffect(() => {
    const interval = setInterval(() => {
      setBottomBarVisible(window.screen.width)
    }, 100);
    return () => clearInterval(interval);
  }, []);

  // eslint-disable-next-line no-unused-vars
  const [value, setValue] = useState(3);
  const ChatRef = useRef(null);
  const msgRef = useRef(null);
  const navigate = useNavigate();
  const messagesEndRef = useRef(null);
  const [store, setStore] = useState(null);
  const [otherUser, setOtherUser] = useState(null);
  const [text, setText] = useState('');
  const [messages, setMessages] = useState([]);
  const { ChatID } = useParams();

  useEffect(() => {
    if (props.ViewChat === "Clicked") {
      ChatRef.current = gsap.fromTo(".content-section-messaging", {
        x: "100%",
      },
        {
          x: 0,
          duration: 0.5,
          ease: "power2.inOut"
        });
    }
  }, [props.ViewChat]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    const unsub = onSnapshot(doc(db, "Users", ChatID.replace(currentUser.uid, '')), (doc) => {
      setOtherUser(doc.data());
      doc.data().StoreAdded === true && props.Shops.length > 0 && setStore(props.Shops.filter(shop => (shop.ownerID === doc.data().uid))[0])
    });

    return () => {
      unsub();
    };
  }, [currentUser, ChatID, props.Shops])

  const MessageCalling = () => {
    const unsub = onSnapshot(doc(db, "Messages", ChatID), (doc) => {
      setMessages(doc.data().Messages);
    });
    return () => {
      unsub();
    };
  }

  useEffect(() => {
    const getMessages = async () => {
      const res = await getDoc(doc(db, "Messages", ChatID));
      if (!res.exists()) {
        setMessages([])
      } else {
        const unsub = onSnapshot(doc(db, "Messages", ChatID), (doc) => {
          setMessages(doc.data().Messages);
        });
        return () => {
          unsub();
        };
      }
    }
    getMessages();
  }, [ChatID]);

  useEffect(() => {
    const MessageRead = async () => {
      await updateDoc(doc(db, "Inbox", currentUser.uid), {
        [ChatID + ".Unread"]: 0,
      });
    }
    currentUser !== null && currentUser.uid && messages.length > 0 && MessageRead();
  }, [currentUser, messages]);

  const SendMessage = async () => {
    const currentTime = moment().format('hh:mm:ss DD MMMM YYYY');
    const msgTime = moment().format('hh:mm A');
    const currentTimeStamp = moment().format('x');
    const res = await getDoc(doc(db, "Messages", ChatID));
    if (!res.exists()) {
      await updateDoc(doc(db, "Inbox", currentUser.uid), {
        [ChatID + ".userInfo"]: {
          uid: otherUser.uid,
          displayName: otherUser.displayName,
          photoURL: otherUser.photoURL,
          chatId: ChatID,
        },
        [ChatID + ".LastMessage"]: {
          text: text,
          time: currentTime,
          msgTime: msgTime,
          timestamp: currentTimeStamp,
          userID: currentUser.uid,
        },
        [ChatID + ".Unread"]: 0,
        [ChatID + ".TimeStamp"]: serverTimestamp(),
        [ChatID + ".Date"]: currentTime,
        [ChatID + ".MessageTime"]: msgTime,
        [ChatID + ".Time"]: currentTimeStamp,
      });
      await updateDoc(doc(db, "Inbox", otherUser.uid), {
        [ChatID + ".userInfo"]: {
          uid: currentUser.uid,
          displayName: currentUser.displayName,
          photoURL: currentUser.photoURL,
          chatId: ChatID,
        },
        [ChatID + ".LastMessage"]: {
          text: text,
          time: currentTime,
          msgTime: msgTime,
          timestamp: currentTimeStamp,
          userID: currentUser.uid,
        },
        [ChatID + ".Unread"]: 1,
        [ChatID + ".TimeStamp"]: serverTimestamp(),
        [ChatID + ".Date"]: currentTime,
        [ChatID + ".MessageTime"]: msgTime,
        [ChatID + ".Time"]: currentTimeStamp,
      });
      await setDoc(doc(db, "Messages", ChatID), {
        Messages: arrayUnion({
          userPFP: currentUser.photoURL,
          userName: currentUser.displayName,
          userUID: currentUser.uid,
          text: text,
          time: currentTime,
          msgTime: msgTime,
          timestamp: currentTimeStamp,
        }),
      });
      MessageCalling();
      setText('');
    } else {
      await updateDoc(doc(db, "Inbox", currentUser.uid), {
        [ChatID + ".LastMessage"]: {
          text: text,
          time: currentTime,
          msgTime: msgTime,
          timestamp: currentTimeStamp,
          userID: currentUser.uid,
        },
        [ChatID + ".Unread"]: 0,
        [ChatID + ".TimeStamp"]: serverTimestamp(),
        [ChatID + ".Date"]: currentTime,
        [ChatID + ".MessageTime"]: msgTime,
        [ChatID + ".Time"]: currentTimeStamp,
      });
      await updateDoc(doc(db, "Inbox", otherUser.uid), {
        [ChatID + ".LastMessage"]: {
          text: text,
          time: currentTime,
          msgTime: msgTime,
          timestamp: currentTimeStamp,
          userID: currentUser.uid,
        },
        [ChatID + ".Unread"]: increment(1),
        [ChatID + ".TimeStamp"]: serverTimestamp(),
        [ChatID + ".Date"]: currentTime,
        [ChatID + ".MessageTime"]: msgTime,
        [ChatID + ".Time"]: currentTimeStamp,
      });
      await updateDoc(doc(db, "Messages", ChatID), {
        Messages: arrayUnion({
          userPFP: currentUser.photoURL,
          userName: currentUser.displayName,
          userUID: currentUser.uid,
          text: text,
          time: currentTime,
          msgTime: msgTime,
          timestamp: currentTimeStamp,
        })
      });
      setText('');
    }
  }

  const TimeDifference = () => {
    const PreviousTime = moment(otherUser.LastSeen, 'hh:mm:ss DD MMMM YYYY');
    const currentTime = moment();
    const diff = moment.duration(currentTime.diff(PreviousTime));
    // return diff['_data'].minutes <= 1 ? 'Active 1m ago' : diff['_data'].minutes > 1 ? `Active ${diff['_data'].minutes}m ago` : diff['_data'].hours >= 1;
    if (diff['_data'].days === 0) {
      if (diff['_data'].hours === 0) {
        if (diff['_data'].minutes === 0) {
          return `Active ${diff['_data'].seconds} sec ago`
        } else if (diff['_data'].minutes === 1) {
          return 'Active 1 min ago'
        } else {
          return `Active ${diff['_data'].minutes} mins ago`
        }
      } else if (diff['_data'].hours === 1) {
        return 'Active 1 hour ago'
      } else {
        return `Active ${diff['_data'].hours} hours ago`
      }
    } else if (diff['_data'].days === 1) {
      return 'Active 1 day ago'
    } else {
      return `Active ${diff['_data'].days} days ago`
    }
  }

  const letters = /^\s/

  const handleKey = async (e) => {
    e.code === "Enter" && e.preventDefault();
    if (letters.test(text) !== true) {
      e.code === "Enter" && SendMessage();
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, []);

  useEffect(() => {
    scrollToBottom();
    if (messages.length > 0 && msgRef !== null) {
      msgRef.current.className.includes('flex-row-reverse') === false ? gsap.fromTo(msgRef.current, { x: '-100%' }, { duration: 1, x: '0%' }) : gsap.fromTo(msgRef.current, { x: '100%' }, { duration: 1, x: '0%' })
    }
  }, [messages]);

  useEffect(() => {
    gsap.fromTo(".content-wrapper-messaging", { opacity: 0 }, { opacity: 1, duration: 2 });
    props.ViewChats('');
  }, []);

  useEffect(() => {
    if (props.UserData !== null) {
      if (currentUser && props.UserData !== null && props.UserData.NewUser === true) {
        navigate('/Onboarding');
      }
    }
  }, [currentUser, props.UserData])

  return (
    <>
      <div className="app">
        <div className="wrapper">
          <div className="left-side">
            <div className="side-wrapper flex h-[100%]">
              <div className="side-menu flex flex-col justify-evenly h-full">
                <Link to="/" className="flex flex-row items-center gap-2 w-full">
                  <img src="https://cdn-icons-png.freepik.com/512/4194/4194676.png" alt="Home Icon" style={{ height: 28, width: 28 }} />
                  <h2 className="font-extrabold text-lg">Home</h2>
                </Link>
                <Link to="/Explore" className="flex flex-row items-center gap-2 w-full">
                  <img src="https://cdn-icons-png.freepik.com/512/6337/6337301.png" alt="Explore Icon" style={{ height: 28, width: 28 }} />
                  <h2 className="font-extrabold text-lg">Feed</h2>
                </Link>
                <Link to="/Sell" className="flex flex-row items-center gap-2 w-full">
                  <img src="https://cdn-icons-png.freepik.com/512/10337/10337579.png" alt="Sell Icon" style={{ height: 28, width: 28 }} />
                  <h2 className="font-extrabold text-lg">Sell</h2>
                </Link>
                <Link to="/Chats" className="flex flex-row items-center gap-2 w-full">
                  <img src="https://cdn-icons-png.freepik.com/512/1041/1041916.png" alt="Chat Icon" style={{ height: 28, width: 28 }} />
                  <h2 className="font-extrabold text-lg">Chat</h2>
                </Link>
                <Link to="/Profile" className="flex flex-row items-center gap-2 w-full">
                  {currentUser ? <img src={currentUser.photoURL} alt="Profile Picture" style={{ height: 28, width: 28, borderRadius: '100%' }} /> : <img src="https://cdn-icons-png.freepik.com/512/1177/1177568.png" alt="Profile Picture" style={{ height: 28, width: 28 }} />}
                  <h2 className="font-extrabold text-lg">Profile</h2>
                </Link>
              </div>
            </div>
          </div>
          <div className="main-container">
            <div className="content-wrapper-messaging overflow-hidden">
              <div className="content-section-messaging rounded-2xl flex flex-col h-full w-full overflow-hidden" ref={ChatRef}>
                <div className="top-chat-header w-full flex items-center justify-between min-h-16 p-2 rounded-tl-2xl rounded-tr-2xl border-b-2 border-slate-800" style={{ backgroundColor: 'var(--search-bg)' }}>
                  <div className="leftSideChatHeader flex items-center flex-row gap-5">
                    <img src="https://cdn-icons-png.freepik.com/512/13526/13526096.png" alt="Back Icon" className='h-5 w-5 cursor-pointer' onClick={() => navigate(-1)} />
                    <img src={otherUser !== null && otherUser.photoURL} alt="Profile Pic" className='h-10 w-10 cursor-pointer rounded-full' />
                    <div className="userDetailsChatHeader flex flex-col">
                      <div className="nameOfUser font-extrabold">{otherUser !== null && otherUser.displayName}</div>
                      {otherUser !== null && <div className="lastLoginOfUser">{otherUser !== null && otherUser.Status === 'Active Now' ? 'Active Now' : TimeDifference()}</div>}
                    </div>
                  </div>
                  {otherUser !== null && otherUser.StoreAdded === true &&
                    <div className="rightSideChatHeader flex items-center">
                      {otherUser !== null && otherUser.StoreAdded === true && store !== null && <Link to={`tel:${store.number}`}><img src="https://cdn-icons-png.freepik.com/512/126/126523.png" alt="Phone Icon" className='h-5 w-5 cursor-pointer' /></Link>}
                    </div>}
                </div>
                <div className="chatMessagingSection w-full flex-grow flex flex-col hide-scrollbar overflow-x-hidden overflow-y-scroll" style={{ backgroundColor: 'var(--content-bg)' }}>
                  {messages.length > 0 ?
                    <>
                      <div className="h-max p-2 flex flex-col gap-5">
                        {messages.map((item, index) => (
                          <div key={index} ref={msgRef} className={`messages-chat w-full flex ${item.userUID === currentUser.uid ? 'flex-row-reverse' : 'flex-row'} gap-2 items-start`}>
                            <img src={item.userPFP} alt="" className="h-8 w-8 rounded-full" />
                            <div className="message-chat max-w-[71%] bg-white text-slate-800 rounded-md p-2">{item.text}</div>
                          </div>
                        ))}
                        <div ref={messagesEndRef} />
                      </div>
                    </>
                    :
                    <>
                      <div className="h-full w-full p-2 flex justify-center items-center font-extrabold text-2xl uppercase">
                        No messages yet...
                      </div>
                    </>
                  }
                </div>
                <div className="chatInputSection min-h-max w-full justify-center items-center flex flex-row p-2 gap-1">
                  <input type="text" placeholder="Type a message..." className="chatInputBar w-full bg-transparent border-none outline-none rounded-md" style={{ border: '2px solid rgba(113 119 144 / 25%)' }} value={text} onChange={(e) => setText(e.target.value)} onKeyPress={handleKey} />
                  {text.length >= 1 && <img src="https://cdn-icons-png.freepik.com/512/12299/12299444.png" alt="Send Icon" className='h-8 w-8 cursor-pointer' onClick={SendMessage} />}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="overlay-app" />
        {/* {bottomBarVisible <= 945 &&
          <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, backgroundColor: 'transparent' }} elevation={3}>
            <BottomNavigation
              className="backgroundColor: 'transparent',"
              showLabels
              value={-1}
              onChange={(event, newValue) => {
                setValue(newValue);
                newValue === 0 ? navigate('/') : newValue === 1 ? navigate('/Explore') : newValue === 2 ? navigate('/Sell') : newValue === 3 ? navigate('/Chats') : newValue === 4 ? navigate('/Profile') : navigate('/')
              }}
            >
              <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<HomeRoundedIcon />} label="Home" />
              <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<ExploreRoundedIcon />} label="Feed" />
              <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<AddCircleRoundedIcon />} label="Sell" />
              <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<QuestionAnswerRoundedIcon />} label="Chat" />
              {currentUser ? <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<img src={currentUser.photoURL} className='h-6 w-6 rounded-full' />} label="Profile" /> : <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<PersonRoundedIcon />} label="Profile" />}
            </BottomNavigation>
          </Paper>
        } */}
      </div>
    </>
  )
}

export default Chat