/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable react-hooks/exhaustive-deps */
import { BottomNavigation, BottomNavigationAction, Paper } from '@mui/material';
import React, { useContext, useEffect, useRef, useState } from 'react';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import SlideshowIcon from '@mui/icons-material/Slideshow';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import QuestionAnswerRoundedIcon from '@mui/icons-material/QuestionAnswerRounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import {
    Link, useNavigate,
} from 'react-router-dom';
import gsap from 'gsap';
import { AuthContext } from '../context/AuthContext';
import { FileInput, Label } from 'flowbite-react';
import swal from '@sweetalert/with-react';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { arrayUnion, doc, updateDoc } from 'firebase/firestore';
import { db, storage } from '../firebase';
import { v4 as uuid } from "uuid";
import LoadingBar from 'react-top-loading-bar';
import moment from 'moment';
import BackButton from '../components/BackButton';

const BannerAds = (props) => {
    const { currentUser } = useContext(AuthContext);
    const [bottomBarVisible, setBottomBarVisible] = useState(window.screen.width);
    const [files, setFiles] = useState([]);
    const [multipleFiles, setMultipleFiles] = useState([]);
    const [progresspercent, setProgressPercent] = useState();
    // eslint-disable-next-line no-unused-vars
    const [err, setErr] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const interval = setInterval(() => {
            setBottomBarVisible(window.screen.width)
        }, 100);
        return () => clearInterval(interval);
    }, []);

    // eslint-disable-next-line no-unused-vars
    const [value, setValue] = useState(4);
    const ProfileRef = useRef(null);
    const navigate = useNavigate();

    const HandleMultipleFileUpload = (event) => {
        const files = Array.from(event.target.files);
        setFiles(Array.from(event.target.files));
        const readers = files.map((file) => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => resolve(reader.result);
                reader.onerror = reject;
                reader.readAsDataURL(file);
            });
        });

        Promise.all(readers).then((values) => {
            setMultipleFiles(values);
        });
    }

    const SubmitBannerAds = () => {
        setLoading(true);
        // eslint-disable-next-line array-callback-return
        files.map(file => {
            const adsID = uuid();
            const currentTime = moment().format('hh:mm:ss DD MMMM YYYY');
            const currentTimeStamp = moment().format('x');
            const storageRef = ref(storage, "Ads/" + adsID);
            const uploadTask = uploadBytesResumable(storageRef, file);
            uploadTask.on(
                'state_changed',
                (snapshot) => {
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    const roundedprogress = Math.round(progress);
                    setProgressPercent(roundedprogress);
                },
                (error) => {
                    setLoading(false);
                    swal(error.code, '', 'error', {
                        buttons: {
                            confirm: {
                                text: "OK",
                                value: true,
                                visible: true,
                                className: "bg-[#8c52ff]",
                                closeModal: true
                            }
                        }
                    });
                },
                () => {
                    getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
                        await updateDoc(doc(db, "Banner", "Ads"), {
                            Ads: arrayUnion({
                                adsID,
                                ad: downloadURL,
                                storeID: props.UserData.storeID,
                                storeState: props.UserData.storeState,
                                storeCity: props.UserData.storeCity,
                                TimeCreated: currentTime,
                                TimeStamp: currentTimeStamp,
                            }),
                        });
                    }
                    );
                })
        })
        setLoading(false);
        setFiles([]);
        setMultipleFiles([]);
        swal('Banner Ads Added!!', '', 'success', {
            buttons: {
                confirm: {
                    text: "OK",
                    value: true,
                    visible: true,
                    className: "bg-[#8c52ff]",
                    closeModal: true
                }
            }
        });
        navigate('/Profile');
    }

    useEffect(() => {
        gsap.fromTo(".content-wrapper", { opacity: 0 }, { opacity: 1, duration: 2 });
    }, []);

    useEffect(() => {
        if (!currentUser) {
            navigate('/Auth')
        }
    }, [currentUser])

    useEffect(() => {
        if (props.UserData !== null) {
            if (currentUser && props.UserData !== null && props.UserData.NewUser === true) {
                navigate('/Onboarding');
            }
        }
    }, [currentUser, props.UserData])

    return (
        <>
            <div className="app">
                {loading && <LoadingBar color='linear-gradient(45deg,#ffbc00,#00d0ff ,#ff0058)' shadow={true} progress={progresspercent} onLoaderFinished={() => setProgressPercent(0)} height={4} style={{ "border-radius": "44px" }} />}
                <div className="wrapper">
                    <div className="left-side">
                        <div className="side-wrapper flex h-[100%]">
                            <div className="side-menu flex flex-col justify-evenly h-full">
                                <Link to="/" className="flex flex-row items-center gap-2 w-full">
                                    <img src="https://cdn-icons-png.freepik.com/512/4194/4194676.png" alt="Home Icon" style={{ height: 28, width: 28 }} />
                                    <h2 className="font-extrabold text-lg">Home</h2>
                                </Link>
                                <Link to="/Explore" className="flex flex-row items-center gap-2 w-full">
                                    <img src="https://cdn-icons-png.freepik.com/512/6337/6337301.png" alt="Explore Icon" style={{ height: 28, width: 28 }} />
                                    <h2 className="font-extrabold text-lg">Feed</h2>
                                </Link>
                                <Link to="/Sell" className="flex flex-row items-center gap-2 w-full">
                                    <img src="https://cdn-icons-png.freepik.com/512/10337/10337579.png" alt="Sell Icon" style={{ height: 28, width: 28 }} />
                                    <h2 className="font-extrabold text-lg">Sell</h2>
                                </Link>
                                <Link to="/Chats" className="flex flex-row items-center gap-2 w-full">
                                    <img src="https://cdn-icons-png.freepik.com/512/1041/1041916.png" alt="Chat Icon" style={{ height: 28, width: 28 }} />
                                    <h2 className="font-extrabold text-lg">Chat</h2>
                                </Link>
                                <Link to="/Profile" className="flex flex-row items-center gap-2 w-full">
                                    {currentUser ? <img src={currentUser.photoURL} alt="Profile Picture" style={{ height: 28, width: 28, borderRadius: '100%' }} /> : <img src="https://cdn-icons-png.freepik.com/512/1177/1177568.png" alt="Profile Picture" style={{ height: 28, width: 28 }} />}
                                    <h2 className="font-extrabold text-lg">Profile</h2>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="main-container">
                        <div className="content-wrapper">
                            {bottomBarVisible <= 945 && <BackButton />}
                            <div className={`${bottomBarVisible <= 945 ? 'mb-28' : ''} mt-2`}>
                                <div className="content-wrapper-header flex justify-center align-center items-center text-center">
                                    <h1 className='font-extrabold text-3xl'>BANNER ADS</h1>
                                </div>
                                <div className="content-section-profile mt-5" ref={ProfileRef}>
                                    {currentUser && props.UserData !== null && props.UserData.StoreAdded !== false &&
                                        <>
                                            <div className="bannerAdsSection">
                                                <div>
                                                    <Label htmlFor="multiple-file-upload" value="Upload multiple Banner Ads" className="uppercase text-xl" />
                                                </div>
                                                <FileInput id="multiple-file-upload" accept="image/jpg,image/jpeg,image/png" multiple className='mt-2' onChange={HandleMultipleFileUpload} />
                                            </div>
                                        </>
                                    }
                                </div>
                                <div class="content-section">
                                    <div class="apps-card gap-10">
                                        {multipleFiles.map(file => (
                                            <img src={file} alt="" className='h-80 w-80 rounded-2xl' />
                                        ))}
                                    </div>
                                </div>
                                <div class="content-section mb-14">
                                    <div className="SubmitBannerAdsBtn flex justify-center items-center w-full">
                                        {files.length > 0 ?
                                            <>
                                                {loading === false ?
                                                    <div className="SubmitBTNAds w-max pl-5 pr-5 pt-2 pb-2 bg-blue-500 text-white text-2xl font-extrabold rounded-xl cursor-pointer" onClick={SubmitBannerAds}>
                                                        SUBMIT
                                                    </div>
                                                    :
                                                    <div className="SubmitBTNAds w-max pl-5 pr-5 pt-2 pb-2 bg-blue-300 text-white text-2xl font-extrabold rounded-xl cursor-default">
                                                        SUBMITTING
                                                    </div>
                                                }
                                            </>
                                            :
                                            <div className="SubmitBTNAds w-max pl-5 pr-5 pt-2 pb-2 bg-blue-300 text-white text-2xl font-extrabold rounded-xl cursor-default">
                                                SUBMIT
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="overlay-app" />
            </div>
            {bottomBarVisible <= 945 &&
                <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, backgroundColor: 'transparent' }} elevation={3}>
                    <BottomNavigation
                        className="backgroundColor: 'transparent',"
                        showLabels
                        value={-1}
                        onChange={(event, newValue) => {
                            setValue(newValue);
                            newValue === 0 ? navigate('/') : newValue === 1 ? navigate('/Explore') : newValue === 2 ? navigate('/Sell') : newValue === 3 ? navigate('/Chats') : newValue === 4 ? navigate('/Profile') : navigate('/')
                        }}
                    >
                        <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<HomeRoundedIcon />} label="Home" />
                        <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<SlideshowIcon />} label="Shorts" />
                        <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<AddCircleRoundedIcon />} label="Sell" />
                        <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<QuestionAnswerRoundedIcon />} label="Chat" />
                        {currentUser ? <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<img src={currentUser.photoURL} className='h-6 w-6 rounded-full' />} label="Manage" /> : <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<PersonRoundedIcon />} label="Profile" />}
                    </BottomNavigation>
                </Paper>
            }
        </>
    )
}

export default BannerAds