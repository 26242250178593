/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Dialog, Divider, Drawer } from '@mui/material';
import React, { createRef, useContext, useEffect, useRef, useState } from 'react';
import {
    useNavigate,
    useParams,
} from 'react-router-dom';
import gsap from 'gsap';
import ReactPlayer from 'react-player/lazy';
import { AuthContext } from '../context/AuthContext';
import swal from '@sweetalert/with-react';
import { arrayRemove, arrayUnion, deleteDoc, doc, increment, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';
// import { TextWithSeeMore } from '../components/TextWithSeeMore';
import ScaleLoader from "react-spinners/ScaleLoader";

const Feed = (props) => {
    const { currentUser } = useContext(AuthContext);
    const [bottomBarVisible, setBottomBarVisible] = useState(window.screen.width);

    useEffect(() => {
        const interval = setInterval(() => {
            setBottomBarVisible(window.screen.width)
        }, 100);
        return () => clearInterval(interval);
    }, []);

    const [muted, setMuted] = useState(false);
    const navigate = useNavigate();
    const [likeLoading, setLikeLoading] = useState(false);
    const [unlikeLoading, setUnLikeLoading] = useState(false);
    const { FID } = useParams();
    // const [fullScreen, setFullScreen] = useState(false);

    // const exitFullScreen = () => {
    //     if (document.exitFullscreen) {
    //         document.exitFullscreen();
    //     } else if (document.webkitExitFullscreen) {
    //         document.webkitExitFullscreen();
    //     } else if (document.mozExitFullscreen) {
    //         document.mozExitFullscreen();
    //     } else if (document.msExitFullscreen) {
    //         document.msExitFullscreen();
    //     }
    // };

    // useEffect(() => {
    //     if (fullScreen) {
    //         const element = document.body;
    //         if (document.fullscreenEnabled) {
    //             element.requestFullscreen();
    //         } else if (document.webkitFullscreenEnabled) {
    //             element.webkitRequestFullscreen();
    //         } else if (document.mozFullScreenEnabled) {
    //             element.mozRequestFullScreen();
    //         } else if (document.msFullscreenEnabled) {
    //             element.msRequestFullscreen();
    //         }
    //     }
    // }, [fullScreen])

    useEffect(() => {
        gsap.fromTo(".reels-section", { opacity: 0 }, { opacity: 1, duration: 2 });
    }, []);

    // const urls = ['https://firebasestorage.googleapis.com/v0/b/kwizzr-257980.appspot.com/o/vid1.mp4?alt=media&token=3b38ce71-cc1b-4d42-9530-8e0e93453f3d', 'https://firebasestorage.googleapis.com/v0/b/kwizzr-257980.appspot.com/o/vid2.mp4?alt=media&token=ca1d26ac-86d3-46be-bc14-bfcb0e26d0d3', 'https://firebasestorage.googleapis.com/v0/b/kwizzr-257980.appspot.com/o/vid3.mp4?alt=media&token=5d44857e-1c8e-43eb-bbf6-72d3bf34100f', 'https://firebasestorage.googleapis.com/v0/b/kwizzr-257980.appspot.com/o/vid4.mp4?alt=media&token=52753caa-aff5-4446-af97-b4f5a2c3a9a3', 'https://firebasestorage.googleapis.com/v0/b/kwizzr-257980.appspot.com/o/vid5.mp4?alt=media&token=a37a008b-af1f-4d16-8c9b-dd9b1b05155f', 'https://firebasestorage.googleapis.com/v0/b/kwizzr-257980.appspot.com/o/vid6.mp4?alt=media&token=fc599adb-14ce-4146-b56f-9c8b92ad2b4f', 'https://firebasestorage.googleapis.com/v0/b/kwizzr-257980.appspot.com/o/vid7.mp4?alt=media&token=5361736b-c871-45c6-bd97-809b8d4e365c', 'https://firebasestorage.googleapis.com/v0/b/kwizzr-257980.appspot.com/o/vid8.mp4?alt=media&token=584801f7-0be8-42ad-8cb9-e1616550842f'];

    const [playing, setPlaying] = useState({});

    const [refs, setRefs] = useState([]);

    // useEffect(() => {
    //     setRefs((refs) => Array(props.VideosData.length).fill().map((_, i) => refs[i] || createRef()));
    // }, [props.VideosData, fullScreen]);

    useEffect(() => {
        setRefs((refs) => Array(props.VideosData.length).fill().map((_, i) => refs[i] || createRef()));
    }, [props.VideosData]);

    const onEnter = (id) => {
        setPlaying({ ...playing, [id]: true });
        // navigate(`/Explore/${id}`)
    };

    const onExit = (id) => {
        setPlaying({ ...playing, [id]: false });
    };

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                const id = entry.target.getAttribute('data-id');
                if (entry.isIntersecting) {
                    onEnter(id);
                } else {
                    onExit(id);
                }
            },
            {
                root: null,
                rootMargin: '0px',
                threshold: 0.9
            }
        );

        refs.forEach((ref, index) => {
            if (ref.current) {
                observer.observe(ref.current);
                // If this is the first video, manually trigger onEnter
                if (index === 0) {
                    onEnter(props.VideosData[0].videoID);
                }
            }
        });

        return () => {
            if (refs.length > 0) {
                refs.forEach((ref, index) => {
                    if (ref.current) {
                        observer.unobserve(ref.current);
                    }
                });
            }
        };
    }, [refs]);

    const ChatID = async (value) => {
        if (currentUser !== null) {
            const combinedId =
                currentUser.uid > value.Store.ownerID
                    ? currentUser.uid + value.Store.ownerID
                    : value.Store.ownerID + currentUser.uid;
            navigate(`/Chat/${combinedId}`);
        } else {
            swal('Please Login To Chat', '', 'warning', {
                buttons: {
                    confirm: {
                        text: "OK",
                        value: true,
                        visible: true,
                        className: "bg-[#8c52ff]",
                        closeModal: true
                    }
                }
            })
        }
    }

    const [state, setState] = useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const [stateShare, setStateShare] = useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const [open, setOpen] = useState(false);
    const [openShare, setOpenShare] = useState(false);
    // const [openCommentsSmall, setOpenCommentsSmall] = useState(false);
    // const [openCommentsLarge, setOpenCommentsLarge] = useState(false);
    const [currentReelData, setCurrentReelData] = useState(null);

    const SaveItem = async (val) => {
        await updateDoc(doc(db, "Users", currentUser.uid), {
            'Saved.Videos': arrayUnion(val)
        });
    }

    const UnSaveItem = async (val) => {
        await updateDoc(doc(db, "Users", currentUser.uid), {
            'Saved.Videos': arrayRemove(val)
        });
    }

    const toggleDrawer = (anchor, open) => {
        // if (
        //     event &&
        //     event.type === 'keydown' &&
        //     (event.key === 'Tab' || event.key === 'Shift')
        // ) {
        //     return;
        // }
        setState({ ...state, [anchor]: open });
    };

    const toggleDrawerShare = (anchorShare, openShare) => {
        // if (
        //     event &&
        //     event.type === 'keydown' &&
        //     (event.key === 'Tab' || event.key === 'Shift')
        // ) {
        //     return;
        // }
        setStateShare({ ...stateShare, [anchorShare]: openShare });
    };

    const list = (anchor) => (
        <Box
            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250, backgroundColor: 'var(--search-bg)' }}
            role="presentation"
            onClick={() => toggleDrawer(anchor, false)}
            onKeyDown={() => toggleDrawer(anchor, false)}
        >
            <div className="w-full">
                <div className="functions p-5 flex flex-row justify-between items-center">
                    {currentUser ?
                        <>
                            {currentReelData !== null && props.UserData !== null && props.UserData.Saved.Videos.includes(currentReelData.videoID) === false ?
                                <div className="saveUnSave cursor-pointer h-20 w-20 rounded-full flex justify-center items-center flex-col gap-1" onClick={() => SaveItem(currentReelData.videoID)}>
                                    <div className="border border-black flex justify-center items-center p-4 rounded-full">
                                        <img src="https://cdn-icons-png.freepik.com/512/5662/5662990.png" alt="Save Icon" className="h-10 w-10" />
                                    </div>
                                    <span>Save</span>
                                </div>
                                :
                                <div className="saveUnSave cursor-pointer h-20 w-20 rounded-full flex justify-center items-center flex-col gap-1" onClick={() => UnSaveItem(currentReelData.videoID)}>
                                    <div className="border border-black flex justify-center items-center p-4 rounded-full">
                                        <img src="https://cdn-icons-png.freepik.com/512/102/102279.png" alt="Save Icon" className="h-10 w-10" />
                                    </div>
                                    <span>Unsave</span>
                                </div>
                            }
                        </>
                        :
                        <>
                            <div className="saveUnSave cursor-pointer h-20 w-20 rounded-full flex justify-center items-center flex-col gap-1" onClick={() => swal('Login to save the video', '', 'warning')}>
                                <div className="border border-black flex justify-center items-center p-4 rounded-full">
                                    <img src="https://cdn-icons-png.freepik.com/512/5662/5662990.png" alt="Save Icon" className="h-10 w-10" />
                                </div>
                                <span>Save</span>
                            </div>
                        </>
                    }
                    {/* <div className="saveUnSave cursor-pointer h-20 w-20 rounded-full flex justify-center items-center flex-col gap-1" onClick={() => { swal('Link Copied', '', 'success'); navigator.clipboard.writeText(currentReelData.ad) }}>
                        <div className="border border-black flex justify-center items-center p-4 rounded-full">
                            <img src="https://cdn-icons-png.freepik.com/512/1230/1230216.png" alt="Share Icon" className="h-10 w-10" />
                        </div>
                        <span>Share</span>
                    </div> */}
                    {currentUser &&
                        <>
                            {currentReelData !== null && currentUser && currentUser.uid !== currentReelData.Store.ownerID &&
                                <div className="blockUnblock cursor-pointer h-20 w-20 rounded-full flex justify-center items-center flex-col gap-1" onClick={HandleVideoDelete}>
                                    <div className="border border-black flex justify-center items-center p-4 rounded-full">
                                        <img src="https://cdn-icons-png.freepik.com/512/484/484611.png" alt="Delete Icon" className="h-10 w-10" />
                                    </div>
                                    <span>Delete</span>
                                </div>
                            }
                        </>
                    }
                </div>
                <Divider />
                <div className="functions p-5 flex flex-row justify-between items-center">
                    <div className="app-card-buttons flex justify-center items-center text-center w-full">
                        {currentReelData !== null &&
                            <div className="productsShowOne2 flex flex-row w-full justify-between items-center text-center pt-2 pb-2 pl-5 pr-5 rounded-xl cursor-pointer" onClick={() => navigate(`/Product/${currentReelData.Store.Products[0].productID}`)}>
                                <img src={currentReelData.Store.Products[0].productImage[0]} alt="" className='h-14 w-14 rounded-lg' />
                                <span className='font-medium text-lg text-white'>{currentReelData.Store.Products[0].productName}</span>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </Box>
    );

    const VideoURL = `https://martpuram.com/Feed/${currentReelData !== null && currentReelData.videoID}`;

    const listShare = (anchor) => (
        <Box
            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250, backgroundColor: 'var(--search-bg)', height: '25vh' }}
            role="presentation"
            onClick={() => toggleDrawerShare(anchorShare, false)}
            onKeyDown={() => toggleDrawerShare(anchorShare, false)}
        >
            <div className="flex flex-col w-full h-full justify-evenly items-center pl-2 pr-2 pt-5 pb-5">
                <div className="w-full flex justify-center items-center font-black text-2xl">
                    SHARE
                </div>
                <div className="flex flex-row justify-evenly items-center w-full">
                    <img src="https://cdn-icons-png.freepik.com/512/9911/9911565.png" className="h-10 w-10 cursor-pointer" alt="Share Icon" onClick={() => { navigator.clipboard.writeText(VideoURL); swal('Link Copied', '', 'success') }} />
                    <img src="https://cdn-icons-png.freepik.com/512/5968/5968764.png" className="h-10 w-10 cursor-pointer" alt="Share Icon" onClick={() => window.open(`https://www.facebook.com/sharer/sharer.php?u=${VideoURL}`)} />
                    <img src="https://cdn-icons-png.freepik.com/512/4423/4423697.png" className="h-10 w-10 cursor-pointer" alt="Share Icon" onClick={() => window.open(`https://api.whatsapp.com/send?text=${VideoURL}`)} />
                    <img src="https://cdn-icons-png.freepik.com/512/2111/2111646.png" className="h-10 w-10 cursor-pointer" alt="Share Icon" onClick={() => window.open(`https://t.me/share/url?url=${VideoURL}&text=Watch this video on MartPuram`)} />
                    <img src="https://cdn-icons-png.freepik.com/512/5968/5968830.png" className="h-10 w-10 cursor-pointer" alt="Share Icon" onClick={() => window.open(`https://twitter.com/intent/tweet?url=${VideoURL}`)} />
                </div>
            </div>
        </Box>
    );

    const Like = async (val) => {
        if (currentUser) {
            setLikeLoading(true);
            await updateDoc(doc(db, "Users", currentUser.uid), {
                Liked: arrayUnion(val.videoID),
            });
            await updateDoc(doc(db, "Video", val.videoID), {
                LikesCount: increment(1),
                Likes: arrayUnion(currentUser.uid),
            });
            setLikeLoading(false);
        } else {
            swal({
                title: "Login",
                text: "Please Login To Like Video",
                icon: "warning",
                buttons: true,
                dangerMode: false,
            }).then((willGo) => {
                if (willGo) {
                    navigate('/Auth')
                }
            });
        }
    }

    const UnLike = async (val) => {
        if (currentUser) {
            setUnLikeLoading(true);
            props.UserData !== null && props.UserData.Liked.includes(val.videoID) === true && await updateDoc(doc(db, "Users", currentUser.uid), {
                Liked: arrayRemove(val.videoID),
            });
            val.LikesCount > 0 && val.Likes.includes(currentUser.uid) === true && await updateDoc(doc(db, "Video", val.videoID), {
                LikesCount: increment(-1),
                Likes: arrayRemove(currentUser.uid),
            });
            setUnLikeLoading(false);
        } else {
            swal('Login to unlike the video', '', 'warning')
        }
    }

    // const [comment, setComment] = useState('')

    // const handleChangeComment = (event) => {
    //     setComment(event.target.value)
    // }

    // const AddComment = async () => {
    //     if (currentUser) {
    //         await updateDoc(doc(db, "Video", currentReelData.videoID), {
    //             CommentCount: increment(1),
    //             Comments: arrayUnion({
    //                 userID: currentUser.uid,
    //                 userPFP: currentUser.photoURL,
    //                 userName: currentUser.displayName,
    //                 comment: comment,
    //             }),
    //         });
    //         setComment('');
    //     } else {
    //         swal('Login to add a Comment', '', 'warning')
    //     }
    // }

    // const DeleteComment = async (val) => {
    //     if (currentUser) {
    //         await updateDoc(doc(db, "Video", currentReelData.videoID), {
    //             CommentCount: props.VideosData.filter(item => (item.videoID === currentReelData.videoID))[0].CommentCount > 0 ? increment(-1) : increment(0),
    //             Comments: arrayRemove(val),
    //         });
    //     } else {
    //         swal('Login to add a Comment', '', 'warning')
    //     }
    // }

    const FollowUser = async (val) => {
        if (currentUser) {
            await updateDoc(doc(db, "Users", currentUser.uid), {
                Following: increment(1),
                FollowingUsers: arrayUnion(val.Store.ownerID),
            });
            await updateDoc(doc(db, "Users", val.Store.ownerID), {
                Followers: increment(1),
                FollowerUsers: arrayUnion(currentUser.uid),
            });
        } else {
            swal('Login to Follow', '', 'warning')
        }
    }

    const UnFollowUser = async (val) => {
        if (currentUser) {
            await updateDoc(doc(db, "Users", currentUser.uid), {
                Following: increment(-1),
                FollowingUsers: arrayRemove(val.Store.ownerID),
            });
            await updateDoc(doc(db, "Users", val.Store.ownerID), {
                Followers: increment(-1),
                FollowerUsers: arrayRemove(currentUser.uid),
            });
        } else {
            swal('Login to Follow', '', 'warning')
        }
    }

    const anchor = 'bottom';
    const anchorShare = 'bottom';

    const handleClickOpen = (val) => {
        setCurrentReelData(val)
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        // setCurrentReelData(null);
    };

    const handleClickOpenShare = (val) => {
        setCurrentReelData(val)
        setOpenShare(true);
    };

    const handleCloseShare = () => {
        setOpenShare(false);
        // setCurrentReelData(null);
    };

    // const handleClickOpenCommentsSmall = (val) => {
    //     setCurrentReelData(val)
    //     setOpenCommentsSmall(true);
    // };

    // const handleCloseCommentsSmall = () => {
    //     setOpenCommentsSmall(false);
    //     setComment('');
    //     setCurrentReelData(null);
    // };

    // const handleClickOpenCommentsLarge = (val) => {
    //     setCurrentReelData(val)
    //     setOpenCommentsLarge(true);
    // };

    // const handleCloseCommentsLarge = () => {
    //     setOpenCommentsLarge(false);
    //     setComment('');
    //     setCurrentReelData(null);
    // };

    const HandleVideoDelete = async () => {
        swal({
            title: "Delete Video Ad",
            text: "Are You Sure?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (willDelete) => {
            if (willDelete) {
                navigate('/');
                await deleteDoc(doc(db, "Video", currentReelData.videoID)).then(() => {
                    swal('Video Deleted', '', 'success')
                });
            }
        });
    };

    const dialogBox = () => (
        <div className="w-[340px]">
            <div className="functions p-5 flex flex-row justify-between items-center">
                {currentUser ?
                    <>
                        {currentReelData !== null && props.UserData !== null && props.UserData.Saved.Videos.includes(currentReelData.videoID) === false ?
                            <div className="saveUnSave cursor-pointer h-20 w-20 rounded-full flex justify-center items-center flex-col gap-1" onClick={() => SaveItem(currentReelData.videoID)}>
                                <div className="border border-slate-400 flex justify-center items-center p-4 rounded-full">
                                    <img src="https://cdn-icons-png.freepik.com/512/5662/5662990.png" alt="Save Icon" className="h-10 w-10" />
                                </div>
                                <span>Save</span>
                            </div>
                            :
                            <div className="saveUnSave cursor-pointer h-20 w-20 rounded-full flex justify-center items-center flex-col gap-1" onClick={() => UnSaveItem(currentReelData.videoID)}>
                                <div className="border border-slate-400 flex justify-center items-center p-4 rounded-full">
                                    <img src="https://cdn-icons-png.freepik.com/512/102/102279.png" alt="Save Icon" className="h-10 w-10" />
                                </div>
                                <span>Unsave</span>
                            </div>
                        }
                    </>
                    :
                    <>
                        <div className="saveUnSave cursor-pointer h-20 w-20 rounded-full flex justify-center items-center flex-col gap-1" onClick={() => swal('Login to save the video', '', 'warning')}>
                            <div className="border border-slate-400 flex justify-center items-center p-4 rounded-full">
                                <img src="https://cdn-icons-png.freepik.com/512/5662/5662990.png" alt="Save Icon" className="h-10 w-10" />
                            </div>
                            <span>Save</span>
                        </div>
                    </>
                }
                {/* <div className="saveUnSave cursor-pointer h-20 w-20 rounded-full flex justify-center items-center flex-col gap-1" onClick={() => { swal('Link Copied', '', 'success'); navigator.clipboard.writeText(currentReelData.ad) }}>
                    <div className="border border-slate-400 flex justify-center items-center p-4 rounded-full">
                        <img src="https://cdn-icons-png.freepik.com/512/1230/1230216.png" alt="Share Icon" className="h-10 w-10" />
                    </div>
                    <span>Share</span>
                </div> */}
                {currentUser &&
                    <>
                        {currentReelData !== null && currentUser && currentUser.uid !== currentReelData.Store.ownerID &&
                            <div className="blockUnblock cursor-pointer h-20 w-20 rounded-full flex justify-center items-center flex-col gap-1" onClick={HandleVideoDelete}>
                                <div className="border border-slate-400 flex justify-center items-center p-4 rounded-full">
                                    <img src="https://cdn-icons-png.freepik.com/512/484/484611.png" alt="Delete Icon" className="h-10 w-10" />
                                </div>
                                <span>Delete</span>
                            </div>
                        }
                    </>
                }
            </div>
            <Divider />
            <div className="functions p-5 flex flex-row justify-between items-center">
                <div className="app-card-buttons flex justify-center items-center text-center w-full">
                    {currentReelData !== null &&
                        <div className="productsShowOne flex flex-row w-full justify-between items-center text-center pt-2 pb-2 pl-5 pr-5 rounded-xl cursor-pointer" onClick={() => navigate(`/Product/${currentReelData.Store.Products[0].productID}`)}>
                            <img src={currentReelData.Store.Products[0].productImage[0]} alt="" className='h-14 w-14 rounded-lg' />
                            <span className='font-medium text-lg'>{currentReelData.Store.Products[0].productName}</span>
                        </div>
                    }
                </div>
            </div>
        </div>
    );

    const dialogBoxShare = () => (
        <div className="w-[340px] h-max">
            <div className="flex flex-col w-full h-full justify-between items-center p-2">
                <div className="w-full flex justify-center items-center font-black text-2xl">
                    SHARE
                </div>
                <div className="flex flex-row justify-evenly items-center w-full">
                    <img src="https://cdn-icons-png.freepik.com/512/9911/9911565.png" className="h-10 w-10 cursor-pointer" alt="Share Icon" onClick={() => { navigator.clipboard.writeText(VideoURL); swal('Link Copied', '', 'success') }} />
                    <img src="https://cdn-icons-png.freepik.com/512/5968/5968764.png" className="h-10 w-10 cursor-pointer" alt="Share Icon" onClick={() => window.open(`https://www.facebook.com/sharer/sharer.php?u=${VideoURL}`)} />
                    <img src="https://cdn-icons-png.freepik.com/512/4423/4423697.png" className="h-10 w-10 cursor-pointer" alt="Share Icon" onClick={() => window.open(`https://api.whatsapp.com/send?text=${VideoURL}`)} />
                    <img src="https://cdn-icons-png.freepik.com/512/2111/2111646.png" className="h-10 w-10 cursor-pointer" alt="Share Icon" onClick={() => window.open(`https://t.me/share/url?url=${VideoURL}&text=Watch this video on MartPuram`)} />
                    <img src="https://cdn-icons-png.freepik.com/512/5968/5968830.png" className="h-10 w-10 cursor-pointer" alt="Share Icon" onClick={() => window.open(`https://twitter.com/intent/tweet?url=${VideoURL}`)} />
                </div>
            </div>
        </div>
    );

    // const letters = /^\s/
    // const handleKey = async (e) => {
    //     e.code === "Enter" && e.preventDefault();
    //     if (letters.test(comment) !== true) {
    //         e.code === "Enter" && AddComment();
    //     }
    // };

    // const dialogBoxCommentsSmall = () => (
    //     <div className="w-[310px] overflow-hidden">
    //         <div className="p-2 flex justify-center items-center font-extrabold text-2xl">
    //             COMMENTS
    //         </div>
    //         <Divider />
    //         <div className="Cmts w-full p-2 h-80">
    //             {currentReelData !== null && props.VideosData.filter(item => (item.videoID === currentReelData.videoID))[0].Comments.length > 0 ?
    //                 <div className="CmtsMsg flex flex-col gap-2 h-full overflow-x-hidden overflow-y-auto">
    //                     {currentReelData !== null && props.VideosData.filter(item => (item.videoID === currentReelData.videoID))[0].Comments.map((item, index) => (
    //                         <div className="CommentMainContainer flex w-full flex-col">
    //                             <div key={index} className="UserComment flex w-full p-2 flex-row gap-2">
    //                                 <img src={item.userPFP} alt="" className="h-10 w-10 rounded-full" />
    //                                 <div className="NameAnf flex justify-start flex-col gap-1 items-center">
    //                                     <div className="UserName w-full font-extrabold">{item.userName}</div>
    //                                     <div className="UserName w-full">{<TextWithSeeMore text={item.comment} limit={25} />}</div>
    //                                 </div>
    //                             </div>
    //                             {currentUser && item.userID === currentUser.uid &&
    //                                 <div className="DeleteComment w-full text-xl text-red-500 font-bold flex justify-end items-center pr-2" onClick={() => DeleteComment(item)}>
    //                                     DELETE
    //                                 </div>
    //                             }
    //                         </div>
    //                     ))
    //                     }
    //                 </div>
    //                 :
    //                 <div className="CmtsMsg flex h-full w-full justify-center items-center text-center font-semibold">
    //                     No Comments Available. Be The First One To Add A Comment.
    //                 </div>
    //             }
    //         </div>
    //         <Divider />
    //         <div className="CmtsInput w-full p-2 h-20 flex flex-row gap-1 justify-center items-center">
    //             <TextField id="outlined-basic" className="w-full" label="Add A Comment" variant="filled" value={comment} onChange={handleChangeComment} onKeyPress={handleKey} />
    //             {comment.length > 0 && <span className="text-blue-600 font-bold cursor-pointer" onClick={AddComment}>SEND</span>}
    //         </div>
    //     </div>
    // );

    // const dialogBoxCommentsLarge = () => (
    //     <div className="w-[340px] overflow-hidden">
    //         <div className="p-2 flex justify-center items-center font-extrabold text-2xl">
    //             COMMENTS
    //         </div>
    //         <Divider />
    //         <div className="Cmts w-full p-2 h-80">
    //             {currentReelData !== null && props.VideosData.filter(item => (item.videoID === currentReelData.videoID))[0].Comments.length > 0 ?
    //                 <div className="CmtsMsg flex flex-col gap-2 h-full overflow-x-hidden overflow-y-auto">
    //                     {currentReelData !== null && props.VideosData.filter(item => (item.videoID === currentReelData.videoID))[0].Comments.map((item, index) => (
    //                         <div key={index} className="UserComment flex w-full p-2 flex-row gap-2">
    //                             <img src={item.userPFP} alt="" className="h-10 w-10 rounded-full" />
    //                             <div className="NameAnf flex justify-start flex-col gap-1 items-center">
    //                                 <div className="UserName w-full font-extrabold">{item.userName}</div>
    //                                 <div className="UserName w-full">{<TextWithSeeMore text={item.comment} limit={25} />}</div>
    //                             </div>
    //                         </div>
    //                     ))
    //                     }
    //                 </div>
    //                 :
    //                 <div className="CmtsMsg flex h-full w-full justify-center items-center text-center font-semibold">
    //                     No Comments Available. Be The First One To Add A Comment.
    //                 </div>
    //             }
    //         </div>
    //         <Divider />
    //         <div className="CmtsInput w-full p-2 h-20 flex flex-row gap-1 justify-center items-center">
    //             <TextField id="outlined-basic" className="w-full" label="Add A Comment" variant="filled" value={comment} onChange={handleChangeComment} onKeyPress={handleKey} />
    //             {comment.length > 0 && <span className="text-blue-600 font-bold cursor-pointer" onClick={AddComment}>SEND</span>}
    //         </div>
    //     </div>
    // );

    function useLongPress(callback = () => { }, ms = 300) {
        const [startLongPress, setStartLongPress] = useState(false);
        const callbackRef = useRef(callback);

        useEffect(() => {
            callbackRef.current = callback;
        });

        useEffect(() => {
            let timerId;
            if (startLongPress) {
                timerId = setTimeout(callbackRef.current, ms);
            } else {
                clearTimeout(timerId);
            }

            return () => {
                clearTimeout(timerId);
            };
        }, [ms, startLongPress]);

        return {
            onMouseDown: () => setStartLongPress(true),
            onMouseUp: () => setStartLongPress(false),
            onMouseLeave: () => setStartLongPress(false),
            onTouchStart: () => setStartLongPress(true),
            onTouchEnd: () => setStartLongPress(false),
        };
    }

    const longPressEventHandlers = useLongPress(() => {
        return
    }, 500);

    const truncate = (str, length) => {
        if (str.length > length) {
            return str.slice(0, length) + "...";
        } else {
            return str;
        }
    }

    useEffect(() => {
        if (props.UserData !== null) {
            if (currentUser && props.UserData !== null && props.UserData.NewUser === true) {
                navigate('/Onboarding');
            }
        }
    }, [currentUser, props.UserData])

    return (
        <>
            <div className="app">
                <div className="wrapper">
                    {/* <div className="left-side">
                        <div className="side-wrapper flex h-[100%]">
                            <div className="side-menu flex flex-col justify-evenly h-full">
                                <Link to="/" className="flex flex-row items-center gap-2 w-full">
                                    <img src="https://cdn-icons-png.freepik.com/512/4194/4194676.png" alt="Home Icon" style={{ height: 28, width: 28 }} />
                                    <h2 className="font-extrabold text-lg">Home</h2>
                                </Link>
                                <Link to="/Explore" className="flex flex-row items-center gap-2 w-full">
                                    <img src="https://cdn-icons-png.freepik.com/512/6337/6337301.png" alt="Explore Icon" style={{ height: 28, width: 28 }} />
                                    <h2 className="font-extrabold text-lg">Feed</h2>
                                </Link>
                                <Link to="/Sell" className="flex flex-row items-center gap-2 w-full">
                                    <img src="https://cdn-icons-png.freepik.com/512/10337/10337579.png" alt="Sell Icon" style={{ height: 28, width: 28 }} />
                                    <h2 className="font-extrabold text-lg">Sell</h2>
                                </Link>
                                <Link to="/Chats" className="flex flex-row items-center gap-2 w-full">
                                    <img src="https://cdn-icons-png.freepik.com/512/1041/1041916.png" alt="Chat Icon" style={{ height: 28, width: 28 }} />
                                    <h2 className="font-extrabold text-lg">Chat</h2>
                                </Link>
                                <Link to="/Profile" className="flex flex-row items-center gap-2 w-full">
                                    {currentUser ? <img src={currentUser.photoURL} alt="Profile Picture" style={{ height: 28, width: 28, borderRadius: '100%' }} /> : <img src="https://cdn-icons-png.freepik.com/512/1177/1177568.png" alt="Profile Picture" style={{ height: 28, width: 28 }} />}
                                    <h2 className="font-extrabold text-lg">Profile</h2>
                                </Link>
                            </div>
                        </div>
                    </div> */}
                    {/* <Dialog
                        open={openCommentsSmall}
                        onClose={handleCloseCommentsSmall}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        sx={{
                            margin: 0, '& .MuiPaper-root': { backgroundColor: 'rgb(255 255 255 / 70%)', },
                        }}
                    >
                        {dialogBoxCommentsSmall()}
                    </Dialog>
                    <Dialog
                        open={openCommentsLarge}
                        onClose={handleCloseCommentsLarge}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        sx={{
                            marginLeft: '200px', '& .MuiPaper-root': { backgroundColor: 'rgb(255 255 255 / 70%)', },
                        }}
                    >
                        {dialogBoxCommentsLarge()}
                    </Dialog> */}
                    {props.VideosData.length > 0 ?
                        <>
                            {bottomBarVisible <= 945 ?
                                <>
                                    {/* {fullScreen ? */}
                                    <div className="reels-section flex w-full justify-center items-center">
                                        <section className='section-reel hide-scrollbar' style={{ scrollSnapType: 'y mandatory', transition: '0.5s', }}>
                                            <div class="section-reels-container hide-scrollbar">
                                                <div className="reels-inner-container" style={{ height: 'max-content', paddingTop: '71px', paddingBottom: '71px' }}>
                                                    {props.VideosData.filter(item => item.videoID === FID).map((reel, index) =>
                                                        <div {...longPressEventHandlers} ref={refs[index]} id={reel.videoID} data-id={reel.videoID} key={index} className="reel__container" style={{ position: 'relative' }} onDoubleClick={() => {
                                                            if (currentUser) { props.UserData !== null && props.UserData.Liked.includes(reel.videoID) === false ? Like(reel) : UnLike(reel) } else {
                                                                swal({
                                                                    title: "Login",
                                                                    text: "Please Login To Like Video",
                                                                    icon: "warning",
                                                                    buttons: true,
                                                                    dangerMode: false,
                                                                }).then((willGo) => {
                                                                    if (willGo) {
                                                                        navigate('/Auth')
                                                                    }
                                                                })
                                                            }
                                                        }}>
                                                            <div style={{ position: 'absolute', top: 0, left: 0, zIndex: -10, width: '100%', height: '100%', scrollSnapAlign: 'center' }}>
                                                                <ReactPlayer url={reel.ad} key={index} playing={!!playing[reel.videoID]} loop={true} muted={muted} width='100%' height='100%' pip={false} playsinline={false} onContextMenu={(e) => e.preventDefault()} />
                                                            </div>
                                                            <div className="reel__title">
                                                                <div className="reel__back-button cursor-pointer">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-narrow-left" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round" onClick={(e) => { e.stopPropagation(); navigate(-1); }}>
                                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                                        <line x1="5" y1="12" x2="19" y2="12"></line>
                                                                        <line x1="5" y1="12" x2="9" y2="16"></line>
                                                                        <line x1="5" y1="12" x2="9" y2="8"></line>
                                                                    </svg>
                                                                </div>
                                                                {!muted ?
                                                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-volume cur" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="#ffffff" fill="none" stroke-linecap="round" stroke-linejoin="round" onClick={() => setMuted(true)}>
                                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                        <path d="M15 8a5 5 0 0 1 0 8" />
                                                                        <path d="M17.7 5a9 9 0 0 1 0 14" />
                                                                        <path d="M6 15h-2a1 1 0 0 1 -1 -1v-4a1 1 0 0 1 1 -1h2l3.5 -4.5a.8 .8 0 0 1 1.5 .5v14a.8 .8 0 0 1 -1.5 .5l-3.5 -4.5" />
                                                                    </svg>
                                                                    :
                                                                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-volume-off" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="#ffffff" fill="none" stroke-linecap="round" stroke-linejoin="round" onClick={() => setMuted(false)}>
                                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                        <path d="M15 8a5 5 0 0 1 1.912 4.934m-1.377 2.602a5 5 0 0 1 -.535 .464" />
                                                                        <path d="M17.7 5a9 9 0 0 1 2.362 11.086m-1.676 2.299a9 9 0 0 1 -.686 .615" />
                                                                        <path d="M9.069 5.054l.431 -.554a.8 .8 0 0 1 1.5 .5v2m0 4v8a.8 .8 0 0 1 -1.5 .5l-3.5 -4.5h-2a1 1 0 0 1 -1 -1v-4a1 1 0 0 1 1 -1h2l1.294 -1.664" />
                                                                        <path d="M3 3l18 18" />
                                                                    </svg>}
                                                            </div>
                                                            <div className="reel__content">
                                                                <div className="reel__desc">
                                                                    <div className="reel__user">
                                                                        <img src={reel.Store.logo} className="reel__avatar cursor-pointer" onClick={() => navigate(`/Store/${reel.storeID}`)} />
                                                                        <p className="reel__username cursor-pointer" onClick={() => navigate(`/Store/${reel.storeID}`)}>{truncate(reel.Store.storeName, 12)}</p>
                                                                        {/* {currentUser && currentUser.uid !== reel.Store.ownerID && props.UserData !== null &&
                                                                            <>{props.UserData.FollowingUsers.includes(reel.Store.ownerID) ? <button className='button-reel cursor-pointer' onClick={() => UnFollowUser(reel)}>Following</button> : <button className='button-reel cursor-pointer' onClick={() => FollowUser(reel)}>Follow</button>}</>
                                                                        } */}
                                                                    </div>
                                                                    {/* <p className="reel__caption cursor-pointer">Trees, fogs, and mountains⛰️🌳</p> */}
                                                                </div>
                                                                <div className="reel__options">
                                                                    {currentUser ?
                                                                        <>
                                                                            {props.UserData !== null && props.UserData.Liked.includes(reel.videoID) === false ?
                                                                                <>
                                                                                    {likeLoading === true ?
                                                                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-heart" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                                                            <path d="M19.5 13.572l-7.5 7.428l-7.5 -7.428m0 0a5 5 0 1 1 7.5 -6.566a5 5 0 1 1 7.5 6.572"></path>
                                                                                        </svg>
                                                                                        :
                                                                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-heart" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round" onClick={() => Like(reel)}>
                                                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                                                            <path d="M19.5 13.572l-7.5 7.428l-7.5 -7.428m0 0a5 5 0 1 1 7.5 -6.566a5 5 0 1 1 7.5 6.572"></path>
                                                                                        </svg>
                                                                                    }
                                                                                </>
                                                                                :
                                                                                <>
                                                                                    {unlikeLoading === true ?
                                                                                        <svg svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-heart-filled" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="#ff0000" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                                            <path d="M6.979 3.074a6 6 0 0 1 4.988 1.425l.037 .033l.034 -.03a6 6 0 0 1 4.733 -1.44l.246 .036a6 6 0 0 1 3.364 10.008l-.18 .185l-.048 .041l-7.45 7.379a1 1 0 0 1 -1.313 .082l-.094 -.082l-7.493 -7.422a6 6 0 0 1 3.176 -10.215z" stroke-width="0" fill="#ff0000" />
                                                                                        </svg>
                                                                                        :
                                                                                        <svg svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-heart-filled" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="#ff0000" fill="none" stroke-linecap="round" stroke-linejoin="round" onClick={() => UnLike(reel)}>
                                                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                                            <path d="M6.979 3.074a6 6 0 0 1 4.988 1.425l.037 .033l.034 -.03a6 6 0 0 1 4.733 -1.44l.246 .036a6 6 0 0 1 3.364 10.008l-.18 .185l-.048 .041l-7.45 7.379a1 1 0 0 1 -1.313 .082l-.094 -.082l-7.493 -7.422a6 6 0 0 1 3.176 -10.215z" stroke-width="0" fill="#ff0000" />
                                                                                        </svg>
                                                                                    }
                                                                                </>
                                                                            }
                                                                        </>
                                                                        :
                                                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-heart" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round" onClick={() => Like(reel)}>
                                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                                            <path d="M19.5 13.572l-7.5 7.428l-7.5 -7.428m0 0a5 5 0 1 1 7.5 -6.566a5 5 0 1 1 7.5 6.572"></path>
                                                                        </svg>
                                                                    }
                                                                    <p className="reel__likes mb-4">{reel.LikesCount}</p>
                                                                    {/* <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-message-circle" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round" onClick={() => handleClickOpenCommentsSmall(reel)}>
                                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                                    <path d="M3 20l1.3 -3.9a9 8 0 1 1 3.4 2.9l-4.7 1"></path>
                                                                </svg>
                                                                <p className="reel__likes mb-4">{reel.CommentCount}</p> */}
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-share" onClick={() => { setCurrentReelData(reel); toggleDrawerShare(anchor, true) }}>
                                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                        <path d="M6 12m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" />
                                                                        <path d="M18 6m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" />
                                                                        <path d="M18 18m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" />
                                                                        <path d="M8.7 10.7l6.6 -3.4" />
                                                                        <path d="M8.7 13.3l6.6 3.4" />
                                                                    </svg>
                                                                    <p className="reel__likes mb-4">SHARE</p>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-building-store" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round" onClick={() => navigate(`/Store/${reel.storeID}`)}>
                                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                        <path d="M3 21l18 0" />
                                                                        <path d="M3 7v1a3 3 0 0 0 6 0v-1m0 1a3 3 0 0 0 6 0v-1m0 1a3 3 0 0 0 6 0v-1h-18l2 -4h14l2 4" /><path d="M5 21l0 -10.15" />
                                                                        <path d="M19 21l0 -10.15" />
                                                                        <path d="M9 21v-4a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v4" />
                                                                    </svg>
                                                                    <p className="reel__likes mb-4">VISIT</p>
                                                                    {currentUser && currentUser.uid !== reel.Store.ownerID &&
                                                                        <>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-send" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round" onClick={() => ChatID(reel)}>
                                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                                                <line x1="10" y1="14" x2="21" y2="3"></line>
                                                                                <path d="M21 3l-6.5 18a0.55 .55 0 0 1 -1 0l-3.5 -7l-7 -3.5a0.55 .55 0 0 1 0 -1l18 -6.5"></path>
                                                                            </svg>
                                                                            <p className="reel__likes mb-4" onClick={() => ChatID(reel)}>CHAT</p>
                                                                        </>
                                                                    }
                                                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-dots-vertical" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round" onClick={() => { setCurrentReelData(reel); toggleDrawer(anchor, true) }}>
                                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                                        <circle cx="12" cy="12" r="1"></circle>
                                                                        <circle cx="12" cy="19" r="1"></circle>
                                                                        <circle cx="12" cy="5" r="1"></circle>
                                                                    </svg>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                    {/* :
                                        <>
                                            <div className="hiding-container flex justify-center items-center">
                                                <img src="https://cdn-icons-png.freepik.com/512/9344/9344455.png" alt="Play Icon" className="h-20 w-20 cursor-pointer" onClick={() => setFullScreen(true)} />
                                            </div>
                                            <div className="reels-section flex w-full justify-center items-center">
                                                <section className='section-reel hide-scrollbar' style={{ scrollSnapType: 'y mandatory', transition: '0.5s', }}>
                                                    <div class="section-reels-container hide-scrollbar">
                                                        <div className="reels-inner-container" style={{ height: 'max-content', paddingTop: '71px', paddingBottom: '71px', display: 'flex', flexDirection: 'column', gap: '100px' }}>
                                                            <div {...longPressEventHandlers} className="reel__container" style={{ position: 'relative' }}>
                                                                <div style={{ position: 'absolute', top: 0, left: 0, zIndex: -10, width: '100%', height: '100%', scrollSnapAlign: 'center' }}>
                                                                    <ReactPlayer url="https://firebasestorage.googleapis.com/v0/b/business-5884d.appspot.com/o/Video%2Fdcd026a1-2b2f-4bfa-b629-49443c32c536?alt=media&token=f055fe72-7e4a-4958-87c8-f767e05092bf" playing={false} loop={false} muted={true} width='100%' height='100%' pip={false} playsinline={false} onContextMenu={(e) => e.preventDefault()} />
                                                                </div>
                                                                <div className="reel__title">
                                                                    <div className="reel__back-button cursor-pointer">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-narrow-left" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round" onClick={(e) => { e.stopPropagation(); navigate(-1); }}>
                                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                                            <line x1="5" y1="12" x2="19" y2="12"></line>
                                                                            <line x1="5" y1="12" x2="9" y2="16"></line>
                                                                            <line x1="5" y1="12" x2="9" y2="8"></line>
                                                                        </svg>
                                                                    </div>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-volume cur" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="#ffffff" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                        <path d="M15 8a5 5 0 0 1 0 8" />
                                                                        <path d="M17.7 5a9 9 0 0 1 0 14" />
                                                                        <path d="M6 15h-2a1 1 0 0 1 -1 -1v-4a1 1 0 0 1 1 -1h2l3.5 -4.5a.8 .8 0 0 1 1.5 .5v14a.8 .8 0 0 1 -1.5 .5l-3.5 -4.5" />
                                                                    </svg>
                                                                </div>
                                                                <div className="reel__content">
                                                                    <div className="reel__desc">
                                                                        <div className="reel__user">
                                                                            <img src="https://cdn-icons-png.freepik.com/512/4140/4140048.png" className="reel__avatar cursor-pointer" />
                                                                            <p className="reel__username cursor-pointer">Lorem Ipsum</p>
                                                                            <button className='button-reel cursor-pointer'>Follow</button>
                                                                        </div>
                                                                        <p className="reel__caption cursor-pointer">Trees, fogs, and mountains⛰️🌳</p>
                                                                    </div>
                                                                    <div className="reel__options">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-heart" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                                            <path d="M19.5 13.572l-7.5 7.428l-7.5 -7.428m0 0a5 5 0 1 1 7.5 -6.566a5 5 0 1 1 7.5 6.572"></path>
                                                                        </svg>
                                                                        <p className="reel__likes mb-4">0</p>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-message-circle" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round" onClick={() => handleClickOpenCommentsSmall(reel)}>
                                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                                    <path d="M3 20l1.3 -3.9a9 8 0 1 1 3.4 2.9l-4.7 1"></path>
                                                                </svg>
                                                                <p className="reel__likes mb-4">{reel.CommentCount}</p>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-share">
                                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                            <path d="M6 12m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" />
                                                                            <path d="M18 6m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" />
                                                                            <path d="M18 18m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" />
                                                                            <path d="M8.7 10.7l6.6 -3.4" />
                                                                            <path d="M8.7 13.3l6.6 3.4" />
                                                                        </svg>
                                                                        <p className="reel__likes mb-4">SHARE</p>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-building-store" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                            <path d="M3 21l18 0" />
                                                                            <path d="M3 7v1a3 3 0 0 0 6 0v-1m0 1a3 3 0 0 0 6 0v-1m0 1a3 3 0 0 0 6 0v-1h-18l2 -4h14l2 4" /><path d="M5 21l0 -10.15" />
                                                                            <path d="M19 21l0 -10.15" />
                                                                            <path d="M9 21v-4a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v4" />
                                                                        </svg>
                                                                        <p className="reel__likes mb-4">VISIT</p>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-send" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                                            <line x1="10" y1="14" x2="21" y2="3"></line>
                                                                            <path d="M21 3l-6.5 18a0.55 .55 0 0 1 -1 0l-3.5 -7l-7 -3.5a0.55 .55 0 0 1 0 -1l18 -6.5"></path>
                                                                        </svg>
                                                                        <p className="reel__likes mb-4">CHAT</p>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-dots-vertical" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                                            <circle cx="12" cy="12" r="1"></circle>
                                                                            <circle cx="12" cy="19" r="1"></circle>
                                                                            <circle cx="12" cy="5" r="1"></circle>
                                                                        </svg>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                            </div>
                                        </>
                                    } */}
                                </>
                                :
                                <div className="reels-section flex w-full justify-center items-center">
                                    <section className='section-reel'>
                                        <div class="section-reels-container hide-scrollbar">
                                            <div className="reels-inner-container" style={{ height: 'max-content', paddingTop: '71px', paddingBottom: '71px' }}>
                                                <Dialog
                                                    open={open}
                                                    onClose={handleClose}
                                                    aria-labelledby="alert-dialog-title"
                                                    aria-describedby="alert-dialog-description"
                                                    sx={{
                                                        '& .MuiPaper-root': { backgroundColor: 'var(--search-bg)', },
                                                    }}
                                                >
                                                    {dialogBox()}
                                                </Dialog>
                                                <Dialog
                                                    open={openShare}
                                                    onClose={handleCloseShare}
                                                    aria-labelledby="alert-dialog-title"
                                                    aria-describedby="alert-dialog-description"
                                                    sx={{
                                                        '& .MuiPaper-root': { backgroundColor: 'var(--search-bg)', },
                                                    }}
                                                >
                                                    {dialogBoxShare()}
                                                </Dialog>
                                                {props.VideosData.filter(item => item.videoID === FID).map((reel, index) =>
                                                    <div {...longPressEventHandlers} ref={refs[index]} id={reel.videoID} data-id={reel.videoID} key={index} className="reel__container" style={{ position: 'relative' }} onDoubleClick={() => {
                                                        if (currentUser) { props.UserData !== null && props.UserData.Liked.includes(reel.videoID) === false ? Like(reel) : UnLike(reel) } else {
                                                            swal({
                                                                title: "Login",
                                                                text: "Please Login To Like Video",
                                                                icon: "warning",
                                                                buttons: true,
                                                                dangerMode: false,
                                                            }).then((willGo) => {
                                                                if (willGo) {
                                                                    navigate('/Auth')
                                                                }
                                                            })
                                                        }
                                                    }}>
                                                        <div style={{ position: 'absolute', top: 0, left: 0, zIndex: -10, width: '100%', height: '100%', scrollSnapAlign: 'center' }}>
                                                            <ReactPlayer url={reel.ad} key={index} playing={!!playing[reel.videoID]} loop={true} muted={muted} width='100%' height='100%' pip={false} playsinline={false} onContextMenu={(e) => e.preventDefault()} />
                                                        </div>
                                                        <div className="reel__title">
                                                            <div className="reel__back-button cursor-pointer">
                                                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-narrow-left" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round" onClick={(e) => { e.stopPropagation(); navigate(-1); }}>
                                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                                    <line x1="5" y1="12" x2="19" y2="12"></line>
                                                                    <line x1="5" y1="12" x2="9" y2="16"></line>
                                                                    <line x1="5" y1="12" x2="9" y2="8"></line>
                                                                </svg>
                                                            </div>
                                                            {!muted ?
                                                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-volume cur" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="#ffffff" fill="none" stroke-linecap="round" stroke-linejoin="round" onClick={() => setMuted(true)}>
                                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                    <path d="M15 8a5 5 0 0 1 0 8" />
                                                                    <path d="M17.7 5a9 9 0 0 1 0 14" />
                                                                    <path d="M6 15h-2a1 1 0 0 1 -1 -1v-4a1 1 0 0 1 1 -1h2l3.5 -4.5a.8 .8 0 0 1 1.5 .5v14a.8 .8 0 0 1 -1.5 .5l-3.5 -4.5" />
                                                                </svg>
                                                                :
                                                                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-volume-off" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="#ffffff" fill="none" stroke-linecap="round" stroke-linejoin="round" onClick={() => setMuted(false)}>
                                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                    <path d="M15 8a5 5 0 0 1 1.912 4.934m-1.377 2.602a5 5 0 0 1 -.535 .464" />
                                                                    <path d="M17.7 5a9 9 0 0 1 2.362 11.086m-1.676 2.299a9 9 0 0 1 -.686 .615" />
                                                                    <path d="M9.069 5.054l.431 -.554a.8 .8 0 0 1 1.5 .5v2m0 4v8a.8 .8 0 0 1 -1.5 .5l-3.5 -4.5h-2a1 1 0 0 1 -1 -1v-4a1 1 0 0 1 1 -1h2l1.294 -1.664" />
                                                                    <path d="M3 3l18 18" />
                                                                </svg>}
                                                        </div>
                                                        <div className="reel__content">
                                                            <div className="reel__desc">
                                                                <div className="reel__user">
                                                                    <img src={reel.Store.logo} className="reel__avatar cursor-pointer" onClick={() => navigate(`/Store/${reel.storeID}`)} />
                                                                    <p className="reel__username cursor-pointer" onClick={() => navigate(`/Store/${reel.storeID}`)}>{truncate(reel.Store.storeName, 12)}</p>
                                                                    {/* {currentUser && currentUser.uid !== reel.Store.ownerID && props.UserData !== null &&
                                                                        <>{props.UserData.FollowingUsers.includes(reel.Store.ownerID) ? <button className='button-reel cursor-pointer' onClick={() => UnFollowUser(reel)}>Following</button> : <button className='button-reel cursor-pointer' onClick={() => FollowUser(reel)}>Follow</button>}</>
                                                                    } */}
                                                                </div>
                                                                {/* <p className="reel__caption cursor-pointer">Trees, fogs, and mountains⛰️🌳</p> */}
                                                            </div>
                                                            <div className="reel__options">
                                                                {currentUser ?
                                                                    <>
                                                                        {props.UserData !== null && props.UserData.Liked.includes(reel.videoID) === false ?
                                                                            <>
                                                                                {likeLoading === true ?
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-heart" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                                                        <path d="M19.5 13.572l-7.5 7.428l-7.5 -7.428m0 0a5 5 0 1 1 7.5 -6.566a5 5 0 1 1 7.5 6.572"></path>
                                                                                    </svg>
                                                                                    :
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-heart" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round" onClick={() => Like(reel)}>
                                                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                                                        <path d="M19.5 13.572l-7.5 7.428l-7.5 -7.428m0 0a5 5 0 1 1 7.5 -6.566a5 5 0 1 1 7.5 6.572"></path>
                                                                                    </svg>
                                                                                }
                                                                            </>
                                                                            :
                                                                            <>
                                                                                {unlikeLoading === true ?
                                                                                    <svg svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-heart-filled" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="#ff0000" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                                        <path d="M6.979 3.074a6 6 0 0 1 4.988 1.425l.037 .033l.034 -.03a6 6 0 0 1 4.733 -1.44l.246 .036a6 6 0 0 1 3.364 10.008l-.18 .185l-.048 .041l-7.45 7.379a1 1 0 0 1 -1.313 .082l-.094 -.082l-7.493 -7.422a6 6 0 0 1 3.176 -10.215z" stroke-width="0" fill="#ff0000" />
                                                                                    </svg>
                                                                                    :
                                                                                    <svg svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-heart-filled" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="#ff0000" fill="none" stroke-linecap="round" stroke-linejoin="round" onClick={() => UnLike(reel)}>
                                                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                                        <path d="M6.979 3.074a6 6 0 0 1 4.988 1.425l.037 .033l.034 -.03a6 6 0 0 1 4.733 -1.44l.246 .036a6 6 0 0 1 3.364 10.008l-.18 .185l-.048 .041l-7.45 7.379a1 1 0 0 1 -1.313 .082l-.094 -.082l-7.493 -7.422a6 6 0 0 1 3.176 -10.215z" stroke-width="0" fill="#ff0000" />
                                                                                    </svg>
                                                                                }
                                                                            </>
                                                                        }
                                                                    </>
                                                                    :
                                                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-heart" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round" onClick={() => Like(reel)}>
                                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                                        <path d="M19.5 13.572l-7.5 7.428l-7.5 -7.428m0 0a5 5 0 1 1 7.5 -6.566a5 5 0 1 1 7.5 6.572"></path>
                                                                    </svg>
                                                                }
                                                                <p className="reel__likes mb-4">{reel.LikesCount}</p>
                                                                {/* <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-message-circle" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round" onClick={() => handleClickOpenCommentsLarge(reel)}>
                                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                                    <path d="M3 20l1.3 -3.9a9 8 0 1 1 3.4 2.9l-4.7 1"></path>
                                                                </svg>
                                                                <p className="reel__likes mb-4">{reel.CommentCount}</p> */}
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-share" onClick={() => { handleClickOpenShare(reel) }}>
                                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                    <path d="M6 12m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" />
                                                                    <path d="M18 6m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" />
                                                                    <path d="M18 18m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" />
                                                                    <path d="M8.7 10.7l6.6 -3.4" />
                                                                    <path d="M8.7 13.3l6.6 3.4" />
                                                                </svg>
                                                                <p className="reel__likes mb-4">SHARE</p>
                                                                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-building-store" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round" onClick={() => navigate(`/Store/${reel.storeID}`)}>
                                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                    <path d="M3 21l18 0" />
                                                                    <path d="M3 7v1a3 3 0 0 0 6 0v-1m0 1a3 3 0 0 0 6 0v-1m0 1a3 3 0 0 0 6 0v-1h-18l2 -4h14l2 4" /><path d="M5 21l0 -10.15" />
                                                                    <path d="M19 21l0 -10.15" />
                                                                    <path d="M9 21v-4a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v4" />
                                                                </svg>
                                                                <p className="reel__likes mb-4">VISIT</p>
                                                                {currentUser && currentUser.uid !== reel.Store.ownerID &&
                                                                    <>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-send" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round" onClick={() => ChatID(reel)}>
                                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                                            <line x1="10" y1="14" x2="21" y2="3"></line>
                                                                            <path d="M21 3l-6.5 18a0.55 .55 0 0 1 -1 0l-3.5 -7l-7 -3.5a0.55 .55 0 0 1 0 -1l18 -6.5"></path>
                                                                        </svg>
                                                                        <p className="reel__likes mb-4" onClick={() => ChatID(reel)}>CHAT</p>
                                                                    </>
                                                                }
                                                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-dots-vertical" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round" onClick={() => handleClickOpen(reel)}>
                                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                                    <circle cx="12" cy="12" r="1"></circle>
                                                                    <circle cx="12" cy="19" r="1"></circle>
                                                                    <circle cx="12" cy="5" r="1"></circle>
                                                                </svg>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            }
                        </>
                        :
                        <>
                            <div className="NoReelVideo h-full w-full flex justify-center items-center text-center text-2xl font-extrabold">
                                <ScaleLoader color='#ff0058' loading={true} size={150} />
                            </div>
                        </>
                    }
                </div>
                <div className="overlay-app" />
                {bottomBarVisible <= 945 &&
                    <React.Fragment key={anchor}>
                        <Drawer
                            anchor={anchor}
                            open={state[anchor]}
                            onClose={() => toggleDrawer(anchor, false)}
                            onOpen={() => toggleDrawer(anchor, true)}
                            PaperProps={{
                                sx: {
                                    backgroundColor: 'var(--search-bg-2)'
                                },
                            }}
                        >
                            {list(anchor)}
                        </Drawer>
                    </React.Fragment>
                }
                {bottomBarVisible <= 945 &&
                    <React.Fragment key={anchorShare}>
                        <Drawer
                            anchor={anchorShare}
                            open={stateShare[anchorShare]}
                            onClose={() => toggleDrawerShare(anchorShare, false)}
                            onOpen={() => toggleDrawerShare(anchorShare, true)}
                            PaperProps={{
                                sx: {
                                    backgroundColor: 'var(--search-bg-2)'
                                },
                            }}
                        >
                            {listShare(anchorShare)}
                        </Drawer>
                    </React.Fragment>
                }
                {/* {bottomBarVisible <= 945 &&
                    <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, backgroundColor: 'transparent' }} elevation={3}>
                        <BottomNavigation
                            className="backgroundColor: 'transparent',"
                            showLabels
                            value={value}
                            onChange={(event, newValue) => {
                                setValue(newValue);
                                newValue === 0 ? navigate('/') : newValue === 1 ? navigate('/Explore') : newValue === 2 ? navigate('/Sell') : newValue === 3 ? navigate('/Chats') : newValue === 4 ? navigate('/Profile') : navigate('/')
                            }}
                        >
                            <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<HomeRoundedIcon />} label="Home" />
                            <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<SlideshowIcon/>} label="Shorts" />
                            <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<AddCircleRoundedIcon />} label="Sell" />
                            <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<QuestionAnswerRoundedIcon />} label="Chat" />
                            {currentUser ? <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<img src={currentUser.photoURL} className='h-6 w-6 rounded-full' />} label="Manage" /> : <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<PersonRoundedIcon />} label="Profile" />}
                        </BottomNavigation>
                    </Paper>
                } */}
            </div>
        </>
    )
}

export default Feed