import React from 'react'
import { useNavigate } from 'react-router-dom'

const BackButton = () => {
    const navigate = useNavigate();

    return (
        <div className="w-max h-max pl-2 pr-2 flex items-center justify-start">
            {/* <div className="w-full h-max p-2 flex items-center justify-start sticky top-0"> */}
            <img src="https://cdn-icons-png.freepik.com/512/13526/13526096.png" alt="Back Icon" className='h-8 w-8 cursor-pointer' onClick={() => navigate(-1)} />
        </div>
    )
}

export default BackButton