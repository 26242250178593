/* eslint-disable react-hooks/exhaustive-deps */
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import {
    useNavigate,
} from 'react-router-dom';
import gsap from 'gsap';
import { AuthContext } from '../context/AuthContext';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';

const Region = (props) => {
    const { currentUser } = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        gsap.fromTo(".content-wrapper", { opacity: 0 }, { opacity: 1, duration: 2 });
    }, []);

    const [state, setState] = useState(localStorage.getItem("State"));
    const [city, setCity] = useState(localStorage.getItem("City"));

    const handleChangeState = (e) => {
        setState(e.target.value);
    };

    const handleChangeCity = (e) => {
        setCity(e.target.value);
    };

    const HandleSubmit = async () => {
        if (currentUser) {
            if (state !== null && city !== null) {
                await updateDoc(doc(db, "Users", currentUser.uid), {
                    state,
                    city,
                    NewUser: false,
                });
                navigate('/')
            }
        } else {
            if (state !== null && city !== null) {
                localStorage.setItem("State", state)
                localStorage.setItem("City", city)
                navigate('/')
            }
        }
    }

    useEffect(() => {
        if (props.UserData !== null) {
            if (currentUser && props.UserData !== null && props.UserData.NewUser === true) {
                navigate('/Onboarding');
            }
        }
    }, [currentUser, props.UserData])

    return (
        <>
            <div className="app">
                <div className="OnboardingContentSection overflow-y-auto flex-col gap-5 p-5">
                    <h1 className="text-2xl font-black">MARTPURAM</h1>
                    <div className="InnerContentSection rounded-2xl flex flex-col pt-2 pb-2 pl-5 pr-5">
                        <div className="HeadingOnboarding w-full p-2 flex justify-center items-center text-center">
                            <span className="TopHeading font-extrabold text-2xl uppercase">CHOOSE YOUR REGION</span>
                        </div>
                        <div className="StateSelection w-full mt-10 p-2 flex flex-col justify-start items-center">
                            <span className="TopHeading w-full flex justify-start text-start items-start font-semibold text-lg uppercase">STATE / राज्य</span>
                            <FormControl variant="filled" sx={{ m: 1, minWidth: '100%' }}>
                                <InputLabel id="demo-simple-select-standard-label">CHOOSE YOUR STATE / राज्य चुने</InputLabel>
                                <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={state}
                                    onChange={handleChangeState}
                                    label="CHOOSE YOUR STATE / राज्य चुने"
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    {props.Locations !== null && props.Locations.States.length > 0 && props.Locations.States?.sort((a, b) => a.localeCompare(b)).map(city => (<MenuItem value={city}>{city}</MenuItem>))}
                                </Select>
                            </FormControl>
                        </div>
                        <div className="CitySelection w-full mt-5 p-2 flex flex-col justify-start items-center">
                            <span className="TopHeading w-full flex justify-start text-start items-start font-semibold text-lg uppercase">CITY / शहर</span>
                            <FormControl variant="filled" sx={{ m: 1, minWidth: '100%' }}>
                                <InputLabel id="demo-simple-select-standard-label">CHOOSE YOUR CITY / शहर चुने</InputLabel>
                                <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={city}
                                    onChange={handleChangeCity}
                                    label="CHOOSE YOUR CITY / शहर चुने"
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    {state !== '' && props.Locations !== null && props.Locations.Cities.length > 0 && props.Locations.Cities?.filter((item, idx) => item.state_name === state).sort((a, b) => a.name.localeCompare(b.name)).map(city => (<MenuItem value={city.name}>{city.name}</MenuItem>))}
                                </Select>
                            </FormControl>
                        </div>
                        <div className="SubmitBTNInfo w-full flex justify-center items-center text-center pt-2 pb-2 pr-5 pl-5 rounded-2xl mt-5 bg-blue-500 font-extrabold text-xl mb-2 cursor-pointer" onClick={HandleSubmit}>
                            CONFIRM
                        </div>
                    </div>
                    <div className="lowerPolicy w-full flex justify-center items-center">
                        <div className="content-section-sell-store mt-5">
                            <div className="content-section-title-profile flex w-full justify-between items-center mb-5">
                                <span className="font-extrabold uppercase">Help & Support</span>
                            </div>
                            <div className="apps-card-profile gap-5">
                                <div className="app-card-profile h-36" onClick={() => navigate('/FAQ')}>
                                    <span className="w-full flex text-center justify-center items-center"><img src="https://cdn-icons-png.freepik.com/512/9046/9046106.png" alt="" className='h-10 w-10' /></span>
                                    <span className="w-full flex text-center justify-center items-center">FAQs</span>
                                </div>
                                <div className="app-card-profile h-36" onClick={() => navigate('/Contact')}>
                                    <span className="w-full flex text-center justify-center items-center"><img src="https://cdn-icons-png.freepik.com/512/13651/13651428.png" alt="" className='h-10 w-10' /></span>
                                    <span className="w-full flex text-center justify-center items-center">Contact Us</span>
                                </div>
                                <div className="app-card-profile h-36" onClick={() => navigate('/Terms-And-Conditions')}>
                                    <span className="w-full flex text-center justify-center items-center"><img src="https://cdn-icons-png.freepik.com/512/8299/8299673.png" alt="" className='h-10 w-10' /></span>
                                    <span className="w-full flex text-center justify-center items-center">Terms & Conditions</span>
                                </div>
                                <div className="app-card-profile h-36" onClick={() => navigate('/Privacy-Policy')}>
                                    <span className="w-full flex text-center justify-center items-center"><img src="https://cdn-icons-png.freepik.com/512/11547/11547608.png" alt="" className='h-10 w-10' /></span>
                                    <span className="w-full flex text-center justify-center items-center">Privacy Policy</span>
                                </div>
                                <div className="app-card-profile h-36" onClick={() => navigate('/Refund-Policy')}>
                                    <span className="w-full flex text-center justify-center items-center"><img src="https://cdn-icons-png.freepik.com/512/1332/1332675.png" alt="" className='h-10 w-10' /></span>
                                    <span className="w-full flex text-center justify-center items-center">Refund Policy</span>
                                </div>
                                {/* <div className="app-card-profile h-36" onClick={() => navigate('/Shipping-Policy')}>
                                    <span className="w-full flex text-center justify-center items-center"><img src="https://cdn-icons-png.freepik.com/512/11477/11477567.png" alt="" className='h-10 w-10' /></span>
                                    <span className="w-full flex text-center justify-center items-center">Shipping Policy</span>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="overlay-app" />
            </div>
        </>
    )
}

export default Region