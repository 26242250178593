/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import { BottomNavigation, BottomNavigationAction, Dialog, Paper } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import SlideshowIcon from '@mui/icons-material/Slideshow';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import QuestionAnswerRoundedIcon from '@mui/icons-material/QuestionAnswerRounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import { Link, useNavigate, useParams } from 'react-router-dom';
import gsap from 'gsap';
import { AuthContext } from '../context/AuthContext';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { arrayRemove, arrayUnion, doc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';
import swal from '@sweetalert/with-react';
import BackButton from '../components/BackButton';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
// import { Helmet } from 'react-helmet-async';

const Product = (props) => {
    const { currentUser } = useContext(AuthContext);
    const [bottomBarVisible, setBottomBarVisible] = useState(window.screen.width);
    const { PID } = useParams();
    // eslint-disable-next-line no-unused-vars
    const [product, setProduct] = useState(null);

    useEffect(() => {
        props.Products.length > 0 && setProduct(props.Products.filter(item => item.productID === PID)[0])
    }, [props.Products])

    useEffect(() => {
        const interval = setInterval(() => {
            setBottomBarVisible(window.screen.width)
        }, 100);
        return () => clearInterval(interval);
    }, []);

    // eslint-disable-next-line no-unused-vars
    const [value, setValue] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
        gsap.fromTo(".content-wrapper", { opacity: 0 }, { opacity: 1, duration: 2 });
    }, []);

    useEffect(() => {
        if (props.UserData !== null) {
            if (currentUser && props.UserData !== null && props.UserData.NewUser === true) {
                navigate('/Onboarding');
            }
        }
    }, [currentUser, props.UserData])

    const SaveItem = async (val) => {
        if (currentUser) {
            await updateDoc(doc(db, "Users", currentUser.uid), {
                'Saved.Products': arrayUnion(val.productID)
            });
        } else {
            swal('Please Login To Save Products', '', 'warning')
        }
    }

    const UnSaveItem = async (val) => {
        if (currentUser) {
            await updateDoc(doc(db, "Users", currentUser.uid), {
                'Saved.Products': arrayRemove(val.productID)
            });
        } else {
            swal('Please Login To Unsave Products', '', 'warning')
        }
    }

    const ChatID = async () => {
        if (currentUser !== null) {
            const combinedId =
                currentUser.uid > props.Shops.filter(item => item.storeID === product.storeID)[0].ownerID
                    ? currentUser.uid + props.Shops.filter(item => item.storeID === product.storeID)[0].ownerID
                    : props.Shops.filter(item => item.storeID === product.storeID)[0].ownerID + currentUser.uid;
            navigate(`/Chat/${combinedId}`);
        } else {
            swal('Please Login To Chat', '', 'warning', {
                buttons: {
                    confirm: {
                        text: "OK",
                        value: true,
                        visible: true,
                        className: "bg-[#8c52ff]",
                        closeModal: true
                    }
                }
            })
        }
    }

    // const truncate = (str, length) => {
    //     if (str.length > length) {
    //         return str.slice(0, length) + "...";
    //     } else {
    //         return str;
    //     }
    // }

    const [openShare, setOpenShare] = useState(false);

    const handleOpenShare = () => {
        setOpenShare(true);
    };

    const handleCloseShare = () => {
        setOpenShare(false);
    };

    const VideoURL = window.location.href;

    const dialogBoxShare = () => (
        <div className="w-max h-max">
            <div className="flex flex-col w-full h-full justify-between items-center p-2">
                <div className="w-full flex justify-center items-center font-black text-2xl">
                    SHARE
                </div>
                <div className="flex flex-row justify-evenly items-center w-full gap-5">
                    <img src="https://cdn-icons-png.freepik.com/512/9911/9911565.png" className="h-10 w-10 cursor-pointer" alt="Share Icon" onClick={() => { navigator.clipboard.writeText(VideoURL); swal('Link Copied', '', 'success') }} />
                    <img src="https://cdn-icons-png.freepik.com/512/5968/5968764.png" className="h-10 w-10 cursor-pointer" alt="Share Icon" onClick={() => window.open(`https://www.facebook.com/sharer/sharer.php?u=${VideoURL}`)} />
                    <img src="https://cdn-icons-png.freepik.com/512/4423/4423697.png" className="h-10 w-10 cursor-pointer" alt="Share Icon" onClick={() => window.open(`https://api.whatsapp.com/send?text=${VideoURL}`)} />
                    <img src="https://cdn-icons-png.freepik.com/512/2111/2111646.png" className="h-10 w-10 cursor-pointer" alt="Share Icon" onClick={() => window.open(`https://t.me/share/url?url=${VideoURL}&text=Watch this video on MartPuram`)} />
                    <img src="https://cdn-icons-png.freepik.com/512/5968/5968830.png" className="h-10 w-10 cursor-pointer" alt="Share Icon" onClick={() => window.open(`https://twitter.com/intent/tweet?url=${VideoURL}`)} />
                </div>
            </div>
        </div>
    );

    if (!product) {
        return <div className="app">
            <div className="wrapper h-full w-full flex justify-center items-center text-center text-2xl font-black text-red-500">
                LOADING...
            </div>
        </div>
            ;
    }

    return (
        <>
            <div className="app">
                <div className="wrapper">
                    <div className="left-side">
                        <div className="side-wrapper flex h-[100%]">
                            <div className="side-menu flex flex-col justify-evenly h-full">
                                <Link to="/" className="flex flex-row items-center gap-2 w-full">
                                    <img src="https://cdn-icons-png.freepik.com/512/4194/4194676.png" alt="Home Icon" style={{ height: 28, width: 28 }} />
                                    <h2 className="font-extrabold text-lg">Home</h2>
                                </Link>
                                <Link to="/Explore" className="flex flex-row items-center gap-2 w-full">
                                    <img src="https://cdn-icons-png.freepik.com/512/6337/6337301.png" alt="Explore Icon" style={{ height: 28, width: 28 }} />
                                    <h2 className="font-extrabold text-lg">Feed</h2>
                                </Link>
                                <Link to="/Sell" className="flex flex-row items-center gap-2 w-full">
                                    <img src="https://cdn-icons-png.freepik.com/512/10337/10337579.png" alt="Sell Icon" style={{ height: 28, width: 28 }} />
                                    <h2 className="font-extrabold text-lg">Sell</h2>
                                </Link>
                                <Link to="/Chats" className="flex flex-row items-center gap-2 w-full">
                                    <img src="https://cdn-icons-png.freepik.com/512/1041/1041916.png" alt="Chat Icon" style={{ height: 28, width: 28 }} />
                                    <h2 className="font-extrabold text-lg">Chat</h2>
                                </Link>
                                <Link to="/Profile" className="flex flex-row items-center gap-2 w-full">
                                    {currentUser ? <img src={currentUser.photoURL} alt="Profile Picture" style={{ height: 28, width: 28, borderRadius: '100%' }} /> : <img src="https://cdn-icons-png.freepik.com/512/1177/1177568.png" alt="Profile Picture" style={{ height: 28, width: 28 }} />}
                                    <h2 className="font-extrabold text-lg">Profile</h2>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="main-container">
                        <div className="content-wrapper">
                            {bottomBarVisible <= 945 && <BackButton />}
                            <div className={`${bottomBarVisible <= 945 ? 'mb-28' : ''} mt-2`}>
                                <Dialog
                                    open={openShare}
                                    onClose={handleCloseShare}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                    sx={{
                                        '& .MuiPaper-root': { backgroundColor: 'var(--search-bg)', },
                                    }}
                                >
                                    {dialogBoxShare()}
                                </Dialog>
                                {product !== null ?
                                    <div className="apps-card-products flex gap-5 p-5 w-full rounded-2xl justify-center items-center bg-[var(--search-bg)]">
                                        {/* <Helmet>
                                            <title>{`MartPuram | Your Local E-Market - ${product.productName}`}</title>
                                            <meta name='description' content={product.description} />
                                            <meta property="og:image" content={product.productImage[0]} />
                                            <meta property="og:url" content={window.location.href} />
                                            <meta property="og:type" content="website" />
                                        </Helmet> */}
                                        <div className="ProductImage">
                                            {/* <img src={product.productImage[0]} alt="" className='h-80 w-80 rounded-2xl' /> */}
                                            <Carousel autoPlay interval={4000} infiniteLoop showThumbs={false} showStatus={false} showIndicators={false} className="BannerAdsDiv h-max w-80 rounded-2xl">
                                                {product.productImage.length > 0 && product.productImage?.map((ad, index) => (
                                                    <div key={index} className="h-full w-full cursor-pointer rounded-2xl p-2">
                                                        <img src={ad} alt={`slide ${index + 1}`} className={`h-full w-full object-contain rounded-2xl cursor-pointer`} />
                                                    </div>
                                                ))}
                                            </Carousel>
                                        </div>
                                        <div className={`ProductDetails flex flex-col ${bottomBarVisible <= 945 ? 'w-full' : 'w-full'}`}>
                                            <h1 className='font-bold text-2xl'>{product.productName}</h1>
                                            <div className={`ItemPrice w-full mt-5 flex ${bottomBarVisible <= 945 ? 'flex-col gap-5' : 'flex-row'} justify-between items-center`}>
                                                <div className="w-max flex justify-between gap-5 items-center bg-[var(--search-bg)] p-2 rounded-xl">
                                                    <h1 className='font-semibold text-xl'>₹{product.priceDiscounted}</h1>
                                                    <h1 className='font-semibold text-xl'><s>₹{product.priceOriginal}</s></h1>
                                                </div>
                                                {product.fixed === true ? <h1 className='font-semibold text-xl p-2 rounded-xl bg-[var(--search-bg)]'>Fixed Price</h1> : <h1 className='font-semibold text-xl  p-2 rounded-xl bg-[var(--search-bg)]'>Price Negotiable</h1>}
                                            </div>
                                            <h1 className='font-semibold text-xl mt-5'>{product.description}</h1>
                                        </div>
                                        <div className="ItemPrice w-full mt-5 flex flex-row justify-center items-center gap-5">
                                            {props.UserData !== null && props.UserData.Saved.Products.includes(product.productID) ? <h1 className='font-semibold text-xl p-2 rounded-xl bg-[var(--search-bg)] cursor-pointer flex justify-center items-center text-center' onClick={() => UnSaveItem(product)}>UNSAVE</h1> : <h1 className='font-semibold text-xl p-2 rounded-xl bg-[var(--search-bg)] cursor-pointer flex justify-center items-center text-center' onClick={() => SaveItem(product)}>SAVE</h1>}
                                            {/* {props.UserData !== null && props.UserData.storeID !== product.storeID && <h1 className='font-semibold text-xl p-2 rounded-xl bg-[var(--search-bg)] cursor-pointer flex justify-center items-center text-center' onClick={ChatID}>CONTACT STORE</h1>} */}
                                            <h1 className='font-semibold text-xl p-2 rounded-xl bg-[var(--search-bg)] cursor-pointer flex justify-center items-center text-center' onClick={handleOpenShare}>SHARE</h1>
                                            <h1 className='font-semibold text-xl p-2 rounded-xl bg-[var(--search-bg)] cursor-pointer flex justify-center items-center text-center' onClick={ChatID}>CONTACT STORE</h1>
                                        </div>
                                    </div>
                                    :
                                    <div className="h-[90vh] w-full flex justify-center items-center text-center text-2xl font-black">
                                        PRODUCT NOT FOUND!
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="overlay-app" />
            </div>
            {bottomBarVisible <= 945 &&
                <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, backgroundColor: 'transparent' }} elevation={3}>
                    <BottomNavigation
                        className="backgroundColor: 'transparent',"
                        showLabels
                        value={-1}
                        onChange={(event, newValue) => {
                            setValue(-1);
                            newValue === 0 ? navigate('/') : newValue === 1 ? navigate('/Explore') : newValue === 2 ? navigate('/Sell') : newValue === 3 ? navigate('/Chats') : newValue === 4 ? navigate('/Profile') : navigate('/')
                        }}
                    >
                        <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<HomeRoundedIcon />} label="Home" />
                        <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<SlideshowIcon />} label="Shorts" />
                        <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<AddCircleRoundedIcon />} label="Sell" />
                        <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<QuestionAnswerRoundedIcon />} label="Chat" />
                        {currentUser ? <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<img src={currentUser.photoURL} className='h-6 w-6 rounded-full' />} label="Manage" /> : <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<PersonRoundedIcon />} label="Profile" />}
                    </BottomNavigation>
                </Paper>
            }
        </>
    )
}

export default Product