/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import SlideshowIcon from '@mui/icons-material/Slideshow';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import QuestionAnswerRoundedIcon from '@mui/icons-material/QuestionAnswerRounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import {
    Link, useNavigate,
} from 'react-router-dom';
import gsap from 'gsap';
import { AuthContext } from '../context/AuthContext';
import { BottomNavigation, BottomNavigationAction, Paper } from '@mui/material';
import BackButton from '../components/BackButton';

const TermsAndConditions = (props) => {
    const { currentUser } = useContext(AuthContext);
    const [bottomBarVisible, setBottomBarVisible] = useState(window.screen.width);

    useEffect(() => {
        const interval = setInterval(() => {
            setBottomBarVisible(window.screen.width)
        }, 100);
        return () => clearInterval(interval);
    }, []);

    // eslint-disable-next-line no-unused-vars
    const [value, setValue] = useState(4);
    // const ProfileRef = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        gsap.fromTo(".content-wrapper", { opacity: 0 }, { opacity: 1, duration: 2 });
    }, []);

    // useEffect(() => {
    //     if (!currentUser) {
    //         navigate('/Auth')
    //     }
    // }, [currentUser])

    useEffect(() => {
        if (props.UserData !== null) {
            if (currentUser && props.UserData !== null && props.UserData.NewUser === true) {
                navigate('/Onboarding');
            }
        }
    }, [currentUser, props.UserData])

    return (
        <>
            <div className="app">
                <div className="wrapper">
                    <div className="left-side">
                        <div className="side-wrapper flex h-[100%]">
                            <div className="side-menu flex flex-col justify-evenly h-full">
                                <Link to="/" className="flex flex-row items-center gap-2 w-full">
                                    <img src="https://cdn-icons-png.freepik.com/512/4194/4194676.png" alt="Home Icon" style={{ height: 28, width: 28 }} />
                                    <h2 className="font-extrabold text-lg">Home</h2>
                                </Link>
                                <Link to="/Explore" className="flex flex-row items-center gap-2 w-full">
                                    <img src="https://cdn-icons-png.freepik.com/512/6337/6337301.png" alt="Explore Icon" style={{ height: 28, width: 28 }} />
                                    <h2 className="font-extrabold text-lg">Feed</h2>
                                </Link>
                                <Link to="/Sell" className="flex flex-row items-center gap-2 w-full">
                                    <img src="https://cdn-icons-png.freepik.com/512/10337/10337579.png" alt="Sell Icon" style={{ height: 28, width: 28 }} />
                                    <h2 className="font-extrabold text-lg">Sell</h2>
                                </Link>
                                <Link to="/Chats" className="flex flex-row items-center gap-2 w-full">
                                    <img src="https://cdn-icons-png.freepik.com/512/1041/1041916.png" alt="Chat Icon" style={{ height: 28, width: 28 }} />
                                    <h2 className="font-extrabold text-lg">Chat</h2>
                                </Link>
                                <Link to="/Profile" className="flex flex-row items-center gap-2 w-full">
                                    {currentUser ? <img src={currentUser.photoURL} alt="Profile Picture" style={{ height: 28, width: 28, borderRadius: '100%' }} /> : <img src="https://cdn-icons-png.freepik.com/512/1177/1177568.png" alt="Profile Picture" style={{ height: 28, width: 28 }} />}
                                    <h2 className="font-extrabold text-lg">Profile</h2>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="main-container">
                        <div className="content-wrapper">
                            {bottomBarVisible <= 945 && <BackButton />}
                            <div className={`${bottomBarVisible <= 945 ? 'mb-28' : ''} mt-2`}>
                                <div className="content-wrapper-header flex justify-center align-center items-center text-center">
                                    <h1 className='font-extrabold text-3xl'>Terms & Conditions</h1>
                                </div>
                                <div className="content-section">
                                    <div className="content-section-title font-bold">Effective Date: March 19, 2024</div>
                                    <div className="QuestionAnswers flex w-full h-full flex-col gap-2">
                                        These Terms and Conditions govern your access to and use of the Martpuram online marketplace platform. By accessing or using the Platform, you agree to be bound by these Terms.
                                    </div>
                                </div>
                                <div className="content-section">
                                    <div className="content-section-title font-bold">1) User Accounts</div>
                                    <div className="QuestionAnswers flex w-full h-full flex-col gap-2">
                                        <ol className="flex gap-2 flex-col">
                                            <li><span className="font-bold">•</span> You may be required to create an account to access certain features of the Platform.</li>
                                            <li><span className="font-bold">•</span> You are responsible for maintaining the confidentiality of your account information, including your password.</li>
                                            <li><span className="font-bold">•</span> You are responsible for all activity that occurs under your account.</li>
                                            <li><span className="font-bold">•</span> You agree to notify Martpuram immediately of any unauthorized use of your account or any other breach of security.</li>
                                        </ol>
                                    </div>
                                </div>
                                <div className="content-section">
                                    <div className="content-section-title font-bold">2) Use of the Platform</div>
                                    <div className="QuestionAnswers flex w-full h-full flex-col gap-2">
                                        <ol className="flex gap-2 flex-col">
                                            <li><span className="font-bold">•</span> You agree to use the Platform for lawful purposes only.</li>
                                            <li><span className="font-bold">•</span> You agree not to use the Platform to:</li>
                                            <div className="ml-5">
                                                <li className="mt-2"><span className="font-bold">1)</span> Upload or post any content that is illegal, harmful, threatening, abusive, harassing, defamatory, obscene, hateful, or racially or ethnically offensive.</li>
                                                <li className="mt-2"><span className="font-bold">2)</span> Infringe on the intellectual property rights of others.</li>
                                                <li className="mt-2"><span className="font-bold">3)</span> Transmit any viruses or other harmful code.</li>
                                                <li className="mt-2"><span className="font-bold">4)</span> Interfere with the use of the Platform by others.</li>
                                                <li className="mt-2"><span className="font-bold">5)</span> Attempt to gain unauthorized access to the Platform or its systems.</li>
                                            </div>
                                        </ol>
                                    </div>
                                </div>
                                <div className="content-section">
                                    <div className="content-section-title font-bold">3) Vendor Policies</div>
                                    <div className="QuestionAnswers flex w-full h-full flex-col gap-2">
                                        <ol className="flex gap-2 flex-col">
                                            <li><span className="font-bold">•</span> Vendors are responsible for complying with all applicable laws and regulations related to the listing and sale of their products.</li>
                                            <li><span className="font-bold">•</span> Vendors are responsible for the accuracy, completeness, and timeliness of their product listings.</li>
                                            <li><span className="font-bold">•</span> Vendors are responsible for fulfilling all orders placed through the Platform.</li>
                                            <li><span className="font-bold">•</span> Martpuram reserves the right to remove any product listing that violates these Terms or is otherwise deemed inappropriate.</li>
                                        </ol>
                                    </div>
                                </div>
                                <div className="content-section">
                                    <div className="content-section-title font-bold">4) Buyer Policies</div>
                                    <div className="QuestionAnswers flex w-full h-full flex-col gap-2">
                                        <ol className="flex gap-2 flex-col">
                                            <li><span className="font-bold">•</span> Buyers are responsible for reviewing product listings carefully before making a purchase.</li>
                                            <li><span className="font-bold">•</span> Buyers agree to pay for all purchases made through the Platform.</li>
                                            <li><span className="font-bold">•</span> Martpuram does not guarantee the quality, safety, or accuracy of any products listed on the Platform.</li>
                                            <li><span className="font-bold">•</span> Buyers are responsible for resolving any disputes with vendors directly.</li>
                                        </ol>
                                    </div>
                                </div>
                                <div className="content-section">
                                    <div className="content-section-title font-bold">5) Payment Processing</div>
                                    <div className="QuestionAnswers flex w-full h-full flex-col gap-2">
                                        <ol className="flex gap-2 flex-col">
                                            <li><span className="font-bold">•</span> Martpuram may use a third-party payment processor to facilitate transactions on the Platform.</li>
                                            <li><span className="font-bold">•</span> You agree to comply with the terms of service of any third-party payment processor used by Martpuram.</li>
                                        </ol>
                                    </div>
                                </div>
                                <div className="content-section">
                                    <div className="content-section-title font-bold">6) Intellectual Property</div>
                                    <div className="QuestionAnswers flex w-full h-full flex-col gap-2">
                                        <ol className="flex gap-2 flex-col">
                                            <li><span className="font-bold">•</span> The Platform and its content are the property of Martpuram or its licensors and are protected by copyright, trademark, and other intellectual property laws.</li>
                                            <li><span className="font-bold">•</span> You may not use any content from the Platform without the express written permission of Martpuram.</li>
                                        </ol>
                                    </div>
                                </div>
                                <div className="content-section">
                                    <div className="content-section-title font-bold">7) Disclaimers</div>
                                    <div className="QuestionAnswers flex w-full h-full flex-col gap-2">
                                        <ol className="flex gap-2 flex-col">
                                            <li><span className="font-bold">•</span> The Platform is provided "as is" and without warranties of any kind, express or implied.</li>
                                            <li><span className="font-bold">•</span> Martpuram disclaims all warranties, including, but not limited to, warranties of merchantability, fitness for a particular purpose, and non-infringement.</li>
                                            <li><span className="font-bold">•</span> Martpuram does not warrant that the Platform will be uninterrupted, secure, or error-free.</li>
                                        </ol>
                                    </div>
                                </div>
                                <div className="content-section">
                                    <div className="content-section-title font-bold">8) Limitation of Liability</div>
                                    <div className="QuestionAnswers flex w-full h-full flex-col gap-2">
                                        <ol className="flex gap-2 flex-col">
                                            <li><span className="font-bold">•</span> Martpuram will not be liable for any damages arising out of your use of the Platform, including, but not limited to, direct, indirect, incidental, consequential, or punitive damages.</li>
                                        </ol>
                                    </div>
                                </div>
                                <div className="content-section">
                                    <div className="content-section-title font-bold">9) Termination</div>
                                    <div className="QuestionAnswers flex w-full h-full flex-col gap-2">
                                        <ol className="flex gap-2 flex-col">
                                            <li><span className="font-bold">•</span> Martpuram may terminate your access to the Platform for any reason, at any time, without notice.</li>
                                            <li><span className="font-bold">•</span> You may terminate your account at any time.</li>
                                        </ol>
                                    </div>
                                </div>
                                <div className="content-section">
                                    <div className="content-section-title font-bold">10) Governing Law</div>
                                    <div className="QuestionAnswers flex w-full h-full flex-col gap-2">
                                        <ol className="flex gap-2 flex-col">
                                            <li><span className="font-bold">•</span> These Terms will be governed by and construed in accordance with the laws of [Your Jurisdiction].</li>
                                        </ol>
                                    </div>
                                </div>
                                <div className="content-section">
                                    <div className="content-section-title font-bold">11) Dispute Resolution</div>
                                    <div className="QuestionAnswers flex w-full h-full flex-col gap-2">
                                        <ol className="flex gap-2 flex-col">
                                            <li><span className="font-bold">•</span> Any dispute arising out of or relating to these Terms will be resolved by [Arbitration or litigation, as applicable in your jurisdiction] in [Your Jurisdiction].</li>
                                        </ol>
                                    </div>
                                </div>
                                <div className="content-section">
                                    <div className="content-section-title font-bold">12) Entire Agreement</div>
                                    <div className="QuestionAnswers flex w-full h-full flex-col gap-2">
                                        <ol className="flex gap-2 flex-col">
                                            <li><span className="font-bold">•</span> These Terms constitute the entire agreement between you and Martpuram regarding your use of the Platform.</li>
                                        </ol>
                                    </div>
                                </div>
                                <div className="content-section">
                                    <div className="content-section-title font-bold">13) Severability</div>
                                    <div className="QuestionAnswers flex w-full h-full flex-col gap-2">
                                        <ol className="flex gap-2 flex-col">
                                            <li><span className="font-bold">•</span> If any provision of these Terms is held to be invalid or unenforceable, such provision will be struck and the remaining provisions will remain in full force and effect.</li>
                                        </ol>
                                    </div>
                                </div>
                                <div className="content-section">
                                    <div className="content-section-title font-bold">14) Amendment</div>
                                    <div className="QuestionAnswers flex w-full h-full flex-col gap-2">
                                        <ol className="flex gap-2 flex-col">
                                            <li><span className="font-bold">•</span> Martpuram may amend these Terms at any time by posting the amended Terms on the Platform. You are responsible for reviewing the Terms regularly. Your continued use of the Platform after the amended Terms are posted will constitute your acceptance of the amended Terms.</li>
                                        </ol>
                                    </div>
                                </div>
                                <div className={`content-section`}>
                                    <div className="content-section-title font-bold">Changes to this Privacy Policy</div>
                                    <div className="QuestionAnswers flex w-full h-full flex-col gap-2">
                                        We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on the Platform.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="overlay-app" />
            </div>
            {bottomBarVisible <= 945 &&
                <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, backgroundColor: 'transparent' }} elevation={3}>
                    <BottomNavigation
                        className="backgroundColor: 'transparent',"
                        showLabels
                        value={-1}
                        onChange={(event, newValue) => {
                            setValue(newValue);
                            newValue === 0 ? navigate('/') : newValue === 1 ? navigate('/Explore') : newValue === 2 ? navigate('/Sell') : newValue === 3 ? navigate('/Chats') : newValue === 4 ? navigate('/Profile') : navigate('/')
                        }}
                    >
                        <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<HomeRoundedIcon />} label="Home" />
                        <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<SlideshowIcon />} label="Shorts" />
                        <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<AddCircleRoundedIcon />} label="Sell" />
                        <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<QuestionAnswerRoundedIcon />} label="Chat" />
                        {currentUser ? <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<img src={currentUser.photoURL} className='h-6 w-6 rounded-full' />} label="Manage" /> : <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<PersonRoundedIcon />} label="Profile" />}
                    </BottomNavigation>
                </Paper>
            }
        </>
    )
}

export default TermsAndConditions