/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import { BottomNavigation, BottomNavigationAction, Paper } from '@mui/material';
import React, { useContext, useEffect, useRef, useState } from 'react';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import SlideshowIcon from '@mui/icons-material/Slideshow';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import QuestionAnswerRoundedIcon from '@mui/icons-material/QuestionAnswerRounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import {
    Link, useNavigate,
} from 'react-router-dom';
import gsap from 'gsap';
import { AuthContext } from '../context/AuthContext';
import { arrayUnion, doc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';
import BackButton from '../components/BackButton';

const Stories = (props) => {
    const { currentUser } = useContext(AuthContext);
    const [bottomBarVisible, setBottomBarVisible] = useState(window.screen.width);
    const [searchText, setSearchText] = useState('');

    useEffect(() => {
        const interval = setInterval(() => {
            setBottomBarVisible(window.screen.width)
        }, 100);
        return () => clearInterval(interval);
    }, []);

    // eslint-disable-next-line no-unused-vars
    const [value, setValue] = useState(0);
    const StoriesRef = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (props.ViewAllStory === "Clicked") {
            StoriesRef.current = gsap.fromTo(".content-section", {
                x: "100%",
            },
                {
                    x: 0,
                    duration: 0.5,
                    ease: "power2.inOut"
                });
        }
    }, [props.ViewAllStory]);


    useEffect(() => {
        gsap.fromTo(".content-wrapper", { opacity: 0 }, { opacity: 1, duration: 2 });
        props.ViewAllStories("");
    }, []);

    const truncate = (str, length) => {
        if (str.length > length) {
            return str.slice(0, length) + "...";
        } else {
            return str;
        }
    }

    const stories = [];
    props.StoriesData.map(item => stories.includes(item.storeID) === false && stories.push(item.storeID))

    const HandleShowStory = async (val) => {
        navigate(`/Story/${val}`)
        currentUser && await updateDoc(doc(db, 'Users', currentUser.uid), {
            Viewed: arrayUnion(val),
        })
    }

    function shuffleArray1(array) {
        const newArray = array;
        for (let i = newArray.length - 1; i > 0; i--) {
            let j = Math.floor(Math.random() * (i + 1));
            [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
        }
        return newArray;
    }

    useEffect(() => {
        if (props.UserData !== null) {
            if (currentUser && props.UserData !== null && props.UserData.NewUser === true) {
                navigate('/Onboarding');
            }
        }
    }, [currentUser, props.UserData])

    return (
        <>
            <div className="app">
                <div className="wrapper">
                    <div className="left-side">
                        <div className="side-wrapper flex h-[100%]">
                            <div className="side-menu flex flex-col justify-evenly h-full">
                                <Link to="/" className="flex flex-row items-center gap-2 w-full">
                                    <img src="https://cdn-icons-png.freepik.com/512/4194/4194676.png" alt="Home Icon" style={{ height: 28, width: 28 }} />
                                    <h2 className="font-extrabold text-lg">Home</h2>
                                </Link>
                                <Link to="/Explore" className="flex flex-row items-center gap-2 w-full">
                                    <img src="https://cdn-icons-png.freepik.com/512/6337/6337301.png" alt="Explore Icon" style={{ height: 28, width: 28 }} />
                                    <h2 className="font-extrabold text-lg">Feed</h2>
                                </Link>
                                <Link to="/Sell" className="flex flex-row items-center gap-2 w-full">
                                    <img src="https://cdn-icons-png.freepik.com/512/10337/10337579.png" alt="Sell Icon" style={{ height: 28, width: 28 }} />
                                    <h2 className="font-extrabold text-lg">Sell</h2>
                                </Link>
                                <Link to="/Chats" className="flex flex-row items-center gap-2 w-full">
                                    <img src="https://cdn-icons-png.freepik.com/512/1041/1041916.png" alt="Chat Icon" style={{ height: 28, width: 28 }} />
                                    <h2 className="font-extrabold text-lg">Chat</h2>
                                </Link>
                                <Link to="/Profile" className="flex flex-row items-center gap-2 w-full">
                                    {currentUser ? <img src={currentUser.photoURL} alt="Profile Picture" style={{ height: 28, width: 28, borderRadius: '100%' }} /> : <img src="https://cdn-icons-png.freepik.com/512/1177/1177568.png" alt="Profile Picture" style={{ height: 28, width: 28 }} />}
                                    <h2 className="font-extrabold text-lg">Profile</h2>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="main-container">
                        <div className="main-header">
                            <div className="search-bar gap-2">
                                {bottomBarVisible <= 945 && <BackButton />}
                                <input type="text" placeholder="Search Stories" value={searchText} onChange={(e) => setSearchText(e.target.value)} />
                            </div>
                        </div>
                        <div className="content-wrapper">
                            <div className={`${bottomBarVisible <= 945 ? 'mb-28' : ''}`}>
                                <div className="content-section" ref={StoriesRef}>
                                    <div className="content-section-title">Stories</div>
                                    <div className={`apps-card flex gap-2 ${bottomBarVisible <= 730 && 'justify-evenly'}`}>
                                        {currentUser ?
                                            <>
                                                {props.UserData !== null && props.Shops?.length > 0 && shuffleArray1(stories).sort((a, b) => {
                                                    const aIsViewed = props.UserData.Viewed.includes(a);
                                                    const bIsViewed = props.UserData.Viewed.includes(b);
                                                    if (aIsViewed && !bIsViewed) {
                                                        return 1;
                                                    } else if (!aIsViewed && bIsViewed) {
                                                        return -1;
                                                    } else {
                                                        return 0;
                                                    }
                                                }).map((item, index) => (
                                                    <>
                                                        {props.Shops?.length > 0 &&
                                                            <div key={index} className="catCard h-40 w-40 flex flex-col gap-2 justify-center items-center text-center p-5 rounded-md" onClick={() => HandleShowStory(props.Shops.filter(i => (i.storeID === item))[0].storeID)}>
                                                                <div className={`storyCircle ${props.UserData.Viewed.includes(props.Shops.filter(i => (i.storeID === item))[0].storeID) === true ? 'storyViewed' : 'storyUnViewed'} p-1 rounded-full h-max w-max`}>
                                                                    <img src={props.Shops.filter(i => (i.storeID === item))[0].logo} alt="" className='h-24 w-24 rounded-full' />
                                                                </div>
                                                                <div className="categoryName"><h1>{truncate(props.Shops.filter(i => (i.storeID === item))[0].storeName, 10)}</h1></div>
                                                            </div>
                                                        }
                                                    </>
                                                ))}
                                            </>
                                            :
                                            <>
                                                {props.Shops?.length > 0 && stories.map((item, index) => (
                                                    <>
                                                        {props.Shops?.length > 0 &&
                                                            <div key={index} className="catCard h-40 w-40 flex flex-col gap-2 justify-center items-center text-center p-5 rounded-md" onClick={() => navigate(`/Story/${props.Shops.filter(i => (i.storeID === item))[0].storeID}`)}>
                                                                <div className={`storyCircle storyUnViewed p-1 rounded-full h-max w-max`}>
                                                                    <img src={props.Shops.filter(i => (i.storeID === item))[0].logo} alt="" className='h-24 w-24 rounded-full' />
                                                                </div>
                                                                <div className="categoryName"><h1>{truncate(props.Shops.filter(i => (i.storeID === item))[0].storeName, 10)}</h1></div>
                                                            </div>
                                                        }
                                                    </>
                                                ))}
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="overlay-app" />
            </div>
            {bottomBarVisible <= 945 &&
                <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, backgroundColor: 'transparent' }} elevation={3}>
                    <BottomNavigation
                        className="backgroundColor: 'transparent',"
                        showLabels
                        value={-1}
                        onChange={(event, newValue) => {
                            setValue(newValue);
                            newValue === 0 ? navigate('/') : newValue === 1 ? navigate('/Explore') : newValue === 2 ? navigate('/Sell') : newValue === 3 ? navigate('/Chats') : newValue === 4 ? navigate('/Profile') : navigate('/')
                        }}
                    >
                        <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<HomeRoundedIcon />} label="Home" />
                        <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<SlideshowIcon />} label="Shorts" />
                        <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<AddCircleRoundedIcon />} label="Sell" />
                        <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<QuestionAnswerRoundedIcon />} label="Chat" />
                        {currentUser ? <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<img src={currentUser.photoURL} className='h-6 w-6 rounded-full' />} label="Manage" /> : <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<PersonRoundedIcon />} label="Profile" />}
                    </BottomNavigation>
                </Paper>
            }
        </>
    )
}

export default Stories