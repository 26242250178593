/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable react-hooks/exhaustive-deps */
import { BottomNavigation, BottomNavigationAction, Paper } from '@mui/material';
import React, { useContext, useEffect, useRef, useState } from 'react';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import SlideshowIcon from '@mui/icons-material/Slideshow';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import QuestionAnswerRoundedIcon from '@mui/icons-material/QuestionAnswerRounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import {
    Link, useNavigate,
} from 'react-router-dom';
import gsap from 'gsap';
import { AuthContext } from '../context/AuthContext';
import BackButton from '../components/BackButton';

const Chats = (props) => {
    const { currentUser } = useContext(AuthContext);
    const [bottomBarVisible, setBottomBarVisible] = useState(window.screen.width);
    const [searchText, setSearchText] = useState('');

    useEffect(() => {
        const interval = setInterval(() => {
            setBottomBarVisible(window.screen.width)
        }, 100);
        return () => clearInterval(interval);
    }, []);

    const [value, setValue] = useState(3);
    const ChatsRef = useRef(null);
    const navigate = useNavigate();

    const truncate = (str, length) => {
        if (str.length > length) {
            return str.slice(0, length) + "...";
        } else {
            return str;
        }
    }

    useEffect(() => {
        gsap.fromTo(".content-wrapper", { opacity: 0 }, { opacity: 1, duration: 2 });
    }, []);

    useEffect(() => {
        if (props.UserData !== null) {
            if (currentUser && props.UserData !== null && props.UserData.NewUser === true) {
                navigate('/Onboarding');
            }
        }
    }, [currentUser, props.UserData])

    return (
        <>
            <div className="app">
                <div className="wrapper">
                    <div className="left-side">
                        <div className="side-wrapper flex h-[100%]">
                            <div className="side-menu flex flex-col justify-evenly h-full">
                                <Link to="/" className="flex flex-row items-center gap-2 w-full">
                                    <img src="https://cdn-icons-png.freepik.com/512/4194/4194676.png" alt="Home Icon" style={{ height: 28, width: 28 }} />
                                    <h2 className="font-extrabold text-lg">Home</h2>
                                </Link>
                                <Link to="/Explore" className="flex flex-row items-center gap-2 w-full">
                                    <img src="https://cdn-icons-png.freepik.com/512/6337/6337301.png" alt="Explore Icon" style={{ height: 28, width: 28 }} />
                                    <h2 className="font-extrabold text-lg">Feed</h2>
                                </Link>
                                <Link to="/Sell" className="flex flex-row items-center gap-2 w-full">
                                    <img src="https://cdn-icons-png.freepik.com/512/10337/10337579.png" alt="Sell Icon" style={{ height: 28, width: 28 }} />
                                    <h2 className="font-extrabold text-lg">Sell</h2>
                                </Link>
                                <Link to="/Chats" className="flex flex-row items-center gap-2 w-full">
                                    <img src="https://cdn-icons-png.freepik.com/512/1041/1041916.png" alt="Chat Icon" style={{ height: 28, width: 28 }} />
                                    <h2 className="font-extrabold text-lg">Chat</h2>
                                </Link>
                                <Link to="/Profile" className="flex flex-row items-center gap-2 w-full">
                                    {currentUser ? <img src={currentUser.photoURL} alt="Profile Picture" style={{ height: 28, width: 28, borderRadius: '100%' }} /> : <img src="https://cdn-icons-png.freepik.com/512/1177/1177568.png" alt="Profile Picture" style={{ height: 28, width: 28 }} />}
                                    <h2 className="font-extrabold text-lg">Profile</h2>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="main-container">
                        <div className="main-header">
                            <div className="search-bar">
                                {bottomBarVisible <= 945 && <BackButton />}
                                <input type="text" placeholder="Search Inbox" value={searchText} onChange={(e) => setSearchText(e.target.value)} />
                            </div>
                        </div>
                        {Object.keys(props.Inbox).length > 0 ?
                            <div className="content-wrapper">
                                <div className={`${bottomBarVisible <= 945 ? 'mb-28' : ''}`}>
                                    {bottomBarVisible <= 945 ?
                                        <div className="content-section-chats mb-14" ref={ChatsRef}>
                                            {searchText ?
                                                <div className="InboxUsers hide-scrollbar flex flex-col h-full w-full overflow-y-scroll overflow-x-hidden justify-center items-center gap-2">
                                                    {Object.entries(props.Inbox)?.filter((entry) => entry[1].userInfo.displayName.toLowerCase().includes(searchText.toLowerCase())).sort((a, b) => b[1].Time - a[1].Time).length > 0 ?
                                                        Object.entries(props.Inbox)?.filter((entry) => entry[1].userInfo.displayName.toLowerCase().includes(searchText.toLowerCase())).sort((a, b) => b[1].Time - a[1].Time).map((item, index) => (
                                                            <div key={index} className="UserChat cursor-pointer rounded-xl h-20 w-full" style={{ backgroundColor: 'rgba(155, 155, 155, 0.3)' }} onClick={() => { props.ViewChats('Clicked'); navigate(`/Chat/${item[1].userInfo.chatId}`) }}>
                                                                <div className="detailsUser w-full h-full p-2 flex flex-row gap-5 items-center">
                                                                    <img src={item[1].userInfo.photoURL} alt="" className='userProfilePic h-10 w-10 rounded-full' />
                                                                    <div className="userNameAndLastMessage w-full flex flex-col items-center justify-start">
                                                                        <div className="userName w-full flex justify-start font-extrabold text-xl">{item[1].userInfo.displayName}</div>
                                                                        <div className="userLastMessage w-full flex justify-start">{truncate(item[1].LastMessage.text, 28)}</div>
                                                                    </div>
                                                                    {item[1].Unread > 0 &&
                                                                        <div className="NewMessage h-full flex justify-center items-center">
                                                                            <div className="MessageCountNumber flex justify-center items-center rounded-full h-8 w-8 bg-[#8c52ff] text-white text-base font-extrabold">
                                                                                {item[1].Unread > 9 ? `9+` : `${item[1].Unread}`}
                                                                            </div>
                                                                        </div>}
                                                                </div>
                                                            </div>
                                                        ))
                                                        :
                                                        <div className="NoSearchInbox font-extrabold uppercase text-2xl">
                                                            No Users Found
                                                        </div>
                                                    }
                                                </div>
                                                :
                                                <div className="InboxUsers hide-scrollbar flex flex-col h-full w-full overflow-y-scroll overflow-x-hidden justify-center items-center gap-2">
                                                    {Object.entries(props.Inbox)?.sort((a, b) => b[1].Time - a[1].Time).map((item, index) => (
                                                        <div key={index} className="UserChat cursor-pointer rounded-xl h-20 w-full" style={{ backgroundColor: 'rgba(155, 155, 155, 0.3)' }} onClick={() => { props.ViewChats('Clicked'); navigate(`/Chat/${item[1].userInfo.chatId}`) }}>
                                                            <div className="detailsUser w-full h-full p-2 flex flex-row gap-5 items-center">
                                                                <img src={item[1].userInfo.photoURL} alt="" className='userProfilePic h-10 w-10 rounded-full' />
                                                                <div className="userNameAndLastMessage w-full flex flex-col items-center justify-start">
                                                                    <div className="userName w-full flex justify-start font-extrabold text-xl">{item[1].userInfo.displayName}</div>
                                                                    <div className="userLastMessage w-full flex justify-start">{truncate(item[1].LastMessage.text, 28)}</div>
                                                                </div>
                                                                {item[1].Unread > 0 &&
                                                                    <div className="NewMessage h-full flex justify-center items-center">
                                                                        <div className="MessageCountNumber flex justify-center items-center rounded-full h-8 w-8 bg-[#8c52ff] text-white text-base font-extrabold">
                                                                            {item[1].Unread > 9 ? `9+` : `${item[1].Unread}`}
                                                                        </div>
                                                                    </div>}
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            }
                                        </div>
                                        :
                                        <div className="content-section-chats mb-2" ref={ChatsRef}>
                                            {searchText ?
                                                <div className="InboxUsers hide-scrollbar flex flex-col h-full w-full overflow-y-scroll overflow-x-hidden justify-center items-center gap-2">
                                                    {Object.entries(props.Inbox)?.filter((entry) => entry[1].userInfo.displayName.toLowerCase().includes(searchText.toLowerCase())).sort((a, b) => b[1].Time - a[1].Time).length > 0 ?
                                                        Object.entries(props.Inbox)?.filter((entry) => entry[1].userInfo.displayName.toLowerCase().includes(searchText.toLowerCase())).sort((a, b) => b[1].Time - a[1].Time).map((item, index) => (
                                                            <div key={index} className="UserChat cursor-pointer rounded-xl h-20 w-full" style={{ backgroundColor: 'rgba(155, 155, 155, 0.3)' }} onClick={() => { props.ViewChats('Clicked'); navigate(`/Chat/${item[1].userInfo.chatId}`) }}>
                                                                <div className="detailsUser w-full h-full p-2 flex flex-row gap-5 items-center">
                                                                    <img src={item[1].userInfo.photoURL} alt="" className='userProfilePic h-10 w-10 rounded-full' />
                                                                    <div className="userNameAndLastMessage w-full flex flex-col items-center justify-start">
                                                                        <div className="userName w-full flex justify-start font-extrabold text-xl">{item[1].userInfo.displayName}</div>
                                                                        <div className="userLastMessage w-full flex justify-start">{truncate(item[1].LastMessage.text, 80)}</div>
                                                                    </div>
                                                                    {item[1].Unread > 0 &&
                                                                        <div className="NewMessage h-full flex justify-center items-center">
                                                                            <div className="MessageCountNumber flex justify-center items-center rounded-full h-8 w-8 bg-[#8c52ff] text-white text-base font-extrabold">
                                                                                {item[1].Unread > 9 ? `9+` : `${item[1].Unread}`}
                                                                            </div>
                                                                        </div>}
                                                                </div>
                                                            </div>
                                                        ))
                                                        :
                                                        <div className="NoSearchInbox font-extrabold uppercase text-2xl">
                                                            No Users Found
                                                        </div>
                                                    }
                                                </div>
                                                :
                                                <div className="InboxUsers hide-scrollbar flex flex-col h-full w-full overflow-y-scroll overflow-x-hidden justify-center items-center gap-2">
                                                    {Object.entries(props.Inbox)?.sort((a, b) => b[1].Time - a[1].Time).map((item, index) => (
                                                        <div key={index} className="UserChat cursor-pointer rounded-xl h-20 w-full" style={{ backgroundColor: 'rgba(155, 155, 155, 0.3)' }} onClick={() => { props.ViewChats('Clicked'); navigate(`/Chat/${item[1].userInfo.chatId}`) }}>
                                                            <div className="detailsUser w-full h-full p-2 flex flex-row gap-5 items-center">
                                                                <img src={item[1].userInfo.photoURL} alt="" className='userProfilePic h-10 w-10 rounded-full' />
                                                                <div className="userNameAndLastMessage w-full flex flex-col items-center justify-start">
                                                                    <div className="userName w-full flex justify-start font-extrabold text-xl">{item[1].userInfo.displayName}</div>
                                                                    <div className="userLastMessage w-full flex justify-start">{truncate(item[1].LastMessage.text, 80)}</div>
                                                                </div>
                                                                {item[1].Unread > 0 &&
                                                                    <div className="NewMessage h-full flex justify-center items-center">
                                                                        <div className="MessageCountNumber flex justify-center items-center rounded-full h-8 w-8 bg-[#8c52ff] text-white text-base font-extrabold">
                                                                            {item[1].Unread > 9 ? `9+` : `${item[1].Unread}`}
                                                                        </div>
                                                                    </div>}
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                            :
                            <div className="content-wrapper flex justify-center items-center text-2xl font-extrabold">
                                NO RECENT CHATS
                            </div>
                        }
                    </div>
                </div>
                <div className="overlay-app" />
            </div>
            {bottomBarVisible <= 945 &&
                <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, backgroundColor: 'transparent' }} elevation={3}>
                    <BottomNavigation
                        className="backgroundColor: 'transparent',"
                        showLabels
                        value={value}
                        onChange={(event, newValue) => {
                            setValue(newValue);
                            newValue === 0 ? navigate('/') : newValue === 1 ? navigate('/Explore') : newValue === 2 ? navigate('/Sell') : newValue === 3 ? navigate('/Chats') : newValue === 4 ? navigate('/Profile') : navigate('/')
                        }}
                    >
                        <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<HomeRoundedIcon />} label="Home" />
                        <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<SlideshowIcon />} label="Shorts" />
                        <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<AddCircleRoundedIcon />} label="Sell" />
                        <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<QuestionAnswerRoundedIcon />} label="Chat" />
                        {currentUser ? <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<img src={currentUser.photoURL} className='h-6 w-6 rounded-full' />} label="Manage" /> : <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<PersonRoundedIcon />} label="Profile" />}
                    </BottomNavigation>
                </Paper>
            }
        </>
    )
}

export default Chats