/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import SlideshowIcon from '@mui/icons-material/Slideshow';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import QuestionAnswerRoundedIcon from '@mui/icons-material/QuestionAnswerRounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import {
    Link, useNavigate,
} from 'react-router-dom';
import gsap from 'gsap';
import { AuthContext } from '../context/AuthContext';
import { Accordion, AccordionDetails, AccordionSummary, BottomNavigation, BottomNavigationAction, Paper } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import BackButton from '../components/BackButton';

const FAQ = (props) => {
    const { currentUser } = useContext(AuthContext);
    const [bottomBarVisible, setBottomBarVisible] = useState(window.screen.width);

    useEffect(() => {
        const interval = setInterval(() => {
            setBottomBarVisible(window.screen.width)
        }, 100);
        return () => clearInterval(interval);
    }, []);

    // eslint-disable-next-line no-unused-vars
    const [value, setValue] = useState(4);
    // const ProfileRef = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        gsap.fromTo(".content-wrapper", { opacity: 0 }, { opacity: 1, duration: 2 });
    }, []);

    // useEffect(() => {
    //     if (!currentUser) {
    //         navigate('/Auth')
    //     }
    // }, [currentUser])

    useEffect(() => {
        if (props.UserData !== null) {
            if (currentUser && props.UserData !== null && props.UserData.NewUser === true) {
                navigate('/Onboarding');
            }
        }
    }, [currentUser, props.UserData])

    return (
        <>
            <div className="app">
                <div className="wrapper">
                    <div className="left-side">
                        <div className="side-wrapper flex h-[100%]">
                            <div className="side-menu flex flex-col justify-evenly h-full">
                                <Link to="/" className="flex flex-row items-center gap-2 w-full">
                                    <img src="https://cdn-icons-png.freepik.com/512/4194/4194676.png" alt="Home Icon" style={{ height: 28, width: 28 }} />
                                    <h2 className="font-extrabold text-lg">Home</h2>
                                </Link>
                                <Link to="/Explore" className="flex flex-row items-center gap-2 w-full">
                                    <img src="https://cdn-icons-png.freepik.com/512/6337/6337301.png" alt="Explore Icon" style={{ height: 28, width: 28 }} />
                                    <h2 className="font-extrabold text-lg">Feed</h2>
                                </Link>
                                <Link to="/Sell" className="flex flex-row items-center gap-2 w-full">
                                    <img src="https://cdn-icons-png.freepik.com/512/10337/10337579.png" alt="Sell Icon" style={{ height: 28, width: 28 }} />
                                    <h2 className="font-extrabold text-lg">Sell</h2>
                                </Link>
                                <Link to="/Chats" className="flex flex-row items-center gap-2 w-full">
                                    <img src="https://cdn-icons-png.freepik.com/512/1041/1041916.png" alt="Chat Icon" style={{ height: 28, width: 28 }} />
                                    <h2 className="font-extrabold text-lg">Chat</h2>
                                </Link>
                                <Link to="/Profile" className="flex flex-row items-center gap-2 w-full">
                                    {currentUser ? <img src={currentUser.photoURL} alt="Profile Picture" style={{ height: 28, width: 28, borderRadius: '100%' }} /> : <img src="https://cdn-icons-png.freepik.com/512/1177/1177568.png" alt="Profile Picture" style={{ height: 28, width: 28 }} />}
                                    <h2 className="font-extrabold text-lg">Profile</h2>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="main-container">
                        <div className="content-wrapper">
                            {bottomBarVisible <= 945 && <BackButton />}
                            <div className={`${bottomBarVisible <= 945 ? 'mb-28' : ''} mt-2`}>
                                <div className="content-wrapper-header flex justify-center align-center items-center text-center">
                                    <h1 className='font-extrabold text-3xl'>FAQs</h1>
                                </div>
                                <div className="content-section">
                                    <div className="content-section-title">General FAQs</div>
                                    <div className="QuestionAnswers flex w-full h-full flex-col gap-2">
                                        <Accordion>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1-content"
                                                id="panel1-header"
                                            >
                                                What is Martpuram?
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                Martpuram is an online marketplace platform that connects local vendors with potential customers. Vendors can list their products on our platform but cannot process sales directly through Martpuram.
                                            </AccordionDetails>
                                        </Accordion>
                                        <Accordion>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1-content"
                                                id="panel1-header"
                                            >
                                                Is Martpuram free to use?
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                Yes, creating an account and listing products on Martpuram is free. However, sellers can pay for custom advertising campaigns to promote their listings.
                                            </AccordionDetails>
                                        </Accordion>
                                        <Accordion>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1-content"
                                                id="panel1-header"
                                            >
                                                How do I create an account?
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                Visit the Martpuram website and click on "Sign Up" to create a vendor account. You will need to provide your contact information and business details.
                                            </AccordionDetails>
                                        </Accordion>
                                        <Accordion>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1-content"
                                                id="panel1-header"
                                            >
                                                What information do I need to list my products?
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                You will need to provide product descriptions, photos, pricing information, and any other relevant details about your offerings.
                                            </AccordionDetails>
                                        </Accordion>
                                    </div>
                                </div>
                                <div className="content-section">
                                    <div className="content-section-title">Vendor FAQs</div>
                                    <div className="QuestionAnswers flex w-full h-full flex-col gap-2">
                                        <Accordion>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1-content"
                                                id="panel1-header"
                                            >
                                                Can I sell products directly on Martpuram?
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                No, Martpuram does not facilitate transactions between buyers and sellers.  Our platform is designed to help you showcase your products and connect with potential customers.
                                            </AccordionDetails>
                                        </Accordion>
                                        <Accordion>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1-content"
                                                id="panel1-header"
                                            >
                                                How do I get paid for my sales?
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                You will need to handle sales and customer transactions directly. Customers will contact you through the platform messaging system to inquire about your products.
                                            </AccordionDetails>
                                        </Accordion>
                                        <Accordion>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1-content"
                                                id="panel1-header"
                                            >
                                                How can I promote my products?
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                Martpuram offers sellers the opportunity to purchase custom advertising campaigns. These campaigns can target specific audiences and help you increase brand awareness and product visibility.
                                            </AccordionDetails>
                                        </Accordion>
                                        <Accordion>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1-content"
                                                id="panel1-header"
                                            >
                                                Are there any fees for advertising?
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                Yes, Martpuram advertising campaigns are purchased for a set fee.  Our policy is non-refundable once the campaign launches due to the costs associated with ad delivery.  However, you have options to manage your campaigns, including setting budgets and pausing campaigns.
                                            </AccordionDetails>
                                        </Accordion>
                                    </div>
                                </div>
                                <div className="content-section">
                                    <div className="content-section-title">Customer FAQs (Limited Functionality)</div>
                                    <div className="QuestionAnswers flex w-full h-full flex-col gap-2">
                                        <Accordion>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1-content"
                                                id="panel1-header"
                                            >
                                                Can I buy products on Martpuram?
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                Currently, Martpuram does not facilitate purchases directly on the platform. Our platform is designed to connect you with local vendors.  Once you find a product you are interested in, you can contact the seller directly to discuss the purchase.
                                            </AccordionDetails>
                                        </Accordion>
                                        <Accordion>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1-content"
                                                id="panel1-header"
                                            >
                                                How do I contact a seller about a product?
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                There is a messaging system within the Martpuram platform that allows you to communicate with sellers about their listed products.
                                            </AccordionDetails>
                                        </Accordion>
                                    </div>
                                </div>
                                <div className={`content-section`}>
                                    <div className="content-section-title">Refund and Cancellation FAQs</div>
                                    <div className="QuestionAnswers flex w-full h-full flex-col gap-2">
                                        <Accordion>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1-content"
                                                id="panel1-header"
                                            >
                                                What is your return policy?
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                Since Martpuram does not handle transactions, any return or refund policy would be determined by the individual seller.  We recommend checking the product listing or contacting the seller directly for their specific return policy.
                                            </AccordionDetails>
                                        </Accordion>
                                        <Accordion>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1-content"
                                                id="panel1-header"
                                            >
                                                Can I cancel my ad campaign and get a refund?
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                Martpuram operates on a non-refundable policy for ad campaigns once they have been launched due to the nature of digital advertising services and associated costs.  However, you can manage your campaigns by setting budgets, scheduling start and end dates, or pausing the campaign to stop further delivery of ads.
                                            </AccordionDetails>
                                        </Accordion>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="overlay-app" />
            </div>
            {bottomBarVisible <= 945 &&
                <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, backgroundColor: 'transparent' }} elevation={3}>
                    <BottomNavigation
                        className="backgroundColor: 'transparent',"
                        showLabels
                        value={-1}
                        onChange={(event, newValue) => {
                            setValue(newValue);
                            newValue === 0 ? navigate('/') : newValue === 1 ? navigate('/Explore') : newValue === 2 ? navigate('/Sell') : newValue === 3 ? navigate('/Chats') : newValue === 4 ? navigate('/Profile') : navigate('/')
                        }}
                    >
                        <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<HomeRoundedIcon />} label="Home" />
                        <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<SlideshowIcon />} label="Shorts" />
                        <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<AddCircleRoundedIcon />} label="Sell" />
                        <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<QuestionAnswerRoundedIcon />} label="Chat" />
                        {currentUser ? <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<img src={currentUser.photoURL} className='h-6 w-6 rounded-full' />} label="Manage" /> : <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<PersonRoundedIcon />} label="Profile" />}
                    </BottomNavigation>
                </Paper>
            }
        </>
    )
}

export default FAQ