/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import { BottomNavigation, BottomNavigationAction, Paper } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import SlideshowIcon from '@mui/icons-material/Slideshow';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import QuestionAnswerRoundedIcon from '@mui/icons-material/QuestionAnswerRounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import { Link, useNavigate, useParams } from 'react-router-dom';
import gsap from 'gsap';
import { AuthContext } from '../context/AuthContext';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { doc, onSnapshot } from 'firebase/firestore';
import { db } from '../firebase';
import BackButton from '../components/BackButton';

const Store = (props) => {
    const { currentUser } = useContext(AuthContext);
    const [bottomBarVisible, setBottomBarVisible] = useState(window.screen.width);
    const { SID } = useParams();
    const [storeData, setStoreData] = useState(null);
    const [searchText, setSearchText] = useState('');

    useEffect(() => {
        const GetStoreData = () => {
            const unsub = onSnapshot(doc(db, "Stores", SID), (doc) => {
                setStoreData(doc.data());
            });

            return () => {
                unsub();
            };
        }
        GetStoreData();
    }, [SID])

    useEffect(() => {
        const interval = setInterval(() => {
            setBottomBarVisible(window.screen.width)
        }, 100);
        return () => clearInterval(interval);
    }, []);

    // eslint-disable-next-line no-unused-vars
    const [value, setValue] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
        gsap.fromTo(".content-wrapper", { opacity: 0 }, { opacity: 1, duration: 2 });
    }, []);

    const truncate = (str, length) => {
        if (str.length > length) {
            return str.slice(0, length) + "...";
        } else {
            return str;
        }
    }

    useEffect(() => {
        if (props.UserData !== null) {
            if (currentUser && props.UserData !== null && props.UserData.NewUser === true) {
                navigate('/Onboarding');
            }
        }
    }, [currentUser, props.UserData])

    return (
        <>
            <div className="app">
                <div className="wrapper">
                    <div className="left-side">
                        <div className="side-wrapper flex h-[100%]">
                            <div className="side-menu flex flex-col justify-evenly h-full">
                                <Link to="/" className="flex flex-row items-center gap-2 w-full">
                                    <img src="https://cdn-icons-png.freepik.com/512/4194/4194676.png" alt="Home Icon" style={{ height: 28, width: 28 }} />
                                    <h2 className="font-extrabold text-lg">Home</h2>
                                </Link>
                                <Link to="/Explore" className="flex flex-row items-center gap-2 w-full">
                                    <img src="https://cdn-icons-png.freepik.com/512/6337/6337301.png" alt="Explore Icon" style={{ height: 28, width: 28 }} />
                                    <h2 className="font-extrabold text-lg">Feed</h2>
                                </Link>
                                <Link to="/Sell" className="flex flex-row items-center gap-2 w-full">
                                    <img src="https://cdn-icons-png.freepik.com/512/10337/10337579.png" alt="Sell Icon" style={{ height: 28, width: 28 }} />
                                    <h2 className="font-extrabold text-lg">Sell</h2>
                                </Link>
                                <Link to="/Chats" className="flex flex-row items-center gap-2 w-full">
                                    <img src="https://cdn-icons-png.freepik.com/512/1041/1041916.png" alt="Chat Icon" style={{ height: 28, width: 28 }} />
                                    <h2 className="font-extrabold text-lg">Chat</h2>
                                </Link>
                                <Link to="/Profile" className="flex flex-row items-center gap-2 w-full">
                                    {currentUser ? <img src={currentUser.photoURL} alt="Profile Picture" style={{ height: 28, width: 28, borderRadius: '100%' }} /> : <img src="https://cdn-icons-png.freepik.com/512/1177/1177568.png" alt="Profile Picture" style={{ height: 28, width: 28 }} />}
                                    <h2 className="font-extrabold text-lg">Profile</h2>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="main-container">
                        <div className="main-header">
                            <div className="search-bar gap-2">
                                {bottomBarVisible <= 945 && <BackButton />}
                                <input type="text" placeholder="Search Products" value={searchText} onChange={(e) => setSearchText(e.target.value)} />
                                {/* <div className="search-icon w-10 h-full flex justify-center items-center" style={{ borderRadius: '4px' }}>
                                    <img src="https://cdn-icons-png.freepik.com/512/4687/4687318.png" alt="search icon" className="h-6 w-6" />
                                </div> */}
                            </div>
                        </div>
                        <div className="content-wrapper">
                            <div className={`${bottomBarVisible <= 945 ? 'mb-28' : ''}`}>
                                {searchText ?
                                    <>
                                        {storeData !== null &&
                                            <div className="content-section">
                                                {storeData.Products.filter((entry) => entry.productName.toLowerCase().includes(searchText.toLowerCase())).sort((a, b) => a.productName.localeCompare(b.productName)).length > 0 ?
                                                    <>
                                                        <div className="content-section-title text-2xl">Products</div>
                                                        {/* <div className="apps-card flex gap-2">
                                                            {storeData.Products.filter((entry) => entry.productName.toLowerCase().includes(searchText.toLowerCase())).sort((a, b) => a.productName.localeCompare(b.productName)).map(product => (
                                                                <div className="catCard h-44 w-40 cursor-pointer flex flex-col gap-2 justify-center items-center p-2 rounded-md" onClick={() => navigate(`/Product/${product.productID}`)}>
                                                                    <div className="h-36 w-full flex justify-center items-center">
                                                                        <img src={product.productImage[0]} alt={product.productName} className="h-32 w-32 rounded-2xl" />
                                                                    </div>
                                                                    <div className="categoryName text-lg font-extrabold">{truncate(product.productName, 12)}</div>
                                                                </div>
                                                            ))}
                                                        </div> */}
                                                        <div className={`apps-card flex gap-2 ${bottomBarVisible <= 730 && 'justify-evenly'}`}>
                                                            {storeData.Products.filter((entry) => entry.productName.toLowerCase().includes(searchText.toLowerCase())).sort((a, b) => a.productName.localeCompare(b.productName)).map(product => (
                                                                <div className={`catCard ${bottomBarVisible <= 360 ? 'h-52 w-36' : 'h-52 w-40'} cursor-pointer flex flex-col gap-2 justify-center items-center p-2 rounded-md`} onClick={() => navigate(`/Product/${product.productID}`)}>
                                                                    <div className="h-36 w-full flex justify-center items-center">
                                                                        <img src={product.productImage[0]} alt={product.productName} className="h-32 w-32 rounded-2xl" />
                                                                    </div>
                                                                    <div className="categoryName text-base font-medium">{truncate(product.productName, 12)}</div>
                                                                    <div className="categoryName w-full flex justify-between">
                                                                        <span className="text-base font-medium">₹{product.priceDiscounted}</span>
                                                                        <span className="text-base font-medium"><s>₹{product.priceOriginal}</s></span>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                            {storeData.Products.filter((entry) => entry.productName.toLowerCase().includes(searchText.toLowerCase())).length % 2 !== 0 &&
                                                                <div className={`catCard ${bottomBarVisible <= 360 ? 'h-52 w-36' : 'h-52 w-40'} flex flex-col gap-2 justify-center items-center p-2 rounded-md`} style={{ visibility: 'hidden' }}>
                                                                    <div className="h-36 w-full flex justify-center items-center">
                                                                        <img src="" alt="" className="h-32 w-32 rounded-2xl" />
                                                                    </div>
                                                                    <div className="categoryName text-lg font-extrabold">lorem ipsum</div>
                                                                    <div className="categoryName text-lg font-extrabold">1000</div>
                                                                </div>
                                                            }
                                                        </div>
                                                    </>
                                                    :
                                                    <div className="NoProducts font-extrabold uppercase text-2xl">
                                                        No Products Found
                                                    </div>
                                                }
                                            </div>
                                        }
                                    </>
                                    :
                                    <>
                                        {storeData !== null &&
                                            <div className="StorePageDetails h-max w-full flex justify-center items-center">
                                                <div className="StoreLogoAndName flex w-max p-5 flex-col rounded-xl justify-between items-center gap-5">
                                                    <h1 className="text-4xl font-extrabold">{storeData.storeName}</h1>
                                                    <div className="flex flex-row h-full w-full gap-5 justify-center items-center">
                                                        <img src={storeData.logo} alt="logo" className="h-20 w-20 rounded-full" />
                                                        <div className="StoreDetails flex flex-col gap-2 items-start justify-center">
                                                            <div className="StoreOwner flex flex-row gap-2 items-center justify-center">
                                                                <img src={storeData.ownerPFP} alt="logo" className="h-10 w-10 rounded-full" />
                                                                <h1 className="text-base font-semibold">{storeData.owner}</h1>
                                                            </div>
                                                            <div className="StoreContact flex flex-row gap-2 items-center justify-center">
                                                                <img src="https://cdn-icons-png.freepik.com/512/8103/8103459.png" alt="Drop Icon" className='cursor-pointer' style={{ height: 40, width: 40 }} />
                                                                <h1 className="text-base font-semibold overflow-auto hide-scrollbar"><Link to={`tel:${storeData.number}`}>{storeData.number}</Link></h1>
                                                            </div>
                                                            <div className="StoreLocation flex flex-row gap-2 items-center justify-center">
                                                                <img src="https://cdn-icons-png.freepik.com/512/656/656803.png" alt="Drop Icon" className='cursor-pointer' style={{ height: 40, width: 40 }} />
                                                                <h1 className="text-base font-semibold overflow-auto hide-scrollbar">{storeData.address}</h1>
                                                            </div>
                                                            <div className="StoreProducts flex flex-row gap-2 items-center justify-center">
                                                                <img src="https://cdn-icons-png.freepik.com/512/10330/10330026.png" alt="Drop Icon" className='cursor-pointer' style={{ height: 40, width: 40 }} />
                                                                <h1 className="text-base font-semibold">{storeData.Products.length} {storeData.Products.length === 1 ? 'Product' : 'Products'}</h1>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {storeData !== null &&
                                            <div className="content-section">
                                                <div className="content-section-title text-2xl">Products</div>
                                                {/* <div className="apps-card flex gap-2">
                                                    {storeData.Products.sort((a, b) => a.productName.localeCompare(b.productName)).map(product => (
                                                        <div className="catCard h-44 w-40 cursor-pointer flex flex-col gap-2 justify-center items-center p-2 rounded-md" onClick={() => navigate(`/Product/${product.productID}`)}>
                                                            <div className="h-36 w-full flex justify-center items-center">
                                                                <img src={product.productImage[0]} alt={product.productName} className="h-32 w-32 rounded-2xl" />
                                                            </div>
                                                            <div className="categoryName text-lg font-extrabold">{truncate(product.productName, 12)}</div>
                                                        </div>
                                                    ))}
                                                </div> */}
                                                <div className={`apps-card flex gap-2 ${bottomBarVisible <= 730 && 'justify-evenly'}`}>
                                                    {storeData.Products.filter((entry) => entry.productName.toLowerCase().includes(searchText.toLowerCase())).sort((a, b) => a.productName.localeCompare(b.productName)).map(product => (
                                                        <div className={`catCard ${bottomBarVisible <= 360 ? 'h-52 w-36' : 'h-52 w-40'} cursor-pointer flex flex-col gap-2 justify-center items-center p-2 rounded-md`} onClick={() => navigate(`/Product/${product.productID}`)}>
                                                            <div className="h-36 w-full flex justify-center items-center">
                                                                <img src={product.productImage[0]} alt={product.productName} className="h-32 w-32 rounded-2xl" />
                                                            </div>
                                                            <div className="categoryName text-base font-medium">{truncate(product.productName, 12)}</div>
                                                            <div className="categoryName w-full flex justify-between">
                                                                <span className="text-base font-medium">₹{product.priceDiscounted}</span>
                                                                <span className="text-base font-medium"><s>₹{product.priceOriginal}</s></span>
                                                            </div>
                                                        </div>
                                                    ))}
                                                    {storeData.Products.filter((entry) => entry.productName.toLowerCase().includes(searchText.toLowerCase())).length % 2 !== 0 &&
                                                        <div className={`catCard ${bottomBarVisible <= 360 ? 'h-52 w-36' : 'h-52 w-40'} flex flex-col gap-2 justify-center items-center p-2 rounded-md`} style={{ visibility: 'hidden' }}>
                                                            <div className="h-36 w-full flex justify-center items-center">
                                                                <img src="" alt="" className="h-32 w-32 rounded-2xl" />
                                                            </div>
                                                            <div className="categoryName text-lg font-extrabold">lorem ipsum</div>
                                                            <div className="categoryName text-lg font-extrabold">1000</div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        }
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="overlay-app" />
            </div>
            {bottomBarVisible <= 945 &&
                <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, backgroundColor: 'transparent' }} elevation={3}>
                    <BottomNavigation
                        className="backgroundColor: 'transparent',"
                        showLabels
                        value={-1}
                        onChange={(event, newValue) => {
                            setValue(newValue);
                            newValue === 0 ? navigate('/') : newValue === 1 ? navigate('/Explore') : newValue === 2 ? navigate('/Sell') : newValue === 3 ? navigate('/Chats') : newValue === 4 ? navigate('/Profile') : navigate('/')
                        }}
                    >
                        <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<HomeRoundedIcon />} label="Home" />
                        <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<SlideshowIcon />} label="Shorts" />
                        <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<AddCircleRoundedIcon />} label="Sell" />
                        <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<QuestionAnswerRoundedIcon />} label="Chat" />
                        {currentUser ? <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<img src={currentUser.photoURL} className='h-6 w-6 rounded-full' />} label="Manage" /> : <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<PersonRoundedIcon />} label="Profile" />}
                    </BottomNavigation>
                </Paper>
            }
        </>
    )
}

export default Store