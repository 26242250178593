/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable react-hooks/exhaustive-deps */
import { BottomNavigation, BottomNavigationAction, Paper } from '@mui/material';
import React, { useContext, useEffect, useRef, useState } from 'react';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import SlideshowIcon from '@mui/icons-material/Slideshow';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import QuestionAnswerRoundedIcon from '@mui/icons-material/QuestionAnswerRounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import {
    Link, useNavigate,
} from 'react-router-dom';
import gsap from 'gsap';
import { AuthContext } from '../context/AuthContext';

const Pricing = (props) => {
    const { currentUser } = useContext(AuthContext);
    const [bottomBarVisible, setBottomBarVisible] = useState(window.screen.width);

    useEffect(() => {
        const interval = setInterval(() => {
            setBottomBarVisible(window.screen.width)
        }, 100);
        return () => clearInterval(interval);
    }, []);

    const [value, setValue] = useState(4);
    const ProfileRef = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        gsap.fromTo(".content-wrapper", { opacity: 0 }, { opacity: 1, duration: 2 });
    }, []);

    useEffect(() => {
        if (props.UserData !== null) {
            if (currentUser && props.UserData !== null && props.UserData.NewUser === true) {
                navigate('/Onboarding');
            }
        }
    }, [currentUser, props.UserData])

    return (
        <>
            <div className="app">
                <div className="wrapper">
                    <div className="left-side">
                        <div className="side-wrapper flex h-[100%]">
                            <div className="side-menu flex flex-col justify-evenly h-full">
                                <Link to="/" className="flex flex-row items-center gap-2 w-full">
                                    <img src="https://cdn-icons-png.freepik.com/512/4194/4194676.png" alt="Home Icon" style={{ height: 28, width: 28 }} />
                                    <h2 className="font-extrabold text-lg">Home</h2>
                                </Link>
                                <Link to="/Explore" className="flex flex-row items-center gap-2 w-full">
                                    <img src="https://cdn-icons-png.freepik.com/512/6337/6337301.png" alt="Explore Icon" style={{ height: 28, width: 28 }} />
                                    <h2 className="font-extrabold text-lg">Feed</h2>
                                </Link>
                                <Link to="/Sell" className="flex flex-row items-center gap-2 w-full">
                                    <img src="https://cdn-icons-png.freepik.com/512/10337/10337579.png" alt="Sell Icon" style={{ height: 28, width: 28 }} />
                                    <h2 className="font-extrabold text-lg">Sell</h2>
                                </Link>
                                <Link to="/Chats" className="flex flex-row items-center gap-2 w-full">
                                    <img src="https://cdn-icons-png.freepik.com/512/1041/1041916.png" alt="Chat Icon" style={{ height: 28, width: 28 }} />
                                    <h2 className="font-extrabold text-lg">Chat</h2>
                                </Link>
                                <Link to="/Profile" className="flex flex-row items-center gap-2 w-full">
                                    {currentUser ? <img src={currentUser.photoURL} alt="Profile Picture" style={{ height: 28, width: 28, borderRadius: '100%' }} /> : <img src="https://cdn-icons-png.freepik.com/512/1177/1177568.png" alt="Profile Picture" style={{ height: 28, width: 28 }} />}
                                    <h2 className="font-extrabold text-lg">Profile</h2>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="main-container">
                        <div className="content-wrapper">
                            <div className={`${bottomBarVisible <= 945 ? 'mb-28' : ''}`}>
                                <div className="content-section-profile" ref={ProfileRef}>
                                    <div className="content-section-sell-store mt-5">
                                        <div className={`apps-card flex gap-2 ${bottomBarVisible <= 730 && 'justify-evenly'}`}>
                                            <div className="app-card-profile-ads">
                                                <span className="w-full flex text-center justify-center items-center">Home Page Banner</span>
                                                <span className="w-full flex text-center justify-center items-center pl-4 pr-4 pt-2 pb-2 rounded-xl mt-5 cursor-pointer" style={{ backgroundColor: 'var(--content-bg)' }} onClick={() => navigate('/Ads/Banner')}>Place Ads</span>
                                            </div>
                                            <div className="app-card-profile-ads">
                                                <span className="w-full flex text-center justify-center items-center">Video Ads</span>
                                                <span className="w-full flex text-center justify-center items-center pl-4 pr-4 pt-2 pb-2 rounded-xl mt-11 cursor-pointer" style={{ backgroundColor: 'var(--content-bg)' }} onClick={() => navigate('/Ads/Video')}>Place Ads</span>
                                            </div>
                                            <div className="app-card-profile-ads">
                                                <span className="w-full flex text-center justify-center items-center">Story Ads</span>
                                                <span className="w-full flex text-center justify-center items-center pl-4 pr-4 pt-2 pb-2 rounded-xl mt-11 cursor-pointer" style={{ backgroundColor: 'var(--content-bg)' }} onClick={() => navigate('/Ads/Story')}>Place Ads</span>
                                            </div>
                                            <div className="app-card-profile-ads">
                                                <span className="w-full flex text-center justify-center items-center">Category Ads</span>
                                                <span className="w-full flex text-center justify-center items-center pl-4 pr-4 pt-2 pb-2 rounded-xl mt-11 cursor-pointer" style={{ backgroundColor: 'var(--content-bg)' }} onClick={() => navigate('/Ads/Category')}>Place Ads</span>
                                            </div>
                                            <div className="app-card-profile-ads">
                                                <span className="w-full flex text-center justify-center items-center">Promoted Products</span>
                                                <span className="w-full flex text-center justify-center items-center pl-4 pr-4 pt-2 pb-2 rounded-xl mt-5 cursor-pointer" style={{ backgroundColor: 'var(--content-bg)' }} onClick={() => navigate('/Ads/PromoteProducts')}>Place Ads</span>
                                            </div>
                                            {bottomBarVisible <= 730 &&
                                                <div className="app-card-profile-ads" style={{ visibility: 'hidden' }}>
                                                    <span className="w-full flex text-center justify-center items-center">Promoted Products</span>
                                                    <span className="w-full flex text-center justify-center items-center pl-4 pr-4 pt-2 pb-2 rounded-xl mt-5 cursor-pointer" style={{ backgroundColor: 'var(--content-bg)' }} onClick={() => navigate('/Ads/PromoteProducts')}>Place Ads</span>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="overlay-app" />
            </div>
            {bottomBarVisible <= 945 &&
                <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, backgroundColor: 'transparent' }} elevation={3}>
                    <BottomNavigation
                        className="backgroundColor: 'transparent',"
                        showLabels
                        value={-1}
                        onChange={(event, newValue) => {
                            setValue(newValue);
                            newValue === 0 ? navigate('/') : newValue === 1 ? navigate('/Explore') : newValue === 2 ? navigate('/Sell') : newValue === 3 ? navigate('/Chats') : newValue === 4 ? navigate('/Profile') : navigate('/')
                        }}
                    >
                        <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<HomeRoundedIcon />} label="Home" />
                        <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<SlideshowIcon />} label="Shorts" />
                        <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<AddCircleRoundedIcon />} label="Sell" />
                        <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<QuestionAnswerRoundedIcon />} label="Chat" />
                        {currentUser ? <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<img src={currentUser.photoURL} className='h-6 w-6 rounded-full' />} label="Manage" /> : <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<PersonRoundedIcon />} label="Profile" />}
                    </BottomNavigation>
                </Paper>
            }
        </>
    )
}

export default Pricing