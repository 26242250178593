/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import TopBarProgress from "react-topbar-progress-indicator";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation
} from "react-router-dom";
import { AuthContext } from './context/AuthContext';
import './App.css';
import Home from './pages/Home';
import Categories from './pages/Categories';
import Stories from './pages/Stories';
import Shops from './pages/Shops';
import Explore from './pages/Explore';
import Sell from './pages/Sell';
import Chats from './pages/Chats';
import Profile from './pages/Profile';
import { ThemeProvider, createTheme } from '@mui/material';
import Auth from './pages/Auth';
import { collection, doc, onSnapshot, query } from 'firebase/firestore';
import { db } from './firebase';
import Onboarding from './pages/Onboarding';
import axios from 'axios';
import Category from './pages/Category';
import Chat from './pages/Chat';
import BannerAds from './pages/BannerAds';
import VideoAds from './pages/VideoAds';
import EditStore from './pages/EditStore';
import Story from './pages/Story';
import Store from './pages/Store';
import Saved from './pages/Saved';
import Product from './pages/Product';
import Search from './pages/Search';
import Region from './pages/Region';
import CategoryAds from './pages/CategoryAds';
import PromoteProductsAds from './pages/PromoteProductsAds';
import Feed from './pages/Feed';
import FAQ from './pages/FAQ';
import Contact from './pages/Contact';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsAndConditions from './pages/TermsAndConditions';
import StoryAds from './pages/StoryAds';
import Pricing from './pages/Pricing';
import RefundPolicy from './pages/RefundPolicy';
// import ShippingPolicy from './pages/ShippingPolicy';
// import gsap from 'gsap';
// import { HelmetProvider } from 'react-helmet-async';
import PullToRefresh from 'react-simple-pull-to-refresh';
import PricingBannerAds from './pages/PricingBannerAds';
import PricingVideoAds from './pages/PricingVideoAds';
import PricingStoryAds from './pages/PricingStoryAds';
import PricingCategoryAds from './pages/PricingCategoryAds';
import PricingPromoteProductsAds from './pages/PricingPromoteProducts';
import Checkout from './pages/Checkout';

const CustomSwitch = ({ children }) => {
  const [progress, setProgress] = useState(false)
  const [prevLoc, setPrevLoc] = useState("")
  const location = useLocation()

  useEffect(() => {
    // gsap.fromTo(".app", { opacity: 0 }, { opacity: 1, duration: 2 });
    setPrevLoc(location.pathname)
    setProgress(true)
    if (location.pathname === prevLoc) {
      setPrevLoc('')
    }
  }, [location])

  useEffect(() => {
    setProgress(false)
  }, [prevLoc])

  return (
    <>
      {progress && <TopBarProgress />}
      <Routes>{children}</Routes>
    </>
  )
}

function App() {
  // const [userData, setUserData] = useState(null);
  const { currentUser } = useContext(AuthContext);
  const [vaCat, setVaCat] = useState("");
  const [vaSto, setVaSto] = useState("");
  const [vaSho, setVaSho] = useState("");
  const [vaCats, setVaCats] = useState("");
  const [vChat, setVChat] = useState("");
  const [userData, setUserData] = useState(null);
  const [ip, setIp] = useState(null);
  const [statesData, setStatesData] = useState([]);
  const [citiesData, setCitiesData] = useState([]);
  const [locationsData, setLocationsData] = useState(null);
  const [storeData, setStoreData] = useState(null);
  const [categoriesData, setCategoriesData] = useState([]);
  const [shopsData, setShopsData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [videosData, setVideosData] = useState([]);
  const [storyData, setStoryData] = useState([]);
  const [products, setProducts] = useState([]);
  const [promotedData, setPromotedData] = useState([]);
  const [promotedProductCount, setPromotedProductCount] = useState(0);
  const [inboxData, setInboxData] = useState([]);
  const [bannerAds, setBannerAds] = useState([]);
  const [categoryAds, setCategoryAds] = useState([]);

  const GetStoreData = (val) => {
    const unsub = onSnapshot(doc(db, "Stores", val), (doc) => {
      setStoreData(doc.data());
    });

    return () => {
      unsub();
    };
  }

  const GetInboxData = (val) => {
    const unsub = onSnapshot(doc(db, "Inbox", val), (doc) => {
      setInboxData(doc.data());
    });

    return () => {
      unsub();
    };
  }

  const GetUserData = () => {
    if (currentUser !== null && currentUser.uid) {
      const unsub = onSnapshot(doc(db, "Users", currentUser.uid), (doc) => {
        setUserData(doc.data());
        doc.data().NewUser === false && doc.data().StoreAdded === true && doc.data().isApproved === true && GetStoreData(doc.data().storeID);
        GetInboxData(doc.data().uid);
      });

      return () => {
        unsub();
      };
    }
  }

  const GetStates = () => {
    const unsub = onSnapshot(doc(db, "States", "States"), (doc) => {
      setStatesData(doc.data().States);
    });

    return () => {
      unsub();
    };
  }

  const GetProducts = () => {
    const unsub = onSnapshot(doc(db, "Products", "Products"), (doc) => {
      setProducts(doc.data().Products);
    });

    return () => {
      unsub();
    };
  }

  const GetCities = () => {
    const unsub = onSnapshot(doc(db, "Cities", "Cities"), (doc) => {
      setCitiesData(doc.data().Cities);
    });

    return () => {
      unsub();
    };
  }

  const GetLocations = () => {
    const unsub = onSnapshot(doc(db, "ApprovedLocations", "Locations"), (doc) => {
      setLocationsData(doc.data());
    });

    return () => {
      unsub();
    };
  }

  const GetCategoriesData = () => {
    const q = query(collection(db, "Categories"));
    onSnapshot(q, (querySnapshot) => {
      const cats = [];
      querySnapshot.forEach((doc) => {
        cats.push(doc.data());
      });
      setCategoriesData(cats);
    });
  }

  const GetShopsData = () => {
    const unsub = onSnapshot(doc(db, "Shops", "Stores"), (doc) => {
      setShopsData(doc.data().Shops);
    });

    return () => {
      unsub();
    };
  }

  const GetCategoryData = () => {
    const q = query(collection(db, "Category"));
    onSnapshot(q, (querySnapshot) => {
      const cat = [];
      querySnapshot.forEach((doc) => {
        cat.push(doc.data());
      });
      setCategoryData(cat);
    });
  }

  const GetVideosData = () => {
    const q = query(collection(db, "Video"));
    onSnapshot(q, (querySnapshot) => {
      const vids = [];
      querySnapshot.forEach((doc) => {
        vids.push(doc.data());
      });
      setVideosData(vids);
    });
  }

  const GetStoryData = () => {
    const q = query(collection(db, "Story"));
    onSnapshot(q, (querySnapshot) => {
      const sto = [];
      querySnapshot.forEach((doc) => {
        sto.push(doc.data());
      });
      setStoryData(sto);
    });
  }

  const GetBannerAdsData = () => {
    const unsub = onSnapshot(doc(db, "Banner", "Ads"), (doc) => {
      setBannerAds(doc.data().Ads);
    });

    return () => {
      unsub();
    };
  }

  const GetCategoryAdsData = () => {
    const unsub = onSnapshot(doc(db, "CategoryAds", "Ads"), (doc) => {
      setCategoryAds(doc.data().Ads);
    });

    return () => {
      unsub();
    };
  }

  const GetPromotedData = () => {
    const q = query(collection(db, "Promoted"));
    onSnapshot(q, (querySnapshot) => {
      const promote = [];
      querySnapshot.forEach((doc) => {
        promote.push(doc.data());
      });
      setPromotedData(promote);
    });
  }

  const GetPromotedProductCount = () => {
    const unsub = onSnapshot(doc(db, "PromotedProductCount", "ProductCount"), (doc) => {
      setPromotedProductCount(doc.data().Count);
    });

    return () => {
      unsub();
    };
  }

  useEffect(() => {
    if (currentUser !== null && currentUser.uid) { GetUserData() }
  }, [currentUser]);

  useEffect(() => {
    GetStates()
    GetCities()
    GetLocations()
    GetProducts()
    GetCategoriesData()
    GetShopsData()
    GetCategoryData()
    GetPromotedData()
    GetPromotedProductCount()
    GetBannerAdsData()
    GetVideosData()
    GetStoryData()
    GetCategoryAdsData()
  }, []);

  useEffect(() => {
    const getIpAddress = async () => {
      try {
        const response = await axios.get('https://ipinfo.io');
        setIp(response);
      } catch (error) {
        console.error('Error:', error);
      }
    };

    getIpAddress();
  }, []);

  TopBarProgress.config({
    barThickness: 5,
    barColors: {
      "0": "#ff0058",
      "0.5": "#ffbc00",
      "1.0": "#03a9f4",
    },
  });

  const darkTheme = createTheme({
    palette: {
      mode: 'light',
    },
  });

  useEffect(() => {
    if (currentUser) {
      if (currentUser.email !== 'drakeparker257980@gmail.com' || currentUser.email !== 'freezingdragon257980@gmail.com') {
        document.addEventListener('contextmenu', (event) => {
          event.preventDefault();
        });
      }
    } else {
      document.addEventListener('contextmenu', (event) => {
        event.preventDefault();
      });
    }
  }, [currentUser]);

  useEffect(() => {
    if (currentUser) {
      if (userData !== null) {
        localStorage.setItem("State", userData.state);
        localStorage.setItem("City", userData.city);
      }
    }
  }, [currentUser, userData]);

  // const helmetContext = {};

  const handleRefresh = () => {
    window.location.reload()
  };

  useEffect(() => {
    const elements = document.getElementsByClassName('ptr__pull-down');
    Array.from(elements).forEach(element => {
      element.classList.add('w-full', 'flex', 'justify-center');
    });
    const elements1 = Array.from(document.querySelectorAll('p')).filter(element => element.textContent.includes('↧&nbsp;&nbsp;pull to refresh&nbsp;&nbsp;↧'));
    elements1.forEach(element => {
      element.textContent = '↧&nbsp;&nbsp;Pull To Refresh&nbsp;&nbsp;↧';
    });
    const elements2 = Array.from(document.querySelectorAll('p')).filter(element => element.textContent.includes('↧  pull to refresh  ↧'));
    elements2.forEach(element => {
      element.textContent = '↧&nbsp;&nbsp;Pull To Refresh&nbsp;&nbsp;↧';
    });
    const observer = new MutationObserver((mutationsList, observer) => {
      for (let mutation of mutationsList) {
        if (mutation.addedNodes.length) {
          // const elements = Array.from(document.querySelectorAll('p')).filter(element => element.textContent.includes('↧  pull to refresh  ↧'));
          // elements.forEach(element => {
          //   element.textContent = '↧&nbsp;&nbsp;Pull To Refresh&nbsp;&nbsp;↧';
          // });
          const elements1 = Array.from(document.querySelectorAll('p')).filter(element => element.textContent.includes('↧&nbsp;&nbsp;pull to refresh&nbsp;&nbsp;↧'));
          elements1.forEach(element => {
            element.textContent = '↧&nbsp;&nbsp;Pull To Refresh&nbsp;&nbsp;↧';
          });
          const elements2 = Array.from(document.querySelectorAll('p')).filter(element => element.textContent.includes('↧  pull to refresh  ↧'));
          elements2.forEach(element => {
            element.textContent = '↧&nbsp;&nbsp;Pull To Refresh&nbsp;&nbsp;↧';
          });
        }
      }
    });
    observer.observe(document, { childList: true, subtree: true });
    return () => observer.disconnect();
  }, []);

  return (
    // <HelmetProvider context={helmetContext}>
    <PullToRefresh onRefresh={handleRefresh}>
      <ThemeProvider theme={darkTheme}>
        <Router basename={"/"}>
          <CustomSwitch>
            <Route exact path="/" element={<Home currentUserData={currentUser} ViewAllCategories={(val) => setVaCat(val)} ViewAllCat={vaCat} ViewAllStories={(val) => setVaSto(val)} ViewAllStory={vaSto} ViewAllShops={(val) => setVaSho(val)} ViewAllShop={vaSho} ViewAllCats={(val) => setVaCats(val)} ViewAllCategory={vaCats} ViewChats={(val) => setVChat(val)} ViewChat={vChat} UserData={userData} StoreData={storeData} CategoriesData={categoriesData} States={statesData} Cities={citiesData} Locations={locationsData} Shops={shopsData} Category={categoryData} IP={ip} Promoted={promotedData} PromoCount={promotedProductCount} Inbox={inboxData} BannerAds={bannerAds} CategoryAds={categoryAds} VideosData={videosData} StoriesData={storyData} Products={products} />} />
            <Route exact path="/Categories" element={<Categories currentUserData={currentUser} ViewAllCategories={(val) => setVaCat(val)} ViewAllCat={vaCat} ViewAllStories={(val) => setVaSto(val)} ViewAllStory={vaSto} ViewAllShops={(val) => setVaSho(val)} ViewAllShop={vaSho} ViewAllCats={(val) => setVaCats(val)} ViewAllCategory={vaCats} ViewChats={(val) => setVChat(val)} ViewChat={vChat} UserData={userData} StoreData={storeData} CategoriesData={categoriesData} States={statesData} Cities={citiesData} Locations={locationsData} Shops={shopsData} Category={categoryData} IP={ip} Promoted={promotedData} PromoCount={promotedProductCount} Inbox={inboxData} BannerAds={bannerAds} CategoryAds={categoryAds} VideosData={videosData} StoriesData={storyData} Products={products} />} />
            <Route exact path="/Category/:CAT" element={<Category currentUserData={currentUser} ViewAllCategories={(val) => setVaCat(val)} ViewAllCat={vaCat} ViewAllStories={(val) => setVaSto(val)} ViewAllStory={vaSto} ViewAllShops={(val) => setVaSho(val)} ViewAllShop={vaSho} ViewAllCats={(val) => setVaCats(val)} ViewAllCategory={vaCats} ViewChats={(val) => setVChat(val)} ViewChat={vChat} UserData={userData} StoreData={storeData} CategoriesData={categoriesData} States={statesData} Cities={citiesData} Locations={locationsData} Shops={shopsData} Category={categoryData} IP={ip} Promoted={promotedData} PromoCount={promotedProductCount} Inbox={inboxData} BannerAds={bannerAds} CategoryAds={categoryAds} VideosData={videosData} StoriesData={storyData} Products={products} />} />
            <Route exact path="/Stories" element={<Stories currentUserData={currentUser} ViewAllCategories={(val) => setVaCat(val)} ViewAllCat={vaCat} ViewAllStories={(val) => setVaSto(val)} ViewAllStory={vaSto} ViewAllShops={(val) => setVaSho(val)} ViewAllShop={vaSho} ViewAllCats={(val) => setVaCats(val)} ViewAllCategory={vaCats} ViewChats={(val) => setVChat(val)} ViewChat={vChat} UserData={userData} StoreData={storeData} CategoriesData={categoriesData} States={statesData} Cities={citiesData} Locations={locationsData} Shops={shopsData} Category={categoryData} IP={ip} Promoted={promotedData} PromoCount={promotedProductCount} Inbox={inboxData} BannerAds={bannerAds} CategoryAds={categoryAds} VideosData={videosData} StoriesData={storyData} Products={products} />} />
            <Route exact path="/Shops" element={<Shops currentUserData={currentUser} ViewAllCategories={(val) => setVaCat(val)} ViewAllCat={vaCat} ViewAllStories={(val) => setVaSto(val)} ViewAllStory={vaSto} ViewAllShops={(val) => setVaSho(val)} ViewAllShop={vaSho} ViewAllCats={(val) => setVaCats(val)} ViewAllCategory={vaCats} ViewChats={(val) => setVChat(val)} ViewChat={vChat} UserData={userData} StoreData={storeData} CategoriesData={categoriesData} States={statesData} Cities={citiesData} Locations={locationsData} Shops={shopsData} Category={categoryData} IP={ip} Promoted={promotedData} PromoCount={promotedProductCount} Inbox={inboxData} BannerAds={bannerAds} CategoryAds={categoryAds} VideosData={videosData} StoriesData={storyData} Products={products} />} />
            <Route exact path="/Explore" element={<Explore currentUserData={currentUser} ViewAllCategories={(val) => setVaCat(val)} ViewAllCat={vaCat} ViewAllStories={(val) => setVaSto(val)} ViewAllStory={vaSto} ViewAllShops={(val) => setVaSho(val)} ViewAllShop={vaSho} ViewAllCats={(val) => setVaCats(val)} ViewAllCategory={vaCats} ViewChats={(val) => setVChat(val)} ViewChat={vChat} UserData={userData} StoreData={storeData} CategoriesData={categoriesData} States={statesData} Cities={citiesData} Locations={locationsData} Shops={shopsData} Category={categoryData} IP={ip} Promoted={promotedData} PromoCount={promotedProductCount} Inbox={inboxData} BannerAds={bannerAds} CategoryAds={categoryAds} VideosData={videosData} StoriesData={storyData} Products={products} />} />
            <Route exact path="/Sell" element={<Sell currentUserData={currentUser} ViewAllCategories={(val) => setVaCat(val)} ViewAllCat={vaCat} ViewAllStories={(val) => setVaSto(val)} ViewAllStory={vaSto} ViewAllShops={(val) => setVaSho(val)} ViewAllShop={vaSho} ViewAllCats={(val) => setVaCats(val)} ViewAllCategory={vaCats} ViewChats={(val) => setVChat(val)} ViewChat={vChat} UserData={userData} StoreData={storeData} CategoriesData={categoriesData} States={statesData} Cities={citiesData} Locations={locationsData} Shops={shopsData} Category={categoryData} IP={ip} Promoted={promotedData} PromoCount={promotedProductCount} Inbox={inboxData} BannerAds={bannerAds} CategoryAds={categoryAds} VideosData={videosData} StoriesData={storyData} Products={products} />} />
            <Route exact path="/Chats" element={<Chats currentUserData={currentUser} ViewAllCategories={(val) => setVaCat(val)} ViewAllCat={vaCat} ViewAllStories={(val) => setVaSto(val)} ViewAllStory={vaSto} ViewAllShops={(val) => setVaSho(val)} ViewAllShop={vaSho} ViewAllCats={(val) => setVaCats(val)} ViewAllCategory={vaCats} ViewChats={(val) => setVChat(val)} ViewChat={vChat} UserData={userData} StoreData={storeData} CategoriesData={categoriesData} States={statesData} Cities={citiesData} Locations={locationsData} Shops={shopsData} Category={categoryData} IP={ip} Promoted={promotedData} PromoCount={promotedProductCount} Inbox={inboxData} BannerAds={bannerAds} CategoryAds={categoryAds} VideosData={videosData} StoriesData={storyData} Products={products} />} />
            <Route exact path="/Profile" element={<Profile currentUserData={currentUser} ViewAllCategories={(val) => setVaCat(val)} ViewAllCat={vaCat} ViewAllStories={(val) => setVaSto(val)} ViewAllStory={vaSto} ViewAllShops={(val) => setVaSho(val)} ViewAllShop={vaSho} ViewAllCats={(val) => setVaCats(val)} ViewAllCategory={vaCats} ViewChats={(val) => setVChat(val)} ViewChat={vChat} UserData={userData} StoreData={storeData} CategoriesData={categoriesData} States={statesData} Cities={citiesData} Locations={locationsData} Shops={shopsData} Category={categoryData} IP={ip} Promoted={promotedData} PromoCount={promotedProductCount} Inbox={inboxData} BannerAds={bannerAds} CategoryAds={categoryAds} VideosData={videosData} StoriesData={storyData} Products={products} />} />
            <Route exact path="/Auth" element={<Auth currentUserData={currentUser} ViewAllCategories={(val) => setVaCat(val)} ViewAllCat={vaCat} ViewAllStories={(val) => setVaSto(val)} ViewAllStory={vaSto} ViewAllShops={(val) => setVaSho(val)} ViewAllShop={vaSho} ViewAllCats={(val) => setVaCats(val)} ViewAllCategory={vaCats} ViewChats={(val) => setVChat(val)} ViewChat={vChat} UserData={userData} StoreData={storeData} CategoriesData={categoriesData} States={statesData} Cities={citiesData} Locations={locationsData} Shops={shopsData} Category={categoryData} IP={ip} Promoted={promotedData} PromoCount={promotedProductCount} Inbox={inboxData} BannerAds={bannerAds} CategoryAds={categoryAds} VideosData={videosData} StoriesData={storyData} Products={products} />} />
            <Route exact path="/Onboarding" element={<Onboarding currentUserData={currentUser} ViewAllCategories={(val) => setVaCat(val)} ViewAllCat={vaCat} ViewAllStories={(val) => setVaSto(val)} ViewAllStory={vaSto} ViewAllShops={(val) => setVaSho(val)} ViewAllShop={vaSho} ViewAllCats={(val) => setVaCats(val)} ViewAllCategory={vaCats} ViewChats={(val) => setVChat(val)} ViewChat={vChat} UserData={userData} StoreData={storeData} CategoriesData={categoriesData} States={statesData} Cities={citiesData} Locations={locationsData} Shops={shopsData} Category={categoryData} IP={ip} Promoted={promotedData} PromoCount={promotedProductCount} Inbox={inboxData} BannerAds={bannerAds} CategoryAds={categoryAds} VideosData={videosData} StoriesData={storyData} Products={products} />} />
            <Route exact path="/Chat/:ChatID" element={<Chat currentUserData={currentUser} ViewAllCategories={(val) => setVaCat(val)} ViewAllCat={vaCat} ViewAllStories={(val) => setVaSto(val)} ViewAllStory={vaSto} ViewAllShops={(val) => setVaSho(val)} ViewAllShop={vaSho} ViewAllCats={(val) => setVaCats(val)} ViewAllCategory={vaCats} ViewChats={(val) => setVChat(val)} ViewChat={vChat} UserData={userData} StoreData={storeData} CategoriesData={categoriesData} States={statesData} Cities={citiesData} Locations={locationsData} Shops={shopsData} Category={categoryData} IP={ip} Promoted={promotedData} PromoCount={promotedProductCount} Inbox={inboxData} BannerAds={bannerAds} CategoryAds={categoryAds} VideosData={videosData} StoriesData={storyData} Products={products} />} />
            <Route exact path="/Ads/Banner" element={<BannerAds currentUserData={currentUser} ViewAllCategories={(val) => setVaCat(val)} ViewAllCat={vaCat} ViewAllStories={(val) => setVaSto(val)} ViewAllStory={vaSto} ViewAllShops={(val) => setVaSho(val)} ViewAllShop={vaSho} ViewAllCats={(val) => setVaCats(val)} ViewAllCategory={vaCats} ViewChats={(val) => setVChat(val)} ViewChat={vChat} UserData={userData} StoreData={storeData} CategoriesData={categoriesData} States={statesData} Cities={citiesData} Locations={locationsData} Shops={shopsData} Category={categoryData} IP={ip} Promoted={promotedData} PromoCount={promotedProductCount} Inbox={inboxData} BannerAds={bannerAds} CategoryAds={categoryAds} VideosData={videosData} StoriesData={storyData} Products={products} />} />
            <Route exact path="/Ads/Video" element={<VideoAds currentUserData={currentUser} ViewAllCategories={(val) => setVaCat(val)} ViewAllCat={vaCat} ViewAllStories={(val) => setVaSto(val)} ViewAllStory={vaSto} ViewAllShops={(val) => setVaSho(val)} ViewAllShop={vaSho} ViewAllCats={(val) => setVaCats(val)} ViewAllCategory={vaCats} ViewChats={(val) => setVChat(val)} ViewChat={vChat} UserData={userData} StoreData={storeData} CategoriesData={categoriesData} States={statesData} Cities={citiesData} Locations={locationsData} Shops={shopsData} Category={categoryData} IP={ip} Promoted={promotedData} PromoCount={promotedProductCount} Inbox={inboxData} BannerAds={bannerAds} CategoryAds={categoryAds} VideosData={videosData} StoriesData={storyData} Products={products} />} />
            <Route exact path="/Ads/Story" element={<StoryAds currentUserData={currentUser} ViewAllCategories={(val) => setVaCat(val)} ViewAllCat={vaCat} ViewAllStories={(val) => setVaSto(val)} ViewAllStory={vaSto} ViewAllShops={(val) => setVaSho(val)} ViewAllShop={vaSho} ViewAllCats={(val) => setVaCats(val)} ViewAllCategory={vaCats} ViewChats={(val) => setVChat(val)} ViewChat={vChat} UserData={userData} StoreData={storeData} CategoriesData={categoriesData} States={statesData} Cities={citiesData} Locations={locationsData} Shops={shopsData} Category={categoryData} IP={ip} Promoted={promotedData} PromoCount={promotedProductCount} Inbox={inboxData} BannerAds={bannerAds} CategoryAds={categoryAds} VideosData={videosData} StoriesData={storyData} Products={products} />} />
            <Route exact path="/Ads/Category" element={<CategoryAds currentUserData={currentUser} ViewAllCategories={(val) => setVaCat(val)} ViewAllCat={vaCat} ViewAllStories={(val) => setVaSto(val)} ViewAllStory={vaSto} ViewAllShops={(val) => setVaSho(val)} ViewAllShop={vaSho} ViewAllCats={(val) => setVaCats(val)} ViewAllCategory={vaCats} ViewChats={(val) => setVChat(val)} ViewChat={vChat} UserData={userData} StoreData={storeData} CategoriesData={categoriesData} States={statesData} Cities={citiesData} Locations={locationsData} Shops={shopsData} Category={categoryData} IP={ip} Promoted={promotedData} PromoCount={promotedProductCount} Inbox={inboxData} BannerAds={bannerAds} CategoryAds={categoryAds} VideosData={videosData} StoriesData={storyData} Products={products} />} />
            <Route exact path="/Ads/PromoteProducts" element={<PromoteProductsAds currentUserData={currentUser} ViewAllCategories={(val) => setVaCat(val)} ViewAllCat={vaCat} ViewAllStories={(val) => setVaSto(val)} ViewAllStory={vaSto} ViewAllShops={(val) => setVaSho(val)} ViewAllShop={vaSho} ViewAllCats={(val) => setVaCats(val)} ViewAllCategory={vaCats} ViewChats={(val) => setVChat(val)} ViewChat={vChat} UserData={userData} StoreData={storeData} CategoriesData={categoriesData} States={statesData} Cities={citiesData} Locations={locationsData} Shops={shopsData} Category={categoryData} IP={ip} Promoted={promotedData} PromoCount={promotedProductCount} Inbox={inboxData} BannerAds={bannerAds} CategoryAds={categoryAds} VideosData={videosData} StoriesData={storyData} Products={products} />} />
            <Route exact path="/Pricing/Ads/Banner" element={<PricingBannerAds currentUserData={currentUser} ViewAllCategories={(val) => setVaCat(val)} ViewAllCat={vaCat} ViewAllStories={(val) => setVaSto(val)} ViewAllStory={vaSto} ViewAllShops={(val) => setVaSho(val)} ViewAllShop={vaSho} ViewAllCats={(val) => setVaCats(val)} ViewAllCategory={vaCats} ViewChats={(val) => setVChat(val)} ViewChat={vChat} UserData={userData} StoreData={storeData} CategoriesData={categoriesData} States={statesData} Cities={citiesData} Locations={locationsData} Shops={shopsData} Category={categoryData} IP={ip} Promoted={promotedData} PromoCount={promotedProductCount} Inbox={inboxData} BannerAds={bannerAds} CategoryAds={categoryAds} VideosData={videosData} StoriesData={storyData} Products={products} />} />
            <Route exact path="/Pricing/Ads/Video" element={<PricingVideoAds currentUserData={currentUser} ViewAllCategories={(val) => setVaCat(val)} ViewAllCat={vaCat} ViewAllStories={(val) => setVaSto(val)} ViewAllStory={vaSto} ViewAllShops={(val) => setVaSho(val)} ViewAllShop={vaSho} ViewAllCats={(val) => setVaCats(val)} ViewAllCategory={vaCats} ViewChats={(val) => setVChat(val)} ViewChat={vChat} UserData={userData} StoreData={storeData} CategoriesData={categoriesData} States={statesData} Cities={citiesData} Locations={locationsData} Shops={shopsData} Category={categoryData} IP={ip} Promoted={promotedData} PromoCount={promotedProductCount} Inbox={inboxData} BannerAds={bannerAds} CategoryAds={categoryAds} VideosData={videosData} StoriesData={storyData} Products={products} />} />
            <Route exact path="/Pricing/Ads/Story" element={<PricingStoryAds currentUserData={currentUser} ViewAllCategories={(val) => setVaCat(val)} ViewAllCat={vaCat} ViewAllStories={(val) => setVaSto(val)} ViewAllStory={vaSto} ViewAllShops={(val) => setVaSho(val)} ViewAllShop={vaSho} ViewAllCats={(val) => setVaCats(val)} ViewAllCategory={vaCats} ViewChats={(val) => setVChat(val)} ViewChat={vChat} UserData={userData} StoreData={storeData} CategoriesData={categoriesData} States={statesData} Cities={citiesData} Locations={locationsData} Shops={shopsData} Category={categoryData} IP={ip} Promoted={promotedData} PromoCount={promotedProductCount} Inbox={inboxData} BannerAds={bannerAds} CategoryAds={categoryAds} VideosData={videosData} StoriesData={storyData} Products={products} />} />
            <Route exact path="/Pricing/Ads/Category" element={<PricingCategoryAds currentUserData={currentUser} ViewAllCategories={(val) => setVaCat(val)} ViewAllCat={vaCat} ViewAllStories={(val) => setVaSto(val)} ViewAllStory={vaSto} ViewAllShops={(val) => setVaSho(val)} ViewAllShop={vaSho} ViewAllCats={(val) => setVaCats(val)} ViewAllCategory={vaCats} ViewChats={(val) => setVChat(val)} ViewChat={vChat} UserData={userData} StoreData={storeData} CategoriesData={categoriesData} States={statesData} Cities={citiesData} Locations={locationsData} Shops={shopsData} Category={categoryData} IP={ip} Promoted={promotedData} PromoCount={promotedProductCount} Inbox={inboxData} BannerAds={bannerAds} CategoryAds={categoryAds} VideosData={videosData} StoriesData={storyData} Products={products} />} />
            <Route exact path="/Pricing/Ads/PromoteProducts" element={<PricingPromoteProductsAds currentUserData={currentUser} ViewAllCategories={(val) => setVaCat(val)} ViewAllCat={vaCat} ViewAllStories={(val) => setVaSto(val)} ViewAllStory={vaSto} ViewAllShops={(val) => setVaSho(val)} ViewAllShop={vaSho} ViewAllCats={(val) => setVaCats(val)} ViewAllCategory={vaCats} ViewChats={(val) => setVChat(val)} ViewChat={vChat} UserData={userData} StoreData={storeData} CategoriesData={categoriesData} States={statesData} Cities={citiesData} Locations={locationsData} Shops={shopsData} Category={categoryData} IP={ip} Promoted={promotedData} PromoCount={promotedProductCount} Inbox={inboxData} BannerAds={bannerAds} CategoryAds={categoryAds} VideosData={videosData} StoriesData={storyData} Products={products} />} />
            <Route exact path="/Edit/Store" element={<EditStore currentUserData={currentUser} ViewAllCategories={(val) => setVaCat(val)} ViewAllCat={vaCat} ViewAllStories={(val) => setVaSto(val)} ViewAllStory={vaSto} ViewAllShops={(val) => setVaSho(val)} ViewAllShop={vaSho} ViewAllCats={(val) => setVaCats(val)} ViewAllCategory={vaCats} ViewChats={(val) => setVChat(val)} ViewChat={vChat} UserData={userData} StoreData={storeData} CategoriesData={categoriesData} States={statesData} Cities={citiesData} Locations={locationsData} Shops={shopsData} Category={categoryData} IP={ip} Promoted={promotedData} PromoCount={promotedProductCount} Inbox={inboxData} BannerAds={bannerAds} CategoryAds={categoryAds} VideosData={videosData} StoriesData={storyData} Products={products} />} />
            <Route exact path="/Story/:VID" element={<Story currentUserData={currentUser} ViewAllCategories={(val) => setVaCat(val)} ViewAllCat={vaCat} ViewAllStories={(val) => setVaSto(val)} ViewAllStory={vaSto} ViewAllShops={(val) => setVaSho(val)} ViewAllShop={vaSho} ViewAllCats={(val) => setVaCats(val)} ViewAllCategory={vaCats} ViewChats={(val) => setVChat(val)} ViewChat={vChat} UserData={userData} StoreData={storeData} CategoriesData={categoriesData} States={statesData} Cities={citiesData} Locations={locationsData} Shops={shopsData} Category={categoryData} IP={ip} Promoted={promotedData} PromoCount={promotedProductCount} Inbox={inboxData} BannerAds={bannerAds} CategoryAds={categoryAds} VideosData={videosData} StoriesData={storyData} Products={products} />} />
            <Route exact path="/Store/:SID" element={<Store currentUserData={currentUser} ViewAllCategories={(val) => setVaCat(val)} ViewAllCat={vaCat} ViewAllStories={(val) => setVaSto(val)} ViewAllStory={vaSto} ViewAllShops={(val) => setVaSho(val)} ViewAllShop={vaSho} ViewAllCats={(val) => setVaCats(val)} ViewAllCategory={vaCats} ViewChats={(val) => setVChat(val)} ViewChat={vChat} UserData={userData} StoreData={storeData} CategoriesData={categoriesData} States={statesData} Cities={citiesData} Locations={locationsData} Shops={shopsData} Category={categoryData} IP={ip} Promoted={promotedData} PromoCount={promotedProductCount} Inbox={inboxData} BannerAds={bannerAds} CategoryAds={categoryAds} VideosData={videosData} StoriesData={storyData} Products={products} />} />
            <Route exact path="/Product/:PID" element={<Product currentUserData={currentUser} ViewAllCategories={(val) => setVaCat(val)} ViewAllCat={vaCat} ViewAllStories={(val) => setVaSto(val)} ViewAllStory={vaSto} ViewAllShops={(val) => setVaSho(val)} ViewAllShop={vaSho} ViewAllCats={(val) => setVaCats(val)} ViewAllCategory={vaCats} ViewChats={(val) => setVChat(val)} ViewChat={vChat} UserData={userData} StoreData={storeData} CategoriesData={categoriesData} States={statesData} Cities={citiesData} Locations={locationsData} Shops={shopsData} Category={categoryData} IP={ip} Promoted={promotedData} PromoCount={promotedProductCount} Inbox={inboxData} BannerAds={bannerAds} CategoryAds={categoryAds} VideosData={videosData} StoriesData={storyData} Products={products} />} />
            <Route exact path="/Saved" element={<Saved currentUserData={currentUser} ViewAllCategories={(val) => setVaCat(val)} ViewAllCat={vaCat} ViewAllStories={(val) => setVaSto(val)} ViewAllStory={vaSto} ViewAllShops={(val) => setVaSho(val)} ViewAllShop={vaSho} ViewAllCats={(val) => setVaCats(val)} ViewAllCategory={vaCats} ViewChats={(val) => setVChat(val)} ViewChat={vChat} UserData={userData} StoreData={storeData} CategoriesData={categoriesData} States={statesData} Cities={citiesData} Locations={locationsData} Shops={shopsData} Category={categoryData} IP={ip} Promoted={promotedData} PromoCount={promotedProductCount} Inbox={inboxData} BannerAds={bannerAds} CategoryAds={categoryAds} VideosData={videosData} StoriesData={storyData} Products={products} />} />
            <Route exact path="/Search/:ST" element={<Search currentUserData={currentUser} ViewAllCategories={(val) => setVaCat(val)} ViewAllCat={vaCat} ViewAllStories={(val) => setVaSto(val)} ViewAllStory={vaSto} ViewAllShops={(val) => setVaSho(val)} ViewAllShop={vaSho} ViewAllCats={(val) => setVaCats(val)} ViewAllCategory={vaCats} ViewChats={(val) => setVChat(val)} ViewChat={vChat} UserData={userData} StoreData={storeData} CategoriesData={categoriesData} States={statesData} Cities={citiesData} Locations={locationsData} Shops={shopsData} Category={categoryData} IP={ip} Promoted={promotedData} PromoCount={promotedProductCount} Inbox={inboxData} BannerAds={bannerAds} CategoryAds={categoryAds} VideosData={videosData} StoriesData={storyData} Products={products} />} />
            <Route exact path="/Region" element={<Region currentUserData={currentUser} ViewAllCategories={(val) => setVaCat(val)} ViewAllCat={vaCat} ViewAllStories={(val) => setVaSto(val)} ViewAllStory={vaSto} ViewAllShops={(val) => setVaSho(val)} ViewAllShop={vaSho} ViewAllCats={(val) => setVaCats(val)} ViewAllCategory={vaCats} ViewChats={(val) => setVChat(val)} ViewChat={vChat} UserData={userData} StoreData={storeData} CategoriesData={categoriesData} States={statesData} Cities={citiesData} Locations={locationsData} Shops={shopsData} Category={categoryData} IP={ip} Promoted={promotedData} PromoCount={promotedProductCount} Inbox={inboxData} BannerAds={bannerAds} CategoryAds={categoryAds} VideosData={videosData} StoriesData={storyData} Products={products} />} />
            <Route exact path="/Feed/:FID" element={<Feed currentUserData={currentUser} ViewAllCategories={(val) => setVaCat(val)} ViewAllCat={vaCat} ViewAllStories={(val) => setVaSto(val)} ViewAllStory={vaSto} ViewAllShops={(val) => setVaSho(val)} ViewAllShop={vaSho} ViewAllCats={(val) => setVaCats(val)} ViewAllCategory={vaCats} ViewChats={(val) => setVChat(val)} ViewChat={vChat} UserData={userData} StoreData={storeData} CategoriesData={categoriesData} States={statesData} Cities={citiesData} Locations={locationsData} Shops={shopsData} Category={categoryData} IP={ip} Promoted={promotedData} PromoCount={promotedProductCount} Inbox={inboxData} BannerAds={bannerAds} CategoryAds={categoryAds} VideosData={videosData} StoriesData={storyData} Products={products} />} />
            <Route exact path="/FAQ" element={<FAQ currentUserData={currentUser} ViewAllCategories={(val) => setVaCat(val)} ViewAllCat={vaCat} ViewAllStories={(val) => setVaSto(val)} ViewAllStory={vaSto} ViewAllShops={(val) => setVaSho(val)} ViewAllShop={vaSho} ViewAllCats={(val) => setVaCats(val)} ViewAllCategory={vaCats} ViewChats={(val) => setVChat(val)} ViewChat={vChat} UserData={userData} StoreData={storeData} CategoriesData={categoriesData} States={statesData} Cities={citiesData} Locations={locationsData} Shops={shopsData} Category={categoryData} IP={ip} Promoted={promotedData} PromoCount={promotedProductCount} Inbox={inboxData} BannerAds={bannerAds} CategoryAds={categoryAds} VideosData={videosData} StoriesData={storyData} Products={products} />} />
            <Route exact path="/Contact" element={<Contact currentUserData={currentUser} ViewAllCategories={(val) => setVaCat(val)} ViewAllCat={vaCat} ViewAllStories={(val) => setVaSto(val)} ViewAllStory={vaSto} ViewAllShops={(val) => setVaSho(val)} ViewAllShop={vaSho} ViewAllCats={(val) => setVaCats(val)} ViewAllCategory={vaCats} ViewChats={(val) => setVChat(val)} ViewChat={vChat} UserData={userData} StoreData={storeData} CategoriesData={categoriesData} States={statesData} Cities={citiesData} Locations={locationsData} Shops={shopsData} Category={categoryData} IP={ip} Promoted={promotedData} PromoCount={promotedProductCount} Inbox={inboxData} BannerAds={bannerAds} CategoryAds={categoryAds} VideosData={videosData} StoriesData={storyData} Products={products} />} />
            <Route exact path="/Privacy-Policy" element={<PrivacyPolicy currentUserData={currentUser} ViewAllCategories={(val) => setVaCat(val)} ViewAllCat={vaCat} ViewAllStories={(val) => setVaSto(val)} ViewAllStory={vaSto} ViewAllShops={(val) => setVaSho(val)} ViewAllShop={vaSho} ViewAllCats={(val) => setVaCats(val)} ViewAllCategory={vaCats} ViewChats={(val) => setVChat(val)} ViewChat={vChat} UserData={userData} StoreData={storeData} CategoriesData={categoriesData} States={statesData} Cities={citiesData} Locations={locationsData} Shops={shopsData} Category={categoryData} IP={ip} Promoted={promotedData} PromoCount={promotedProductCount} Inbox={inboxData} BannerAds={bannerAds} CategoryAds={categoryAds} VideosData={videosData} StoriesData={storyData} Products={products} />} />
            <Route exact path="/Terms-And-Conditions" element={<TermsAndConditions currentUserData={currentUser} ViewAllCategories={(val) => setVaCat(val)} ViewAllCat={vaCat} ViewAllStories={(val) => setVaSto(val)} ViewAllStory={vaSto} ViewAllShops={(val) => setVaSho(val)} ViewAllShop={vaSho} ViewAllCats={(val) => setVaCats(val)} ViewAllCategory={vaCats} ViewChats={(val) => setVChat(val)} ViewChat={vChat} UserData={userData} StoreData={storeData} CategoriesData={categoriesData} States={statesData} Cities={citiesData} Locations={locationsData} Shops={shopsData} Category={categoryData} IP={ip} Promoted={promotedData} PromoCount={promotedProductCount} Inbox={inboxData} BannerAds={bannerAds} CategoryAds={categoryAds} VideosData={videosData} StoriesData={storyData} Products={products} />} />
            <Route exact path="/Pricing" element={<Pricing currentUserData={currentUser} ViewAllCategories={(val) => setVaCat(val)} ViewAllCat={vaCat} ViewAllStories={(val) => setVaSto(val)} ViewAllStory={vaSto} ViewAllShops={(val) => setVaSho(val)} ViewAllShop={vaSho} ViewAllCats={(val) => setVaCats(val)} ViewAllCategory={vaCats} ViewChats={(val) => setVChat(val)} ViewChat={vChat} UserData={userData} StoreData={storeData} CategoriesData={categoriesData} States={statesData} Cities={citiesData} Locations={locationsData} Shops={shopsData} Category={categoryData} IP={ip} Promoted={promotedData} PromoCount={promotedProductCount} Inbox={inboxData} BannerAds={bannerAds} CategoryAds={categoryAds} VideosData={videosData} StoriesData={storyData} Products={products} />} />
            <Route exact path="/Refund-Policy" element={<RefundPolicy currentUserData={currentUser} ViewAllCategories={(val) => setVaCat(val)} ViewAllCat={vaCat} ViewAllStories={(val) => setVaSto(val)} ViewAllStory={vaSto} ViewAllShops={(val) => setVaSho(val)} ViewAllShop={vaSho} ViewAllCats={(val) => setVaCats(val)} ViewAllCategory={vaCats} ViewChats={(val) => setVChat(val)} ViewChat={vChat} UserData={userData} StoreData={storeData} CategoriesData={categoriesData} States={statesData} Cities={citiesData} Locations={locationsData} Shops={shopsData} Category={categoryData} IP={ip} Promoted={promotedData} PromoCount={promotedProductCount} Inbox={inboxData} BannerAds={bannerAds} CategoryAds={categoryAds} VideosData={videosData} StoriesData={storyData} Products={products} />} />
            {/* <Route exact path="/Shipping-Policy" element={<ShippingPolicy currentUserData={currentUser} ViewAllCategories={(val) => setVaCat(val)} ViewAllCat={vaCat} ViewAllStories={(val) => setVaSto(val)} ViewAllStory={vaSto} ViewAllShops={(val) => setVaSho(val)} ViewAllShop={vaSho} ViewAllCats={(val) => setVaCats(val)} ViewAllCategory={vaCats} ViewChats={(val) => setVChat(val)} ViewChat={vChat} UserData={userData} StoreData={storeData} CategoriesData={categoriesData} States={statesData} Cities={citiesData} Locations={locationsData} Shops={shopsData} Category={categoryData} IP={ip} Promoted={promotedData} PromoCount={promotedProductCount} Inbox={inboxData} BannerAds={bannerAds} CategoryAds={categoryAds} VideosData={videosData} StoriesData={storyData} Products={products} />} /> */}
            <Route exact path="/Checkout" element={<Checkout currentUserData={currentUser} ViewAllCategories={(val) => setVaCat(val)} ViewAllCat={vaCat} ViewAllStories={(val) => setVaSto(val)} ViewAllStory={vaSto} ViewAllShops={(val) => setVaSho(val)} ViewAllShop={vaSho} ViewAllCats={(val) => setVaCats(val)} ViewAllCategory={vaCats} ViewChats={(val) => setVChat(val)} ViewChat={vChat} UserData={userData} StoreData={storeData} CategoriesData={categoriesData} States={statesData} Cities={citiesData} Locations={locationsData} Shops={shopsData} Category={categoryData} IP={ip} Promoted={promotedData} PromoCount={promotedProductCount} Inbox={inboxData} BannerAds={bannerAds} CategoryAds={categoryAds} VideosData={videosData} StoriesData={storyData} Products={products} />} />
          </CustomSwitch>
        </Router>
      </ThemeProvider>
    </PullToRefresh>
    // </HelmetProvider>
  );
}

export default App;
