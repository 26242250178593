/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable react-hooks/exhaustive-deps */
import { BottomNavigation, BottomNavigationAction, Paper } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import SlideshowIcon from '@mui/icons-material/Slideshow';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import QuestionAnswerRoundedIcon from '@mui/icons-material/QuestionAnswerRounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import {
    Link, useNavigate,
} from 'react-router-dom';
import gsap from 'gsap';
import { AuthContext } from '../context/AuthContext';
import swal from '@sweetalert/with-react';
import { arrayRemove, arrayUnion, doc, onSnapshot, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { v4 as uuid } from "uuid";
import LoadingBar from 'react-top-loading-bar';
import moment from 'moment';
import BackButton from '../components/BackButton';

const CategoryAds = (props) => {
    const { currentUser } = useContext(AuthContext);
    const [bottomBarVisible, setBottomBarVisible] = useState(window.screen.width);
    // const [files, setFiles] = useState([]);
    // const [multipleFiles, setMultipleFiles] = useState([]);
    const [progresspercent, setProgressPercent] = useState();
    // eslint-disable-next-line no-unused-vars
    const [err, setErr] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const interval = setInterval(() => {
            setBottomBarVisible(window.screen.width)
        }, 100);
        return () => clearInterval(interval);
    }, []);

    // eslint-disable-next-line no-unused-vars
    const [value, setValue] = useState(4);
    // const ProfileRef = useRef(null);
    const navigate = useNavigate();
    const [storeData, setStoreData] = useState(null);

    useEffect(() => {
        const GetStoreData = () => {
            const unsub = onSnapshot(doc(db, "Stores", props.UserData.storeID), (doc) => {
                setStoreData(doc.data());
            });

            return () => {
                unsub();
            };
        }
        props.UserData !== null && GetStoreData();
    }, [props.UserData])

    const truncate = (str, length) => {
        if (str.length > length) {
            return str.slice(0, length) + "...";
        } else {
            return str;
        }
    }

    const AddSelectedItem = async (item) => {
        // setAddedID([...addedID, item.productID])
        // setSelectedProducts([...selectedProducts, item])
        await updateDoc(doc(db, "Users", currentUser.uid), {
            CategoryAds: arrayUnion(item),
        });
    }

    const RemoveSelectedItem = async (item) => {
        await updateDoc(doc(db, "Users", currentUser.uid), {
            CategoryAds: arrayRemove(item),
        });
    }

    const SubmitCategoryAds = async () => {
        setLoading(true);
        const adsID = uuid();
        const currentTime = moment().format('hh:mm:ss DD MMMM YYYY');
        const currentTimeStamp = moment().format('x');
        props.UserData.CategoryAds.map(async (item) => (
            await updateDoc(doc(db, "CategoryAds", "Ads"), {
                Ads: arrayUnion({
                    adsID,
                    productID: item.productID,
                    productImage: item.productImage,
                    productName: item.productName,
                    description: item.description,
                    priceDiscounted: item.priceDiscounted,
                    priceOriginal: item.priceOriginal,
                    productCategory: item.productCategory,
                    storeID: props.UserData.storeID,
                    storeState: props.UserData.storeState,
                    storeCity: props.UserData.storeCity,
                    TimeCreated: currentTime,
                    TimeStamp: currentTimeStamp,
                }),
            }).then(
                updateDoc(doc(db, "Users", currentUser.uid), {
                    CategoryAdsDone: arrayUnion(item),
                })
            )
        ))
        await updateDoc(doc(db, "Users", currentUser.uid), {
            CategoryAds: [],
        });
        navigate('/Profile');
        setLoading(false);
        swal('Category Ads Added!!', '', 'success', {
            buttons: {
                confirm: {
                    text: "OK",
                    value: true,
                    visible: true,
                    className: "bg-[#8c52ff]",
                    closeModal: true
                }
            }
        });
    }

    const [addedID, setAddedID] = useState([]);
    const [alreadyAddedID, setAlreadyAddedID] = useState([]);

    useEffect(() => {
        const dataID = [];
        props.UserData !== null && props.UserData.CategoryAds.map(item => (
            dataID.push(item.productID)
        ));
        setAddedID(dataID);
    }, [props.UserData])

    useEffect(() => {
        const alreadyDataID = [];
        props.UserData !== null && props.UserData.CategoryAdsDone.map(item => (
            alreadyDataID.push(item.productID)
        ));
        setAlreadyAddedID(alreadyDataID);
    }, [props.UserData])

    useEffect(() => {
        gsap.fromTo(".content-wrapper", { opacity: 0 }, { opacity: 1, duration: 2 });
    }, []);

    useEffect(() => {
        if (!currentUser) {
            navigate('/Auth')
        }
    }, [currentUser])

    useEffect(() => {
        if (props.UserData !== null) {
            if (currentUser && props.UserData !== null && props.UserData.NewUser === true) {
                navigate('/Onboarding');
            }
        }
    }, [currentUser, props.UserData])

    return (
        <>
            <div className="app">
                {loading && <LoadingBar color='linear-gradient(45deg,#ffbc00,#00d0ff ,#ff0058)' shadow={true} progress={progresspercent} onLoaderFinished={() => setProgressPercent(0)} height={4} style={{ "border-radius": "44px" }} />}
                <div className="wrapper">
                    <div className="left-side">
                        <div className="side-wrapper flex h-[100%]">
                            <div className="side-menu flex flex-col justify-evenly h-full">
                                <Link to="/" className="flex flex-row items-center gap-2 w-full">
                                    <img src="https://cdn-icons-png.freepik.com/512/4194/4194676.png" alt="Home Icon" style={{ height: 28, width: 28 }} />
                                    <h2 className="font-extrabold text-lg">Home</h2>
                                </Link>
                                <Link to="/Explore" className="flex flex-row items-center gap-2 w-full">
                                    <img src="https://cdn-icons-png.freepik.com/512/6337/6337301.png" alt="Explore Icon" style={{ height: 28, width: 28 }} />
                                    <h2 className="font-extrabold text-lg">Feed</h2>
                                </Link>
                                <Link to="/Sell" className="flex flex-row items-center gap-2 w-full">
                                    <img src="https://cdn-icons-png.freepik.com/512/10337/10337579.png" alt="Sell Icon" style={{ height: 28, width: 28 }} />
                                    <h2 className="font-extrabold text-lg">Sell</h2>
                                </Link>
                                <Link to="/Chats" className="flex flex-row items-center gap-2 w-full">
                                    <img src="https://cdn-icons-png.freepik.com/512/1041/1041916.png" alt="Chat Icon" style={{ height: 28, width: 28 }} />
                                    <h2 className="font-extrabold text-lg">Chat</h2>
                                </Link>
                                <Link to="/Profile" className="flex flex-row items-center gap-2 w-full">
                                    {currentUser ? <img src={currentUser.photoURL} alt="Profile Picture" style={{ height: 28, width: 28, borderRadius: '100%' }} /> : <img src="https://cdn-icons-png.freepik.com/512/1177/1177568.png" alt="Profile Picture" style={{ height: 28, width: 28 }} />}
                                    <h2 className="font-extrabold text-lg">Profile</h2>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="main-container">
                        <div className="content-wrapper">
                            {bottomBarVisible <= 945 && <BackButton />}
                            <div className={`${bottomBarVisible <= 945 ? 'mb-28' : ''} mt-2`}>
                                <div className="content-wrapper-header flex justify-center align-center items-center text-center">
                                    <h1 className='font-extrabold text-3xl'>CATEGORY ADS</h1>
                                </div>
                                {props.UserData !== null && props.UserData.CategoryAds.length > 0 &&
                                    <div className="content-section">
                                        <div className="content-section-title text-2xl">Selected Products</div>
                                        <div className={`apps-card flex gap-2 ${bottomBarVisible <= 730 && 'justify-evenly'}`}>
                                            {props.UserData !== null && props.UserData.CategoryAds.map(product => (
                                                <div className={`catCard ${bottomBarVisible <= 360 ? 'h-52 w-36' : 'h-52 w-40'} cursor-pointer flex flex-col gap-2 justify-center items-center p-2 rounded-md`} onClick={() => RemoveSelectedItem(product)}>
                                                    <div className="h-36 w-full flex justify-center items-center">
                                                        <img src={product.productImage[0]} alt={product.productName} className="h-32 w-32 rounded-2xl" />
                                                    </div>
                                                    <div className="categoryName text-base font-medium">{truncate(product.productName, 12)}</div>
                                                    <div className="categoryName w-full flex justify-between">
                                                        <span className="text-base font-medium">₹{product.priceDiscounted}</span>
                                                        <span className="text-base font-medium"><s>₹{product.priceOriginal}</s></span>
                                                    </div>
                                                </div>
                                            ))}
                                            {props.UserData !== null && props.UserData.CategoryAds.length % 2 !== 0 &&
                                                <div className={`catCard ${bottomBarVisible <= 360 ? 'h-52 w-36' : 'h-52 w-40'} flex flex-col gap-2 justify-center items-center p-2 rounded-md`} style={{ visibility: 'hidden' }}>
                                                    <div className="h-36 w-full flex justify-center items-center">
                                                        <img src="" alt="" className="h-32 w-32 rounded-2xl" />
                                                    </div>
                                                    <div className="categoryName text-lg font-extrabold">lorem ipsum</div>
                                                    <div className="categoryName text-lg font-extrabold">1000</div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                }
                                {storeData !== null && storeData.Products.filter(item => (addedID.includes(item.productID) === false)).filter(item => (alreadyAddedID.includes(item.productID) === false)).length > 0 ?
                                    <div className="content-section">
                                        <div className="content-section-title text-2xl">Products</div>
                                        <div className={`apps-card flex gap-2 ${bottomBarVisible <= 730 && 'justify-evenly'}`}>
                                            {storeData.Products.filter(item => (addedID.includes(item.productID) === false)).filter(item => (alreadyAddedID.includes(item.productID) === false)).sort((a, b) => a.productName.localeCompare(b.productName)).map(product => (
                                                <div className={`catCard ${bottomBarVisible <= 360 ? 'h-52 w-36' : 'h-52 w-40'} cursor-pointer flex flex-col gap-2 justify-center items-center p-2 rounded-md`} onClick={() => AddSelectedItem(product)}>
                                                    <div className="h-36 w-full flex justify-center items-center">
                                                        <img src={product.productImage[0]} alt={product.productName} className="h-32 w-32 rounded-2xl" />
                                                    </div>
                                                    <div className="categoryName text-base font-medium">{truncate(product.productName, 12)}</div>
                                                    <div className="categoryName w-full flex justify-between">
                                                        <span className="text-base font-medium">₹{product.priceDiscounted}</span>
                                                        <span className="text-base font-medium"><s>₹{product.priceOriginal}</s></span>
                                                    </div>
                                                </div>
                                            ))}
                                            {storeData.Products.filter(item => (addedID.includes(item.productID) === false)).filter(item => (alreadyAddedID.includes(item.productID) === false)).length % 2 !== 0 &&
                                                <div className={`catCard ${bottomBarVisible <= 360 ? 'h-52 w-36' : 'h-52 w-40'} flex flex-col gap-2 justify-center items-center p-2 rounded-md`} style={{ visibility: 'hidden' }}>
                                                    <div className="h-36 w-full flex justify-center items-center">
                                                        <img src="" alt="" className="h-32 w-32 rounded-2xl" />
                                                    </div>
                                                    <div className="categoryName text-lg font-extrabold">lorem ipsum</div>
                                                    <div className="categoryName text-lg font-extrabold">1000</div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    :
                                    <div className="w-full flex justify-center items-center mt-10 font-black text-2xl text-center">
                                        NO PRODUCTS AVAILABLE TO PROMOTE
                                    </div>
                                }
                                <div class="content-section">
                                    <div className="SubmitCategoryAdsBtn flex justify-center items-center w-full">
                                        {props.UserData !== null && props.UserData.CategoryAds.length > 0 ?
                                            <>
                                                {loading === false ?
                                                    <div className="SubmitBTNAds w-max pl-5 pr-5 pt-2 pb-2 bg-blue-500 text-white text-2xl font-extrabold rounded-xl cursor-pointer" onClick={SubmitCategoryAds}>
                                                        SUBMIT
                                                    </div>
                                                    :
                                                    <div className="SubmitBTNAds w-max pl-5 pr-5 pt-2 pb-2 bg-blue-300 text-white text-2xl font-extrabold rounded-xl cursor-default">
                                                        SUBMITTING
                                                    </div>
                                                }
                                            </>
                                            :
                                            <div className="SubmitBTNAds w-max pl-5 pr-5 pt-2 pb-2 bg-blue-300 text-white text-2xl font-extrabold rounded-xl cursor-default">
                                                SUBMIT
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="overlay-app" />
            </div>
            {bottomBarVisible <= 945 &&
                <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, backgroundColor: 'transparent' }} elevation={3}>
                    <BottomNavigation
                        className="backgroundColor: 'transparent',"
                        showLabels
                        value={-1}
                        onChange={(event, newValue) => {
                            setValue(newValue);
                            newValue === 0 ? navigate('/') : newValue === 1 ? navigate('/Explore') : newValue === 2 ? navigate('/Sell') : newValue === 3 ? navigate('/Chats') : newValue === 4 ? navigate('/Profile') : navigate('/')
                        }}
                    >
                        <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<HomeRoundedIcon />} label="Home" />
                        <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<SlideshowIcon />} label="Shorts" />
                        <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<AddCircleRoundedIcon />} label="Sell" />
                        <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<QuestionAnswerRoundedIcon />} label="Chat" />
                        {currentUser ? <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<img src={currentUser.photoURL} className='h-6 w-6 rounded-full' />} label="Manage" /> : <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<PersonRoundedIcon />} label="Profile" />}
                    </BottomNavigation>
                </Paper>
            }
        </>
    )
}

export default CategoryAds