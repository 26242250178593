/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import SlideshowIcon from '@mui/icons-material/Slideshow';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import QuestionAnswerRoundedIcon from '@mui/icons-material/QuestionAnswerRounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import {
    Link, useNavigate,
} from 'react-router-dom';
import gsap from 'gsap';
import { AuthContext } from '../context/AuthContext';
import { BottomNavigation, BottomNavigationAction, Paper } from '@mui/material';
import BackButton from '../components/BackButton';

const PrivacyPolicy = (props) => {
    const { currentUser } = useContext(AuthContext);
    const [bottomBarVisible, setBottomBarVisible] = useState(window.screen.width);

    useEffect(() => {
        const interval = setInterval(() => {
            setBottomBarVisible(window.screen.width)
        }, 100);
        return () => clearInterval(interval);
    }, []);

    // eslint-disable-next-line no-unused-vars
    const [value, setValue] = useState(4);
    // const ProfileRef = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        gsap.fromTo(".content-wrapper", { opacity: 0 }, { opacity: 1, duration: 2 });
    }, []);

    // useEffect(() => {
    //     if (!currentUser) {
    //         navigate('/Auth')
    //     }
    // }, [currentUser])

    useEffect(() => {
        if (props.UserData !== null) {
            if (currentUser && props.UserData !== null && props.UserData.NewUser === true) {
                navigate('/Onboarding');
            }
        }
    }, [currentUser, props.UserData])

    return (
        <>
            <div className="app">
                <div className="wrapper">
                    <div className="left-side">
                        <div className="side-wrapper flex h-[100%]">
                            <div className="side-menu flex flex-col justify-evenly h-full">
                                <Link to="/" className="flex flex-row items-center gap-2 w-full">
                                    <img src="https://cdn-icons-png.freepik.com/512/4194/4194676.png" alt="Home Icon" style={{ height: 28, width: 28 }} />
                                    <h2 className="font-extrabold text-lg">Home</h2>
                                </Link>
                                <Link to="/Explore" className="flex flex-row items-center gap-2 w-full">
                                    <img src="https://cdn-icons-png.freepik.com/512/6337/6337301.png" alt="Explore Icon" style={{ height: 28, width: 28 }} />
                                    <h2 className="font-extrabold text-lg">Feed</h2>
                                </Link>
                                <Link to="/Sell" className="flex flex-row items-center gap-2 w-full">
                                    <img src="https://cdn-icons-png.freepik.com/512/10337/10337579.png" alt="Sell Icon" style={{ height: 28, width: 28 }} />
                                    <h2 className="font-extrabold text-lg">Sell</h2>
                                </Link>
                                <Link to="/Chats" className="flex flex-row items-center gap-2 w-full">
                                    <img src="https://cdn-icons-png.freepik.com/512/1041/1041916.png" alt="Chat Icon" style={{ height: 28, width: 28 }} />
                                    <h2 className="font-extrabold text-lg">Chat</h2>
                                </Link>
                                <Link to="/Profile" className="flex flex-row items-center gap-2 w-full">
                                    {currentUser ? <img src={currentUser.photoURL} alt="Profile Picture" style={{ height: 28, width: 28, borderRadius: '100%' }} /> : <img src="https://cdn-icons-png.freepik.com/512/1177/1177568.png" alt="Profile Picture" style={{ height: 28, width: 28 }} />}
                                    <h2 className="font-extrabold text-lg">Profile</h2>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="main-container">
                        <div className="content-wrapper">
                            {bottomBarVisible <= 945 && <BackButton />}
                            <div className={`${bottomBarVisible <= 945 ? 'mb-28' : ''} mt-2`}>
                                <div className="content-wrapper-header flex justify-center align-center items-center text-center">
                                    <h1 className='font-extrabold text-3xl'>Privacy Policy</h1>
                                </div>
                                <div className="content-section">
                                    <div className="content-section-title font-bold">Effective Date: March 19, 2024</div>
                                    <div className="QuestionAnswers flex w-full h-full flex-col gap-2">
                                        This Privacy Policy describes how Martpuram ("we," "us," or "our") collects, uses, and discloses your information when you use our online marketplace platform.
                                    </div>
                                </div>
                                <div className="content-section">
                                    <div className="content-section-title font-bold">Information We Collect</div>
                                    <div className="QuestionAnswers flex w-full h-full flex-col gap-2">
                                        We collect several types of information from and about users of our Platform:
                                        <ol className="flex gap-2 flex-col">
                                            <li><span className="font-bold">Information You Provide Directly:</span> This includes information you provide when you create an account, list products, make purchases, or contact customer support. This may include your name, email address, phone number, billing address, shipping address, and payment information.</li>
                                            <li><span className="font-bold">Information You Provide Directly:</span> This includes information you provide when you create an account, list products, make purchases, or contact customer support. This may include your name, email address, phone number, billing address, shipping address, and payment information.</li>
                                            <li><span className="font-bold">Information You Provide Directly:</span> This includes information you provide when you create an account, list products, make purchases, or contact customer support. This may include your name, email address, phone number, billing address, shipping address, and payment information.</li>
                                        </ol>
                                    </div>
                                </div>
                                <div className="content-section">
                                    <div className="content-section-title font-bold">Use of Your Information</div>
                                    <div className="QuestionAnswers flex w-full h-full flex-col gap-2">
                                        We use the information we collect for a variety of purposes, including:
                                        <ol className="flex gap-2 flex-col">
                                            <li>To provide and operate the Platform</li>
                                            <li>To process your orders and transactions</li>
                                            <li>To communicate with you about your use of the Platform</li>
                                            <li>To personalize your experience on the Platform</li>
                                            <li>To send you marketing and promotional communications (with your consent)</li>
                                            <li>To improve the Platform and our services</li>
                                            <li>To comply with legal and regulatory requirements</li>
                                        </ol>
                                    </div>
                                </div>
                                <div className="content-section">
                                    <div className="content-section-title font-bold">Sharing Your Information</div>
                                    <div className="QuestionAnswers flex w-full h-full flex-col gap-2">
                                        We may share your information with third-party service providers who help us operate the Platform and provide our services. These service providers are contractually obligated to keep your information confidential and secure.
                                        <br />
                                        <br />
                                        We may also disclose your information if we are required to do so by law or if we believe it is necessary to protect the rights, property, or safety of ourselves or others.
                                    </div>
                                </div>
                                <div className="content-section">
                                    <div className="content-section-title font-bold">Your Choices</div>
                                    <div className="QuestionAnswers flex w-full h-full flex-col gap-2">
                                        You have choices regarding your information:
                                        <ol className="flex gap-2 flex-col">
                                            <li><span className="font-bold">Accessing and Updating Your Information:</span> You can access and update your account information at any time by logging into your account.</li>
                                            <li><span className="font-bold">Marketing Communications:</span> You can opt out of receiving marketing communications from us by following the unsubscribe instructions in those communications.</li>
                                        </ol>
                                    </div>
                                </div>
                                <div className="content-section">
                                    <div className="content-section-title font-bold">Data Security</div>
                                    <div className="QuestionAnswers flex w-full h-full flex-col gap-2">
                                        We take reasonable steps to protect the information you provide to us from loss, misuse, unauthorized access, disclosure, alteration, and destruction. However, no internet or electronic storage system is 100% secure.
                                    </div>
                                </div>
                                <div className="content-section">
                                    <div className="content-section-title font-bold">Children's Privacy</div>
                                    <div className="QuestionAnswers flex w-full h-full flex-col gap-2">
                                        Our Platform is not directed to children under the age of 13. We do not knowingly collect information from children under 13. If you are a parent or guardian and you believe your child has provided us with information, please contact us.
                                    </div>
                                </div>
                                <div className={`content-section`}>
                                    <div className="content-section-title font-bold">Changes to this Privacy Policy</div>
                                    <div className="QuestionAnswers flex w-full h-full flex-col gap-2">
                                        We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on the Platform.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="overlay-app" />
            </div>
            {bottomBarVisible <= 945 &&
                <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, backgroundColor: 'transparent' }} elevation={3}>
                    <BottomNavigation
                        className="backgroundColor: 'transparent',"
                        showLabels
                        value={-1}
                        onChange={(event, newValue) => {
                            setValue(newValue);
                            newValue === 0 ? navigate('/') : newValue === 1 ? navigate('/Explore') : newValue === 2 ? navigate('/Sell') : newValue === 3 ? navigate('/Chats') : newValue === 4 ? navigate('/Profile') : navigate('/')
                        }}
                    >
                        <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<HomeRoundedIcon />} label="Home" />
                        <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<SlideshowIcon />} label="Shorts" />
                        <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<AddCircleRoundedIcon />} label="Sell" />
                        <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<QuestionAnswerRoundedIcon />} label="Chat" />
                        {currentUser ? <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<img src={currentUser.photoURL} className='h-6 w-6 rounded-full' />} label="Manage" /> : <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<PersonRoundedIcon />} label="Profile" />}
                    </BottomNavigation>
                </Paper>
            }
        </>
    )
}

export default PrivacyPolicy