/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable react-hooks/exhaustive-deps */
import { BottomNavigation, BottomNavigationAction, Paper } from '@mui/material';
import React, { useContext, useEffect, useRef, useState } from 'react';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import SlideshowIcon from '@mui/icons-material/Slideshow';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import QuestionAnswerRoundedIcon from '@mui/icons-material/QuestionAnswerRounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import {
    Link, useNavigate,
} from 'react-router-dom';
import gsap from 'gsap';
import { AuthContext } from '../context/AuthContext';
import ReactPlayer from 'react-player';
import BackButton from '../components/BackButton';

const Saved = (props) => {
    const { currentUser } = useContext(AuthContext);
    const [bottomBarVisible, setBottomBarVisible] = useState(window.screen.width);

    useEffect(() => {
        const interval = setInterval(() => {
            setBottomBarVisible(window.screen.width)
        }, 100);
        return () => clearInterval(interval);
    }, []);

    // eslint-disable-next-line no-unused-vars
    const [value, setValue] = useState(3);
    const [mode, setMode] = useState('Products');
    const navigate = useNavigate();

    const truncate = (str, length) => {
        if (str.length > length) {
            return str.slice(0, length) + "...";
        } else {
            return str;
        }
    }

    useEffect(() => {
        gsap.fromTo(".content-wrapper", { opacity: 0 }, { opacity: 1, duration: 2 });
    }, []);

    const playerRef = useRef();

    // const seekToTime = (timeInSeconds) => {
    //     playerRef.current.seekTo(timeInSeconds);
    // };

    // useEffect(() => {
    // seekToTime(10)
    // },[props.VideosData.filter((item) => props.UserData.Saved.Videos.includes(item.videoID)).length])

    useEffect(() => {
        if (props.UserData !== null) {
            if (currentUser && props.UserData !== null && props.UserData.NewUser === true) {
                navigate('/Onboarding');
            }
        }
    }, [currentUser, props.UserData])

    return (
        <>
            <div className="app">
                <div className="wrapper">
                    <div className="left-side">
                        <div className="side-wrapper flex h-[100%]">
                            <div className="side-menu flex flex-col justify-evenly h-full">
                                <Link to="/" className="flex flex-row items-center gap-2 w-full">
                                    <img src="https://cdn-icons-png.freepik.com/512/4194/4194676.png" alt="Home Icon" style={{ height: 28, width: 28 }} />
                                    <h2 className="font-extrabold text-lg">Home</h2>
                                </Link>
                                <Link to="/Explore" className="flex flex-row items-center gap-2 w-full">
                                    <img src="https://cdn-icons-png.freepik.com/512/6337/6337301.png" alt="Explore Icon" style={{ height: 28, width: 28 }} />
                                    <h2 className="font-extrabold text-lg">Feed</h2>
                                </Link>
                                <Link to="/Sell" className="flex flex-row items-center gap-2 w-full">
                                    <img src="https://cdn-icons-png.freepik.com/512/10337/10337579.png" alt="Sell Icon" style={{ height: 28, width: 28 }} />
                                    <h2 className="font-extrabold text-lg">Sell</h2>
                                </Link>
                                <Link to="/Chats" className="flex flex-row items-center gap-2 w-full">
                                    <img src="https://cdn-icons-png.freepik.com/512/1041/1041916.png" alt="Chat Icon" style={{ height: 28, width: 28 }} />
                                    <h2 className="font-extrabold text-lg">Chat</h2>
                                </Link>
                                <Link to="/Profile" className="flex flex-row items-center gap-2 w-full">
                                    {currentUser ? <img src={currentUser.photoURL} alt="Profile Picture" style={{ height: 28, width: 28, borderRadius: '100%' }} /> : <img src="https://cdn-icons-png.freepik.com/512/1177/1177568.png" alt="Profile Picture" style={{ height: 28, width: 28 }} />}
                                    <h2 className="font-extrabold text-lg">Profile</h2>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="main-container">
                        <div className="main-header">
                            <div className="search-bar flex flex-row w-full justify-between items-center">
                                <div className={`w-full flex justify-center items-center hover:bg-[var(--search-bg)] ${mode === 'Products' && 'bg-[var(--search-bg)]'} rounded-l-xl h-full cursor-pointer font-extrabold text-2xl uppercase`} onClick={() => setMode('Products')}>Products</div>
                                <div className={`w-full flex justify-center items-center hover:bg-[var(--search-bg)] ${mode === 'Videos' && 'bg-[var(--search-bg)]'} rounded-r-xl h-full cursor-pointer font-extrabold text-2xl uppercase`} onClick={() => setMode('Videos')}>Videos</div>
                            </div>
                        </div>
                        {currentUser !== null ?
                            <>
                                {props.UserData !== null &&
                                    <>
                                        {mode === 'Products' ?
                                            <div className="content-wrapper">
                                                {bottomBarVisible <= 945 && <BackButton />}
                                                <div className={`${bottomBarVisible <= 945 ? 'mb-28' : ''} mt-2`}>
                                                    <div className="content-section">
                                                        {props.Products.length > 0 && props.Products.filter((item) => props.UserData.Saved.Products.includes(item.productID)).sort((a, b) => a.productName.localeCompare(b.productName)).length > 0 ?
                                                            <>
                                                                <div className="content-section-title text-2xl">Saved Products</div>
                                                                {/* <div className="apps-card flex gap-5">
                                                                    {props.Products.filter((item) => props.UserData.Saved.Products.includes(item.productID)).sort((a, b) => a.productName.localeCompare(b.productName)).map(product => (
                                                                        <div className="catCard h-60 w-60 cursor-pointer flex flex-col gap-2 justify-center items-center p-2 rounded-md" onClick={() => navigate(`/Product/${product.productID}`)}>
                                                                            <div className="h-40 w-40">
                                                                                <img src={product.productImage[0]} alt={product.productName} className="h-full w-full rounded-2xl" />
                                                                            </div>
                                                                            <div className="categoryName text-2xl font-extrabold">{truncate(product.productName, 12)}</div>
                                                                        </div>
                                                                    ))}
                                                                </div> */}
                                                                <div className={`apps-card mt-10 flex gap-2 ${bottomBarVisible <= 730 && 'justify-evenly'}`}>
                                                                    {props.Products.filter((item) => props.UserData.Saved.Products.includes(item.productID)).sort((a, b) => a.productName.localeCompare(b.productName)).map(product => (
                                                                        <div className={`catCard ${bottomBarVisible <= 360 ? 'h-52 w-36' : 'h-52 w-40'} cursor-pointer flex flex-col gap-2 justify-center items-center p-2 rounded-md`} onClick={() => navigate(`/Product/${product.productID}`)}>
                                                                            <div className="h-36 w-full flex justify-center items-center">
                                                                                <img src={product.productImage[0]} alt={product.productName} className="h-32 w-32 rounded-2xl" />
                                                                            </div>
                                                                            <div className="categoryName text-base font-medium">{truncate(product.productName, 12)}</div>
                                                                            <div className="categoryName w-full flex justify-between">
                                                                                <span className="text-base font-medium">₹{product.priceDiscounted}</span>
                                                                                <span className="text-base font-medium"><s>₹{product.priceOriginal}</s></span>
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                    {props.Products.filter((item) => props.UserData.Saved.Products.includes(item.productID)).length % 2 !== 0 &&
                                                                        <div className={`catCard ${bottomBarVisible <= 360 ? 'h-52 w-36' : 'h-52 w-40'} flex flex-col gap-2 justify-center items-center p-2 rounded-md`} style={{ visibility: 'hidden' }}>
                                                                            <div className="h-36 w-full flex justify-center items-center">
                                                                                <img src="" alt="" className="h-32 w-32 rounded-2xl" />
                                                                            </div>
                                                                            <div className="categoryName text-lg font-extrabold">lorem ipsum</div>
                                                                            <div className="categoryName text-lg font-extrabold">1000</div>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </>
                                                            :
                                                            <div className="NoProducts w-full flex justify-center items-center font-extrabold uppercase text-2xl">
                                                                No Products Saved
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <div className="content-wrapper">
                                                {bottomBarVisible <= 945 && <BackButton />}
                                                <div className={`${bottomBarVisible <= 945 ? 'mb-28' : ''} mt-2`}>
                                                    <div className="content-section">
                                                        {props.VideosData.length > 0 && props.VideosData.filter((item) => props.UserData.Saved.Videos.includes(item.videoID)).length > 0 ?
                                                            <>
                                                                <div className="content-section-title text-2xl">Saved Videos</div>
                                                                <div className="apps-card mt-10 flex gap-5">
                                                                    {props.VideosData.filter((item) => props.UserData.Saved.Videos.includes(item.videoID)).map((video, index) => (
                                                                        <div className="catCard cursor-pointer flex justify-center items-center">
                                                                            <div className="h-full w-[340px]">
                                                                                <ReactPlayer ref={playerRef} url={video.ad} key={index} playing={false} loop={false} muted={true} width='100%' height='100%' pip={false} playsinline={false} onContextMenu={(e) => e.preventDefault()} onClick={() => navigate(`/Feed/${video.videoID}`)} />
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </>
                                                            :
                                                            <div className="NoProducts w-full flex justify-center items-center font-extrabold uppercase text-2xl">
                                                                No Videos Saved
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </>
                                }
                            </>
                            :
                            <>
                                <div className="NoLoginUserSaved flex w-full h-full justify-center items-center text-center p-2 font-extrabold text-2xl">
                                    PLEASE LOGIN TO SAVE PRODUCTS AND VIDEOS
                                </div>
                            </>
                        }
                    </div>
                </div>
                <div className="overlay-app" />
            </div>
            {bottomBarVisible <= 945 &&
                <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, backgroundColor: 'transparent' }} elevation={3}>
                    <BottomNavigation
                        className="backgroundColor: 'transparent',"
                        showLabels
                        value={-1}
                        onChange={(event, newValue) => {
                            setValue(newValue);
                            newValue === 0 ? navigate('/') : newValue === 1 ? navigate('/Explore') : newValue === 2 ? navigate('/Sell') : newValue === 3 ? navigate('/Chats') : newValue === 4 ? navigate('/Profile') : navigate('/')
                        }}
                    >
                        <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<HomeRoundedIcon />} label="Home" />
                        <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<SlideshowIcon />} label="Shorts" />
                        <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<AddCircleRoundedIcon />} label="Sell" />
                        <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<QuestionAnswerRoundedIcon />} label="Chat" />
                        {currentUser ? <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<img src={currentUser.photoURL} className='h-6 w-6 rounded-full' />} label="Manage" /> : <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<PersonRoundedIcon />} label="Profile" />}
                    </BottomNavigation>
                </Paper>
            }
        </>
    )
}

export default Saved