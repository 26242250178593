/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable react-hooks/exhaustive-deps */
import { BottomNavigation, BottomNavigationAction, Paper, TextField } from '@mui/material';
import React, { useContext, useEffect, useRef, useState } from 'react';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import SlideshowIcon from '@mui/icons-material/Slideshow';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import QuestionAnswerRoundedIcon from '@mui/icons-material/QuestionAnswerRounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import { Checkbox, FileInput, Label, Select, Textarea } from 'flowbite-react';
import {
    Link, useNavigate,
} from 'react-router-dom';
import gsap from 'gsap';
import { AuthContext } from '../context/AuthContext';
import { v4 as uuid } from "uuid";
import { arrayUnion, doc, serverTimestamp, setDoc, updateDoc } from 'firebase/firestore';
import { db, storage } from '../firebase';
import LoadingBar from 'react-top-loading-bar';
import swal from '@sweetalert/with-react';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import moment from 'moment';
import BackButton from '../components/BackButton';

const Sell = (props) => {
    const { currentUser } = useContext(AuthContext);
    const [bottomBarVisible, setBottomBarVisible] = useState(window.screen.width);

    useEffect(() => {
        const interval = setInterval(() => {
            setBottomBarVisible(window.screen.width)
        }, 100);
        return () => clearInterval(interval);
    }, []);

    const [value, setValue] = useState(2);
    const SellRef = useRef(null);
    const navigate = useNavigate();
    const [storeName, setStoreName] = useState('');
    const [yourName, setYourName] = useState('');
    const [file, setFile] = useState(null);
    const [address, setAddress] = useState('');
    const [storeState, setStoreState] = useState('');
    const [storeCity, setStoreCity] = useState('');
    const [gst, setGst] = useState('');
    const [category, setCategory] = useState('');
    const [number, setNumber] = useState();
    const [productName, setProductName] = useState('');
    const [priceDiscounted, setPriceDiscounted] = useState('');
    const [priceOriginal, setPriceOriginal] = useState('');
    const [description, setDescription] = useState('');
    const [image, setImage] = useState([]);
    const [productCategory, setProductCategory] = useState('');
    const [fixed, setFixed] = useState(false);
    const [negotiable, setNegotiable] = useState(false);
    const [tags, setTags] = useState('');
    const [progresspercent, setProgressPercent] = useState();
    // eslint-disable-next-line no-unused-vars
    const [err, setErr] = useState(false);
    const [loading, setLoading] = useState(false);
    const [clicked, setClicked] = useState(false);
    const [multipleFiles, setMultipleFiles] = useState([]);
    const [productImageUrls, setProductImageUrls] = useState([]);
    const TextAreaRef = useRef();

    const handleChangeStoreName = (event) => {
        setStoreName(event.target.value);
    };

    const handleChangeYourName = (event) => {
        setYourName(event.target.value);
    };

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const handleChangeAddress = (event) => {
        setAddress(event.target.value);
    };

    const handleChangeStoreState = (event) => {
        setStoreState(event.target.value);
    };

    const handleChangeStoreCity = (event) => {
        setStoreCity(event.target.value);
    };

    const handleChangeGST = (event) => {
        setGst(event.target.value);
    };

    const handleChangeCategory = (event) => {
        setCategory(event.target.value);
    };

    const handleChangeNumber = (event) => {
        if (/^\d*$/.test(event.target.value)) {
            setNumber(event.target.value);
        } else {
            swal('Only Numbers Allowed', '', 'warning', {
                buttons: {
                    confirm: {
                        text: "OK",
                        value: true,
                        visible: true,
                        className: "bg-[#8c52ff]",
                        closeModal: true
                    }
                }
            })
        }
    };

    const handleChangeProductName = (event) => {
        setProductName(event.target.value);
    };

    const handleChangePriceDiscounted = (event) => {
        if (/^\d*$/.test(event.target.value)) {
            setPriceDiscounted(event.target.value);
        } else {
            swal('Only Numbers Allowed', '', 'warning', {
                buttons: {
                    confirm: {
                        text: "OK",
                        value: true,
                        visible: true,
                        className: "bg-[#8c52ff]",
                        closeModal: true
                    }
                }
            })
        }
    };

    const handleChangePriceOriginal = (event) => {
        if (/^\d*$/.test(event.target.value)) {
            setPriceOriginal(event.target.value);
        } else {
            swal('Only Numbers Allowed', '', 'warning', {
                buttons: {
                    confirm: {
                        text: "OK",
                        value: true,
                        visible: true,
                        className: "bg-[#8c52ff]",
                        closeModal: true
                    }
                }
            })
        }
    };

    const handleChangeDescription = (event) => {
        setDescription(event.target.value);
    };

    const handleImageChange = async (event) => {
        if (event.target.files.length > 4) {
            swal('You can only upload a maximum of 4 files', '', 'warning');
            return;
        }
        // setImage(event.target.files[0]);
        const imgURLS = [];
        const files = Array.from(event.target.files);
        setImage(Array.from(event.target.files));
        // eslint-disable-next-line array-callback-return
        await Promise.all(Array.from(event.target.files).map(file => {
            const ProductImageID = uuid();
            const storageRef = ref(storage, "ProductsImage/" + ProductImageID);
            const uploadTask = uploadBytesResumable(storageRef, file);
            uploadTask.on(
                'state_changed',
                (snapshot) => {
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    const roundedprogress = Math.round(progress);
                    setProgressPercent(roundedprogress);
                },
                (error) => {
                    setLoading(false);
                    swal(error.code, '', 'error', {
                        buttons: {
                            confirm: {
                                text: "OK",
                                value: true,
                                visible: true,
                                className: "bg-[#8c52ff]",
                                closeModal: true
                            }
                        }
                    });
                },
                () => {
                    getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => { imgURLS.push(downloadURL); });
                })
        }));
        const readers = files.map((file) => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => resolve(reader.result);
                reader.onerror = reject;
                reader.readAsDataURL(file);
            });
        });
        Promise.all(readers).then((values) => {
            setMultipleFiles(values);
        });
        setProductImageUrls(imgURLS)
    };

    const handleChangeProductCategory = (event) => {
        setProductCategory(event.target.value);
    };

    const handleChangeFixed = (event) => {
        setFixed(true);
        setNegotiable(false);
    };

    const handleChangeNegotiable = (event) => {
        setNegotiable(true);
        setFixed(false);
    };

    const handleChangeTags = (event) => {
        setTags(event.target.value.split(',').map(word => word.trim()));
    };

    const HandleCreateFirst = async (e) => {
        const currentTime = moment().format('hh:mm:ss DD MMMM YYYY');
        const currentTimeStamp = moment().format('x');
        setClicked(true);
        if (storeName !== '' && yourName !== '' && file !== null && address !== '' && storeState !== '' && storeCity !== '' && productName !== '' && priceDiscounted !== '' && priceOriginal !== '' && description !== '' && productImageUrls.length > 0 && productCategory !== '' && tags.length > 0 && storeState !== '' && storeCity !== '') {
            setLoading(true);
            e.preventDefault();
            const storeID = uuid();
            const productID = uuid();
            const storageRef1 = ref(storage, "StoresLogo/" + storeID);
            const uploadTask1 = uploadBytesResumable(storageRef1, file);
            uploadTask1.on(
                'state_changed',
                (snapshot) => {
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    const roundedprogress = Math.round(progress);
                    setProgressPercent(roundedprogress);
                },
                (error) => {
                    setClicked(false);
                    setErr(true);
                    setLoading(false);
                },
                () => {
                    getDownloadURL(uploadTask1.snapshot.ref).then(async (downloadURL1) => {
                        await setDoc(doc(db, "Stores", storeID), {
                            approved: false,
                            storeID,
                            owner: yourName,
                            ownerID: currentUser.uid,
                            ownerPFP: currentUser.photoURL,
                            number,
                            storeName,
                            logo: downloadURL1,
                            address,
                            storeState: storeState,
                            storeCity: storeCity,
                            gst,
                            category,
                            Products: arrayUnion({
                                storeID,
                                owner: yourName,
                                ownerID: currentUser.uid,
                                ownerPFP: currentUser.photoURL,
                                number,
                                storeName,
                                logo: downloadURL1,
                                address,
                                storeState: storeState,
                                storeCity: storeCity,
                                productID,
                                productName,
                                priceDiscounted,
                                priceOriginal,
                                description,
                                productImage: productImageUrls,
                                productCategory,
                                fixed,
                                negotiable,
                                tags,
                                TimeCreated: currentTime,
                                TimeStamp: currentTimeStamp,
                            }),
                            TimeCreated: currentTime,
                            TimeStamp: serverTimestamp(),
                        });
                        await updateDoc(doc(db, "Users", currentUser.uid), {
                            StoreAdded: true,
                            isApproved: false,
                        });
                        setClicked(false);
                        setLoading(false);
                        setClicked(false);
                        setLoading(false);
                        setStoreName('');
                        setYourName('');
                        setFile(null);
                        setAddress('');
                        setGst('');
                        setCategory('');
                        setProductName('');
                        setPriceDiscounted('');
                        setPriceOriginal('');
                        setDescription('');
                        setImage([]);
                        setMultipleFiles([]);
                        setProductCategory('');
                        setFixed(false);
                        setNegotiable(false);
                        setTags('');
                        TextAreaRef.current.value = '';
                        swal('Store Created!!', '', 'success', {
                            buttons: {
                                confirm: {
                                    text: "OK",
                                    value: true,
                                    visible: true,
                                    className: "bg-[#8c52ff]",
                                    closeModal: true
                                }
                            }
                        });
                    }
                    );

                });
        } else {
            setClicked(false);
            setLoading(false);
            swal('Some Required Fields Are Empty!!', '', 'warning', {
                buttons: {
                    confirm: {
                        text: "OK",
                        value: true,
                        visible: true,
                        className: "bg-[#8c52ff]",
                        closeModal: true
                    }
                }
            })
        }
    };

    const HandleProductAdd = async (e) => {
        const currentTime = moment().format('hh:mm:ss DD MMMM YYYY');
        const currentTimeStamp = moment().format('x');
        setClicked(true);
        if (productName !== '' && priceDiscounted !== '' && priceOriginal !== '' && description !== '' && productImageUrls.length > 0 && productCategory !== '' && tags.length > 0) {
            setLoading(true);
            e.preventDefault();
            const productID = uuid();
            await updateDoc(doc(db, "Stores", props.UserData.storeID), {
                Products: arrayUnion({
                    storeID: props.UserData.storeID,
                    owner: props.StoreData.owner,
                    ownerID: props.StoreData.ownerID,
                    ownerPFP: props.StoreData.ownerPFP,
                    number: props.StoreData.number,
                    storeName: props.StoreData.storeName,
                    logo: props.StoreData.logo,
                    address: props.StoreData.address,
                    storeState: props.StoreData.storeState,
                    storeCity: props.StoreData.storeCity,
                    productID,
                    productName,
                    priceDiscounted,
                    priceOriginal,
                    description,
                    productImage: productImageUrls,
                    productCategory,
                    fixed,
                    negotiable,
                    tags,
                    TimeCreated: currentTime,
                    TimeStamp: currentTimeStamp,
                }),
            });
            await updateDoc(doc(db, "Products", 'Products'), {
                Products: arrayUnion({
                    storeID: props.UserData.storeID,
                    owner: props.StoreData.owner,
                    ownerID: props.StoreData.ownerID,
                    ownerPFP: props.StoreData.ownerPFP,
                    number: props.StoreData.number,
                    storeName: props.StoreData.storeName,
                    logo: props.StoreData.logo,
                    address: props.StoreData.address,
                    storeState: props.StoreData.storeState,
                    storeCity: props.StoreData.storeCity,
                    productID,
                    productName,
                    priceDiscounted,
                    priceOriginal,
                    description,
                    productImage: productImageUrls,
                    productCategory,
                    fixed,
                    negotiable,
                    tags,
                    TimeCreated: currentTime,
                    TimeStamp: currentTimeStamp,
                })
            });
            await updateDoc(doc(db, "Category", props.StoreData.category), {
                Products: arrayUnion({
                    storeID: props.UserData.storeID,
                    owner: props.StoreData.owner,
                    ownerID: props.StoreData.ownerID,
                    ownerPFP: props.StoreData.ownerPFP,
                    number: props.StoreData.number,
                    storeName: props.StoreData.storeName,
                    logo: props.StoreData.logo,
                    address: props.StoreData.address,
                    storeState: props.StoreData.storeState,
                    storeCity: props.StoreData.storeCity,
                    productID,
                    productName,
                    priceDiscounted,
                    priceOriginal,
                    description,
                    productImage: productImageUrls,
                    productCategory,
                    fixed,
                    negotiable,
                    tags,
                    TimeCreated: currentTime,
                    TimeStamp: currentTimeStamp,
                })
            });
            setClicked(false);
            setLoading(false);
            setStoreName('');
            setYourName('');
            setFile(null);
            setAddress('');
            setGst('');
            setCategory('');
            setProductName('');
            setPriceDiscounted('');
            setPriceOriginal('');
            setDescription('');
            setImage([]);
            setMultipleFiles([]);
            setProductCategory('');
            setFixed(false);
            setNegotiable(false);
            setTags('');
            TextAreaRef.current.value = '';
            swal('Product Added!!', '', 'success', {
                buttons: {
                    confirm: {
                        text: "OK",
                        value: true,
                        visible: true,
                        className: "bg-[#8c52ff]",
                        closeModal: true
                    }
                }
            });
        } else {
            setClicked(false);
            setLoading(false);
            swal('Some Required Fields Are Empty!!', '', 'warning', {
                buttons: {
                    confirm: {
                        text: "OK",
                        value: true,
                        visible: true,
                        className: "bg-[#8c52ff]",
                        closeModal: true
                    }
                }
            })
        }
    };

    useEffect(() => {
        gsap.fromTo(".content-wrapper", { opacity: 0 }, { opacity: 1, duration: 2 });
    }, []);

    useEffect(() => {
        if (!currentUser) {
            swal({
                title: "Login",
                text: "Please Login To Sell Products",
                icon: "warning",
                buttons: true,
                dangerMode: false,
            }).then((willGo) => {
                if (willGo) {
                    navigate('/Auth')
                } else {
                    navigate('/')
                }
            });
        }
    }, [currentUser])

    useEffect(() => {
        if (props.UserData !== null) {
            if (currentUser && props.UserData !== null && props.UserData.NewUser === true) {
                navigate('/Onboarding');
            }
        }
    }, [currentUser, props.UserData])

    return (
        <>
            <div className="app">
                {loading && <LoadingBar color='linear-gradient(45deg,#ffbc00,#00d0ff ,#ff0058)' shadow={true} progress={progresspercent} onLoaderFinished={() => setProgressPercent(0)} height={4} style={{ "border-radius": "44px" }} />}
                <div className="wrapper">
                    <div className="left-side">
                        <div className="side-wrapper flex h-[100%]">
                            <div className="side-menu flex flex-col justify-evenly h-full">
                                <Link to="/" className="flex flex-row items-center gap-2 w-full">
                                    <img src="https://cdn-icons-png.freepik.com/512/4194/4194676.png" alt="Home Icon" style={{ height: 28, width: 28 }} />
                                    <h2 className="font-extrabold text-lg">Home</h2>
                                </Link>
                                <Link to="/Explore" className="flex flex-row items-center gap-2 w-full">
                                    <img src="https://cdn-icons-png.freepik.com/512/6337/6337301.png" alt="Explore Icon" style={{ height: 28, width: 28 }} />
                                    <h2 className="font-extrabold text-lg">Feed</h2>
                                </Link>
                                <Link to="/Sell" className="flex flex-row items-center gap-2 w-full">
                                    <img src="https://cdn-icons-png.freepik.com/512/10337/10337579.png" alt="Sell Icon" style={{ height: 28, width: 28 }} />
                                    <h2 className="font-extrabold text-lg">Sell</h2>
                                </Link>
                                <Link to="/Chats" className="flex flex-row items-center gap-2 w-full">
                                    <img src="https://cdn-icons-png.freepik.com/512/1041/1041916.png" alt="Chat Icon" style={{ height: 28, width: 28 }} />
                                    <h2 className="font-extrabold text-lg">Chat</h2>
                                </Link>
                                <Link to="/Profile" className="flex flex-row items-center gap-2 w-full">
                                    {currentUser ? <img src={currentUser.photoURL} alt="Profile Picture" style={{ height: 28, width: 28, borderRadius: '100%' }} /> : <img src="https://cdn-icons-png.freepik.com/512/1177/1177568.png" alt="Profile Picture" style={{ height: 28, width: 28 }} />}
                                    <h2 className="font-extrabold text-lg">Profile</h2>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="main-container">
                        <div className="content-wrapper">
                            {bottomBarVisible <= 945 && <BackButton />}
                            <div className={`${bottomBarVisible <= 945 ? 'mb-28' : ''}`}>
                                <div className="content-section-sell" ref={SellRef}>
                                    {currentUser && props.UserData && props.UserData.StoreAdded === false &&
                                        <>
                                            {bottomBarVisible <= 945 ?
                                                <>
                                                    <div className="innerContainerStoreAdd flex flex-col">
                                                        <div class="content-section-sell-store">
                                                            <div class="content-section-title-sell font-extrabold uppercase">Add Your Store</div>
                                                            <div class="apps-card gap-5">
                                                                <div class="app-card-sell">
                                                                    <div className="StoreNameInput flex justify-start items-center mt-5 flex-col gap-2">
                                                                        <span className="w-full flex text-start">Store Name</span>
                                                                        <TextField id="outlined-basic" label="Store Name" variant="filled" value={storeName} onChange={handleChangeStoreName} />
                                                                    </div>
                                                                </div>
                                                                <div class="app-card-sell">
                                                                    <div className="YourNameInput flex justify-start items-center mt-5 flex-col gap-2">
                                                                        <span className="w-full flex text-start">Your Name</span>
                                                                        <TextField id="outlined-basic" label="Your Name" variant="filled" value={yourName} onChange={handleChangeYourName} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="content-section-sell-store">
                                                            <div class="apps-card gap-5">
                                                                <div class="app-card-sell">
                                                                    <div className="FileInput flex justify-start items-center mt-5 flex-col gap-2">
                                                                        <span className="w-full flex text-start">Store Logo</span>
                                                                        <Label
                                                                            htmlFor="dropzone-file"
                                                                            className="dark:hover:bg-bray-800 flex h-max w-72 pl-5 pr-5 pt-2 pb-2 cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed border-gray-300 bg-gray-50 hover:bg-gray-100 dark:border-gray-600 dark:bg-gray-700 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                                                                        >
                                                                            <div className="flex flex-col items-center justify-center pb-6 pt-5">
                                                                                <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                                                                    {file && file.name ? <span className="font-semibold">{file.name}</span> : <span className="font-semibold">Click to upload the Store Logo</span>}
                                                                                </p>
                                                                            </div>
                                                                            <FileInput id="dropzone-file" className="hidden" onChange={handleFileChange} accept="image/jpg,image/jpeg,image/png" />
                                                                        </Label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="content-section-sell-store">
                                                            <div class="apps-card gap-5">
                                                                <div class="app-card-sell">
                                                                    <div class="app-card-sell">
                                                                        <div className="AddressInput flex justify-start items-center mt-5 flex-col gap-2">
                                                                            <span className="w-full flex text-start">Your Address</span>
                                                                            <Textarea id="comment" placeholder="Your Address" required rows={4} cols={35} value={address} onChange={handleChangeAddress} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="content-section-sell-store">
                                                            <div class="apps-card gap-5">
                                                                <div class="app-card-sell">
                                                                    <div className="shopCategoryInput flex justify-start items-center mt-5 flex-col gap-2">
                                                                        <span className="w-full flex text-start">STATE</span>
                                                                        {/* <TextField id="outlined-basic" label="Shop Category" variant="filled" value={category} onChange={handleChangeCategory} /> */}
                                                                        <Select id="store_state" required value={storeState} onChange={handleChangeStoreState} placeholder="CHOOSE YOUR STATE/UT" className='w-max max-w-[80vw]'>
                                                                            <option>NONE</option>
                                                                            {props.Locations.States.length > 0 && props.Locations.States?.sort((a, b) => a.localeCompare(b)).map(city => (
                                                                                <option>{city}</option>
                                                                            ))}
                                                                        </Select>
                                                                    </div>
                                                                </div>
                                                                <div class="app-card-sell">
                                                                    <div className="shopCategoryInput flex justify-start items-center mt-5 flex-col gap-2">
                                                                        <span className="w-full flex text-start">CITY</span>
                                                                        {/* <TextField id="outlined-basic" label="Shop Category" variant="filled" value={category} onChange={handleChangeCategory} /> */}
                                                                        <Select id="store_city" required value={storeCity} onChange={handleChangeStoreCity} placeholder="CHOOSE YOUR CITY" className='w-max max-w-[80vw]'>
                                                                            <option>NONE</option>
                                                                            {storeState !== '' && props.Locations.Cities.length > 0 && props.Locations.Cities?.filter((item, idx) => item.state_name === storeState).sort((a, b) => a.name.localeCompare(b.name)).map(city => (
                                                                                <option>{city.name}</option>
                                                                            ))}
                                                                        </Select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="content-section-sell-store">
                                                            <div class="apps-card gap-5">
                                                                <div class="app-card-sell">
                                                                    <div className="shopCategoryInput flex justify-start items-center mt-5 flex-col gap-2">
                                                                        <span className="w-full flex text-start">Shop Category</span>
                                                                        {/* <TextField id="outlined-basic" label="Shop Category" variant="filled" value={category} onChange={handleChangeCategory} /> */}
                                                                        <Select id="shop_category" required value={category} onChange={handleChangeCategory} placeholder="CHOOSE YOUR SHOP CATEGORY" className='w-max max-w-[80vw]'>
                                                                            <option>NONE</option>
                                                                            {props.CategoriesData.length > 0 && props.CategoriesData?.map(item => (
                                                                                <option>{item.Name}</option>
                                                                            ))}
                                                                        </Select>
                                                                    </div>
                                                                </div>
                                                                <div class="app-card-sell">
                                                                    <div className="gstInput flex justify-start items-center mt-5 flex-col gap-2">
                                                                        <span className="w-full flex text-start">GST (Optional)</span>
                                                                        <TextField id="outlined-basic" label="GST" variant="filled" value={gst} onChange={handleChangeGST} />
                                                                    </div>
                                                                </div>
                                                                <div class="app-card-sell">
                                                                    <div className="PriceInput flex justify-start items-center mt-5 flex-col gap-2">
                                                                        <span className="w-full flex text-start">Contact Number</span>
                                                                        <TextField id="outlined-basic" label="+91" variant="filled" value={number} onChange={handleChangeNumber} inputProps={{ maxLength: 10, inputMode: 'numeric', pattern: '[0-9]*' }} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="content-section-sell-store mt-5">
                                                            <div class="content-section-title-sell font-extrabold uppercase">Add Your First Product</div>
                                                            <div class="apps-card gap-5">
                                                                <div class="app-card-sell">
                                                                    <div className="ProductNameInput flex justify-start items-center mt-5 flex-col gap-2">
                                                                        <span className="w-full flex text-start">Product Name</span>
                                                                        <TextField id="outlined-basic" label="Product Name" variant="filled" value={productName} onChange={handleChangeProductName} />
                                                                    </div>
                                                                </div>
                                                                <div class="app-card-sell">
                                                                    <div className="PriceInput flex justify-start items-center mt-5 flex-col gap-2">
                                                                        <span className="w-full flex text-start">Discounted Price</span>
                                                                        <TextField id="outlined-basic" label="Discounted Price" variant="filled" value={priceDiscounted} onChange={handleChangePriceDiscounted} inputProps={{ maxLength: 6, inputMode: 'numeric', pattern: '[0-9]*' }} />
                                                                    </div>
                                                                </div>
                                                                <div class="app-card-sell">
                                                                    <div className="PriceInput flex justify-start items-center mt-5 flex-col gap-2">
                                                                        <span className="w-full flex text-start">Original Price</span>
                                                                        <TextField id="outlined-basic" label="Original Price" variant="filled" value={priceOriginal} onChange={handleChangePriceOriginal} inputProps={{ maxLength: 6, inputMode: 'numeric', pattern: '[0-9]*' }} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="content-section-sell-store">
                                                            <div class="apps-card gap-5">
                                                                <div class="app-card-sell">
                                                                    <div class="app-card-sell">
                                                                        <div className="DescriptionInput flex justify-start items-center mt-5 flex-col gap-2">
                                                                            <span className="w-full flex text-start">Product Description</span>
                                                                            <Textarea id="comment" placeholder="Product Description" required rows={4} cols={35} value={description} onChange={handleChangeDescription} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="content-section-sell-store">
                                                            <div class="apps-card gap-5">
                                                                <div class="app-card-sell">
                                                                    <div className="ImageInput flex justify-start items-center mt-5 flex-col gap-2">
                                                                        <span className="w-full flex text-start">Product Images</span>
                                                                        <Label
                                                                            htmlFor="dropzone-file2"
                                                                            className="dark:hover:bg-bray-800 flex h-max w-72 pl-5 pr-5 pt-2 pb-2 cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed border-gray-300 bg-gray-50 hover:bg-gray-100 dark:border-gray-600 dark:bg-gray-700 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                                                                        >
                                                                            <div className="flex flex-col items-center justify-center pb-6 pt-5">
                                                                                <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                                                                    {image && image.name ? <span className="font-semibold">{image.name}</span> : <span className="font-semibold">Click to upload the Product Images</span>}
                                                                                </p>
                                                                            </div>
                                                                            <FileInput id="dropzone-file2" className="hidden" accept="image/jpg,image/jpeg,image/png" multiple onChange={handleImageChange} />
                                                                        </Label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {multipleFiles.length > 0 &&
                                                            <div class="content-section-sell-store mt-10">
                                                                <div class="apps-card gap-5">
                                                                    {multipleFiles.map(file => (
                                                                        <div class="app-card-sell">
                                                                            <img src={file} alt="" className='h-40 w-40 rounded-2xl' />
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        }
                                                        <div class="content-section-sell-store">
                                                            <div class="apps-card gap-5">
                                                                <div class="app-card-sell">
                                                                    <div class="app-card-sell">
                                                                        <div className="ProductCategoryInput flex justify-start items-center mt-5 flex-col gap-2">
                                                                            <span className="w-full flex text-start">Product Category</span>
                                                                            <Select id="product_category" required value={productCategory} onChange={handleChangeProductCategory} placeholder="CHOOSE YOUR PRODUCT CATEGORY" className='w-max max-w-[80vw]'>
                                                                                {category !== '' ? <option>NONE</option> : <option>SELECT A SHOP CATEGORY</option>}
                                                                                {category !== '' && props.CategoriesData.length > 0 && props.CategoriesData?.filter(item => item.Name === category)[0].Items.map(val => (
                                                                                    <option>{val}</option>
                                                                                ))}
                                                                            </Select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="content-section-sell-store mt-5">
                                                            <div class="apps-card gap-5">
                                                                <div class="app-card-sell">
                                                                    <div class="app-card-sell flex gap-2 justify-center items-center p-2 rounded-lg" style={{ backgroundColor: 'var(--content-bg)' }}>
                                                                        <Checkbox id="fixedPrice" checked={fixed} onChange={handleChangeFixed} />
                                                                        <Label htmlFor="fixedPrice">Fixed Price</Label>
                                                                    </div>
                                                                </div>
                                                                <div class="app-card-sell">
                                                                    <div class="app-card-sell flex gap-2 justify-center items-center p-2 rounded-lg" style={{ backgroundColor: 'var(--content-bg)' }}>
                                                                        <Checkbox id="negotiablePrice" checked={negotiable} onChange={handleChangeNegotiable} />
                                                                        <Label htmlFor="negotiablePrice">Negotiable</Label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="content-section-sell-store">
                                                            <div class="apps-card gap-5">
                                                                <div class="app-card-sell">
                                                                    <div class="app-card-sell">
                                                                        <div className="TagsInput flex justify-start items-center mt-5 flex-col gap-2">
                                                                            <span className="w-full flex text-start">Add Tags</span>
                                                                            <Textarea id="Tags" ref={TextAreaRef} placeholder="Add Tags" required rows={4} cols={35} onChange={handleChangeTags} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="SubmitFirstSell w-full flex justify-center items-center mt-5">
                                                            {clicked === true ?
                                                                <div className="SubmitBtn text-white font-extrabold rounded-xl pt-2 pb-2 pl-5 pr-5 bg-blue-300 cursor-pointer">
                                                                    SUBMITTING
                                                                </div>
                                                                :
                                                                <div className="SubmitBtn text-white font-extrabold rounded-xl pt-2 pb-2 pl-5 pr-5 bg-blue-500 cursor-pointer" onClick={HandleCreateFirst}>
                                                                    SUBMIT
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </>
                                                :
                                                <>
                                                    <div className="innerContainerStoreAdd flex flex-col">
                                                        <div class="content-section-sell-store">
                                                            <div class="content-section-title-sell font-extrabold uppercase">Add Your Store</div>
                                                            <div class="apps-card gap-5">
                                                                <div class="app-card-sell">
                                                                    <div className="StoreNameInput flex justify-start items-center mt-5 flex-col gap-2">
                                                                        <span className="w-full flex text-start">Store Name</span>
                                                                        <TextField id="outlined-basic" label="Store Name" variant="filled" value={storeName} onChange={handleChangeStoreName} />
                                                                    </div>
                                                                </div>
                                                                <div class="app-card-sell">
                                                                    <div className="YourNameInput flex justify-start items-center mt-5 flex-col gap-2">
                                                                        <span className="w-full flex text-start">Your Name</span>
                                                                        <TextField id="outlined-basic" label="Your Name" variant="filled" value={yourName} onChange={handleChangeYourName} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="content-section-sell-store">
                                                            <div class="apps-card gap-5">
                                                                <div class="app-card-sell">
                                                                    <div className="FileInput flex justify-start items-center mt-5 flex-col gap-2">
                                                                        <span className="w-full flex text-start">Store Logo</span>
                                                                        <Label
                                                                            htmlFor="dropzone-file"
                                                                            className="dark:hover:bg-bray-800 flex h-max w-72 pl-5 pr-5 pt-2 pb-2 cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed border-gray-300 bg-gray-50 hover:bg-gray-100 dark:border-gray-600 dark:bg-gray-700 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                                                                        >
                                                                            <div className="flex flex-col items-center justify-center pb-6 pt-5">
                                                                                <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                                                                    {file && file.name ? <span className="font-semibold">{file.name}</span> : <span className="font-semibold">Click to upload the Store Logo</span>}
                                                                                </p>
                                                                            </div>
                                                                            <FileInput id="dropzone-file" className="hidden" onChange={handleFileChange} accept="image/jpg,image/jpeg,image/png" />
                                                                        </Label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="content-section-sell-store">
                                                            <div class="apps-card gap-5">
                                                                <div class="app-card-sell">
                                                                    <div class="app-card-sell">
                                                                        <div className="AddressInput flex justify-start items-center mt-5 flex-col gap-2">
                                                                            <span className="w-full flex text-start">Your Address</span>
                                                                            <Textarea id="comment" placeholder="Your Address" required rows={4} cols={35} value={address} onChange={handleChangeAddress} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="content-section-sell-store">
                                                            <div class="apps-card gap-5">
                                                                <div class="app-card-sell">
                                                                    <div className="shopCategoryInput flex justify-start items-center mt-5 flex-col gap-2">
                                                                        <span className="w-full flex text-start">STATE</span>
                                                                        {/* <TextField id="outlined-basic" label="Shop Category" variant="filled" value={category} onChange={handleChangeCategory} /> */}
                                                                        <Select id="store_state" required value={storeState} onChange={handleChangeStoreState} placeholder="CHOOSE YOUR STATE/UT" className='w-max max-w-[80vw]'>
                                                                            <option>NONE</option>
                                                                            {props.Locations.States.length > 0 && props.Locations.States?.sort((a, b) => a.localeCompare(b)).map(city => (
                                                                                <option>{city}</option>
                                                                            ))}
                                                                        </Select>
                                                                    </div>
                                                                </div>
                                                                <div class="app-card-sell">
                                                                    <div className="shopCategoryInput flex justify-start items-center mt-5 flex-col gap-2">
                                                                        <span className="w-full flex text-start">CITY</span>
                                                                        {/* <TextField id="outlined-basic" label="Shop Category" variant="filled" value={category} onChange={handleChangeCategory} /> */}
                                                                        <Select id="store_city" required value={storeCity} onChange={handleChangeStoreCity} placeholder="CHOOSE YOUR CITY" className='w-max max-w-[80vw]'>
                                                                            <option>NONE</option>
                                                                            {storeState !== '' && props.Locations.Cities.length > 0 && props.Locations.Cities?.filter((item, idx) => item.state_name === storeState).sort((a, b) => a.name.localeCompare(b.name)).map(city => (
                                                                                <option>{city.name}</option>
                                                                            ))}
                                                                        </Select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="content-section-sell-store">
                                                            <div class="apps-card gap-5">
                                                                <div class="app-card-sell">
                                                                    <div className="shopCategoryInput flex justify-start items-center mt-5 flex-col gap-2">
                                                                        <span className="w-full flex text-start">Shop Category</span>
                                                                        {/* <TextField id="outlined-basic" label="Shop Category" variant="filled" value={category} onChange={handleChangeCategory} /> */}
                                                                        <Select id="shop_category" required value={category} onChange={handleChangeCategory} placeholder="CHOOSE YOUR SHOP CATEGORY" className='w-max max-w-[80vw]'>
                                                                            <option>NONE</option>
                                                                            {props.CategoriesData.length > 0 && props.CategoriesData?.map(item => (
                                                                                <option>{item.Name}</option>
                                                                            ))}
                                                                        </Select>
                                                                    </div>
                                                                </div>
                                                                <div class="app-card-sell">
                                                                    <div className="gstInput flex justify-start items-center mt-5 flex-col gap-2">
                                                                        <span className="w-full flex text-start">GST (Optional)</span>
                                                                        <TextField id="outlined-basic" label="GST" variant="filled" value={gst} onChange={handleChangeGST} />
                                                                    </div>
                                                                </div>
                                                                <div class="app-card-sell">
                                                                    <div className="PriceInput flex justify-start items-center mt-5 flex-col gap-2">
                                                                        <span className="w-full flex text-start">Contact Number</span>
                                                                        <TextField id="outlined-basic" label="+91" variant="filled" value={number} onChange={handleChangeNumber} inputProps={{ maxLength: 10, inputMode: 'numeric', pattern: '[0-9]*' }} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="content-section-sell-store mt-5">
                                                            <div class="content-section-title-sell font-extrabold uppercase">Add Your First Product</div>
                                                            <div class="apps-card gap-5">
                                                                <div class="app-card-sell">
                                                                    <div className="ProductNameInput flex justify-start items-center mt-5 flex-col gap-2">
                                                                        <span className="w-full flex text-start">Product Name</span>
                                                                        <TextField id="outlined-basic" label="Product Name" variant="filled" value={productName} onChange={handleChangeProductName} />
                                                                    </div>
                                                                </div>
                                                                <div class="app-card-sell">
                                                                    <div className="PriceInput flex justify-start items-center mt-5 flex-col gap-2">
                                                                        <span className="w-full flex text-start">Discounted Price</span>
                                                                        <TextField id="outlined-basic" label="Discounted Price" variant="filled" value={priceDiscounted} onChange={handleChangePriceDiscounted} inputProps={{ maxLength: 6, inputMode: 'numeric', pattern: '[0-9]*' }} />
                                                                    </div>
                                                                </div>
                                                                <div class="app-card-sell">
                                                                    <div className="PriceInput flex justify-start items-center mt-5 flex-col gap-2">
                                                                        <span className="w-full flex text-start">Original Price</span>
                                                                        <TextField id="outlined-basic" label="Original Price" variant="filled" value={priceOriginal} onChange={handleChangePriceOriginal} inputProps={{ maxLength: 6, inputMode: 'numeric', pattern: '[0-9]*' }} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="content-section-sell-store">
                                                            <div class="apps-card gap-5">
                                                                <div class="app-card-sell">
                                                                    <div class="app-card-sell">
                                                                        <div className="DescriptionInput flex justify-start items-center mt-5 flex-col gap-2">
                                                                            <span className="w-full flex text-start">Product Description</span>
                                                                            <Textarea id="comment" placeholder="Product Description" required rows={4} cols={35} value={description} onChange={handleChangeDescription} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="content-section-sell-store">
                                                            <div class="apps-card gap-5">
                                                                <div class="app-card-sell">
                                                                    <div className="ImageInput flex justify-start items-center mt-5 flex-col gap-2">
                                                                        <span className="w-full flex text-start">Product Images</span>
                                                                        <Label
                                                                            htmlFor="dropzone-file2"
                                                                            className="dark:hover:bg-bray-800 flex h-max w-72 pl-5 pr-5 pt-2 pb-2 cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed border-gray-300 bg-gray-50 hover:bg-gray-100 dark:border-gray-600 dark:bg-gray-700 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                                                                        >
                                                                            <div className="flex flex-col items-center justify-center pb-6 pt-5">
                                                                                <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                                                                    {image && image.name ? <span className="font-semibold">{image.name}</span> : <span className="font-semibold">Click to upload the Product Images</span>}
                                                                                </p>
                                                                            </div>
                                                                            <FileInput id="dropzone-file2" className="hidden" accept="image/jpg,image/jpeg,image/png" multiple onChange={handleImageChange} />
                                                                        </Label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {multipleFiles.length > 0 &&
                                                            <div class="content-section-sell-store mt-10">
                                                                <div class="apps-card gap-5">
                                                                    {multipleFiles.map(file => (
                                                                        <div class="app-card-sell">
                                                                            <img src={file} alt="" className='h-40 w-40 rounded-2xl' />
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        }
                                                        <div class="content-section-sell-store">
                                                            <div class="apps-card gap-5">
                                                                <div class="app-card-sell">
                                                                    <div class="app-card-sell">
                                                                        <div className="ProductCategoryInput flex justify-start items-center mt-5 flex-col gap-2">
                                                                            <span className="w-full flex text-start">Product Category</span>
                                                                            <Select id="product_category" required value={productCategory} onChange={handleChangeProductCategory} placeholder="CHOOSE YOUR PRODUCT CATEGORY" className='w-max max-w-[80vw]'>
                                                                                {category !== '' ? <option>NONE</option> : <option>SELECT A SHOP CATEGORY</option>}
                                                                                {category !== '' && props.CategoriesData.length > 0 && props.CategoriesData?.filter(item => item.Name === category)[0].Items.map(val => (
                                                                                    <option>{val}</option>
                                                                                ))}
                                                                            </Select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="content-section-sell-store mt-5">
                                                            <div class="apps-card gap-5">
                                                                <div class="app-card-sell">
                                                                    <div class="app-card-sell flex gap-2 justify-center items-center p-2 rounded-lg" style={{ backgroundColor: 'var(--content-bg)' }}>
                                                                        <Checkbox id="fixedPrice" checked={fixed} onChange={handleChangeFixed} />
                                                                        <Label htmlFor="fixedPrice">Fixed Price</Label>
                                                                    </div>
                                                                </div>
                                                                <div class="app-card-sell">
                                                                    <div class="app-card-sell flex gap-2 justify-center items-center p-2 rounded-lg" style={{ backgroundColor: 'var(--content-bg)' }}>
                                                                        <Checkbox id="negotiablePrice" checked={negotiable} onChange={handleChangeNegotiable} />
                                                                        <Label htmlFor="negotiablePrice">Negotiable</Label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="content-section-sell-store">
                                                            <div class="apps-card gap-5">
                                                                <div class="app-card-sell">
                                                                    <div class="app-card-sell">
                                                                        <div className="TagsInput flex justify-start items-center mt-5 flex-col gap-2">
                                                                            <span className="w-full flex text-start">Add Tags</span>
                                                                            <Textarea id="Tags" ref={TextAreaRef} placeholder="Add Tags" required rows={4} cols={35} onChange={handleChangeTags} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="SubmitFirstSell w-full flex justify-center items-center mt-5">
                                                            {clicked === true ?
                                                                <div className="SubmitBtn text-white font-extrabold rounded-xl pt-2 pb-2 pl-5 pr-5 bg-blue-300 cursor-pointer">
                                                                    SUBMITTING
                                                                </div>
                                                                :
                                                                <div className="SubmitBtn text-white font-extrabold rounded-xl pt-2 pb-2 pl-5 pr-5 bg-blue-500 cursor-pointer" onClick={HandleCreateFirst}>
                                                                    SUBMIT
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                        </>}
                                    {currentUser && props.UserData && props.UserData.StoreAdded === true &&
                                        <>
                                            {props.UserData && props.UserData.isApproved === true ?
                                                <>
                                                    {bottomBarVisible <= 945 ?
                                                        <>
                                                            <div className="innerContainerStoreAdd flex flex-col mb-20">
                                                                <div class="content-section-sell-store mt-5">
                                                                    <div class="content-section-title-sell font-extrabold uppercase">Add Your Product</div>
                                                                    <div class="apps-card gap-5">
                                                                        <div class="app-card-sell">
                                                                            <div className="ProductNameInput flex justify-start items-center mt-5 flex-col gap-2">
                                                                                <span className="w-full flex text-start">Product Name</span>
                                                                                <TextField id="outlined-basic" label="Product Name" variant="filled" value={productName} onChange={handleChangeProductName} />
                                                                            </div>
                                                                        </div>
                                                                        <div class="app-card-sell">
                                                                            <div className="PriceInput flex justify-start items-center mt-5 flex-col gap-2">
                                                                                <span className="w-full flex text-start">Discounted Price</span>
                                                                                <TextField id="outlined-basic" label="Discounted Price" variant="filled" value={priceDiscounted} onChange={handleChangePriceDiscounted} inputProps={{ maxLength: 6, inputMode: 'numeric', pattern: '[0-9]*' }} />
                                                                            </div>
                                                                        </div>
                                                                        <div class="app-card-sell">
                                                                            <div className="PriceInput flex justify-start items-center mt-5 flex-col gap-2">
                                                                                <span className="w-full flex text-start">Original Price</span>
                                                                                <TextField id="outlined-basic" label="Original Price" variant="filled" value={priceOriginal} onChange={handleChangePriceOriginal} inputProps={{ maxLength: 6, inputMode: 'numeric', pattern: '[0-9]*' }} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="content-section-sell-store">
                                                                    <div class="apps-card gap-5">
                                                                        <div class="app-card-sell">
                                                                            <div class="app-card-sell">
                                                                                <div className="DescriptionInput flex justify-start items-center mt-5 flex-col gap-2">
                                                                                    <span className="w-full flex text-start">Product Description</span>
                                                                                    <Textarea id="comment" placeholder="Product Description" required rows={4} cols={35} value={description} onChange={handleChangeDescription} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="content-section-sell-store">
                                                                    <div class="apps-card gap-5">
                                                                        <div class="app-card-sell">
                                                                            <div className="ImageInput flex justify-start items-center mt-5 flex-col gap-2">
                                                                                <span className="w-full flex text-start">Product Images</span>
                                                                                <Label
                                                                                    htmlFor="dropzone-file2"
                                                                                    className="dark:hover:bg-bray-800 flex h-max w-72 pl-5 pr-5 pt-2 pb-2 cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed border-gray-300 bg-gray-50 hover:bg-gray-100 dark:border-gray-600 dark:bg-gray-700 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                                                                                >
                                                                                    <div className="flex flex-col items-center justify-center pb-6 pt-5">
                                                                                        <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                                                                            {image && image.name ? <span className="font-semibold">{image.name}</span> : <span className="font-semibold">Click to upload the Product Images</span>}
                                                                                        </p>
                                                                                    </div>
                                                                                    <FileInput id="dropzone-file2" className="hidden" accept="image/jpg,image/jpeg,image/png" multiple onChange={handleImageChange} />
                                                                                </Label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {multipleFiles.length > 0 &&
                                                                    <div class="content-section-sell-store mt-10">
                                                                        <div class="apps-card gap-5">
                                                                            {multipleFiles.map(file => (
                                                                                <div class="app-card-sell">
                                                                                    <img src={file} alt="" className='h-40 w-40 rounded-2xl' />
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                    </div>
                                                                }
                                                                <div class="content-section-sell-store">
                                                                    <div class="apps-card gap-5">
                                                                        <div class="app-card-sell">
                                                                            <div class="app-card-sell">
                                                                                <div className="ProductCategoryInput flex justify-start items-center mt-5 flex-col gap-2">
                                                                                    <span className="w-full flex text-start">Product Category</span>
                                                                                    <Select id="product_category" required value={productCategory} onChange={handleChangeProductCategory} placeholder="CHOOSE YOUR PRODUCT CATEGORY">
                                                                                        <option>NONE</option>
                                                                                        {/* {props.StoreData !== null && props.CategoriesData?.filter(item => item.Name === props.StoreData.category).map(val => (
                                                                                            val[props.StoreData.category].map(opt => (
                                                                                                <option>{opt}</option>
                                                                                            ))
                                                                                        ))} */}
                                                                                        {category !== '' ? <option>NONE</option> : <option>SELECT A SHOP CATEGORY</option>}
                                                                                        {category !== '' && props.CategoriesData.length > 0 && props.CategoriesData?.filter(item => item.Name === category)[0].Items.map(val => (
                                                                                            <option>{val}</option>
                                                                                        ))}
                                                                                    </Select>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="content-section-sell-store mt-5">
                                                                    <div class="apps-card gap-5">
                                                                        <div class="app-card-sell">
                                                                            <div class="app-card-sell flex gap-2 justify-center items-center p-2 rounded-lg" style={{ backgroundColor: 'var(--content-bg)' }}>
                                                                                <Checkbox id="fixedPrice" checked={fixed} onChange={handleChangeFixed} />
                                                                                <Label htmlFor="fixedPrice">Fixed Price</Label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="app-card-sell">
                                                                            <div class="app-card-sell flex gap-2 justify-center items-center p-2 rounded-lg" style={{ backgroundColor: 'var(--content-bg)' }}>
                                                                                <Checkbox id="negotiablePrice" checked={negotiable} onChange={handleChangeNegotiable} />
                                                                                <Label htmlFor="negotiablePrice">Negotiable</Label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="content-section-sell-store">
                                                                    <div class="apps-card gap-5">
                                                                        <div class="app-card-sell">
                                                                            <div class="app-card-sell">
                                                                                <div className="TagsInput flex justify-start items-center mt-5 flex-col gap-2">
                                                                                    <span className="w-full flex text-start">Add Tags</span>
                                                                                    <Textarea id="Tags" ref={TextAreaRef} placeholder="Add Tags" required rows={4} cols={35} onChange={handleChangeTags} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="SubmitFirstSell w-full flex justify-center items-center mt-5">
                                                                    {clicked === true ?
                                                                        <div className="SubmitBtn text-white font-extrabold rounded-xl pt-2 pb-2 pl-5 pr-5 bg-blue-300 cursor-pointer">
                                                                            SUBMITTING
                                                                        </div>
                                                                        :
                                                                        <div className="SubmitBtn text-white font-extrabold rounded-xl pt-2 pb-2 pl-5 pr-5 bg-blue-500 cursor-pointer" onClick={HandleProductAdd}>
                                                                            SUBMIT
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </>
                                                        :
                                                        <>
                                                            <div className="innerContainerStoreAdd flex flex-col">
                                                                <div class="content-section-sell-store mt-5">
                                                                    <div class="content-section-title-sell font-extrabold uppercase">Add Your Product</div>
                                                                    <div class="apps-card gap-5">
                                                                        <div class="app-card-sell">
                                                                            <div className="ProductNameInput flex justify-start items-center mt-5 flex-col gap-2">
                                                                                <span className="w-full flex text-start">Product Name</span>
                                                                                <TextField id="outlined-basic" label="Product Name" variant="filled" value={productName} onChange={handleChangeProductName} />
                                                                            </div>
                                                                        </div>
                                                                        <div class="app-card-sell">
                                                                            <div className="PriceInput flex justify-start items-center mt-5 flex-col gap-2">
                                                                                <span className="w-full flex text-start">Discounted Price</span>
                                                                                <TextField id="outlined-basic" label="Discounted Price" variant="filled" value={priceDiscounted} onChange={handleChangePriceDiscounted} inputProps={{ maxLength: 6, inputMode: 'numeric', pattern: '[0-9]*' }} />
                                                                            </div>
                                                                        </div>
                                                                        <div class="app-card-sell">
                                                                            <div className="PriceInput flex justify-start items-center mt-5 flex-col gap-2">
                                                                                <span className="w-full flex text-start">Original Price</span>
                                                                                <TextField id="outlined-basic" label="Original Price" variant="filled" value={priceOriginal} onChange={handleChangePriceOriginal} inputProps={{ maxLength: 6, inputMode: 'numeric', pattern: '[0-9]*' }} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="content-section-sell-store">
                                                                    <div class="apps-card gap-5">
                                                                        <div class="app-card-sell">
                                                                            <div class="app-card-sell">
                                                                                <div className="DescriptionInput flex justify-start items-center mt-5 flex-col gap-2">
                                                                                    <span className="w-full flex text-start">Product Description</span>
                                                                                    <Textarea id="comment" placeholder="Product Description" required rows={4} cols={35} value={description} onChange={handleChangeDescription} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="content-section-sell-store">
                                                                    <div class="apps-card gap-5">
                                                                        <div class="app-card-sell">
                                                                            <div className="ImageInput flex justify-start items-center mt-5 flex-col gap-2">
                                                                                <span className="w-full flex text-start">Product Images</span>
                                                                                <Label
                                                                                    htmlFor="dropzone-file2"
                                                                                    className="dark:hover:bg-bray-800 flex h-max w-72 pl-5 pr-5 pt-2 pb-2 cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed border-gray-300 bg-gray-50 hover:bg-gray-100 dark:border-gray-600 dark:bg-gray-700 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                                                                                >
                                                                                    <div className="flex flex-col items-center justify-center pb-6 pt-5">
                                                                                        <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                                                                            {image && image.name ? <span className="font-semibold">{image.name}</span> : <span className="font-semibold">Click to upload the Product Images</span>}
                                                                                        </p>
                                                                                    </div>
                                                                                    <FileInput id="dropzone-file2" className="hidden" accept="image/jpg,image/jpeg,image/png" multiple onChange={handleImageChange} />
                                                                                </Label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {multipleFiles.length > 0 &&
                                                                    <div class="content-section-sell-store mt-10">
                                                                        <div class="apps-card gap-5">
                                                                            {multipleFiles.map(file => (
                                                                                <div class="app-card-sell">
                                                                                    <img src={file} alt="" className='h-40 w-40 rounded-2xl' />
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                    </div>
                                                                }
                                                                <div class="content-section-sell-store">
                                                                    <div class="apps-card gap-5">
                                                                        <div class="app-card-sell">
                                                                            <div class="app-card-sell">
                                                                                <div className="ProductCategoryInput flex justify-start items-center mt-5 flex-col gap-2">
                                                                                    <span className="w-full flex text-start">Product Category</span>
                                                                                    <Select id="product_category" required value={productCategory} onChange={handleChangeProductCategory} placeholder="CHOOSE YOUR PRODUCT CATEGORY">
                                                                                        <option></option>
                                                                                        {/* {props.StoreData !== null && props.CategoriesData?.filter(item => item.Name === props.StoreData.category).map(val => (
                                                                                            val[props.StoreData.category].map(opt => (
                                                                                                <option>{opt}</option>
                                                                                            ))
                                                                                        ))} */}
                                                                                        {category !== '' ? <option>NONE</option> : <option>SELECT A SHOP CATEGORY</option>}
                                                                                        {category !== '' && props.CategoriesData.length > 0 && props.CategoriesData?.filter(item => item.Name === category)[0].Items.map(val => (
                                                                                            <option>{val}</option>
                                                                                        ))}
                                                                                    </Select>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="content-section-sell-store mt-5">
                                                                    <div class="apps-card gap-5">
                                                                        <div class="app-card-sell">
                                                                            <div class="app-card-sell flex gap-2 justify-center items-center p-2 rounded-lg" style={{ backgroundColor: 'var(--content-bg)' }}>
                                                                                <Checkbox id="fixedPrice" checked={fixed} onChange={handleChangeFixed} />
                                                                                <Label htmlFor="fixedPrice">Fixed Price</Label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="app-card-sell">
                                                                            <div class="app-card-sell flex gap-2 justify-center items-center p-2 rounded-lg" style={{ backgroundColor: 'var(--content-bg)' }}>
                                                                                <Checkbox id="negotiablePrice" checked={negotiable} onChange={handleChangeNegotiable} />
                                                                                <Label htmlFor="negotiablePrice">Negotiable</Label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="content-section-sell-store">
                                                                    <div class="apps-card gap-5">
                                                                        <div class="app-card-sell">
                                                                            <div class="app-card-sell">
                                                                                <div className="TagsInput flex justify-start items-center mt-5 flex-col gap-2">
                                                                                    <span className="w-full flex text-start">Add Tags</span>
                                                                                    <Textarea id="Tags" ref={TextAreaRef} placeholder="Add Tags" required rows={4} cols={35} onChange={handleChangeTags} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="SubmitFirstSell w-full flex justify-center items-center mt-5">
                                                                    {clicked === true ?
                                                                        <div className="SubmitBtn text-white font-extrabold rounded-xl pt-2 pb-2 pl-5 pr-5 bg-blue-300 cursor-pointer">
                                                                            SUBMITTING
                                                                        </div>
                                                                        :
                                                                        <div className="SubmitBtn text-white font-extrabold rounded-xl pt-2 pb-2 pl-5 pr-5 bg-blue-500 cursor-pointer" onClick={HandleProductAdd}>
                                                                            SUBMIT
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </>
                                                    }
                                                </>
                                                :
                                                <>
                                                    <div className="WaitingForApproval w-full h-full flex justify-center items-center text-center text-2xl font-black">
                                                        WAITING FOR STORE APPROVAL
                                                    </div>
                                                </>
                                            }
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="overlay-app" />
            </div>
            {bottomBarVisible <= 945 &&
                <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, backgroundColor: 'transparent' }} elevation={3}>
                    <BottomNavigation
                        className="backgroundColor: 'transparent',"
                        showLabels
                        value={value}
                        onChange={(event, newValue) => {
                            setValue(newValue);
                            newValue === 0 ? navigate('/') : newValue === 1 ? navigate('/Explore') : newValue === 2 ? navigate('/Sell') : newValue === 3 ? navigate('/Chats') : newValue === 4 ? navigate('/Profile') : navigate('/')
                        }}
                    >
                        <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<HomeRoundedIcon />} label="Home" />
                        <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<SlideshowIcon />} label="Shorts" />
                        <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<AddCircleRoundedIcon />} label="Sell" />
                        <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<QuestionAnswerRoundedIcon />} label="Chat" />
                        {currentUser ? <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<img src={currentUser.photoURL} className='h-6 w-6 rounded-full' />} label="Manage" /> : <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<PersonRoundedIcon />} label="Profile" />}
                    </BottomNavigation>
                </Paper>
            }
        </>
    )
}

export default Sell