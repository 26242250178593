/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable react-hooks/exhaustive-deps */
import { BottomNavigation, BottomNavigationAction, Paper, TextField } from '@mui/material';
import React, { useContext, useEffect, useRef, useState } from 'react';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import SlideshowIcon from '@mui/icons-material/Slideshow';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import QuestionAnswerRoundedIcon from '@mui/icons-material/QuestionAnswerRounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import { FileInput, Label, Textarea } from 'flowbite-react';
import {
    Link, useNavigate,
} from 'react-router-dom';
import gsap from 'gsap';
import { AuthContext } from '../context/AuthContext';
import { v4 as uuid } from "uuid";
import { arrayRemove, arrayUnion, collection, doc, getDocs, query, serverTimestamp, updateDoc, where } from 'firebase/firestore';
import { db, storage } from '../firebase';
import LoadingBar from 'react-top-loading-bar';
import swal from '@sweetalert/with-react';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import moment from 'moment';
import BackButton from '../components/BackButton';

const EditStore = (props) => {
    const { currentUser } = useContext(AuthContext);
    const [bottomBarVisible, setBottomBarVisible] = useState(window.screen.width);

    useEffect(() => {
        const interval = setInterval(() => {
            setBottomBarVisible(window.screen.width)
        }, 100);
        return () => clearInterval(interval);
    }, []);

    // eslint-disable-next-line no-unused-vars
    const [value, setValue] = useState(2);
    const SellRef = useRef(null);
    const navigate = useNavigate();
    const [storeName, setStoreName] = useState('');
    const [file, setFile] = useState(null);
    const [address, setAddress] = useState('');
    const [gst, setGst] = useState('');
    const [number, setNumber] = useState('');
    const [progresspercent, setProgressPercent] = useState();
    // eslint-disable-next-line no-unused-vars
    const [err, setErr] = useState(false);
    const [loading, setLoading] = useState(false);
    const [clicked, setClicked] = useState(false);

    const handleChangeStoreName = (event) => {
        setStoreName(event.target.value);
    };

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const handleChangeAddress = (event) => {
        setAddress(event.target.value);
    };

    const handleChangeGST = (event) => {
        setGst(event.target.value);
    };

    const handleChangeNumber = (event) => {
        if (/^\d*$/.test(event.target.value)) {
            setNumber(event.target.value);
        } else {
            swal('Only Numbers Allowed', '', 'warning', {
                buttons: {
                    confirm: {
                        text: "OK",
                        value: true,
                        visible: true,
                        className: "bg-[#8c52ff]",
                        closeModal: true
                    }
                }
            })
        }
    };

    const HandleUpdateStore = async (e) => {
        const currentTime = moment().format('hh:mm:ss DD MMMM YYYY');
        const currentTimeStamp = moment().format('x');
        setClicked(true);
        if (file !== null) {
            setLoading(true);
            e.preventDefault();
            const storeID = uuid();
            const storageRef1 = ref(storage, "StoresLogo/" + storeID);
            const uploadTask1 = uploadBytesResumable(storageRef1, file);
            uploadTask1.on(
                'state_changed',
                (snapshot) => {
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    const roundedprogress = Math.round(progress);
                    setProgressPercent(roundedprogress);
                },
                (error) => {
                    setClicked(false);
                    setErr(true);
                    setLoading(false);
                },
                () => {
                    getDownloadURL(uploadTask1.snapshot.ref).then(async (downloadURL1) => {
                        await updateDoc(doc(db, "Shops", 'Stores'), {
                            Shops: arrayRemove(props.Shops.filter(shop => shop.storeID === props.UserData.storeID)[0])
                        });
                        await updateDoc(doc(db, "Stores", props.StoreData.storeID), {
                            storeName: storeName !== '' ? storeName : props.StoreData.storeName,
                            logo: downloadURL1,
                            address: address !== '' ? address : props.StoreData.address,
                            gst: gst !== '' ? gst : props.StoreData.gst,
                            number: number !== '' ? number : props.StoreData.number,
                            TimeCreated: currentTime,
                            TimeStamp: serverTimestamp(),
                        });
                        await updateDoc(doc(db, "Shops", 'Stores'), {
                            Shops: arrayUnion({
                                storeID: props.StoreData.storeID,
                                storeState: props.UserData.storeState,
                                storeCity: props.UserData.storeCity,
                                ownerID: currentUser.uid,
                                owner: props.StoreData.owner,
                                ownerPFP: props.StoreData.ownerPFP,
                                storeName: storeName !== '' ? storeName : props.StoreData.storeName,
                                logo: downloadURL1,
                                address: address !== '' ? address : props.StoreData.address,
                                gst: gst !== '' ? gst : props.StoreData.gst,
                                number: number !== '' ? number : props.StoreData.number,
                                category: props.StoreData.category,
                                TimeCreated: currentTime,
                                TimeStamp: currentTimeStamp,
                                Products: {
                                    storeID: props.StoreData.Products[0].storeID,
                                    storeState: props.StoreData.Products[0].storeState,
                                    storeCity: props.StoreData.Products[0].storeCity,
                                    owner: props.StoreData.owner,
                                    ownerID: currentUser.uid,
                                    ownerPFP: currentUser.photoURL,
                                    number: number !== '' ? number : props.StoreData.number,
                                    storeName: storeName !== '' ? storeName : props.StoreData.storeName,
                                    logo: downloadURL1,
                                    productID: props.StoreData.Products[0].productID,
                                    productName: props.StoreData.Products[0].productName,
                                    price: props.StoreData.Products[0].price,
                                    description: props.StoreData.Products[0].description,
                                    productImage: props.StoreData.Products[0].productImage,
                                    productCategory: props.StoreData.Products[0].productCategory,
                                    fixed: props.StoreData.Products[0].fixed,
                                    negotiable: props.StoreData.Products[0].negotiable,
                                    tags: props.StoreData.Products[0].tags,
                                    TimeCreated: props.StoreData.Products[0].TimeCreated,
                                    TimeStamp: props.StoreData.Products[0].TimeStamp,
                                },
                            })
                        });
                        const q = query(collection(db, "Video"), where("Store.storeID", "==", props.StoreData.storeID));
                        const querySnapshot = await getDocs(q);
                        querySnapshot.forEach(async (doc) => {
                            const docRef = doc.ref;
                            await updateDoc(docRef, {
                                "Store.storeName": storeName !== '' ? storeName : props.StoreData.storeName,
                                "Store.logo": downloadURL1,
                                "Store.address": address !== '' ? address : props.StoreData.address,
                                "Store.gst": gst !== '' ? gst : props.StoreData.gst,
                                "Store.number": number !== '' ? number : props.StoreData.number,
                                // storeState: props.UserData.storeState,
                                // storeCity: props.UserData.storeCity,
                            });
                        });
                        setClicked(false);
                        setLoading(false);
                        setClicked(false);
                        setLoading(false);
                        setStoreName('');
                        setFile(null);
                        setAddress('');
                        setGst('');
                        swal('Store Updated!!', '', 'success', {
                            buttons: {
                                confirm: {
                                    text: "OK",
                                    value: true,
                                    visible: true,
                                    className: "bg-[#8c52ff]",
                                    closeModal: true
                                }
                            }
                        });
                    });
                });
        } else {
            await updateDoc(doc(db, "Shops", 'Stores'), {
                Shops: arrayRemove(props.Shops.filter(shop => shop.storeID === props.UserData.storeID)[0])
            });
            await updateDoc(doc(db, "Stores", props.StoreData.storeID), {
                storeName: storeName !== '' ? storeName : props.StoreData.storeName,
                logo: props.StoreData.logo,
                address: address !== '' ? address : props.StoreData.address,
                gst: gst !== '' ? gst : props.StoreData.gst,
                number: number !== '' ? number : props.StoreData.number,
                TimeCreated: currentTime,
                TimeStamp: serverTimestamp(),
            });
            await updateDoc(doc(db, "Shops", 'Stores'), {
                Shops: arrayUnion({
                    storeID: props.StoreData.storeID,
                    storeState: props.StoreData.storeState,
                    storeCity: props.StoreData.storeCity,
                    ownerID: currentUser.uid,
                    owner: props.StoreData.owner,
                    ownerPFP: props.StoreData.ownerPFP,
                    storeName: storeName !== '' ? storeName : props.StoreData.storeName,
                    logo: props.StoreData.logo,
                    address: address !== '' ? address : props.StoreData.address,
                    gst: gst !== '' ? gst : props.StoreData.gst,
                    number: number !== '' ? number : props.StoreData.number,
                    category: props.StoreData.category,
                    TimeCreated: currentTime,
                    TimeStamp: currentTimeStamp,
                    Products: {
                        storeID: props.StoreData.Products[0].storeID,
                        owner: props.StoreData.owner,
                        ownerID: currentUser.uid,
                        ownerPFP: currentUser.photoURL,
                        number: number !== '' ? number : props.StoreData.number,
                        storeName: storeName !== '' ? storeName : props.StoreData.storeName,
                        logo: props.StoreData.logo,
                        productID: props.StoreData.Products[0].productID,
                        productName: props.StoreData.Products[0].productName,
                        price: props.StoreData.Products[0].price,
                        description: props.StoreData.Products[0].description,
                        productImage: props.StoreData.Products[0].productImage,
                        productCategory: props.StoreData.Products[0].productCategory,
                        fixed: props.StoreData.Products[0].fixed,
                        negotiable: props.StoreData.Products[0].negotiable,
                        tags: props.StoreData.Products[0].tags,
                        TimeCreated: props.StoreData.Products[0].TimeCreated,
                        TimeStamp: props.StoreData.Products[0].TimeStamp,
                    },
                })
            });
            const q = query(collection(db, "Video"), where("Store.storeID", "==", props.StoreData.storeID));
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach(async (doc) => {
                const docRef = doc.ref;
                await updateDoc(docRef, {
                    "Store.storeName": storeName !== '' ? storeName : props.StoreData.storeName,
                    "Store.logo": props.StoreData.logo,
                    "Store.address": address !== '' ? address : props.StoreData.address,
                    "Store.gst": gst !== '' ? gst : props.StoreData.gst,
                    "Store.number": number !== '' ? number : props.StoreData.number,
                });
            });
            setClicked(false);
            setLoading(false);
            setClicked(false);
            setLoading(false);
            setStoreName('');
            setFile(null);
            setAddress('');
            setGst('');
            swal('Store Updated!!', '', 'success', {
                buttons: {
                    confirm: {
                        text: "OK",
                        value: true,
                        visible: true,
                        className: "bg-[#8c52ff]",
                        closeModal: true
                    }
                }
            });
        }
    };

    useEffect(() => {
        gsap.fromTo(".content-wrapper", { opacity: 0 }, { opacity: 1, duration: 2 });
    }, []);

    useEffect(() => {
        if (!currentUser) {
            swal('Please login to sell Products', '', 'warning', {
                buttons: {
                    confirm: {
                        text: "OK",
                        value: true,
                        visible: true,
                        className: "bg-[#8c52ff]",
                        closeModal: true
                    }
                }
            });
            navigate('/');
        }
    }, [currentUser])

    useEffect(() => {
        if (props.UserData !== null) {
            if (currentUser && props.UserData !== null && props.UserData.NewUser === true) {
                navigate('/Onboarding');
            }
        }
    }, [currentUser, props.UserData])

    return (
        <>
            <div className="app">
                {loading && <LoadingBar color='linear-gradient(45deg,#ffbc00,#00d0ff ,#ff0058)' shadow={true} progress={progresspercent} onLoaderFinished={() => setProgressPercent(0)} height={4} style={{ "border-radius": "44px" }} />}
                <div className="wrapper">
                    <div className="left-side">
                        <div className="side-wrapper flex h-[100%]">
                            <div className="side-menu flex flex-col justify-evenly h-full">
                                <Link to="/" className="flex flex-row items-center gap-2 w-full">
                                    <img src="https://cdn-icons-png.freepik.com/512/4194/4194676.png" alt="Home Icon" style={{ height: 28, width: 28 }} />
                                    <h2 className="font-extrabold text-lg">Home</h2>
                                </Link>
                                <Link to="/Explore" className="flex flex-row items-center gap-2 w-full">
                                    <img src="https://cdn-icons-png.freepik.com/512/6337/6337301.png" alt="Explore Icon" style={{ height: 28, width: 28 }} />
                                    <h2 className="font-extrabold text-lg">Feed</h2>
                                </Link>
                                <Link to="/Sell" className="flex flex-row items-center gap-2 w-full">
                                    <img src="https://cdn-icons-png.freepik.com/512/10337/10337579.png" alt="Sell Icon" style={{ height: 28, width: 28 }} />
                                    <h2 className="font-extrabold text-lg">Sell</h2>
                                </Link>
                                <Link to="/Chats" className="flex flex-row items-center gap-2 w-full">
                                    <img src="https://cdn-icons-png.freepik.com/512/1041/1041916.png" alt="Chat Icon" style={{ height: 28, width: 28 }} />
                                    <h2 className="font-extrabold text-lg">Chat</h2>
                                </Link>
                                <Link to="/Profile" className="flex flex-row items-center gap-2 w-full">
                                    {currentUser ? <img src={currentUser.photoURL} alt="Profile Picture" style={{ height: 28, width: 28, borderRadius: '100%' }} /> : <img src="https://cdn-icons-png.freepik.com/512/1177/1177568.png" alt="Profile Picture" style={{ height: 28, width: 28 }} />}
                                    <h2 className="font-extrabold text-lg">Profile</h2>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="main-container">
                        <div className="content-wrapper">
                            {bottomBarVisible <= 945 && <BackButton />}
                            <div className={`${bottomBarVisible <= 945 ? 'mb-28' : ''} mt-2`}>
                                <div className="content-section-sell" ref={SellRef}>
                                    {bottomBarVisible <= 945 ?
                                        <>
                                            <div className="innerContainerStoreAdd flex flex-col mb-28">
                                                <div class="content-section-sell-store">
                                                    <div class="content-section-title-sell font-extrabold uppercase">Edit Your Store</div>
                                                    <div class="apps-card gap-5">
                                                        <div class="app-card-sell">
                                                            <div className="StoreNameInput flex justify-start items-center mt-5 flex-col gap-2">
                                                                <span className="w-full flex text-start">Store Name</span>
                                                                <TextField id="outlined-basic" label="Store Name" variant="filled" value={storeName} onChange={handleChangeStoreName} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="content-section-sell-store">
                                                    <div class="apps-card gap-5">
                                                        <div class="app-card-sell">
                                                            <div className="FileInput flex justify-start items-center mt-5 flex-col gap-2">
                                                                <span className="w-full flex text-start">Store Logo</span>
                                                                <Label
                                                                    htmlFor="dropzone-file"
                                                                    className="dark:hover:bg-bray-800 flex h-max w-72 pl-5 pr-5 pt-2 pb-2 cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed border-gray-300 bg-gray-50 hover:bg-gray-100 dark:border-gray-600 dark:bg-gray-700 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                                                                >
                                                                    <div className="flex flex-col items-center justify-center pb-6 pt-5">
                                                                        <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                                                            {file && file.name ? <span className="font-semibold">{file.name}</span> : <span className="font-semibold">Click to upload the Store Logo</span>}
                                                                        </p>
                                                                    </div>
                                                                    <FileInput id="dropzone-file" className="hidden" onChange={handleFileChange} />
                                                                </Label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="content-section-sell-store">
                                                    <div class="apps-card gap-5">
                                                        <div class="app-card-sell">
                                                            <div class="app-card-sell">
                                                                <div className="AddressInput flex justify-start items-center mt-5 flex-col gap-2">
                                                                    <span className="w-full flex text-start">Your Address</span>
                                                                    <Textarea id="comment" placeholder="Your Address" required rows={4} cols={35} value={address} onChange={handleChangeAddress} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="content-section-sell-store">
                                                    <div class="apps-card gap-5">
                                                        <div class="app-card-sell">
                                                            <div className="gstInput flex justify-start items-center mt-5 flex-col gap-2">
                                                                <span className="w-full flex text-start">GST (Optional)</span>
                                                                <TextField id="outlined-basic" label="GST" variant="filled" value={gst} onChange={handleChangeGST} />
                                                            </div>
                                                        </div>
                                                        <div class="app-card-sell">
                                                            <div className="PriceInput flex justify-start items-center mt-5 flex-col gap-2">
                                                                <span className="w-full flex text-start">Contact Number</span>
                                                                <TextField id="outlined-basic" label="+91" variant="filled" value={number} onChange={handleChangeNumber} inputProps={{ maxLength: 10, inputMode: 'numeric', pattern: '[0-9]*' }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="SubmitFirstSell w-full flex justify-center items-center mt-5">
                                                    {clicked === true ?
                                                        <div className="SubmitBtn text-white font-extrabold rounded-xl pt-2 pb-2 pl-5 pr-5 bg-blue-300 cursor-pointer">
                                                            SUBMITTING
                                                        </div>
                                                        :
                                                        <div className="SubmitBtn text-white font-extrabold rounded-xl pt-2 pb-2 pl-5 pr-5 bg-blue-500 cursor-pointer" onClick={HandleUpdateStore}>
                                                            SUBMIT
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <>
                                            <div className="innerContainerStoreAdd flex flex-col">
                                                <div class="content-section-sell-store">
                                                    <div class="content-section-title-sell font-extrabold uppercase">Edit Your Store</div>
                                                    <div class="apps-card gap-5">
                                                        <div class="app-card-sell">
                                                            <div className="StoreNameInput flex justify-start items-center mt-5 flex-col gap-2">
                                                                <span className="w-full flex text-start">Store Name</span>
                                                                <TextField id="outlined-basic" label="Store Name" variant="filled" value={storeName} onChange={handleChangeStoreName} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="content-section-sell-store">
                                                    <div class="apps-card gap-5">
                                                        <div class="app-card-sell">
                                                            <div className="FileInput flex justify-start items-center mt-5 flex-col gap-2">
                                                                <span className="w-full flex text-start">Store Logo</span>
                                                                <Label
                                                                    htmlFor="dropzone-file"
                                                                    className="dark:hover:bg-bray-800 flex h-max w-72 pl-5 pr-5 pt-2 pb-2 cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed border-gray-300 bg-gray-50 hover:bg-gray-100 dark:border-gray-600 dark:bg-gray-700 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                                                                >
                                                                    <div className="flex flex-col items-center justify-center pb-6 pt-5">
                                                                        <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                                                            {file && file.name ? <span className="font-semibold">{file.name}</span> : <span className="font-semibold">Click to upload the Store Logo</span>}
                                                                        </p>
                                                                    </div>
                                                                    <FileInput id="dropzone-file" className="hidden" onChange={handleFileChange} />
                                                                </Label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="content-section-sell-store">
                                                    <div class="apps-card gap-5">
                                                        <div class="app-card-sell">
                                                            <div class="app-card-sell">
                                                                <div className="AddressInput flex justify-start items-center mt-5 flex-col gap-2">
                                                                    <span className="w-full flex text-start">Your Address</span>
                                                                    <Textarea id="comment" placeholder="Your Address" required rows={4} cols={35} value={address} onChange={handleChangeAddress} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="content-section-sell-store">
                                                    <div class="apps-card gap-5">
                                                        <div class="app-card-sell">
                                                            <div className="gstInput flex justify-start items-center mt-5 flex-col gap-2">
                                                                <span className="w-full flex text-start">GST (Optional)</span>
                                                                <TextField id="outlined-basic" label="GST" variant="filled" value={gst} onChange={handleChangeGST} />
                                                            </div>
                                                        </div>
                                                        <div class="app-card-sell">
                                                            <div className="PriceInput flex justify-start items-center mt-5 flex-col gap-2">
                                                                <span className="w-full flex text-start">Contact Number</span>
                                                                <TextField id="outlined-basic" label="+91" variant="filled" value={number} onChange={handleChangeNumber} inputProps={{ maxLength: 10, inputMode: 'numeric', pattern: '[0-9]*' }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="SubmitFirstSell w-full flex justify-center items-center mt-5">
                                                    {clicked === true ?
                                                        <div className="SubmitBtn text-white font-extrabold rounded-xl pt-2 pb-2 pl-5 pr-5 bg-blue-300 cursor-pointer">
                                                            SUBMITTING
                                                        </div>
                                                        :
                                                        <div className="SubmitBtn text-white font-extrabold rounded-xl pt-2 pb-2 pl-5 pr-5 bg-blue-500 cursor-pointer" onClick={HandleUpdateStore}>
                                                            SUBMIT
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="overlay-app" />
            </div>
            {bottomBarVisible <= 945 &&
                <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, backgroundColor: 'transparent' }} elevation={3}>
                    <BottomNavigation
                        className="backgroundColor: 'transparent',"
                        showLabels
                        value={-1}
                        onChange={(event, newValue) => {
                            setValue(newValue);
                            newValue === 0 ? navigate('/') : newValue === 1 ? navigate('/Explore') : newValue === 2 ? navigate('/Sell') : newValue === 3 ? navigate('/Chats') : newValue === 4 ? navigate('/Profile') : navigate('/')
                        }}
                    >
                        <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<HomeRoundedIcon />} label="Home" />
                        <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<SlideshowIcon />} label="Shorts" />
                        <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<AddCircleRoundedIcon />} label="Sell" />
                        <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<QuestionAnswerRoundedIcon />} label="Chat" />
                        {currentUser ? <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<img src={currentUser.photoURL} className='h-6 w-6 rounded-full' />} label="Manage" /> : <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<PersonRoundedIcon />} label="Profile" />}
                    </BottomNavigation>
                </Paper>
            }
        </>
    )
}

export default EditStore