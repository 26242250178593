/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import { BottomNavigation, BottomNavigationAction, Paper } from '@mui/material';
import React, { useContext, useEffect, useRef, useState } from 'react';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import SlideshowIcon from '@mui/icons-material/Slideshow';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import QuestionAnswerRoundedIcon from '@mui/icons-material/QuestionAnswerRounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import {
    Link, useNavigate,
} from 'react-router-dom';
import gsap from 'gsap';
import { AuthContext } from '../context/AuthContext';
import BackButton from '../components/BackButton';

const Categories = (props) => {
    const { currentUser } = useContext(AuthContext);
    const [bottomBarVisible, setBottomBarVisible] = useState(window.screen.width);

    useEffect(() => {
        const interval = setInterval(() => {
            setBottomBarVisible(window.screen.width)
        }, 100);
        return () => clearInterval(interval);
    }, []);

    const [value, setValue] = useState(0);
    const CategoriesRef = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (props.ViewAllCat === "Clicked") {
            CategoriesRef.current = gsap.fromTo(".content-section", {
                x: "100%",
            },
                {
                    x: 0,
                    duration: 0.5,
                    ease: "power2.inOut"
                });
        }
    }, [props.ViewAllCat]);

    const ChangeToCategory = (val) => {
        navigate('/Category/' + val)
        props.ViewAllCats("Clicked")
    };

    useEffect(() => {
        gsap.fromTo(".content-wrapper", { opacity: 0 }, { opacity: 1, duration: 2 });
        props.ViewAllCategories("");
    }, []);

    const truncate = (str, length) => {
        if (str.length > length) {
            return str.slice(0, length) + "...";
        } else {
            return str;
        }
    }

    useEffect(() => {
        if (props.UserData !== null) {
            if (currentUser && props.UserData !== null && props.UserData.NewUser === true) {
                navigate('/Onboarding');
            }
        }
    }, [currentUser, props.UserData])

    return (
        <>
            <div className="app">
                <div className="wrapper">
                    <div className="left-side">
                        <div className="side-wrapper flex h-[100%]">
                            <div className="side-menu flex flex-col justify-evenly h-full">
                                <Link to="/" className="flex flex-row items-center gap-2 w-full">
                                    <img src="https://cdn-icons-png.freepik.com/512/4194/4194676.png" alt="Home Icon" style={{ height: 28, width: 28 }} />
                                    <h2 className="font-extrabold text-lg">Home</h2>
                                </Link>
                                <Link to="/Explore" className="flex flex-row items-center gap-2 w-full">
                                    <img src="https://cdn-icons-png.freepik.com/512/6337/6337301.png" alt="Explore Icon" style={{ height: 28, width: 28 }} />
                                    <h2 className="font-extrabold text-lg">Feed</h2>
                                </Link>
                                <Link to="/Sell" className="flex flex-row items-center gap-2 w-full">
                                    <img src="https://cdn-icons-png.freepik.com/512/10337/10337579.png" alt="Sell Icon" style={{ height: 28, width: 28 }} />
                                    <h2 className="font-extrabold text-lg">Sell</h2>
                                </Link>
                                <Link to="/Chats" className="flex flex-row items-center gap-2 w-full">
                                    <img src="https://cdn-icons-png.freepik.com/512/1041/1041916.png" alt="Chat Icon" style={{ height: 28, width: 28 }} />
                                    <h2 className="font-extrabold text-lg">Chat</h2>
                                </Link>
                                <Link to="/Profile" className="flex flex-row items-center gap-2 w-full">
                                    {currentUser ? <img src={currentUser.photoURL} alt="Profile Picture" style={{ height: 28, width: 28, borderRadius: '100%' }} /> : <img src="https://cdn-icons-png.freepik.com/512/1177/1177568.png" alt="Profile Picture" style={{ height: 28, width: 28 }} />}
                                    <h2 className="font-extrabold text-lg">Profile</h2>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="main-container">
                        <div className="main-header">
                            <div className="search-bar gap-2">
                                {bottomBarVisible <= 945 && <BackButton />}
                                <input type="text" placeholder="Search Categories" />
                                <div className="search-icon w-10 h-full flex justify-center items-center" style={{ borderRadius: '4px' }}>
                                    <img src="https://cdn-icons-png.freepik.com/512/4687/4687318.png" alt="search icon" className="h-6 w-6" />
                                </div>
                            </div>
                        </div>
                        <div className="content-wrapper">
                            <div className="content-section" ref={CategoriesRef}>
                                <div className="content-section-title">All Categories</div>
                                <div className={`${bottomBarVisible <= 945 ? 'mb-28' : ''}`}>
                                    {/* <div className="apps-card flex gap-5">
                                        {props.CategoriesData?.sort((a, b) => a.Name.localeCompare(b.Name)).map(category => (
                                            <div className="catCard flex flex-col gap-2 justify-center items-center text-center p-5 rounded-md cursor-pointer" onClick={() => ChangeToCategory(category.Name)}>
                                                <div className="categoryImage bg-transparent h-28 w-28 rounded-md">
                                                    <img src={category.Image} alt={category.Name} className="h-full w-full rounded-xl" />
                                                </div>
                                                <div className="categoryName"><h1>{category.Name}</h1></div>
                                            </div>
                                        ))}
                                    </div> */}
                                    <div className={`apps-card flex gap-2 ${bottomBarVisible <= 730 && 'justify-evenly'}`}>
                                        {props.CategoriesData?.sort((a, b) => a.Name.localeCompare(b.Name)).map(category => (
                                            <div className={`catCard ${bottomBarVisible <= 360 ? 'h-40 w-36' : 'h-44 w-40'} cursor-pointer flex flex-col gap-2 justify-center items-center p-2 rounded-md`} onClick={() => ChangeToCategory(category.Name)}>
                                                <div className="h-36 w-full flex justify-center items-center">
                                                    <img src={category.Image} alt={category.Name} className="h-32 w-32 rounded-2xl" />
                                                </div>
                                                <div className="categoryName text-base font-extrabold">{truncate(category.Name, 12)}</div>
                                            </div>
                                        ))}
                                        {props.CategoriesData.length % 2 !== 0 &&
                                            <div className={`catCard ${bottomBarVisible <= 360 ? 'h-40 w-36' : 'h-44 w-40'} flex flex-col gap-2 justify-center items-center p-2 rounded-md`} style={{ visibility: 'hidden' }}>
                                                <div className="h-36 w-full flex justify-center items-center">
                                                    <img src="" alt="" className="h-32 w-32 rounded-2xl" />
                                                </div>
                                                <div className="categoryName text-lg font-extrabold">lorem ipsum</div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="overlay-app" />
            </div>
            {bottomBarVisible <= 945 &&
                <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, backgroundColor: 'transparent' }} elevation={3}>
                    <BottomNavigation
                        className="backgroundColor: 'transparent',"
                        showLabels
                        value={-1}
                        onChange={(event, newValue) => {
                            setValue(newValue);
                            newValue === 0 ? navigate('/') : newValue === 1 ? navigate('/Explore') : newValue === 2 ? navigate('/Sell') : newValue === 3 ? navigate('/Chats') : newValue === 4 ? navigate('/Profile') : navigate('/')
                        }}
                    >
                        <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<HomeRoundedIcon />} label="Home" />
                        <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<SlideshowIcon />} label="Shorts" />
                        <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<AddCircleRoundedIcon />} label="Sell" />
                        <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<QuestionAnswerRoundedIcon />} label="Chat" />
                        {currentUser ? <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<img src={currentUser.photoURL} className='h-6 w-6 rounded-full' />} label="Manage" /> : <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<PersonRoundedIcon />} label="Profile" />}
                    </BottomNavigation>
                </Paper>
            }
        </>
    )
}

export default Categories