/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import { BottomNavigation, BottomNavigationAction, Paper } from '@mui/material';
import React, { useContext, useEffect, useRef, useState } from 'react';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import SlideshowIcon from '@mui/icons-material/Slideshow';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import QuestionAnswerRoundedIcon from '@mui/icons-material/QuestionAnswerRounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import { Dropdown } from 'flowbite-react';
import { Link, useNavigate } from 'react-router-dom';
// import usePageTransition from '../transitions/PageTransition';
import gsap from 'gsap';
import { AuthContext } from '../context/AuthContext';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { arrayUnion, doc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';

const Home = (props) => {
    const { currentUser } = useContext(AuthContext);
    const [bottomBarVisible, setBottomBarVisible] = useState(window.screen.width);
    // eslint-disable-next-line no-unused-vars
    const [currentCity, setCurrentCity] = useState(currentUser ? props.UserData !== null && props.UserData.city : localStorage.getItem("City") || '');

    useEffect(() => {
        const interval = setInterval(() => {
            setBottomBarVisible(window.screen.width)
        }, 100);
        return () => clearInterval(interval);
    }, []);

    const [value, setValue] = useState(0);
    const HomeRef = useRef(null);
    const navigate = useNavigate();

    const ChangeToCategories = () => {
        HomeRef.current = gsap.fromTo(".combinedHomeContentSection", {
            x: "100%",
        },
            {
                x: 0,
                duration: 0.5,
                ease: "power2.inOut"
            });
        navigate('/Categories')
        props.ViewAllCategories("Clicked")
    };

    const ChangeToStories = () => {
        HomeRef.current = gsap.fromTo(".combinedHomeContentSection", {
            x: "100%",
        },
            {
                x: 0,
                duration: 0.5,
                ease: "power2.inOut"
            });
        navigate('/Stories')
        props.ViewAllStories("Clicked")
    };

    const ChangeToShops = () => {
        HomeRef.current = gsap.fromTo(".combinedHomeContentSection", {
            x: "100%",
        },
            {
                x: 0,
                duration: 0.5,
                ease: "power2.inOut"
            });
        navigate('/Shops')
        props.ViewAllShops("Clicked")
    };

    const ChangeToCategory = (val) => {
        HomeRef.current = gsap.fromTo(".combinedHomeContentSection", {
            x: "100%",
        },
            {
                x: 0,
                duration: 0.5,
                ease: "power2.inOut"
            });
        navigate('/Category/' + val)
        props.ViewAllCats("Clicked")
    };

    useEffect(() => {
        gsap.fromTo(".content-wrapper", { opacity: 0 }, { opacity: 1, duration: 2 });
    }, []);

    const OpenSavedProduct = () => {
        navigate('/Saved');
    }

    // useEffect(() => {
    //     // CategoriesRef.current = gsap.to(".content-wrapper", {
    //     //     x: "200%",
    //     // });
    //     // CategoriesRef.current = gsap.to(".content-wrapper", {
    //     //     x: 0,
    //     //     duration: 1,
    //     //     ease: "power2.inOut",
    //     // });
    //     HomeRef.current = gsap.fromTo(".combinedHomeContentSection", {
    //         x: "100%",
    //         // duration: 1,
    //         // ease: "power2.inOut"
    //     },
    //         {
    //             x: 0,
    //             duration: 0.5,
    //             ease: "power2.inOut"
    //         });
    // }, []);

    // const arr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]
    // const arr2 = [arr[0], arr[1], arr[2]]

    // const arrayHandling = () => {
    //     if (arr[arr.length - 1] !== arr2[arr2.length - 1]) {
    //         console.log(arr2)
    //         arr2.push(arr[arr.indexOf(arr2[arr2.length - 1]) + 1])
    //         arr2.splice(0, 1)
    //         console.log(arr2)
    //     }
    // }

    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         var current = new Date();
    //         var CurrentTime = current.toLocaleString([], { hour12: true, hour: '2-digit', minute: '2-digit' });
    //         var CurrentDate = current.toLocaleDateString();
    //         setCombinedCurrentDateTime(CurrentDate + " " + CurrentTime)
    //     }, 1000);
    //     return () => clearInterval(interval);
    // }, []);

    useEffect(() => {
        if (props.UserData !== null) {
            if (currentUser && props.UserData !== null && props.UserData.NewUser === true) {
                navigate('/Onboarding');
            }
        }
    }, [currentUser, props.UserData])

    // const images = ['https://img.freepik.com/free-photo/sports-car-races-through-dark-blurred-motion-generative-ai_188544-12490.jpg', 'https://img.freepik.com/free-photo/car-is-driving-along-road-along-sea_169016-28489.jpg', 'https://img.freepik.com/free-photo/view-car-running-high-speed_23-2150635443.jpg', 'https://img.freepik.com/free-photo/view-car-running-high-speed_23-2150635418.jpg']

    // const BannerAdsLink = [];

    // useEffect(() => {
    //     props.BannerAds.length > 0 && props.BannerAds?.map(banner => (
    //         BannerAdsLink.includes(banner.ad) === false && BannerAdsLink.push(banner.ad)
    //     ));
    // }, [props.BannerAds]);

    // useEffect(() => {
    //     props.BannerAds.map(banner => (
    //         Object.keys(banner).length !== 0 && Object.keys(banner).map(ad => (
    //             BannerAdsLink.includes(banner[ad].ad) === false && BannerAdsLink.push(banner[ad].ad)
    //         ))
    //     ));
    // }, [props.UserData, props.BannerAds])

    const stories = [];

    useEffect(() => {
        props.StoriesData.filter(item => item.storeCity === localStorage.getItem("City")).map(item => stories.includes(item?.storeID) === false && stories.push(item?.storeID))
    }, [props.StoriesData])

    // console.log(props.Shops.filter(item => item?.storeID === ))
    // stories.forEach((storyId, index) => {
    //     props.Shops.filter(item => (item?.storeID === storyId?.storeID))
    // })

    const HandleShowStory = async (val) => {
        navigate(`/Story/${val}`)
        currentUser && await updateDoc(doc(db, 'Users', currentUser.uid), {
            Viewed: arrayUnion(val),
        })
    }

    const truncate = (str, length) => {
        if (str.length > length) {
            return str.slice(0, length) + "...";
        } else {
            return str;
        }
    }

    const NotificationData = []
    const GetNotificationData = () => {
        if (currentUser !== null) {
            Object.entries(props.Inbox).length > 0 && Object.entries(props.Inbox)?.filter((item) => item[1].Unread > 0).map((item, index) => (
                NotificationData.push(item[1])
            ))
        }
    }
    currentUser !== null && GetNotificationData()

    const [searchText, setSearchText] = useState('');
    const letters = /^\s/

    const Search = async () => {
        if (searchText.length > 0) {
            setSearchText('');
            navigate(`/Search/${searchText}`)
        }
    };

    const handleKey = async (e) => {
        e.code === "Enter" && e.preventDefault();
        if (letters.test(searchText) !== true) {
            e.code === "Enter" && Search();
        }
    };

    useEffect(() => {
        if (!currentUser) {
            if (localStorage.getItem("State") === null || localStorage.getItem("City") === null) {
                navigate('/Region')
            }
        }
    }, [localStorage.getItem("State"), localStorage.getItem("City"), currentUser])

    function shuffleArray1(array) {
        const newArray = array;
        for (let i = newArray.length - 1; i > 0; i--) {
            let j = Math.floor(Math.random() * (i + 1));
            [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
        }
        return newArray;
    }

    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const list = (anchor) => (
        <Box
            sx={{ width: 280 }}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <div className="w-full h-max overflow-y-auto overflow-x-hidden hide-scrollbar pr-5">
                <div className="w-full flex justify-start items-center flex-col p-2">
                    <h1 className="w-full flex justify-start items-center text-2xl font-black mb-5">CATEGORIES</h1>
                    {/* <span className="w-56 ml-5 flex justify-start items-center text-lg font-semibold">HELLO</span> */}
                    {props.CategoriesData?.sort((a, b) => a.Name.localeCompare(b.Name)).map(category => (
                        <div className="catBox w-full ml-5 mt-4 p-2 flex flex-row gap-2 justify-start items-center text-center transform transition duration-500 ease-in-out hover:scale-110 cursor-pointer bg-[var(--search-bg-4)] rounded-xl" onClick={() => ChangeToCategory(category.Name)}>
                            <div className="categoryImage h-10 w-10 bg-transparent rounded-xl">
                                <img src={category.Image} alt={category.Name} className="h-full w-full rounded-xl" />
                            </div>
                            <div className="categoryName">{category.Name}</div>
                        </div>
                    ))}
                </div>
                {props.Shops?.filter(item => item.storeCity === localStorage.getItem("City")).length > 0 &&
                    <>
                        <Divider />
                        <div className="w-full flex justify-start items-center flex-col p-2">
                            <h1 className="w-full flex justify-start items-center text-2xl font-black mb-5">SHOPS</h1>
                            {/* <span className="w-56 ml-5 flex justify-start items-center text-lg font-semibold">HELLO</span> */}
                            {props.Shops?.filter(item => item.storeCity === localStorage.getItem("City")).sort((a, b) => a.storeName.localeCompare(b.storeName)).map(shop => (
                                <div className="catBox w-full ml-5 mt-4 p-2 flex flex-row gap-2 justify-start items-center text-center transform transition duration-500 ease-in-out hover:scale-110 cursor-pointer bg-[var(--search-bg-4)] rounded-xl" onClick={() => navigate(`/Store/${shop?.storeID}`)}>
                                    <div className="categoryImage h-10 w-10 bg-transparent rounded-full">
                                        <img src={shop.logo} alt={shop.storeName} className="h-full w-full rounded-full" />
                                    </div>
                                    <div className="categoryName">{shop.storeName}</div>
                                </div>
                            ))}
                        </div>
                    </>
                }
            </div>
        </Box>
    );

    return (
        <>
            {/* <div className="video-bg">
        <video width={320} height={240} autoPlay="" loop="" muted="">
          <source
            src="https://assets.codepen.io/3364143/7btrrd.mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      </div> */}
            {/* <div className="dark-light">
        <svg
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth="1.5"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M21 12.79A9 9 0 1111.21 3 7 7 0 0021 12.79z" />
        </svg>
      </div> */}
            <div className="app">
                <Drawer
                    anchor={'left'}
                    open={state['left']}
                    onClose={toggleDrawer('left', false)}
                    PaperProps={{
                        sx: {
                            backgroundColor: 'var(--search-bg-5)',
                            backdropFilter: 'blur(20px)'
                        },
                    }}
                >
                    {list('left')}
                </Drawer>
                <div className="header">
                    {/* <div className="menu-circle" />
                    <div className="header-menu">
                        <a className="menu-link is-active" href="#">
                            Apps
                        </a>
                        <a className="menu-link notify" href="#">
                            Your work
                        </a>
                        <a className="menu-link" href="#">
                            Discover
                        </a>
                        <a className="menu-link notify" href="#">
                            Market
                        </a>
                    </div> */}
                    <div className="topheader flex justify-between w-full">
                        <div className="left-align flex flex-row gap-5">
                            {/* {bottomBarVisible <= 945 &&
                                <div className="notification flex flex-col">
                                    <img src="https://cdn-icons-png.freepik.com/512/10264/10264343.png" alt="Saved Product" style={{ height: 20, width: 20 }} />
                                </div>
                            } */}
                            <img src="https://cdn-icons-png.freepik.com/512/9807/9807980.png" alt="Categories Icon" className='cursor-pointer' style={{ height: 20, width: 20 }} onClick={toggleDrawer('left', true)} />
                            <div className="notification flex flex-row gap-2" onClick={() => navigate('/Region')}>
                                <img src="https://cdn-icons-png.freepik.com/512/12536/12536004.png" alt="Language Icon" className='cursor-pointer' style={{ height: 20, width: 20 }} />
                                {/* {currentUser ? props.UserData && props.UserData.city && <h3 className='cursor-pointer'>{props.UserData.city}</h3> : props.IP !== null && <h3 className='cursor-pointer'>{props.IP.data.region}</h3>} */}
                                {currentUser ? props.UserData && props.UserData.city && <h3 className='cursor-pointer'>{props.UserData.city}</h3> : localStorage.getItem("City") !== null && <h3 className='cursor-pointer'>{localStorage.getItem("City")}</h3>}
                                {/* <h3 onClick={arrayHandling}>Delhi</h3> */}
                            </div>
                        </div>
                        <div className="right-align header-profile flex gap-5">
                            <div className="notification" onClick={OpenSavedProduct}>
                                <img src="https://cdn-icons-png.freepik.com/512/13820/13820041.png" alt="Saved Product" style={{ height: 20, width: 20 }} />
                            </div>
                            {/* <div className="notification">
                                <Dropdown label="" dismissOnClick={false} renderTrigger={() => <img src="https://cdn-icons-png.freepik.com/512/888/888878.png" alt="Language Icon" style={{ height: 20, width: 20 }} />}>
                                    <Dropdown.Item><h2 className="bg-gray-500 pl-5 pr-5 pt-2 pb-2 text-white text-center align-center justify-center w-full">English</h2></Dropdown.Item>
                                    <Dropdown.Item><h2 className="bg-gray-500 pl-5 pr-5 pt-2 pb-2 text-white text-center align-center justify-center w-full">हिंदी</h2></Dropdown.Item>
                                    <Dropdown.Item>English</Dropdown.Item>
                                    <Dropdown.Item>Hindi</Dropdown.Item>
                                </Dropdown>
                            </div> */}
                            <div className="notification">
                                {NotificationData.length > 0 && <span className="notification-number">{NotificationData.length <= 9 ? NotificationData.length : '9+'}</span>}
                                <Dropdown label="Large dropdown" size="lg" dismissOnClick={false} renderTrigger={() =>
                                    <svg
                                        viewBox="0 0 24 24"
                                        fill="currentColor"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="feather feather-bell"
                                    >
                                        <path d="M18 8A6 6 0 006 8c0 7-3 9-3 9h18s-3-2-3-9M13.73 21a2 2 0 01-3.46 0" />
                                    </svg>
                                }>
                                    {NotificationData.length > 0 ?
                                        <div className="UserNotifications h-full max-h-60 w-max p-2 flex justify-center items-center">
                                            {NotificationData.sort((a, b) => b.Time - a.Time).map((item, index) => (
                                                <div key={index} className="detailsUser w-full h-full flex flex-row gap-2 items-center cursor-pointer" onClick={() => { props.ViewChats('Clicked'); navigate(`/Chat/${item.userInfo.chatId}`) }}>
                                                    <img src={item.userInfo.photoURL} alt="" className='userProfilePic h-10 w-10 rounded-full' />
                                                    <div className="userNameAndLastMessage w-full flex flex-col items-center justify-start">
                                                        <div className="userName w-full flex justify-start font-extrabold text-xl">{truncate(item.userInfo.displayName, 10)}</div>
                                                        <div className="userLastMessage w-full flex justify-start">{truncate(item.LastMessage.text, 20)}</div>
                                                    </div>
                                                    {item.Unread > 0 &&
                                                        <div className="NewMessage h-full flex justify-center items-center">
                                                            <div className="MessageCountNumber flex justify-center items-center rounded-full h-8 w-8 bg-[#8c52ff] text-white text-base font-extrabold">
                                                                {item.Unread > 9 ? `9+` : `${item.Unread}`}
                                                            </div>
                                                        </div>}
                                                </div>
                                            ))}
                                        </div>
                                        :
                                        <>
                                            <div className="NoNotification h-max w-max p-5 flex justify-center items-center font-extrabold text-2xl uppercase">
                                                No New Notification
                                            </div>
                                        </>
                                    }
                                </Dropdown>
                            </div>
                            {/* <svg viewBox="0 0 512 512" fill="currentColor">
                            <path d="M448.773 235.551A135.893 135.893 0 00451 211c0-74.443-60.557-135-135-135-47.52 0-91.567 25.313-115.766 65.537-32.666-10.59-66.182-6.049-93.794 12.979-27.612 19.013-44.092 49.116-45.425 82.031C24.716 253.788 0 290.497 0 331c0 7.031 1.703 13.887 3.006 20.537l.015.015C12.719 400.492 56.034 436 106 436h300c57.891 0 106-47.109 106-105 0-40.942-25.053-77.798-63.227-95.449z" />
                        </svg>
                        <img
                            className="profile-img"
                            src="https://images.unsplash.com/photo-1600353068440-6361ef3a86e8?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1000&q=80"
                            alt=""
                        /> */}
                        </div>
                    </div>
                </div>
                <div className="wrapper">
                    <div className="left-side">
                        <div className="side-wrapper flex h-[100%]">
                            {/* <div className="side-title">Apps</div> */}
                            <div className="side-menu flex flex-col justify-evenly h-full">
                                <Link to="/" className="flex flex-row items-center gap-2 w-full">
                                    <img src="https://cdn-icons-png.freepik.com/512/4194/4194676.png" alt="Home Icon" style={{ height: 28, width: 28 }} />
                                    <h2 className="font-extrabold text-lg">Home</h2>
                                </Link>
                                <Link to="/Explore" className="flex flex-row items-center gap-2 w-full">
                                    <img src="https://cdn-icons-png.freepik.com/512/6337/6337301.png" alt="Explore Icon" style={{ height: 28, width: 28 }} />
                                    <h2 className="font-extrabold text-lg">Feed</h2>
                                </Link>
                                <Link to="/Sell" className="flex flex-row items-center gap-2 w-full">
                                    <img src="https://cdn-icons-png.freepik.com/512/10337/10337579.png" alt="Sell Icon" style={{ height: 28, width: 28 }} />
                                    <h2 className="font-extrabold text-lg">Sell</h2>
                                </Link>
                                <Link to="/Chats" className="flex flex-row items-center gap-2 w-full">
                                    <img src="https://cdn-icons-png.freepik.com/512/1041/1041916.png" alt="Chat Icon" style={{ height: 28, width: 28 }} />
                                    <h2 className="font-extrabold text-lg">Chat</h2>
                                </Link>
                                <Link to="/Profile" className="flex flex-row items-center gap-2 w-full">
                                    {currentUser ? <img src={currentUser.photoURL} alt="Profile Picture" style={{ height: 28, width: 28, borderRadius: '100%' }} /> : <img src="https://cdn-icons-png.freepik.com/512/1177/1177568.png" alt="Profile Picture" style={{ height: 28, width: 28 }} />}
                                    <h2 className="font-extrabold text-lg">Profile</h2>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="main-container">
                        <div className="main-header">
                            {/* <a className="menu-link-main" href="#">
                                All Apps
                            </a>
                            <div className="header-menu">
                                <a className="main-header-link is-active" href="#">
                                    Desktop
                                </a>
                                <a className="main-header-link" href="#">
                                    Mobile
                                </a>
                                <a className="main-header-link" href="#">
                                    Web
                                </a>
                            </div> */}
                            <div className="search-bar gap-2">
                                <input type="text" placeholder="Search Products" value={searchText} onChange={(e) => setSearchText(e.target.value)} onKeyPress={handleKey} />
                                <div className="search-icon w-10 h-full flex justify-center items-center" style={{ borderRadius: '4px' }}>
                                    <img src="https://cdn-icons-png.freepik.com/512/4687/4687318.png" alt="search icon" className="h-6 w-6" onClick={Search} />
                                </div>
                            </div>
                        </div>
                        <div className="content-wrapper">
                            <div className={`${bottomBarVisible <= 945 ? 'mb-28' : ''}`}>
                                <div className="combinedHomeContentSection" ref={HomeRef}>
                                    {/* <div className="content-wrapper-header flex justify-center align-center items-center text-center"> */}
                                    {/* <h1 className='font-extrabold text-3xl'>BANNER ADS HERE</h1> */}
                                    <Carousel autoPlay interval={5000} infiniteLoop showThumbs={false} showStatus={false} showIndicators={false} className={`BannerAdsDiv ${bottomBarVisible <= 945 ? 'h-[40vh]' : 'h-[50vh]'} w-full rounded-2xl`}>
                                        {props.BannerAds.filter(item => item.storeCity === localStorage.getItem("City")).length > 0 && shuffleArray1(props.BannerAds)?.filter(item => item.storeCity === localStorage.getItem("City")).map((ad, index) => (
                                            <div key={index} className={`${bottomBarVisible <= 945 ? 'h-[40vh]' : 'h-[50vh]'} w-full cursor-pointer rounded-2xl p-2`} onClick={() => navigate(`/Store/${ad?.storeID}`)}>
                                                <img src={ad.ad} alt={`slide ${index + 1}`} className={`h-full w-full object-contain rounded-2xl cursor-pointer`} onClick={() => navigate(`/Store/${ad?.storeID}`)} />
                                            </div>))
                                        }
                                    </Carousel>
                                    {/* </div> */}
                                    <div className="content-section-new flex flex-col mt-5 h-max min-w-[95%]">
                                        <div className="NameAndViewAll h-max w-full flex flex-row justify-between items-center">
                                            <div className="content-section-title">Categories</div>
                                            {props.CategoriesData?.length >= 6 && <h1 className="flex justify-center items-center text-center p-2 rounded-lg uppercase font-extrabold transform transition duration-500 ease-in-out hover:scale-110 cursor-pointer bg-[var(--search-bg)]" onClick={ChangeToCategories}>View All</h1>}
                                        </div>
                                        <div className="allCategories flex flex-row overflow-x-scroll hide-scrollbar gap-5 pt-2 pb-2 pl-2 pr-12">
                                            {props.CategoriesData?.filter((item, idx) => idx <= 5).sort((a, b) => a.Name.localeCompare(b.Name)).map(category => (
                                                <div className="catBox flex flex-col gap-2 justify-center items-center text-center transform transition duration-500 ease-in-out hover:scale-110 cursor-pointer" onClick={() => ChangeToCategory(category.Name)}>
                                                    <div className="categoryImage h-24 w-24 bg-transparent rounded-xl">
                                                        <img src={category.Image} alt={category.Name} className="h-full w-full rounded-xl" />
                                                    </div>
                                                    <div className="categoryName">{category.Name}</div>
                                                </div>
                                            ))}
                                            {/* {props.CategoriesData?.length > 8 &&
                                                <div className="flex justify-center items-center text-center">
                                                    <div className="viewAll flex flex-row justify-center items-center text-center gap-2 p-2 rounded-2xl cursor-pointer w-max transform transition duration-500 ease-in-out hover:scale-110" onClick={ChangeToCategories}>
                                                        <h1 className="uppercase font-extrabold">View All</h1>
                                                        <img src="https://cdn-icons-png.freepik.com/512/724/724812.png" alt="Right Arrow" className="h-10 w-10" />
                                                    </div>
                                                </div>
                                            } */}
                                        </div>
                                    </div>
                                    {shuffleArray1(stories)?.length > 0 &&
                                        <div className="content-section-new flex flex-col mt-5 h-max min-w-[95%]">
                                            {/* <div className="content-section-title">Featured Stories</div> */}
                                            <div className="NameAndViewAll h-max w-full flex flex-row justify-between items-center">
                                                <div className="content-section-title">Featured Stories</div>
                                                {shuffleArray1(stories)?.length >= 6 && <h1 className="flex justify-center items-center text-center p-2 rounded-lg uppercase font-extrabold transform transition duration-500 ease-in-out hover:scale-110 cursor-pointer bg-[var(--search-bg)]" onClick={ChangeToStories}>View All</h1>}
                                            </div>
                                            <div className="allCategories flex flex-row overflow-x-scroll hide-scrollbar gap-5 pt-2 pb-2 pl-2 pr-12">
                                                {currentUser ?
                                                    <>
                                                        {props.UserData !== null && props.Shops?.length > 0 && shuffleArray1(stories).sort((a, b) => {
                                                            const aIsViewed = props.UserData.Viewed.includes(a);
                                                            const bIsViewed = props.UserData.Viewed.includes(b);
                                                            if (aIsViewed && !bIsViewed) {
                                                                return 1;
                                                            } else if (!aIsViewed && bIsViewed) {
                                                                return -1;
                                                            } else {
                                                                return 0;
                                                            }
                                                        }).map((item, index) => (
                                                            <>
                                                                {props.Shops?.length > 0 &&
                                                                    <div key={index} className="catBox flex flex-col gap-2 justify-center items-center text-center transform transition duration-500 ease-in-out hover:scale-110 cursor-pointer" onClick={() => HandleShowStory(props.Shops.filter(i => (i?.storeID === item))[0]?.storeID)}>
                                                                        <div className={`storyCircle ${props.UserData.Viewed.includes(props.Shops.filter(i => (i?.storeID === item))[0]?.storeID) === true ? 'storyViewed' : 'storyUnViewed'} p-1 rounded-full h-24 w-24`}>
                                                                            <img src={props.Shops.filter(i => (i?.storeID === item))[0]?.logo} alt="" className='h-full w-full rounded-full' />
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </>
                                                        ))}
                                                    </>
                                                    :
                                                    <>
                                                        {props.Shops?.length > 0 && shuffleArray1(stories).filter(item => item.storeCity === localStorage.getItem("City")).map((item, index) => (
                                                            <>
                                                                {props.Shops?.length > 0 &&
                                                                    <div key={index} className="catBox flex flex-col gap-2 justify-center items-center text-center transform transition duration-500 ease-in-out hover:scale-110 cursor-pointer" onClick={() => navigate(`/Story/${props.Shops.filter(i => (i?.storeID === item))[0]?.storeID}`)}>
                                                                        <div className={`storyCircle storyUnViewed p-1 rounded-full h-24 w-24`}>
                                                                            <img src={props.Shops.filter(i => (i?.storeID === item))[0]?.logo} alt="" className='h-full w-full rounded-full' />
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </>
                                                        ))}
                                                    </>
                                                }
                                                {/* {stories?.length > 8 &&
                                                <div className="flex justify-center items-center text-center transform transition duration-500 ease-in-out hover:scale-110">
                                                    <div className="viewAll flex flex-row justify-center items-center text-center gap-2 p-2 rounded-2xl cursor-pointer w-max" onClick={ChangeToStories}>
                                                        <h1 className="uppercase font-extrabold">View All</h1>
                                                        <img src="https://cdn-icons-png.freepik.com/512/724/724812.png" alt="Right Arrow" className="h-10 w-10" />
                                                    </div>
                                                </div>
                                            } */}
                                            </div>
                                        </div>
                                    }
                                    {props.Shops.filter(item => item.storeCity === localStorage.getItem("City"))?.length > 0 &&
                                        <div className="content-section-new flex flex-col mt-5 h-max min-w-[95%]">
                                            {/* <div className="content-section-title">Shops</div> */}
                                            <div className="NameAndViewAll h-max w-full flex flex-row justify-between items-center">
                                                <div className="content-section-title">Shops</div>
                                                {props.Shops.filter(item => item.storeCity === localStorage.getItem("City"))?.length >= 6 && <h1 className="flex justify-center items-center text-center p-2 rounded-lg uppercase font-extrabold transform transition duration-500 ease-in-out hover:scale-110 cursor-pointer bg-[var(--search-bg)]" onClick={ChangeToShops}>View All</h1>}
                                            </div>
                                            <div className="allCategories flex flex-row overflow-x-scroll hide-scrollbar gap-5 pt-2 pb-2 pl-2 pr-12">
                                                {/* {shops?.filter((item, idx) => idx <= 7).sort((a, b) => a.storeName.localeCompare(b.storeName)).map(shop => ( */}
                                                {shuffleArray1(props.Shops).filter(item => item.storeCity === localStorage.getItem("City")).filter((item, idx) => idx <= 7).map(shop => (
                                                    <div className="app-card-shops flex-col cursor-pointer p-5 min-w-80 max-w-80 flex justify-center items-center" onClick={() => navigate(`/Store/${shop?.storeID}`)}>
                                                        <div className="app-card-store-logo flex w-full justify-center items-center">
                                                            <img src={shop?.logo} alt="logo" className='h-20 w-20 rounded-full' />
                                                        </div>
                                                        <div className="app-card__subtext w-full flex text-center justify-center items-center font-extrabold">
                                                            <span className='font-extrabold text-xl'>{shop.storeName}</span>
                                                        </div>
                                                        <div className="app-card-buttons flex justify-center items-center text-center w-full">
                                                            <div className="productShowOne flex flex-row w-full gap-2 justify-between items-center text-center pt-2 pb-2 pl-5 pr-5 rounded-xl">
                                                                <img src={shop.Products.productImage[0]} alt="" className='h-14 w-14 rounded-lg' />
                                                                <span className='font-medium text-lg flex flex-grow'>{truncate(shop.Products.productName, 25)}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                                {/* {props.Shops?.length > 8 &&
                                                    <div className="flex justify-center items-center text-center transform transition duration-500 ease-in-out hover:scale-110">
                                                        <div className="viewAll flex flex-row justify-center items-center text-center gap-2 p-2 rounded-2xl cursor-pointer w-max" onClick={ChangeToShops}>
                                                            <h1 className="uppercase font-extrabold">View All</h1>
                                                            <img src="https://cdn-icons-png.freepik.com/512/724/724812.png" alt="Right Arrow" className="h-10 w-10" />
                                                        </div>
                                                    </div>
                                                } */}
                                            </div>
                                        </div>
                                    }
                                    {props.PromoCount > 0 && props.Promoted.length > 0 && props.Promoted.filter(item => item.Products.length > 0).filter(items => { return items.Products.filter(item => item.storeCity === localStorage.getItem("City")).length > 0; }).length > 0 &&
                                        <div className="content-section">
                                            <div className="content-section-title">Promoted Products</div>
                                            {props.Promoted.length > 0 && props.Promoted.filter(item => item.Products.length > 0).sort((a, b) => a.Category.localeCompare(b.Category)).map(cat => (
                                                <>
                                                    {/* <div className="content-section-title-second min-w-full flex cursor-default text-center justify-start items-center">
                                                        <span className='pt-2 pb-2 pl-5 pr-5 mt-5 cursor-default'>{cat.Category}</span>
                                                    </div> */}
                                                    <div className="NameAndViewAll h-max w-full flex flex-row justify-between items-center">
                                                        <div className="content-section-title-second flex cursor-default text-center justify-start items-center">
                                                            <span className='pt-2 pb-2 pl-5 pr-5 mt-5 cursor-default'>{cat.Category}</span>
                                                        </div>
                                                        {cat.Products.filter(item => item.storeCity === localStorage.getItem("City"))?.length >= 12 && <h1 className="flex justify-center items-center text-center p-2 rounded-lg uppercase font-extrabold transform transition duration-500 ease-in-out hover:scale-110 cursor-pointer bg-[var(--search-bg)]" onClick={() => navigate(`/Promoted/${cat.Category}`)}>View All</h1>}
                                                    </div>
                                                    <div className="allCategories flex flex-row overflow-x-scroll hide-scrollbar gap-5 pt-2 pb-2 pl-2 pr-12">
                                                        {shuffleArray1(cat.Products).filter(item => item.storeCity === localStorage.getItem("City")).filter((item, index) => (index <= 100000)).reverse().filter((item, index) => (index <= 7)).map(catProducts => (
                                                            <>
                                                                {/* <div className="catCard h-44 min-w-40 cursor-pointer flex flex-col gap-2 justify-center items-center p-2 rounded-md" onClick={() => navigate(`/Product/${catProducts.id}`)}>
                                                                    <div className="h-36 w-full flex justify-center items-center">
                                                                        <img src={catProducts.image} alt={catProducts.name} className="h-32 w-32 rounded-2xl" />
                                                                    </div>
                                                                    <div className="categoryName text-base font-medium">{truncate(catProducts.name, 10)}</div>
                                                                </div> */}
                                                                <div className={`catCard ${bottomBarVisible <= 360 ? 'h-52 w-36' : 'h-52 w-40'} cursor-pointer flex flex-col gap-2 justify-center items-center p-2 rounded-md`} onClick={() => navigate(`/Product/${catProducts.productID}`)}>
                                                                    <div className="h-36 w-full flex justify-center items-center">
                                                                        <img src={catProducts.productImage[0]} alt={catProducts.productName} className="h-32 w-32 rounded-2xl" />
                                                                    </div>
                                                                    <div className="categoryName text-base font-medium">{truncate(catProducts.productName, 12)}</div>
                                                                    <div className="categoryName w-full flex justify-between">
                                                                        <span className="text-base font-medium">₹{catProducts.priceDiscounted}</span>
                                                                        <span className="text-base font-medium"><s>₹{catProducts.priceOriginal}</s></span>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        ))}
                                                        {cat.Products.length % 2 !== 0 &&
                                                            <div className={`catCard ${bottomBarVisible <= 360 ? 'h-52 w-36' : 'h-52 w-40'} flex flex-col gap-2 justify-center items-center p-2 rounded-md`} style={{ visibility: 'hidden' }}>
                                                                <div className="h-36 w-full flex justify-center items-center">
                                                                    <img src="" alt="" className="h-32 w-32 rounded-2xl" />
                                                                </div>
                                                                <div className="categoryName text-lg font-extrabold">lorem ipsum</div>
                                                                <div className="categoryName text-lg font-extrabold">1000</div>
                                                            </div>
                                                        }
                                                        {/* {cat.Products?.length > 8 &&
                                                            <div className="flex justify-center items-center text-center transform transition duration-500 ease-in-out hover:scale-110">
                                                                <div className="viewAll flex flex-row justify-center items-center text-center gap-2 p-2 rounded-2xl cursor-pointer w-max" onClick={() => ChangeToCategory(cat.Category)}>
                                                                    <h1 className="uppercase font-extrabold">View All</h1>
                                                                    <img src="https://cdn-icons-png.freepik.com/512/724/724812.png" alt="Right Arrow" className="h-10 w-10" />
                                                                </div>
                                                            </div>
                                                        } */}
                                                    </div>
                                                </>
                                            ))}
                                        </div>
                                    }
                                    {props.Category.length > 0 && props.Category.filter(item => item.Products.length > 0).filter(items => { return items.Products.filter(item => item.storeCity === localStorage.getItem("City")).length > 0; }).length > 0 &&
                                        <div className="content-section">
                                            <div className="content-section-title">Products</div>
                                            {props.Category.length > 0 && props.Category.filter(item => item.Products.length > 0).sort((a, b) => a.Category.localeCompare(b.Category)).map(cat => (
                                                <>
                                                    {/* <div className="content-section-title-second min-w-full flex cursor-default text-center justify-start items-center">
                                                    <span className='pt-2 pb-2 pl-5 pr-5 mt-5 cursor-default'>{cat.Category}</span>
                                                </div> */}
                                                    <div className="NameAndViewAll h-max w-full flex flex-row justify-between items-center">
                                                        <div className="content-section-title-second flex cursor-default text-center justify-start items-center">
                                                            <span className='pt-2 pb-2 pl-5 pr-5 mt-5 cursor-default'>{cat.Category}</span>
                                                        </div>
                                                        {cat.Products.filter(item => item.storeCity === localStorage.getItem("City"))?.length >= 12 && <h1 className="flex justify-center items-center text-center p-2 rounded-lg uppercase font-extrabold transform transition duration-500 ease-in-out hover:scale-110 cursor-pointer bg-[var(--search-bg)]" onClick={() => ChangeToCategory(cat.Category)}>View All</h1>}
                                                    </div>
                                                    <div className={`apps-card flex gap-2 ${bottomBarVisible <= 730 && 'justify-evenly'}`}>
                                                        {shuffleArray1(cat.Products).filter(item => item.storeCity === localStorage.getItem("City")).filter((item, index) => (index <= 100000)).reverse().filter((item, index) => (index <= 5)).map(catProducts => (
                                                            <div className={`catCard ${bottomBarVisible <= 360 ? 'h-52 w-36' : 'h-52 w-40'} cursor-pointer flex flex-col gap-2 justify-center items-center p-2 rounded-md`} onClick={() => navigate(`/Product/${catProducts.productID}`)}>
                                                                <div className="h-36 w-full flex justify-center items-center">
                                                                    <img src={catProducts.productImage[0]} alt={catProducts.productName} className="h-32 w-32 rounded-2xl" />
                                                                </div>
                                                                <div className="categoryName text-base font-medium">{truncate(catProducts.productName, 12)}</div>
                                                                <div className="categoryName w-full flex justify-between">
                                                                    <span className="text-base font-medium">₹{catProducts.priceDiscounted}</span>
                                                                    <span className="text-base font-medium"><s>₹{catProducts.priceOriginal}</s></span>
                                                                </div>
                                                            </div>
                                                        ))}
                                                        {cat.Products.filter((item, index) => (index <= 5)).length % 2 !== 0 &&
                                                            <div className={`catCard ${bottomBarVisible <= 360 ? 'h-52 w-36' : 'h-52 w-40'} flex flex-col gap-2 justify-center items-center p-2 rounded-md`} style={{ visibility: 'hidden' }}>
                                                                <div className="h-36 w-full flex justify-center items-center">
                                                                    <img src="" alt="" className="h-32 w-32 rounded-2xl" />
                                                                </div>
                                                                <div className="categoryName text-lg font-extrabold">lorem ipsum</div>
                                                                <div className="categoryName text-lg font-extrabold">1000</div>
                                                            </div>
                                                        }
                                                    </div>
                                                </>
                                            ))}
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="overlay-app" />
            </div>
            {bottomBarVisible <= 945 &&
                <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, backgroundColor: 'transparent' }} elevation={3}>
                    <BottomNavigation
                        className="backgroundColor: 'transparent',"
                        showLabels
                        value={value}
                        onChange={(event, newValue) => {
                            setValue(newValue);
                            newValue === 0 ? navigate('/') : newValue === 1 ? navigate('/Explore') : newValue === 2 ? navigate('/Sell') : newValue === 3 ? navigate('/Chats') : newValue === 4 ? navigate('/Profile') : navigate('/')
                        }}
                    // sx={{ '&.Mui-selected': { color: '#8c52ff', }, }}
                    >
                        <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<HomeRoundedIcon />} label="Home" />
                        <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<SlideshowIcon />} label="Shorts" />
                        <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<AddCircleRoundedIcon />} label="Sell" />
                        <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<QuestionAnswerRoundedIcon />} label="Chat" />
                        {currentUser ? <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<img src={currentUser.photoURL} className='h-6 w-6 rounded-full' />} label="Manage" /> : <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<PersonRoundedIcon />} label="Profile" />}
                    </BottomNavigation>
                </Paper>
            }
        </>
    )
}

export default Home