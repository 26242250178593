/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable react-hooks/exhaustive-deps */
import { BottomNavigation, BottomNavigationAction, Paper } from '@mui/material';
import React, { useContext, useEffect, useRef, useState } from 'react';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import SlideshowIcon from '@mui/icons-material/Slideshow';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import QuestionAnswerRoundedIcon from '@mui/icons-material/QuestionAnswerRounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import {
    Link, useNavigate,
} from 'react-router-dom';
import gsap from 'gsap';
import { AuthContext } from '../context/AuthContext';
import { deleteUser, signOut } from 'firebase/auth';
import { auth, db } from '../firebase';
import { arrayRemove, deleteDoc, deleteField, doc, increment, updateDoc } from 'firebase/firestore';
import moment from 'moment';
import swal from '@sweetalert/with-react';

const Profile = (props) => {
    const { currentUser } = useContext(AuthContext);
    const [bottomBarVisible, setBottomBarVisible] = useState(window.screen.width);

    useEffect(() => {
        const interval = setInterval(() => {
            setBottomBarVisible(window.screen.width)
        }, 100);
        return () => clearInterval(interval);
    }, []);

    const [value, setValue] = useState(4);
    const ProfileRef = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        gsap.fromTo(".content-wrapper", { opacity: 0 }, { opacity: 1, duration: 2 });
    }, []);

    const HandleDelete = async () => {
        swal({
            title: "Delete Account",
            text: "Are You Sure?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (willDelete) => {
            if (willDelete) {
                props.UserData !== null && props.UserData.StoreAdded === true && props.Category?.filter(item => item.Category === props.StoreData.category)[0].Products.filter(item1 => item1.storeID === props.UserData.storeID).map(async (item2, index) => (
                    await updateDoc(doc(db, "Category", props.StoreData.category), {
                        Products: arrayRemove(item2),
                    })
                ));
                props.UserData !== null && props.UserData.StoreAdded === true && props.Products?.filter(item1 => item1.storeID === props.UserData.storeID).map(async (item2, index) => (
                    await updateDoc(doc(db, "Products", "Products"), {
                        Products: arrayRemove(item2),
                    })
                ));
                props.UserData !== null && props.UserData.StoreAdded === true && props.Shops?.filter(item1 => item1.storeID === props.UserData.storeID).map(async (item2, index) => (
                    await updateDoc(doc(db, "Shops", "Stores"), {
                        Shops: arrayRemove(item2),
                    })
                ));
                props.UserData !== null && props.UserData.StoreAdded === true && props.BannerAds?.filter(item1 => item1.storeID === props.UserData.storeID).map(async (item2, index) => (
                    await updateDoc(doc(db, "Banner", "Ads"), {
                        Ads: arrayRemove(item2),
                    })
                ));
                props.UserData !== null && props.UserData.StoreAdded === true && props.CategoryAds?.filter(item1 => item1.storeID === props.UserData.storeID).map(async (item2, index) => (
                    await updateDoc(doc(db, "CategoryAds", "Ads"), {
                        Ads: arrayRemove(item2),
                    })
                ));
                props.UserData !== null && props.UserData.StoreAdded === true && props.StoriesData?.filter(item1 => item1.storeID === props.UserData.storeID).map(async (item2, index) => (
                    await deleteDoc(doc(db, "Story", item2.storyID))
                ));
                props.UserData !== null && props.UserData.StoreAdded === true && props.VideosData?.filter(item1 => item1.storeID === props.UserData.storeID).map(async (item2, index) => (
                    await deleteDoc(doc(db, "Video", item2.videoID))
                ));
                props.UserData !== null && props.UserData.StoreAdded === true && props.Promoted.filter(item => item.Category === props.UserData.storeCategory)[0].Products.filter(item2 => item2.storeID === props.UserData.storeID).map(async (item3, index) => (
                    await updateDoc(doc(db, "Promoted", props.UserData.storeCategory), {
                        Products: arrayRemove(item3),
                    }).then(async (willdoThis) => {
                        await updateDoc(doc(db, "PromotedProductCount", "ProductCount"), {
                            Count: increment(-1),
                        })
                    })
                ));
                await deleteDoc(doc(db, "Inbox", currentUser.uid));
                Object.keys(props.Inbox).map(async (item) => (
                    await updateDoc(doc(db, "Inbox", item.replace(currentUser.uid, '')), {
                        [item]: deleteField(),
                    })
                ));
                props.UserData !== null && props.UserData.StoreAdded === true && await deleteDoc(doc(db, "Stores", props.UserData.storeID));
                await deleteDoc(doc(db, "Users", currentUser.uid));
                const user = auth.currentUser;
                deleteUser(user)
                    .then(() => {
                        swal('Successfully deleted user', '', 'success');
                        window.location.reload();
                        navigate('/')
                    })
                    .catch((error) => {
                        signOut(auth);
                        window.location.reload();
                        navigate('/');
                        // swal('Error deleting user', error.code, 'error').then(() => {
                        //     signOut(auth);
                        //     window.location.reload();
                        //     navigate('/');
                        // });
                    });
            }
        });
    }

    const HandleLogout = () => {
        const currentTime = moment().format('hh:mm:ss DD MMMM YYYY');
        updateDoc(doc(db, "Users", currentUser.uid), {
            Status: "Offline",
            LastSeen: currentTime,
        });
        signOut(auth);
        navigate('/')
    }

    useEffect(() => {
        if (!currentUser) {
            navigate('/Auth')
        }
    }, [currentUser])

    useEffect(() => {
        if (props.UserData !== null) {
            if (currentUser && props.UserData !== null && props.UserData.NewUser === true) {
                navigate('/Onboarding');
            }
        }
    }, [currentUser, props.UserData])

    return (
        <>
            <div className="app">
                <div className="wrapper">
                    <div className="left-side">
                        <div className="side-wrapper flex h-[100%]">
                            <div className="side-menu flex flex-col justify-evenly h-full">
                                <Link to="/" className="flex flex-row items-center gap-2 w-full">
                                    <img src="https://cdn-icons-png.freepik.com/512/4194/4194676.png" alt="Home Icon" style={{ height: 28, width: 28 }} />
                                    <h2 className="font-extrabold text-lg">Home</h2>
                                </Link>
                                <Link to="/Explore" className="flex flex-row items-center gap-2 w-full">
                                    <img src="https://cdn-icons-png.freepik.com/512/6337/6337301.png" alt="Explore Icon" style={{ height: 28, width: 28 }} />
                                    <h2 className="font-extrabold text-lg">Feed</h2>
                                </Link>
                                <Link to="/Sell" className="flex flex-row items-center gap-2 w-full">
                                    <img src="https://cdn-icons-png.freepik.com/512/10337/10337579.png" alt="Sell Icon" style={{ height: 28, width: 28 }} />
                                    <h2 className="font-extrabold text-lg">Sell</h2>
                                </Link>
                                <Link to="/Chats" className="flex flex-row items-center gap-2 w-full">
                                    <img src="https://cdn-icons-png.freepik.com/512/1041/1041916.png" alt="Chat Icon" style={{ height: 28, width: 28 }} />
                                    <h2 className="font-extrabold text-lg">Chat</h2>
                                </Link>
                                <Link to="/Profile" className="flex flex-row items-center gap-2 w-full">
                                    {currentUser ? <img src={currentUser.photoURL} alt="Profile Picture" style={{ height: 28, width: 28, borderRadius: '100%' }} /> : <img src="https://cdn-icons-png.freepik.com/512/1177/1177568.png" alt="Profile Picture" style={{ height: 28, width: 28 }} />}
                                    <h2 className="font-extrabold text-lg">Profile</h2>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="main-container">
                        <div className="content-wrapper">
                            <div className={`${bottomBarVisible <= 945 ? 'mb-28' : ''}`}>
                                <div className="content-section-profile" ref={ProfileRef}>
                                    <div className="DetailedInformation flex w-full justify-between items-center">
                                        {currentUser !== null && props.UserData && props.StoreData && props.UserData.StoreAdded === true ? <img src={props.StoreData.logo} alt="" className="h-12 w-12 rounded-full" onClick={() => navigate(`/Store/${props.StoreData.storeID}`)} /> : currentUser !== null && <img src={currentUser.photoURL} alt="" className="h-12 w-12 rounded-full" onClick={() => navigate(`/Profile`)} />}
                                        {/* <div className="Followers flex flex-col gap-2 justify-center items-center">
                                            {props.UserData && <h2 className='font-bold'>{props.UserData.Followers}</h2>}
                                            <h1 className='font-extrabold'>Followers</h1>
                                        </div>
                                        <div className="Following flex flex-col gap-2 justify-center items-center">
                                            {props.UserData && <h2 className='font-bold'>{props.UserData.Following}</h2>}
                                            <h1 className='font-extrabold'>Following</h1>
                                        </div> */}
                                    </div>
                                    {currentUser && props.UserData !== null && props.UserData.StoreAdded !== false ?
                                        <>
                                            <div className="content-section-sell-store mt-5">
                                                <div className="content-section-title-profile flex w-full justify-between items-center mb-5">
                                                    <span className="font-extrabold uppercase">Promote Products</span>
                                                    {/* <span className="font-bold uppercase cursor-pointer pl-4 pr-4 pt-2 pb-2 rounded-xl" style={{ backgroundColor: 'var(--content-bg)' }} onClick={() => navigate('/Pricing')}>View Pricing</span> */}
                                                </div>
                                                <div className={`apps-card flex gap-2 ${bottomBarVisible <= 730 && 'justify-evenly'}`}>
                                                    <div className="app-card-profile-ads">
                                                        <span className="w-full flex text-center justify-center items-center">Home Page Banner</span>
                                                        <span className="w-full flex text-center justify-center items-center pl-4 pr-4 pt-2 pb-2 rounded-xl bg-blue-500 text-white mt-5 cursor-pointer" onClick={() => navigate('/Pricing/Ads/Banner')}>Place Ads</span>
                                                    </div>
                                                    <div className="app-card-profile-ads">
                                                        <span className="w-full flex text-center justify-center items-center">Video Ads</span>
                                                        <span className="w-full flex text-center justify-center items-center pl-4 pr-4 pt-2 pb-2 rounded-xl bg-blue-500 text-white mt-11 cursor-pointer" onClick={() => navigate('/Pricing/Ads/Video')}>Place Ads</span>
                                                    </div>
                                                    <div className="app-card-profile-ads">
                                                        <span className="w-full flex text-center justify-center items-center">Story Ads</span>
                                                        <span className="w-full flex text-center justify-center items-center pl-4 pr-4 pt-2 pb-2 rounded-xl bg-blue-500 text-white mt-11 cursor-pointer" onClick={() => navigate('/Pricing/Ads/Story')}>Place Ads</span>
                                                    </div>
                                                    <div className="app-card-profile-ads">
                                                        <span className="w-full flex text-center justify-center items-center">Category Ads</span>
                                                        <span className="w-full flex text-center justify-center items-center pl-4 pr-4 pt-2 pb-2 rounded-xl bg-blue-500 text-white mt-11 cursor-pointer" onClick={() => navigate('/Pricing/Ads/Category')}>Place Ads</span>
                                                    </div>
                                                    <div className="app-card-profile-ads">
                                                        <span className="w-full flex text-center justify-center items-center">Promoted Products</span>
                                                        <span className="w-full flex text-center justify-center items-center pl-4 pr-4 pt-2 pb-2 rounded-xl bg-blue-500 text-white mt-5 cursor-pointer" onClick={() => navigate('/Pricing/Ads/PromoteProducts')}>Place Ads</span>
                                                    </div>
                                                    {bottomBarVisible <= 730 &&
                                                        <div className="app-card-profile-ads" style={{ visibility: 'hidden' }}>
                                                            <span className="w-full flex text-center justify-center items-center">Promoted Products</span>
                                                            <span className="w-full flex text-center justify-center items-center pl-4 pr-4 pt-2 pb-2 rounded-xl mt-5 cursor-pointer" style={{ backgroundColor: 'var(--content-bg)' }} onClick={() => navigate('/Ads/PromoteProducts')}>Place Ads</span>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            <div className="content-section-sell-store mt-5">
                                                <div className="content-section-title-profile flex w-full justify-between items-center mb-5">
                                                    <span className="font-extrabold uppercase">Manage Store</span>
                                                </div>
                                                <div className={`apps-card flex gap-2 ${bottomBarVisible <= 730 && 'justify-evenly'}`}>
                                                    <div className="app-card-profile" onClick={() => navigate('/Sell')}>
                                                        <span className="w-full flex text-center justify-center items-center"><img src="https://cdn-icons-png.freepik.com/512/1828/1828925.png" alt="" className='h-10 w-10' /></span>
                                                        <span className="w-full flex text-center justify-center items-center">Add Products</span>
                                                    </div>
                                                    <div className="app-card-profile" onClick={() => navigate('/Edit/Store')}>
                                                        <span className="w-full flex text-center justify-center items-center"><img src="https://cdn-icons-png.freepik.com/512/1827/1827933.png" alt="" className='h-10 w-10' /></span>
                                                        <span className="w-full flex text-center justify-center items-center">Edit Store</span>
                                                    </div>
                                                    <div className="app-card-profile" onClick={() => navigate(`/Store/${props.UserData.storeID}`)}>
                                                        <span className="w-full flex text-center justify-center items-center"><img src="https://cdn-icons-png.freepik.com/512/10483/10483491.png" alt="" className='h-10 w-10' /></span>
                                                        <span className="w-full flex text-center justify-center items-center">My Products</span>
                                                    </div>
                                                    {bottomBarVisible <= 730 &&
                                                        <div className="app-card-profile-ads" style={{ visibility: 'hidden' }}>
                                                            <span className="w-full flex text-center justify-center items-center">Promoted Products</span>
                                                            <span className="w-full flex text-center justify-center items-center pl-4 pr-4 pt-2 pb-2 rounded-xl mt-5 cursor-pointer" style={{ backgroundColor: 'var(--content-bg)' }} onClick={() => navigate('/Ads/PromoteProducts')}>Place Ads</span>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            <div className="content-section-sell-store mt-5">
                                                <div className="content-section-title-profile flex w-full justify-between items-center mb-5">
                                                    <span className="font-extrabold uppercase">Settings</span>
                                                </div>
                                                <div className="apps-card-profile gap-5">
                                                    <div className="app-card-profile h-36" onClick={HandleDelete}>
                                                        <span className="w-full flex text-center justify-center items-center"><img src="https://cdn-icons-png.freepik.com/512/484/484611.png" alt="" className='h-10 w-10' /></span>
                                                        <span className="w-full flex text-center justify-center items-center">Delete Account</span>
                                                    </div>
                                                    {/* <div className="app-card-profile h-36">
                                                    <span className="w-full flex text-center justify-center items-center"><img src="https://cdn-icons-png.freepik.com/512/484/484582.png" alt="" className='h-10 w-10' /></span>
                                                    <span className="w-full flex text-center justify-center items-center">Language</span>
                                                </div> */}
                                                </div>
                                            </div>
                                            <div className="content-section-sell-store mt-5">
                                                <div className="content-section-title-profile flex w-full justify-between items-center mb-5">
                                                    <span className="font-extrabold uppercase">Help & Support</span>
                                                </div>
                                                <div className={`apps-card flex gap-2 ${bottomBarVisible <= 730 && 'justify-evenly'}`}>
                                                    <div className="app-card-profile h-36" onClick={() => navigate('/FAQ')}>
                                                        <span className="w-full flex text-center justify-center items-center"><img src="https://cdn-icons-png.freepik.com/512/9046/9046106.png" alt="" className='h-10 w-10' /></span>
                                                        <span className="w-full flex text-center justify-center items-center">FAQs</span>
                                                    </div>
                                                    <div className="app-card-profile h-36" onClick={() => navigate('/Contact')}>
                                                        <span className="w-full flex text-center justify-center items-center"><img src="https://cdn-icons-png.freepik.com/512/13651/13651428.png" alt="" className='h-10 w-10' /></span>
                                                        <span className="w-full flex text-center justify-center items-center">Contact Us</span>
                                                    </div>
                                                    <div className="app-card-profile h-36" onClick={() => navigate('/Terms-And-Conditions')}>
                                                        <span className="w-full flex text-center justify-center items-center"><img src="https://cdn-icons-png.freepik.com/512/8299/8299673.png" alt="" className='h-10 w-10' /></span>
                                                        <span className="w-full flex text-center justify-center items-center">Terms & Conditions</span>
                                                    </div>
                                                    <div className="app-card-profile h-36" onClick={() => navigate('/Privacy-Policy')}>
                                                        <span className="w-full flex text-center justify-center items-center"><img src="https://cdn-icons-png.freepik.com/512/11547/11547608.png" alt="" className='h-10 w-10' /></span>
                                                        <span className="w-full flex text-center justify-center items-center">Privacy Policy</span>
                                                    </div>
                                                    <div className="app-card-profile h-36" onClick={() => navigate('/Refund-Policy')}>
                                                        <span className="w-full flex text-center justify-center items-center"><img src="https://cdn-icons-png.freepik.com/512/1332/1332675.png" alt="" className='h-10 w-10' /></span>
                                                        <span className="w-full flex text-center justify-center items-center">Refund Policy</span>
                                                    </div>
                                                    {/* <div className="app-card-profile h-36" onClick={() => navigate('/Shipping-Policy')}>
                                                        <span className="w-full flex text-center justify-center items-center"><img src="https://cdn-icons-png.freepik.com/512/11477/11477567.png" alt="" className='h-10 w-10' /></span>
                                                        <span className="w-full flex text-center justify-center items-center">Shipping Policy</span>
                                                    </div> */}
                                                </div>
                                            </div>
                                            <div className="flex w-full justify-center items-center">
                                                <div className="logoutBtn-profile pl-5 pr-5 pt-2 pb-2 bg-red-500 font-extrabold text-white text-xl w-max rounded-xl mt-5 cursor-pointer" onClick={() => signOut(auth)}>
                                                    LOGOUT
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <>
                                            <div className="content-section-sell-store mt-5">
                                                <div className="content-section-title-profile flex w-full justify-between items-center mb-5">
                                                    <span className="font-extrabold uppercase">Settings</span>
                                                </div>
                                                <div className="apps-card-profile gap-5">
                                                    <div className="app-card-profile h-36" onClick={HandleDelete}>
                                                        <span className="w-full flex text-center justify-center items-center"><img src="https://cdn-icons-png.freepik.com/512/484/484611.png" alt="" className='h-10 w-10' /></span>
                                                        <span className="w-full flex text-center justify-center items-center">Delete Account</span>
                                                    </div>
                                                    {/* <div className="app-card-profile h-36">
                                                    <span className="w-full flex text-center justify-center items-center"><img src="https://cdn-icons-png.freepik.com/512/484/484582.png" alt="" className='h-10 w-10' /></span>
                                                    <span className="w-full flex text-center justify-center items-center">Language</span>
                                                </div> */}
                                                </div>
                                            </div>
                                            <div className="content-section-sell-store mt-5">
                                                <div className="content-section-title-profile flex w-full justify-between items-center mb-5">
                                                    <span className="font-extrabold uppercase">Help & Support</span>
                                                </div>
                                                <div className="apps-card-profile gap-5">
                                                    <div className="app-card-profile h-36" onClick={() => navigate('/FAQ')}>
                                                        <span className="w-full flex text-center justify-center items-center"><img src="https://cdn-icons-png.freepik.com/512/9046/9046106.png" alt="" className='h-10 w-10' /></span>
                                                        <span className="w-full flex text-center justify-center items-center">FAQs</span>
                                                    </div>
                                                    <div className="app-card-profile h-36" onClick={() => navigate('/Contact')}>
                                                        <span className="w-full flex text-center justify-center items-center"><img src="https://cdn-icons-png.freepik.com/512/13651/13651428.png" alt="" className='h-10 w-10' /></span>
                                                        <span className="w-full flex text-center justify-center items-center">Contact Us</span>
                                                    </div>
                                                    <div className="app-card-profile h-36" onClick={() => navigate('/Terms-And-Conditions')}>
                                                        <span className="w-full flex text-center justify-center items-center"><img src="https://cdn-icons-png.freepik.com/512/8299/8299673.png" alt="" className='h-10 w-10' /></span>
                                                        <span className="w-full flex text-center justify-center items-center">Terms & Conditions</span>
                                                    </div>
                                                    <div className="app-card-profile h-36" onClick={() => navigate('/Privacy-Policy')}>
                                                        <span className="w-full flex text-center justify-center items-center"><img src="https://cdn-icons-png.freepik.com/512/11547/11547608.png" alt="" className='h-10 w-10' /></span>
                                                        <span className="w-full flex text-center justify-center items-center">Privacy Policy</span>
                                                    </div>
                                                    <div className="app-card-profile h-36" onClick={() => navigate('/Refund-Policy')}>
                                                        <span className="w-full flex text-center justify-center items-center"><img src="https://cdn-icons-png.freepik.com/512/1332/1332675.png" alt="" className='h-10 w-10' /></span>
                                                        <span className="w-full flex text-center justify-center items-center">Refund Policy</span>
                                                    </div>
                                                    {/* <div className="app-card-profile h-36" onClick={() => navigate('/Shipping-Policy')}>
                                                        <span className="w-full flex text-center justify-center items-center"><img src="https://cdn-icons-png.freepik.com/512/11477/11477567.png" alt="" className='h-10 w-10' /></span>
                                                        <span className="w-full flex text-center justify-center items-center">Shipping Policy</span>
                                                    </div> */}
                                                </div>
                                            </div>
                                            <div className="flex w-full justify-center items-center">
                                                <div className="logoutBtn-profile pl-5 pr-5 pt-2 pb-2 bg-red-500 font-extrabold text-white text-xl w-max rounded-xl mt-5 cursor-pointer" onClick={HandleLogout}>
                                                    LOGOUT
                                                </div>
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="overlay-app" />
            </div>
            {bottomBarVisible <= 945 &&
                <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, backgroundColor: 'transparent' }} elevation={3}>
                    <BottomNavigation
                        className="backgroundColor: 'transparent',"
                        showLabels
                        value={value}
                        onChange={(event, newValue) => {
                            setValue(newValue);
                            newValue === 0 ? navigate('/') : newValue === 1 ? navigate('/Explore') : newValue === 2 ? navigate('/Sell') : newValue === 3 ? navigate('/Chats') : newValue === 4 ? navigate('/Profile') : navigate('/')
                        }}
                    >
                        <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<HomeRoundedIcon />} label="Home" />
                        <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<SlideshowIcon />} label="Shorts" />
                        <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<AddCircleRoundedIcon />} label="Sell" />
                        <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<QuestionAnswerRoundedIcon />} label="Chat" />
                        {currentUser ? <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<img src={currentUser.photoURL} className='h-6 w-6 rounded-full' />} label="Manage" /> : <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<PersonRoundedIcon />} label="Profile" />}
                    </BottomNavigation>
                </Paper>
            }
        </>
    )
}

export default Profile