/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import { BottomNavigation, BottomNavigationAction, Paper } from '@mui/material';
import React, { useContext, useEffect, useRef, useState } from 'react';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import SlideshowIcon from '@mui/icons-material/Slideshow';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import QuestionAnswerRoundedIcon from '@mui/icons-material/QuestionAnswerRounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import {
    Link, useNavigate, useParams
} from 'react-router-dom';
import gsap from 'gsap';
import { AuthContext } from '../context/AuthContext';
import { doc, onSnapshot } from 'firebase/firestore';
import { db } from '../firebase';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import BackButton from '../components/BackButton';

const Categories = (props) => {
    const { currentUser } = useContext(AuthContext);
    const { CAT } = useParams();
    const [bottomBarVisible, setBottomBarVisible] = useState(window.screen.width);

    useEffect(() => {
        const interval = setInterval(() => {
            setBottomBarVisible(window.screen.width)
        }, 100);
        return () => clearInterval(interval);
    }, []);

    // eslint-disable-next-line no-unused-vars
    const [value, setValue] = useState(0);
    const CategoryRef = useRef(null);
    const navigate = useNavigate();
    const [categoryData, setCategoryData] = useState(null);
    const [searchText, setSearchText] = useState('');

    useEffect(() => {
        if (props.ViewAllCategory === "Clicked") {
            CategoryRef.current = gsap.fromTo(".content-section", {
                x: "100%",
            },
                {
                    x: 0,
                    duration: 0.5,
                    ease: "power2.inOut"
                });
        }
    }, [props.ViewAllCategory]);

    useEffect(() => {
        gsap.fromTo(".content-wrapper", { opacity: 0 }, { opacity: 1, duration: 2 });
        props.ViewAllCats("");
    }, []);

    useEffect(() => {
        const unsub = onSnapshot(doc(db, "Category", CAT), (doc) => {
            setCategoryData(doc.data());
        });

        return () => {
            unsub();
        };
    }, [CAT]);

    const truncate = (str, length) => {
        if (str.length > length) {
            return str.slice(0, length) + "...";
        } else {
            return str;
        }
    }

    function shuffleArray1(array) {
        const newArray = array;
        for (let i = newArray.length - 1; i > 0; i--) {
            let j = Math.floor(Math.random() * (i + 1));
            [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
        }
        return newArray;
    }

    useEffect(() => {
        if (props.UserData !== null) {
            if (currentUser && props.UserData !== null && props.UserData.NewUser === true) {
                navigate('/Onboarding');
            }
        }
    }, [currentUser, props.UserData])

    return (
        <>
            <div className="app">
                <div className="wrapper">
                    <div className="left-side">
                        <div className="side-wrapper flex h-[100%]">
                            <div className="side-menu flex flex-col justify-evenly h-full">
                                <Link to="/" className="flex flex-row items-center gap-2 w-full">
                                    <img src="https://cdn-icons-png.freepik.com/512/4194/4194676.png" alt="Home Icon" style={{ height: 28, width: 28 }} />
                                    <h2 className="font-extrabold text-lg">Home</h2>
                                </Link>
                                <Link to="/Explore" className="flex flex-row items-center gap-2 w-full">
                                    <img src="https://cdn-icons-png.freepik.com/512/6337/6337301.png" alt="Explore Icon" style={{ height: 28, width: 28 }} />
                                    <h2 className="font-extrabold text-lg">Feed</h2>
                                </Link>
                                <Link to="/Sell" className="flex flex-row items-center gap-2 w-full">
                                    <img src="https://cdn-icons-png.freepik.com/512/10337/10337579.png" alt="Sell Icon" style={{ height: 28, width: 28 }} />
                                    <h2 className="font-extrabold text-lg">Sell</h2>
                                </Link>
                                <Link to="/Chats" className="flex flex-row items-center gap-2 w-full">
                                    <img src="https://cdn-icons-png.freepik.com/512/1041/1041916.png" alt="Chat Icon" style={{ height: 28, width: 28 }} />
                                    <h2 className="font-extrabold text-lg">Chat</h2>
                                </Link>
                                <Link to="/Profile" className="flex flex-row items-center gap-2 w-full">
                                    {currentUser ? <img src={currentUser.photoURL} alt="Profile Picture" style={{ height: 28, width: 28, borderRadius: '100%' }} /> : <img src="https://cdn-icons-png.freepik.com/512/1177/1177568.png" alt="Profile Picture" style={{ height: 28, width: 28 }} />}
                                    <h2 className="font-extrabold text-lg">Profile</h2>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="main-container">
                        <div className="main-header">
                            <div className="search-bar gap-2">
                                {bottomBarVisible <= 945 && <BackButton />}
                                <input type="text" placeholder="Search Products" value={searchText} onChange={(e) => setSearchText(e.target.value)} />
                            </div>
                        </div>
                        <div className="content-wrapper">
                            {searchText ?
                                <>
                                    <div className="content-section" ref={CategoryRef}>
                                        {categoryData !== null && categoryData?.Products.filter(item => item.storeCity === localStorage.getItem("City")).filter((entry) => entry.productName.toLowerCase().includes(searchText.toLowerCase())).length > 0 ?
                                            <>
                                                <div className="content-section-title">Products</div>
                                                <div className={`${bottomBarVisible <= 945 ? 'mb-28' : ''}`}>
                                                    <div className={`apps-card flex gap-2 ${bottomBarVisible <= 730 && 'justify-evenly'}`}>
                                                        {categoryData !== null && categoryData?.Products.filter((entry) => entry.productName.toLowerCase().includes(searchText.toLowerCase())).sort((a, b) => a.productName.localeCompare(b.productName)).map(category => (
                                                            <div className={`catCard ${bottomBarVisible <= 360 ? 'h-52 w-36' : 'h-52 w-40'} cursor-pointer flex flex-col gap-2 justify-center items-center p-2 rounded-md`} onClick={() => navigate(`/Product/${category.productID}`)}>
                                                                <div className="h-36 w-full flex justify-center items-center">
                                                                    <img src={category.productImage[0]} alt={category.productName} className="h-32 w-32 rounded-2xl" />
                                                                </div>
                                                                <div className="categoryName text-base font-medium">{truncate(category.productName, 12)}</div>
                                                                <div className="categoryName w-full flex justify-between">
                                                                    <span className="text-base font-medium">₹{category.priceDiscounted}</span>
                                                                    <span className="text-base font-medium"><s>₹{category.priceOriginal}</s></span>
                                                                </div>
                                                            </div>
                                                        ))}
                                                        {categoryData !== null && categoryData?.Products.filter((entry) => entry.productName.toLowerCase().includes(searchText.toLowerCase())).length % 2 !== 0 &&
                                                            <div className={`catCard ${bottomBarVisible <= 360 ? 'h-52 w-36' : 'h-52 w-40'} flex flex-col gap-2 justify-center items-center p-2 rounded-md`} style={{ visibility: 'hidden' }}>
                                                                <div className="h-36 w-full flex justify-center items-center">
                                                                    <img src="" alt="" className="h-32 w-32 rounded-2xl" />
                                                                </div>
                                                                <div className="categoryName text-lg font-extrabold">lorem ipsum</div>
                                                                <div className="categoryName text-lg font-extrabold">1000</div>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <>
                                                <div className="NoProducts font-extrabold uppercase text-2xl">
                                                    No Products Found
                                                </div>
                                            </>
                                        }
                                    </div>
                                </>
                                :
                                <>
                                    {props.CategoryAds.filter(item => item.storeCity === localStorage.getItem("City")).length > 0 && props.CategoryAds.filter(item => item.storeCity === localStorage.getItem("City")).filter(item => item.productCategory === CAT).length > 0 &&
                                        <div className="content-section-new flex flex-col h-max w-11/12">
                                            <div className="content-section-title">Promoted Products</div>
                                            <div className="allCategories flex w-full flex-row overflow-x-scroll hide-scrollbar gap-5 pt-2 pb-2 pl-2 pr-12">
                                                {props.CategoryAds.filter(item => item.storeCity === localStorage.getItem("City")).length > 0 && shuffleArray1(props.CategoryAds).filter(item => item.storeCity === localStorage.getItem("City")).filter(item => item.productCategory === CAT).map(category => (
                                                    <div className={`catCard ${bottomBarVisible <= 360 ? 'h-52 w-36' : 'h-52 w-40'} cursor-pointer flex flex-col gap-2 justify-center items-center p-2 rounded-md`} onClick={() => navigate(`/Product/${category.productID}`)}>
                                                        <div className="h-36 w-full flex justify-center items-center">
                                                            <img src={category.productImage[0]} alt={category.productName} className="h-32 w-32 rounded-2xl" />
                                                        </div>
                                                        <div className="categoryName text-base font-medium">{truncate(category.productName, 12)}</div>
                                                        <div className="categoryName w-full flex justify-between">
                                                            <span className="text-base font-medium">₹{category.priceDiscounted}</span>
                                                            <span className="text-base font-medium"><s>₹{category.priceOriginal}</s></span>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    }
                                    <div className="content-section" ref={CategoryRef}>
                                        <div className="content-section-title text-2xl font-semibold">{CAT}</div>
                                        <div className={`${bottomBarVisible <= 945 ? 'mb-28' : ''}`}>
                                            {categoryData !== null && categoryData?.Products.filter(item => item.storeCity === localStorage.getItem("City")).length > 0 ?
                                                <div className={`apps-card flex gap-2 ${bottomBarVisible <= 730 && 'justify-evenly'}`}>
                                                    {/* {categoryData !== null && shuffleArray1(categoryData?.Products)?.sort((a, b) => a.productName.localeCompare(b.productName)).map(category => ( */}
                                                    {categoryData !== null && shuffleArray1(categoryData?.Products).filter(item => item.storeCity === localStorage.getItem("City")).map(category => (
                                                        <div className={`catCard ${bottomBarVisible <= 360 ? 'h-52 w-36' : 'h-52 w-40'} cursor-pointer flex flex-col gap-2 justify-center items-center p-2 rounded-md`} onClick={() => navigate(`/Product/${category.productID}`)}>
                                                            <div className="h-36 w-full flex justify-center items-center">
                                                                <img src={category.productImage[0]} alt={category.productName} className="h-32 w-32 rounded-2xl" />
                                                            </div>
                                                            <div className="categoryName text-base font-medium">{truncate(category.productName, 12)}</div>
                                                            <div className="categoryName w-full flex justify-between">
                                                                <span className="text-base font-medium">₹{category.priceDiscounted}</span>
                                                                <span className="text-base font-medium"><s>₹{category.priceOriginal}</s></span>
                                                            </div>
                                                        </div>
                                                    ))}
                                                    {categoryData !== null && shuffleArray1(categoryData?.Products).filter(item => item.storeCity === localStorage.getItem("City")).length % 2 !== 0 &&
                                                        <div className={`catCard ${bottomBarVisible <= 360 ? 'h-52 w-36' : 'h-52 w-40'} flex flex-col gap-2 justify-center items-center p-2 rounded-md`} style={{ visibility: 'hidden' }}>
                                                            <div className="h-36 w-full flex justify-center items-center">
                                                                <img src="" alt="" className="h-32 w-32 rounded-2xl" />
                                                            </div>
                                                            <div className="categoryName text-lg font-extrabold">lorem ipsum</div>
                                                            <div className="categoryName text-lg font-extrabold">1000</div>
                                                        </div>
                                                    }
                                                </div>
                                                :
                                                <div className="w-full h-[50vh] flex justify-center items-center text-center font-bold text-2xl">
                                                    NO PRODUCTS AVAILABLE
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
                <div className="overlay-app" />
            </div>
            {bottomBarVisible <= 945 &&
                <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, backgroundColor: 'transparent' }} elevation={3}>
                    <BottomNavigation
                        className="backgroundColor: 'transparent',"
                        showLabels
                        value={-1}
                        onChange={(event, newValue) => {
                            setValue(newValue);
                            newValue === 0 ? navigate('/') : newValue === 1 ? navigate('/Explore') : newValue === 2 ? navigate('/Sell') : newValue === 3 ? navigate('/Chats') : newValue === 4 ? navigate('/Profile') : navigate('/')
                        }}
                    >
                        <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<HomeRoundedIcon />} label="Home" />
                        <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<SlideshowIcon />} label="Shorts" />
                        <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<AddCircleRoundedIcon />} label="Sell" />
                        <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<QuestionAnswerRoundedIcon />} label="Chat" />
                        {currentUser ? <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<img src={currentUser.photoURL} className='h-6 w-6 rounded-full' />} label="Manage" /> : <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<PersonRoundedIcon />} label="Profile" />}
                    </BottomNavigation>
                </Paper>
            }
        </>
    )
}

export default Categories