/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
// import usePageTransition from '../transitions/PageTransition';
import gsap from 'gsap';
import { AuthContext } from '../context/AuthContext';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Stories from 'react-insta-stories';
import swal from '@sweetalert/with-react';
import { Dialog, Divider } from '@mui/material';

const Story = (props) => {
    const { currentUser } = useContext(AuthContext);
    const [bottomBarVisible, setBottomBarVisible] = useState(window.screen.width);
    const { VID } = useParams();

    useEffect(() => {
        const interval = setInterval(() => {
            setBottomBarVisible(window.screen.width)
        }, 100);
        return () => clearInterval(interval);
    }, []);

    // eslint-disable-next-line no-unused-vars
    const [value, setValue] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
        gsap.fromTo(".content-wrapper", { opacity: 0 }, { opacity: 1, duration: 2 });
    }, []);

    useEffect(() => {
        if (props.UserData !== null) {
            if (currentUser && props.UserData !== null && props.UserData.NewUser === true) {
                navigate('/Onboarding');
            }
        }
    }, [currentUser, props.UserData])

    const elements = document.querySelectorAll('*');

    elements.forEach(element => {
        const zIndex = window.getComputedStyle(element).getPropertyValue('z-index');
        if (zIndex === '999') {
            element.style.zIndex = '0'; // change to the desired value
        }
    });

    // const stories = []
    // props.StoriesData.map(item => stories.includes(item.ad) === false && stories.push(item.ad))

    // console.log(props.StoriesData)

    const [openShare, setOpenShare] = useState(false);
    const [paused, setPaused] = useState(false);
    const [currentReelData, setCurrentReelData] = useState(null);

    const handleClickOpenShare = (val) => {
        setCurrentReelData(val);
        setPaused(true);
        setOpenShare(true);
    };

    const handleCloseShare = () => {
        setPaused(false);
        setOpenShare(false);
        // setCurrentReelData(null);
    };

    const ChatID = async () => {
        if (currentUser !== null) {
            const combinedId =
                currentUser.uid > currentReelData.Store.ownerID
                    ? currentUser.uid + currentReelData.Store.ownerID
                    : currentReelData.Store.ownerID + currentUser.uid;
            navigate(`/Chat/${combinedId}`);
        } else {
            swal('Please Login To Chat', '', 'warning', {
                buttons: {
                    confirm: {
                        text: "OK",
                        value: true,
                        visible: true,
                        className: "bg-[#8c52ff]",
                        closeModal: true
                    }
                }
            })
        }
    };

    const dialogBoxShare = () => (
        <div className="w-[340px] h-max overflow-x-hidden hide-scrollbar">
            <div className="flex flex-col w-full h-full justify-between items-center p-2 overflow-x-hidden hide-scrollbar">
                <div className="w-full flex justify-center items-center font-black text-2xl">
                    OPTIONS
                </div>
                <Divider />
                <div className="flex flex-row justify-evenly items-center w-full mt-5">
                    <div className="flex flex-col justify-center items-center cursor-pointer" onClick={() => navigate(`/Store/${currentReelData.storeID}`)}>
                        <img src="https://cdn-icons-png.freepik.com/512/7382/7382213.png" className="h-10 w-10 cursor-pointer" alt="Share Icon" />
                        <div className="font-extrabold text-lg">VISIT</div>
                    </div>
                    <div className="flex flex-col justify-center items-center cursor-pointer" onClick={ChatID}>
                        <img src="https://cdn-icons-png.freepik.com/512/724/724715.png" className="h-10 w-10 cursor-pointer" alt="Share Icon" />
                        <div className="font-extrabold text-lg">CONTACT</div>
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <>
            <div className="app">
                <div className="wrapper">
                    {/* <div className="left-side">
                        <div className="side-wrapper flex h-[100%]">
                            <div className="side-menu flex flex-col justify-evenly h-full">
                                <Link to="/" className="flex flex-row items-center gap-2 w-full">
                                    <img src="https://cdn-icons-png.freepik.com/512/4194/4194676.png" alt="Home Icon" style={{ height: 28, width: 28 }} />
                                    <h2 className="font-extrabold text-lg">Home</h2>
                                </Link>
                                <Link to="/Explore" className="flex flex-row items-center gap-2 w-full">
                                    <img src="https://cdn-icons-png.freepik.com/512/6337/6337301.png" alt="Explore Icon" style={{ height: 28, width: 28 }} />
                                    <h2 className="font-extrabold text-lg">Feed</h2>
                                </Link>
                                <Link to="/Sell" className="flex flex-row items-center gap-2 w-full">
                                    <img src="https://cdn-icons-png.freepik.com/512/10337/10337579.png" alt="Sell Icon" style={{ height: 28, width: 28 }} />
                                    <h2 className="font-extrabold text-lg">Sell</h2>
                                </Link>
                                <Link to="/Chats" className="flex flex-row items-center gap-2 w-full">
                                    <img src="https://cdn-icons-png.freepik.com/512/1041/1041916.png" alt="Chat Icon" style={{ height: 28, width: 28 }} />
                                    <h2 className="font-extrabold text-lg">Chat</h2>
                                </Link>
                                <Link to="/Profile" className="flex flex-row items-center gap-2 w-full">
                                    {currentUser ? <img src={currentUser.photoURL} alt="Profile Picture" style={{ height: 28, width: 28, borderRadius: '100%' }} /> : <img src="https://cdn-icons-png.freepik.com/512/1177/1177568.png" alt="Profile Picture" style={{ height: 28, width: 28 }} />}
                                    <h2 className="font-extrabold text-lg">Profile</h2>
                                </Link>
                            </div>
                        </div>
                    </div> */}
                    <div className="main-container-story flex justify-center items-center bg-[#111111]">
                        <Dialog
                            open={openShare}
                            onClose={handleCloseShare}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                            sx={{
                                '& .MuiPaper-root': { backgroundColor: 'rgba(255,255,255,0.6)', margin: 0 },
                            }}
                        >
                            {dialogBoxShare()}
                        </Dialog>
                        {props.StoriesData.length > 0 &&
                            <Stories
                                stories={
                                    props.StoriesData.filter(item => (item.storeID === VID)).map(video => ({
                                        url: video.ad,
                                        type: 'video',
                                        header: {
                                            heading: <div onClick={(e) => { e.stopPropagation(); handleClickOpenShare(video); }} className="cursor-pointer" style={{ zIndex: 1000000 }}>{video.Store.storeName}</div>,
                                            profileImage: video.Store.logo,
                                        },
                                    }))
                                }
                                // width="340px"
                                width={`${bottomBarVisible <= 450 ? '100vw' : '340px'}`}
                                // height={`${bottomBarVisible <= 945 ? '92vh' : '100vh'}`}
                                height="100vh"
                                // storyContainerStyles={{ marginBottom: `${bottomBarVisible <= 945 && '58px'}` }}
                                onAllStoriesEnd={() => navigate(-1)}
                                isPaused={paused}
                                keyboardNavigation={true}
                            />
                        }
                    </div>
                </div>
                <div className="overlay-app" />
                {/* {bottomBarVisible <= 945 &&
                    <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, backgroundColor: 'transparent' }} elevation={3}>
                        <BottomNavigation
                            className="backgroundColor: 'transparent',"
                            showLabels
                            value={-1}
                            onChange={(event, newValue) => {
                                setValue(newValue);
                                newValue === 0 ? navigate('/') : newValue === 1 ? navigate('/Explore') : newValue === 2 ? navigate('/Sell') : newValue === 3 ? navigate('/Chats') : newValue === 4 ? navigate('/Profile') : navigate('/')
                            }}
                        // sx={{ '&.Mui-selected': { color: '#8c52ff', }, }}
                        >
                            <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<HomeRoundedIcon />} label="Home" />
                            <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<ExploreRoundedIcon />} label="Feed" />
                            <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<AddCircleRoundedIcon />} label="Sell" />
                            <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<QuestionAnswerRoundedIcon />} label="Chat" />
                            {currentUser ? <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<img src={currentUser.photoURL} className='h-6 w-6 rounded-full' />} label="Profile" /> : <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<PersonRoundedIcon />} label="Profile" />}
                        </BottomNavigation>
                    </Paper>
                } */}
            </div>
        </>
    )
}

export default Story