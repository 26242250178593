/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import SlideshowIcon from '@mui/icons-material/Slideshow';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import QuestionAnswerRoundedIcon from '@mui/icons-material/QuestionAnswerRounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import {
    Link, useNavigate,
} from 'react-router-dom';
import gsap from 'gsap';
import { AuthContext } from '../context/AuthContext';
import { BottomNavigation, BottomNavigationAction, Paper } from '@mui/material';
import BackButton from '../components/BackButton';

const RefundPolicy = (props) => {
    const { currentUser } = useContext(AuthContext);
    const [bottomBarVisible, setBottomBarVisible] = useState(window.screen.width);

    useEffect(() => {
        const interval = setInterval(() => {
            setBottomBarVisible(window.screen.width)
        }, 100);
        return () => clearInterval(interval);
    }, []);

    // eslint-disable-next-line no-unused-vars
    const [value, setValue] = useState(4);
    // const ProfileRef = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        gsap.fromTo(".content-wrapper", { opacity: 0 }, { opacity: 1, duration: 2 });
    }, []);

    // useEffect(() => {
    //     if (!currentUser) {
    //         navigate('/Auth')
    //     }
    // }, [currentUser])

    useEffect(() => {
        if (props.UserData !== null) {
            if (currentUser && props.UserData !== null && props.UserData.NewUser === true) {
                navigate('/Onboarding');
            }
        }
    }, [currentUser, props.UserData])

    return (
        <>
            <div className="app">
                <div className="wrapper">
                    <div className="left-side">
                        <div className="side-wrapper flex h-[100%]">
                            <div className="side-menu flex flex-col justify-evenly h-full">
                                <Link to="/" className="flex flex-row items-center gap-2 w-full">
                                    <img src="https://cdn-icons-png.freepik.com/512/4194/4194676.png" alt="Home Icon" style={{ height: 28, width: 28 }} />
                                    <h2 className="font-extrabold text-lg">Home</h2>
                                </Link>
                                <Link to="/Explore" className="flex flex-row items-center gap-2 w-full">
                                    <img src="https://cdn-icons-png.freepik.com/512/6337/6337301.png" alt="Explore Icon" style={{ height: 28, width: 28 }} />
                                    <h2 className="font-extrabold text-lg">Feed</h2>
                                </Link>
                                <Link to="/Sell" className="flex flex-row items-center gap-2 w-full">
                                    <img src="https://cdn-icons-png.freepik.com/512/10337/10337579.png" alt="Sell Icon" style={{ height: 28, width: 28 }} />
                                    <h2 className="font-extrabold text-lg">Sell</h2>
                                </Link>
                                <Link to="/Chats" className="flex flex-row items-center gap-2 w-full">
                                    <img src="https://cdn-icons-png.freepik.com/512/1041/1041916.png" alt="Chat Icon" style={{ height: 28, width: 28 }} />
                                    <h2 className="font-extrabold text-lg">Chat</h2>
                                </Link>
                                <Link to="/Profile" className="flex flex-row items-center gap-2 w-full">
                                    {currentUser ? <img src={currentUser.photoURL} alt="Profile Picture" style={{ height: 28, width: 28, borderRadius: '100%' }} /> : <img src="https://cdn-icons-png.freepik.com/512/1177/1177568.png" alt="Profile Picture" style={{ height: 28, width: 28 }} />}
                                    <h2 className="font-extrabold text-lg">Profile</h2>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="main-container">
                        <div className="content-wrapper">
                            {bottomBarVisible <= 945 && <BackButton />}
                            <div className={`${bottomBarVisible <= 945 ? 'mb-28' : ''} mt-2`}>
                                <div className="content-wrapper-header flex justify-center align-center items-center text-center">
                                    <h1 className='font-extrabold text-3xl'>Refund Policy</h1>
                                </div>
                                <div className="content-section">
                                    <div className="content-section-title font-bold">No Refund Policy for Advertisement on Martpuram</div>
                                </div>
                                <div className="content-section">
                                    <div className="QuestionAnswers flex w-full h-full flex-col gap-2">
                                        <ol className="flex gap-2 flex-col">
                                            <li><span className="font-bold">Payment Acknowledgment:</span> By initiating payment for advertisement services on Martpuram, you acknowledge that you have thoroughly reviewed and understood our terms and conditions.</li>
                                            <li><span className="font-bold">Non-Refundable Fees:</span> All fees paid for advertisement services on Martpuram are non-refundable unless explicitly stated otherwise in a separate agreement signed by both parties.</li>
                                            <li><span className="font-bold">Service Fulfillment:</span> Martpuram commits to providing the agreed-upon advertisement services promptly upon receipt of payment. However, Martpuram reserves the right to refuse or cancel any advertisement request at its discretion.</li>
                                            <li><span className="font-bold">No Guarantee of Results:</span> Martpuram does not guarantee specific results or outcomes from advertisements placed on its platform. Factors such as audience engagement, market conditions, and other variables beyond Martpuram's control may influence the effectiveness of advertisements.</li>
                                            <li><span className="font-bold">Modification or Cancellation:</span> Requests for modification or cancellation of advertisement services must be submitted in writing to Martpuram. Martpuram will assess such requests on a case-by-case basis and may charge applicable fees for any changes requested after the advertisement has been processed.</li>
                                            <li><span className="font-bold">Intellectual Property Rights:</span> By placing an advertisement on Martpuram, you warrant that you have the necessary rights and permissions to use any content, including but not limited to text, images, and logos, included in the advertisement. Martpuram reserves the right to remove any advertisement that infringes upon the intellectual property rights of others.</li>
                                            <li><span className="font-bold">Indemnification:</span> You agree to indemnify and hold harmless Martpuram, its affiliates, and their respective officers, directors, employees, and agents from any claims, damages, liabilities, costs, or expenses arising out of or related to your advertisement or any breach of these terms and conditions.</li>
                                            <li><span className="font-bold">Policy Updates:</span> Martpuram reserves the right to update or modify this no refund policy for advertisement services at any time without prior notice. The most current version of the policy will be posted on the Martpuram website.</li>
                                        </ol>
                                    </div>
                                </div>
                                <div className="content-section">
                                    <div className="content-section-title font-bold">By proceeding with payment for advertisement services on Martpuram, you indicate your acceptance of and agreement to abide by the terms and conditions outlined in this no refund policy. If you have any questions or concerns regarding this policy, please contact Martpuram customer support for clarification before making payment.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="overlay-app" />
            </div>
            {bottomBarVisible <= 945 &&
                <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, backgroundColor: 'transparent' }} elevation={3}>
                    <BottomNavigation
                        className="backgroundColor: 'transparent',"
                        showLabels
                        value={-1}
                        onChange={(event, newValue) => {
                            setValue(newValue);
                            newValue === 0 ? navigate('/') : newValue === 1 ? navigate('/Explore') : newValue === 2 ? navigate('/Sell') : newValue === 3 ? navigate('/Chats') : newValue === 4 ? navigate('/Profile') : navigate('/')
                        }}
                    >
                        <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<HomeRoundedIcon />} label="Home" />
                        <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<SlideshowIcon />} label="Shorts" />
                        <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<AddCircleRoundedIcon />} label="Sell" />
                        <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<QuestionAnswerRoundedIcon />} label="Chat" />
                        {currentUser ? <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<img src={currentUser.photoURL} className='h-6 w-6 rounded-full' />} label="Manage" /> : <BottomNavigationAction sx={{ '&.Mui-selected': { color: '#8c52ff', }, '&': { minWidth: '0px', }, }} icon={<PersonRoundedIcon />} label="Profile" />}
                    </BottomNavigation>
                </Paper>
            }
        </>
    )
}

export default RefundPolicy