/* eslint-disable react-hooks/exhaustive-deps */
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import {
    useNavigate,
} from 'react-router-dom';
import gsap from 'gsap';
import { AuthContext } from '../context/AuthContext';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';
import swal from '@sweetalert/with-react';

const Onboarding = (props) => {
    const { currentUser } = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        gsap.fromTo(".content-wrapper", { opacity: 0 }, { opacity: 1, duration: 2 });
    }, []);

    const [state, setState] = useState('');
    const [city, setCity] = useState('');

    const handleChangeState = (e) => {
        setState(e.target.value);
    };

    const handleChangeCity = (e) => {
        setCity(e.target.value);
    };

    const HandleSubmit = async () => {
        if (state !== '' && city !== '') {
            await updateDoc(doc(db, "Users", currentUser.uid), {
                state,
                city,
                NewUser: false,
            });
            swal('Information Saved', '', 'success', {
                buttons: {
                    confirm: {
                        text: "OK",
                        value: true,
                        visible: true,
                        className: "bg-[#8c52ff]",
                        closeModal: true
                    }
                }
            })
            navigate('/')
        }
    }

    // useEffect(() => {
    //     const statesAndUTs = [
    //         'Andhra Pradesh', 'Arunachal Pradesh', 'Assam', 'Bihar', 'Chhattisgarh',
    //         'Goa', 'Gujarat', 'Haryana', 'Himachal Pradesh', 'Jharkhand',
    //         'Karnataka', 'Kerala', 'Madhya Pradesh', 'Maharashtra', 'Manipur',
    //         'Meghalaya', 'Mizoram', 'Nagaland', 'Odisha', 'Punjab',
    //         'Rajasthan', 'Sikkim', 'Tamil Nadu', 'Telangana', 'Tripura',
    //         'Uttar Pradesh', 'Uttarakhand', 'West Bengal',
    //         'Andaman and Nicobar Islands', 'Chandigarh', 'Dadra and Nagar Haveli and Daman and Diu',
    //         'The Government of NCT of Delhi', 'Jammu and Kashmir', 'Ladakh', 'Lakshadweep', 'Puducherry'
    //     ];
    //     setDoc(doc(db, "States", 'States'), {
    //         States: statesAndUTs,
    //     });
    // }, [])

    // const [cities, setCities] = useState([]);
    // const [cities1, setCities1] = useState([]);

    // useEffect(() => {
    //     fetch('https://raw.githubusercontent.com/dr5hn/countries-states-cities-database/master/cities.json')
    //         .then(response => response.json())
    //         .then(data => setCities(data));
    // }, []);

    // useEffect(() => {
    //     const city = []
    //     cities.filter((item, idx) => item.country_name === "India").map(item => (city.push(item)))
    //     setCities1(city)
    // }, [cities])

    // useEffect(() => {
    //     cities1.length > 0 && setDoc(doc(db, "Cities", 'Cities'), {
    //         Cities: cities1,
    //     });
    //     cities1.length > 0 && console.log(cities1);
    // }, [cities1])

    useEffect(() => {
        if (!currentUser) {
            navigate('/Auth')
        }
    }, [currentUser])

    useEffect(() => {
        if (props.UserData !== null) {
            if (currentUser && props.UserData !== null && props.UserData.NewUser === true) {
                navigate('/Onboarding');
            }
        }
    }, [currentUser, props.UserData])

    return (
        <>
            <div className="app">
                <div className="OnboardingContentSection2 p-5">
                    <div className="InnerContentSection rounded-2xl flex flex-col pt-2 pb-2 pl-5 pr-5">
                        <div className="HeadingOnboarding w-full p-2 flex justify-center items-center text-center">
                            <span className="TopHeading font-extrabold text-2xl uppercase">Additional Information</span>
                        </div>
                        <div className="StateSelection w-full mt-10 p-2 flex flex-col justify-start items-center">
                            <span className="TopHeading w-full flex justify-start text-start items-start font-semibold text-lg uppercase">STATE / राज्य</span>
                            <FormControl variant="filled" sx={{ m: 1, minWidth: '100%' }}>
                                <InputLabel id="demo-simple-select-standard-label">CHOOSE YOUR STATE / राज्य चुने</InputLabel>
                                <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={state}
                                    onChange={handleChangeState}
                                    label="CHOOSE YOUR STATE / राज्य चुने"
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    {props.Locations !== null && props.Locations.States.length > 0 && props.Locations.States?.sort((a, b) => a.localeCompare(b)).map(city => (<MenuItem value={city}>{city}</MenuItem>))}
                                </Select>
                            </FormControl>
                        </div>
                        <div className="CitySelection w-full mt-5 p-2 flex flex-col justify-start items-center">
                            <span className="TopHeading w-full flex justify-start text-start items-start font-semibold text-lg uppercase">CITY / शहर</span>
                            <FormControl variant="filled" sx={{ m: 1, minWidth: '100%' }}>
                                <InputLabel id="demo-simple-select-standard-label">CHOOSE YOUR CITY / शहर चुने</InputLabel>
                                <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={city}
                                    onChange={handleChangeCity}
                                    label="CHOOSE YOUR CITY / शहर चुने"
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    {state !== '' && props.Locations.Cities.length > 0 && props.Locations.Cities?.filter((item, idx) => item.state_name === state).sort((a, b) => a.name.localeCompare(b.name)).map(city => (<MenuItem value={city.name}>{city.name}</MenuItem>))}
                                </Select>
                            </FormControl>
                        </div>
                        <div className="SubmitBTNInfo w-full flex justify-center items-center text-center pt-2 pb-2 pr-5 pl-5 rounded-2xl mt-5 bg-blue-500 font-extrabold text-xl mb-2 cursor-pointer" onClick={HandleSubmit}>
                            SUBMIT
                        </div>
                    </div>
                </div>
                <div className="overlay-app" />
            </div>
        </>
    )
}

export default Onboarding